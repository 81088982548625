import React, { Component } from "react";
import {
  Col,
  Card,
  CardBody,
  Media,
  Progress,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import * as getSelectedData from "../../Api/Salons/getSelectedSalon";
import { convertTime } from "../../helpers/convertTime";
import DatePicker from "react-datepicker";
import "./dashboard.scss";

class MiniWidgets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trading_goal: 0,
      trading_progress: 0,
      trading_name: "Cel nie dodany",
      start: null,
      end: null,
      btnDanger1: false,
      dateType: "",
    };
    this.getSalonData = this.getSalonData.bind(this);
    this.handleStart = this.handleStart.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
  }

  handleStart = (date) => {
    this.setState({ start: date, dateType: "" });
    if (this.state.end) {
      this.props.handleStartFilter(date, this.state.end);
    }
  };
  handleEnd = (date) => {
    this.setState({ end: date, dateType: "" });
    if (this.state.start) {
      this.props.handleStartFilter(this.state.start, date);
    }
  };

  getSalonData() {
    const storedUserSalon = localStorage.getItem("salon_name");
    getSelectedData.getSalon(storedUserSalon).then((response) => {
      response
        .json()
        .then((data) => {
          if (
            data?.error &&
            (data?.error === "invalid_grant" ||
              data?.error === "error_description")
          ) {
            localStorage.clear();
          } else {
            const slotLength = response?.slot_length || 15;
            localStorage.setItem("salon_slot_min", data.slot_min_time);
            localStorage.setItem("salon_slot_max", data.slot_max_time);
            localStorage.setItem("salon_slot_interval", slotLength);
            localStorage.setItem(
              "salon_min_reservation_length",
              data.min_reservation_length,
            );
            this.setState({
              trading_goal: data.trading_goal,
              trading_progress: data.trading_progress,
              trading_name: data.trading_name,
            });
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    });
  }

  getFirstAndLastYearDay = (customDate = null) => {
    let today = customDate || new Date();
    const firstDay = new Date(today.getFullYear(), 0, 1);
    const lastDay = new Date(today.getFullYear(), 11, 31);

    this.setState({
      start: new Date(firstDay),
      end: new Date(lastDay),
      dateType: "year",
    });

    this.props.handleStartFilter(new Date(firstDay), new Date(lastDay));
  };

  getFirstAndLastMonthDay = (customDate = null) => {
    let today = customDate || new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    this.setState({
      start: new Date(firstDay),
      end: new Date(lastDay),
      dateType: "month",
    });

    this.props.handleStartFilter(new Date(firstDay), new Date(lastDay));
  };

  getMondayAndSunday = (customDate = null) => {
    let today = customDate || new Date();
    let day = today.getDay(),
      diff = today.getDate() - day + (day === 0 ? -6 : 1);
    const monday = new Date(today.setDate(diff));
    let sunday = new Date(today.setDate(diff));
    sunday = sunday.setDate(sunday.getDate() + 6);

    this.setState({
      start: new Date(monday),
      end: new Date(sunday),
      dateType: "week",
    });

    this.props.handleStartFilter(new Date(monday), new Date(sunday));
  };

  handleArrowDateFilter = (buttonType) => {
    const { dateType, start, end } = this.state;
    let startDate = start;
    let endDate = end;

    if (dateType === "day") {
      const numberToAdd = buttonType === "left" ? -1 : 1;
      startDate.setDate(startDate.getDate() + numberToAdd);
      endDate.setDate(endDate.getDate() + numberToAdd);
      this.setState({
        start: startDate,
        end: endDate,
      });

      this.props.handleStartFilter(startDate, endDate);
    }

    if (dateType === "week") {
      const numberToAdd = buttonType === "left" ? -7 : 7;
      return this.getMondayAndSunday(
        new Date(startDate.setDate(startDate.getDate() + numberToAdd)),
      );
    }

    if (dateType === "month") {
      const numberToAdd = buttonType === "left" ? -2 : 35;
      return this.getFirstAndLastMonthDay(
        new Date(startDate.setDate(startDate.getDate() + numberToAdd)),
      );
    }

    if (dateType === "year") {
      const numberToAdd = buttonType === "left" ? -1 : 370;
      return this.getFirstAndLastYearDay(
        new Date(startDate.setDate(startDate.getDate() + numberToAdd)),
      );
    }
  };

  componentDidMount() {
    this.getSalonData();
  }

  renderTitle(renderDatePicker) {
    if (renderDatePicker) {
      return (
        <Col sm={12} className="statistic-title-wrapper">
          <span className="statistic-title"> {this.props.title || ""}</span>
          <Dropdown
            isOpen={this.state.btnDanger1}
            toggle={() =>
              this.setState({
                btnDanger1: !this.state.btnDanger1,
              })
            }
            direction="right down"
            className="statistic-date-wrapper"
          >
            <DropdownToggle tag="button" className="btn btn-white-filter ">
              Wybierz zakres <i className="mdi mdi-chevron-down" />
            </DropdownToggle>
            <DropdownMenu>
              <div className="date-picker-wrapper-dropdown">
                <i
                  className={`mdi mdi-chevron-left font-size-24 ${
                    this.state.dateType !== "" ? "arrow-active" : ""
                  }`}
                  onClick={() => {
                    this.handleArrowDateFilter("left");
                  }}
                />
                <div className="date-picker-items">
                  <div>
                    <label>Od</label>
                    <DatePicker
                      showIcon
                      selected={this.state.start}
                      className="form-control"
                      onChange={this.handleStart}
                      locale={"pl"}
                      showMonthDropdown
                      maxDate={
                        this.state.end ? new Date(this.state.end) : new Date()
                      }
                      icon="fa fa-calendar"
                      dateFormat="yyyy-MM-dd"
                    />
                  </div>
                  <div>
                    <label>Do</label>
                    <DatePicker
                      showIcon
                      selected={this.state.end}
                      onChange={this.handleEnd}
                      showMonthDropdown
                      className="form-control"
                      locale={"pl"}
                      minDate={
                        this.state.start ? new Date(this.state.start) : null
                      }
                      dateFormat="yyyy-MM-dd"
                      popperPlacement="bottom-end"
                    />
                  </div>
                </div>
                <i
                  className={`mdi mdi-chevron-right font-size-24 ${
                    this.state.dateType !== "" ? "arrow-active" : ""
                  }`}
                  onClick={() => {
                    this.handleArrowDateFilter("right");
                  }}
                />
              </div>
              <div
                className={`${
                  this.state.dateType === "day" ? "active" : ""
                } btn-dropdown-filter`}
                onClick={() => {
                  this.setState({
                    start: new Date(),
                    end: new Date(),
                    dateType: "day",
                  });
                  this.props.handleStartFilter(new Date(), new Date());
                }}
              >
                <span>Dzisiaj</span>
              </div>
              <div
                className={`${
                  this.state.dateType === "week" ? "active" : ""
                } btn-dropdown-filter`}
                onClick={() => {
                  this.getMondayAndSunday();
                }}
              >
                <span>Tydzień</span>
              </div>
              <div
                className={`${
                  this.state.dateType === "month" ? "active" : ""
                } btn-dropdown-filter`}
                onClick={() => {
                  this.getFirstAndLastMonthDay();
                }}
              >
                <span>Miesiąc</span>
              </div>
              <div
                className={`${
                  this.state.dateType === "year" ? "active" : ""
                } btn-dropdown-filter`}
                onClick={() => {
                  this.getFirstAndLastYearDay();
                }}
              >
                <span>Rok</span>
              </div>
            </DropdownMenu>
          </Dropdown>
          {/*<div>*/}
          {/*  <div className="date-picker-wrapper">*/}
          {/*    <label>Od</label>*/}
          {/*    <DatePicker*/}
          {/*      showIcon*/}
          {/*      selected={this.state.start}*/}
          {/*      className="form-control"*/}
          {/*      onChange={this.handleStart}*/}
          {/*      locale={"pl"}*/}
          {/*      showMonthDropdown*/}
          {/*      maxDate={this.state.end ? new Date(this.state.end) : new Date()}*/}
          {/*      icon="fa fa-calendar"*/}
          {/*      dateFormat="yyyy-MM-dd"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*  <div className="date-picker-wrapper">*/}
          {/*    <label>Do</label>*/}
          {/*    <DatePicker*/}
          {/*      showIcon*/}
          {/*      selected={this.state.end}*/}
          {/*      onChange={this.handleEnd}*/}
          {/*      showMonthDropdown*/}
          {/*      className="form-control"*/}
          {/*      locale={"pl"}*/}
          {/*      minDate={this.state.start ? new Date(this.state.start) : null}*/}
          {/*      maxDate={new Date()}*/}
          {/*      dateFormat="yyyy-MM-dd"*/}
          {/*      popperPlacement="bottom-end"*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
        </Col>
      );
    }

    return (
      <Col sm={12}>
        <span className="statistic-title"> {this.props.title || ""}</span>
      </Col>
    );
  }

  renderVisits() {
    return (
      <Col sm={6} md={3}>
        <Card>
          <CardBody>
            <Media>
              <Media body className="overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">Liczba Wizyt</p>
                <h4 className="mb-0">
                  {this.props.reports.visits_total_count || 0}
                </h4>
              </Media>
              <div className="text-primary">
                <i className={"ri-user-line font-size-24"} />
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderTimeVisits() {
    return (
      <Col sm={6} md={3}>
        <Card>
          <CardBody>
            <Media>
              <Media body className="overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">Czas Wizyt</p>
                <h4 className="mb-0">
                  {convertTime(this.props.reports.visits_total_time || 0)}
                </h4>
              </Media>
              <div className="text-primary">
                <i className={"ri-timer-line font-size-24"} />
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderTotalPrice() {
    return (
      <Col sm={6} md={3}>
        <Card>
          <CardBody>
            <Media>
              <Media body className="overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">
                  Przychód Brutto
                </p>
                <h4 className="mb-0">
                  {this.props.reports.services_value_brutto || 0} zł
                </h4>
              </Media>
              <div className="text-primary">
                <i className={"ri-wallet-line font-size-24"} />
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderTotalPriceNet() {
    return (
      <Col sm={6} md={3}>
        <Card>
          <CardBody>
            <Media>
              <Media body className="overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">
                  Przychód Netto
                </p>
                <h4 className="mb-0">
                  {this.props.reports.services_value_netto || 0} zł
                </h4>
              </Media>
              <div className="text-primary">
                <i className={"ri-wallet-line font-size-24"} />
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderCustomersTotal() {
    return (
      <Col sm={6} md={3}>
        <Card>
          <CardBody>
            <Media>
              <Media body className="overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">
                  Ilość klientów
                </p>
                <h4 className="mb-0">
                  {this.props.state.customersTotal || `0 `}
                </h4>
              </Media>
              <div className="text-primary">
                <i className={" ri-user-line font-size-24"} />
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderEmployeesTotal() {
    const employeesTotal = this.props.reports.employees
      ? Object.values(this.props.reports.employees).length
      : 0;
    return (
      <Col sm={6} md={3}>
        <Card>
          <CardBody>
            <Media>
              <Media body className="overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">
                  Ilość pracowników
                </p>
                <h4 className="mb-0">{employeesTotal || `0 `}</h4>
              </Media>
              <div className="text-primary">
                <i className={" ri-user-line font-size-24"} />
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderMonthReport() {
    return (
      <Col sm={6} md={3}>
        <Card>
          <CardBody>
            <Media>
              <Media body className="overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">
                  Ilość wizyt w tym miesiącu
                </p>
                <h4 className="mb-0">
                  {this.props.thisMonthReport?.visits_total_count || 0}
                </h4>
              </Media>
              <div className="text-primary">
                <i className={"ri-user-line font-size-24"} />
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderNextMonthReport() {
    return (
      <Col sm={6} md={3}>
        <Card>
          <CardBody>
            <Media>
              <Media body className="overflow-hidden">
                <p className="text-truncate font-size-14 mb-2">
                  Planowana ilość wizyt w następnym miesiącu
                </p>
                <h4 className="mb-0">
                  {this.props.nextMonthReport?.visits_total_count || 0}
                </h4>
              </Media>
              <div className="text-primary">
                <i className={"ri-user-line font-size-24"} />
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderTradingReport() {
    const { trading_progress, trading_goal } = this.state;
    const percentShow = (trading_progress * 100) / trading_goal;
    let percent = Math.round(percentShow * 100) / 100;
    return (
      <Col sm={6} md={3}>
        <Card>
          <CardBody className="card-body">
            <p>Cele marketingowe</p>
            <div className="displayFlex spaceBetween">
              <span>{this.state.trading_name}</span>
              <span>
                {" "}
                {this.state.trading_progress}zł / {this.state.trading_goal}zł
              </span>
            </div>
            <div className="">
              <Progress color="primary" value={isNaN(percent) ? 0 : percent}>
                {isNaN(percent) ? 0 : percent} %
              </Progress>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }

  render() {
    const { trading_progress, trading_goal } = this.state;
    const percentShow = (trading_progress * 100) / trading_goal;
    let percent = Math.round(percentShow * 100) / 100;

    const renderReports = () => {
      switch (this.props.title) {
        case "Sfinalizowane":
          return (
            <>
              {this.renderTitle(true)}
              {this.renderVisits()}
              {this.renderTimeVisits()}
              {this.renderTotalPriceNet()}
              {this.renderTotalPrice()}
            </>
          );
        case "Niesfinalizowane":
          return (
            <>
              {this.renderTitle(false)}
              {this.renderVisits()}
              {this.renderTimeVisits()}
              {this.renderTotalPriceNet()}
              {this.renderTotalPrice()}
            </>
          );
        case "Prognozy":
          return (
            <>
              {this.renderTitle(false)}
              {this.renderVisits()}
              {this.renderTimeVisits()}
              {this.renderTotalPriceNet()}
              {this.renderTotalPrice()}
              {/*{this.renderMonthReport()}*/}
              {/*{this.renderNextMonthReport()}*/}
              {/*<Col sm={6} />*/}
              {/*{this.renderTradingReport()}*/}
            </>
          );
        case "Ogólne":
          return (
            <>
              {this.renderTitle(false)}
              {this.renderMonthReport()}
              {this.renderNextMonthReport()}
              {this.renderCustomersTotal()}
              {this.renderEmployeesTotal()}
              {/*<Col sm={6} />*/}
              {/*{this.renderTradingReport()}*/}
            </>
          );
        default:
          break;
      }
    };

    return (
      <React.Fragment>
        {this.props.reports ? renderReports() : <></>}
      </React.Fragment>
    );
  }
}

export default MiniWidgets;
