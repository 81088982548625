import React, { Component, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardHeader,
  Col,
  Container,
  Row,
  Media,
} from "reactstrap";
import { DatePicker, Space } from "antd";
import { Button } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import "moment/locale/pl";
import locale from "antd/lib/date-picker/locale/pl_PL";

//Api
import * as getVisits from "../../Api/Visits/getVisits";

export const PublicServiceCart = (props) => {
  const [selectedDay, setSelectedDay] = useState(moment());
  const [visitPeriods, setVisitPeriods] = useState([]);

  function disabledDate(current) {
    return current && current.valueOf() < new Date().setHours(0, 0, 0, 0);
  }

  useEffect(() => {
    if (props.selectedEmployee) {
      let date_start = selectedDay.startOf("day").format("YYYY-MM-DD HH:mm:ss");
      let date_end = selectedDay.endOf("day").format("YYYY-MM-DD HH:mm:ss");
      getVisits
        .getPublicVisitsByEmployee(
          props.selectedSalonName,
          props.selectedEmployee.value.id,
          date_start,
          date_end,
        )
        .then((response) => response.json())
        .then((visitsData) => {
          buildAvailablePeriods(visitsData);
        });
    }
  }, [selectedDay, props.selectedEmployee]);

  function buildAvailablePeriods(unavailablePeroids) {
    let appointments = [];
    let startOfWorkingDay = moment(selectedDay.toDate())
      .set("hour", 8)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);
    let endOfWorkingDay = moment(selectedDay.toDate())
      .set("hour", 16)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);
    let availableAppointment = {
      start: moment(startOfWorkingDay),
      end: moment(startOfWorkingDay).add(
        props.selectedService.service_time_max,
        "minute",
      ),
    };

    let temp = moment(selectedDay.toDate())
      .set("hour", 8)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);

    while (availableAppointment.end.isBefore(endOfWorkingDay)) {
      if (isPeriodIsAvailable(availableAppointment, unavailablePeroids))
        appointments.push({
          start: availableAppointment.start,
          end: availableAppointment.end,
        });

      console.log(temp);
      temp = moment(temp).add(15, "minute");
      availableAppointment = {
        start: moment(temp),
        end: moment(temp).add(props.selectedService.service_time_max, "minute"),
      };
    }
    setVisitPeriods(appointments);
  }

  function calculateRoundDate(date) {
    let coeff = 1000 * 60 * 15;
    return new Date(Math.round(date.getTime() / coeff) * coeff);
  }

  function isPeriodIsAvailable(date, unavailablePeroids) {
    for (const unavailablePeroid of unavailablePeroids) {
      if (
        date.start.isBetween(
          moment(unavailablePeroid.appointment_at),
          moment(unavailablePeroid.appointment_at_end_max),
          undefined,
          "[)",
        ) ||
        date.end.isBetween(
          moment(unavailablePeroid.appointment_at),
          moment(unavailablePeroid.appointment_at_end_max),
          undefined,
          "(]",
        )
        //  ||moment(unavailablePeroid.appointment_at).isBetween(date.start,date.end, undefined, '(]')
        //  ||moment(unavailablePeroid.appointment_at_end_max).isBetween(date.start,date.end, undefined, '[)')
      ) {
        return false;
      }
    }
    return true;
  }

  return (
    <>
      <div className="container added-service-wrapper">
        <Card outline color="primary" className="border background-white">
          <CardHeader className="bg-transparent">
            <h5 className="my-0 text-primary text-capitalize">
              <i className=" ri-calendar-check-fill mr-3" />
              {props.selectedService.title}
            </h5>
          </CardHeader>
          <CardBody>
            <Media className="mt-3 w-50">
              <Select
                className="w-100"
                value={props.selectedEmployee}
                onChange={(selectedEmployee) => {
                  props.handleSelectEmployee(selectedEmployee);
                }}
                options={
                  Array.isArray(props.salonEmployees)
                    ? props.salonEmployees.map((employeeData) => ({
                        value: employeeData,
                        label: `${employeeData.first_name} ${employeeData.last_name}`,
                      }))
                    : null
                }
                classNamePrefix="select2-selection"
                isSearchable={false}
              />
            </Media>
            <CardTitle className="mt-0 text-right">
              Kwota: {props.selectedService.price} zł
            </CardTitle>
            <CardTitle className="mt-0 text-right">
              Czas usługi: {props.selectedService.service_time_max}min
            </CardTitle>
          </CardBody>
        </Card>
      </div>
      <div className="container date-wrapper">
        <div className="d-flex flex-column justify-content-between align-items-center date-box">
          <div className="datepicker-header">
            <h5>Wybierz datę</h5>
            <DatePicker
              disabledDate={disabledDate}
              locale={locale}
              onChange={(date, dateString) => setSelectedDay(date)}
              className="datepicker-input"
            />
          </div>
          <div className="date-hours-box">
            <h5 className="datepicker-header">Wybierz godzinę</h5>
            <ul>
              {visitPeriods.length > 0
                ? visitPeriods.map((period, idx) => (
                    <li
                      key={idx}
                      className="row justify-content-between align-items-center py-2"
                    >
                      <div className="col">
                        <span>{`${period.start.format(
                          "HH:mm",
                        )} - ${period.end.format("HH:mm")}`}</span>
                      </div>
                      <div className="col col-sm-3">
                        <Button onClick={() => props.selectVisitDate(period)}>
                          WYBIERZ
                        </Button>
                      </div>
                    </li>
                  ))
                : "Brak Dostępnych Terminów"}
            </ul>
          </div>
        </div>
      </div>
      ;
    </>
  );
};
