import React, { componentDidMount, Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import $ from "jquery";
import "./contact.module.scss";
import axios from "axios";
import ContactTable from "../ContactTable/ContactTable";
import GetCustomers from "../../components/GetCustomers/GetCustomers";
import * as getCustomers from "../../Api/Customers/getCustomers";
import * as postCustomers from "../../Api/Customers/postCustomers";
import * as deleteCustomers from "../../Api/Customers/deleteCustomers";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modal_standard: false,
      modal_large: false,
      modal_xlarge: false,
      modal_small: false,
      modal_center: false,
      modal_scroll: false,
      modal_static: false,
      breadcrumbItems: [
        { title: "UI Elements", link: "#" },
        { title: "Modals", link: "#" },
      ],
      fnm: false,
      lnm: false,
      unm: false,
      city: false,
      stateV: false,

      firstName: "",
      lastName: "",
      phoneNumber: "",
      location: "",
      date: "",
      email: "",
      checkValid: false,

      // TODO ???
      host: "http://visitio-dev.convertio.pl",
      hostApi: "http://visitio-dev.convertio.pl/backend/public",
      salonName: "",
      customersList: null,
      addCustomer: null,

      // EDIT OPTION

      editId: null,
      editData: null,
      editCustomerFirstName: null,
      editCustomerLastName: null,
      editCustomerPhoneNumber: null,
      editCustomerLocality: null,
      editCustomerBirthdate: null,
      editCustomerEmail: null,
    };

    this.tog_standard = this.tog_standard.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeHandeler.bind(this);
    this.handleChange = this.handleChange.bind(this);

    // FUNKCJE API
    this.postData = this.postData.bind(this);
  }

  handleChange(event) {
    this.setState({
      firstName: event.target.value,
      lastName: event.target.value,
      phoneNumber: event.target.value,
      location: event.target.value,
      date: event.target.value,
      email: event.target.value,
    });
  }

  addCustomerFn = (e) => {
    // e.preventDefault();
    // localStorage.setItem('access_token', 'ZWE1YWQyOTEwZmFlYzA5ODM1MDhkMzUyZjhjOTNmNWY5YTVjMjVhODExYWY3MzM5YWFhODY3YjMxOTZkOThiOQ');
    // localStorage.setItem('salonName','pbuklad');
    /***** Host *****/

    this.state.salonName = localStorage.getItem("salonName");

    const fnm = document.getElementById("validationFirstname");
    const lnm = document.getElementById("validationLastname");
    const phone = document.getElementById("validationPhonenumber");
    const city = document.getElementById("validationCity");
    const date = document.getElementById("validationDate");
    const email = document.getElementById("validationEmail");

    document.getElementById("customerForm").classList.add("was-validated");

    const data = {
      firstName: fnm.value,
      lastName: lnm.value,
      phoneNumber: phone.value,
      locality: city.value,
      birthdate: date.value,
      email: email.value,
    };

    if (fnm === "") {
      this.setState({ checkValid: false });
    } else if (lnm === "") {
      this.setState({ checkValid: false });
    } else if (city === "") {
      this.setState({ checkValid: false });
    } else if (date === "") {
      this.setState({ checkValid: false });
    } else if (email === "") {
      this.setState({ checkValid: false });
    } else if (phone === "") {
      this.setState({ checkValid: false });
    } else {
      // const regex = /([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})/;
      // const regexValue = phone.value.match(regex);
      // if (!regexValue || regexValue[0] !== phone.value) {
      //     alert('Numer Telefonu jest nieprawidłowy!');
      // }
      // else{

      // }
      this.setState({ checkValid: true });
    }

    const d1 = document.getElementsByName("validate");

    for (let i = 0; i < d1.length; i++) {
      d1[i].style.display = "block";
    }
    console.log(data);
    if (this.state.checkValid) {
      //this.postData(`${this.state.hostApi}/api/v1/${this.state.salonName}/customers`, data)
      postCustomers
        .registerCustomer(this.state.salonName, data)
        .then(async (response) => {
          if (response.status === 201) {
            await this.getCustomers();
            this.tog_standard();
          } else if (response.status === 401) {
            this.unauthorizedException();
            return null;
          }
        });
    }
    // else{
    //     alert('Błędne Dane');
    // }
  };
  handleSubmit(e) {
    this.addCustomerFn();
  }

  //for change tooltip display propery
  changeHandeler(event, eleId) {
    if (event.target.value !== "")
      document.getElementById(eleId).style.display = "none";
    else document.getElementById(eleId).style.display = "block";
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }
  removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  show() {
    this.setState({ visible: true });
  }
  hide() {
    this.setState({ visible: false });
  }

  //   ----------------------------------

  // response code 401
  unauthorizedException() {
    localStorage.clear();
    window.location.replace(`${this.state.host}/login`);
  }

  async getData() {
    await this.getCustomers();
  }

  async getCustomers() {
    // await fetch(`${this.state.hostApi}/api/v1/${this.state.salonName}/customers`, {
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json',
    //         'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    //     }
    // })
    getCustomers.getSalonCustomers(this.state.salonName).then((response) => {
      // console.log(response)
      response.json().then((data) => {
        if (response.status === 200) {
          $("#customersList").empty();
          $("#customersList").html(`<tr>
                                                        <th>Imię</th>
                                                        <th>Nazwisko</th>
                                                        <th>Numer telefonu</th>
                                                        <th>Lokalizacja</th>
                                                        <th>Data urodzenia</th>
                                                        <th>Email</th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>`);
          data.forEach((event) => {
            const tr = document.createElement("tr");
            tr.dataset.id = event.id;
            tr.innerHTML = `<th>${event.first_name}</th>
                                                <td>${event.last_name}</td>
                                                <td>${
                                                  event.phone_number !==
                                                  undefined
                                                    ? event.phone_number
                                                    : ""
                                                }</td>
                                                <td>${
                                                  event.locality !== undefined
                                                    ? event.locality
                                                    : ""
                                                }</td>
                                                <td>${
                                                  event.birthdate !== undefined
                                                    ? new Date(event.birthdate)
                                                        .toISOString()
                                                        .split("T")[0]
                                                    : ""
                                                }</td>
                                                <td>${
                                                  event.email !== undefined
                                                    ? event.email
                                                    : ""
                                                }</td>
                                                <!--<td></td><td></td>-->
                                                <td>
                                                    <button type="button" class="edit" aria-label="Edit" data-toggle="modal" data-target="#modalPatchCustomer">
                                                        <span aria-hidden="true">&#9998;</span>
                                                    </button>
                                                </td>
                                                <td>
                                                    <button type="button" class="close" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </td>`;

            // Update customer data
            tr.querySelector(".edit").addEventListener("click", () => {
              this.state.editCustomFirstName.value = event.first_name;
              this.state.editCustomLastName.value = event.last_name;
              this.state.editCustomPhoneNumber.value =
                event.phone_number !== undefined ? event.phone_number : "";
              this.state.editCustomLocality.value =
                event.locality !== undefined ? event.locality : "";
              this.state.editCustomBirthdate.value =
                event.birthdate !== undefined
                  ? new Date(event.birthdate).toISOString().split("T")[0]
                  : "";
              this.state.editCustomEmail.value =
                event.email !== undefined ? event.email : "";

              this.state.editId = event.id;
              this.state.editData = {
                firstName: this.state.editCustomFirstName.value,
                lastName: this.state.editCustomLastName.value,
                phoneNumber: this.state.editCustomPhoneNumber.value,
                locality: this.state.editCustomLocality.value,
                birthdate: this.state.editCustomBirthdate.value,
                email: this.state.editCustomEmail.value,
              };
            });

            // Remove customer
            tr.querySelector(".close").addEventListener("click", () => {
              //this.deleteData(`${this.state.hostApi}/api/v1/${this.state.salonName}/customers/${event.id}`)
              deleteCustomers
                .deleteCustomer(this.state.salonName, event.id)
                .then(async (response) => {
                  if (response.status === 200) {
                    this.state.customersList.removeChild(tr);
                  } else if (response.status === 401) {
                    this.unauthorizedException();
                    return null;
                  }
                });
            });

            this.state.customersList.appendChild(tr);
          });
        } else if (response.status === 401) {
          this.unauthorizedException();
          return null;
        }
      });
    });
  }

  async postData(url = "", data = {}) {
    // Default options are marked with *
    return await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
  }

  async patchData(url = "", data = {}) {
    // Default options are marked with *
    return await fetch(url, {
      method: "PATCH", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
  }

  async deleteData(url = "", data = {}) {
    // Default options are marked with *
    return await fetch(url, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  componentDidMount() {
    /***** Host *****/
    this.state.salonName = localStorage.getItem("salonName");

    this.state.customersList = document.querySelector("#customersList");
    this.state.addCustomer = document.querySelector("#add_new_customer");

    this.state.editCustomerFirstName = document.querySelector(
      "#editCustomerFirstName",
    );
    this.state.editCustomerLastName = document.querySelector(
      "#editCustomerLastName",
    );
    this.state.editCustomerPhoneNumber = document.querySelector(
      "#editCustomerPhoneNumber",
    );
    this.state.editCustomerLocality = document.querySelector(
      "#editCustomerLocality",
    );
    this.state.editCustomerBirthdate = document.querySelector(
      "#editCustomerBirthdate",
    );
    this.state.editCustomerEmail = document.querySelector("#editCustomerEmail");

    this.getData();
  }

  render() {
    return (
      <>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <ContactTable />
                {/* <GetCustomers/> */}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default Contact;
