import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import * as getTaxes from "../../Api/Taxes/getTaxes";
import * as deleteServiceCategories from "../../Api/Services/Categories/deleteServiceCategories";
import * as getServiceCategories from "../../Api/Services/Categories/getServiceCategories";
import * as patchServiceCategories from "../../Api/Services/Categories/patchServiceCategories";
import * as postServiceCategories from "../../Api/Services/Categories/postServiceCategories";
import Swal from "sweetalert2";
import { MDBBtn, MDBDataTable } from "mdbreact";
import translations from "../../helpers/translations/pl";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ToastNotifications from "../ToastNotification/ToastNotification";

const Categories = () => {
  const salon_name = localStorage.getItem("salon_name");
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [taxes, setTaxes] = useState([]);
  const [categoryForm, setCategoryForm] = useState({
    name: "",
    service_category: "",
    tax: "",
    newCategory: true,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = () => {
    getServiceCategories
      .getSalonServiceCategories(salon_name)
      .then((response) => response.json())
      .then((data) => {
        setCategories(
          data.map((category) => ({
            ...category,
            service_category: category.service_category
              ? category.service_category
              : "",
            tax: category.tax ? category.tax : "",
          })),
        );
      })
      .catch(() => {
        ToastNotifications(
          "error",
          "Spróbuj ponownie później",
          "Wystąpił błąd",
        );
      });
    getTaxes
      .getAllTaxes()
      .then((response) => response.json())
      .then((data) => setTaxes(data))
      .catch(() => {
        ToastNotifications(
          "error",
          "Spróbuj ponownie później",
          "Wystąpił błąd",
        );
      });
  };

  useEffect(() => {
    let isMounted = true;
    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const deleteSalonServiceCategories = (id) => {
    deleteMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Zmiany będą nieodwracalne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteServiceCategories
            .deleteServiceCategory(salon_name, id)
            .then((response) => {
              if (response.ok) {
                setCategories(categories.filter((cat) => cat.id !== id));
                ToastNotifications(
                  "success",
                  "Kategoria została usunięta pomyślnie.",
                  "Sukces!",
                );
              }
            });
        }
      })
      .catch(() => {
        ToastNotifications(
          "error",
          "Spróbuj ponownie później",
          "Wystąpił błąd",
        );
      });
  };

  const editCategory = (categoryRow) => {
    let categoryOption = categories.find(
      (selectedOption) => selectedOption.id === categoryRow.id,
    );

    setCategoryForm({
      id: categoryRow.id,
      name: categoryRow.name,
      service_category: categoryOption,
      tax: taxes.find((tax) => tax.id === categoryRow.tax.id),
      newCategory: false,
    });
    handleOpen();
  };

  const addCategory = () => {
    setCategoryForm({
      name: "",
      service_category: "",
      tax: null,
      newCategory: true,
    });
    handleOpen();
  };

  const dataValue = () => {
    return {
      columns: [
        {
          label: "Nazwa",
          field: "name",
          sort: "asc",
          width: 300,
        },
        {
          label: "Podatek",
          field: "tax",
          sort: "asc",
          width: 300,
        },
        {
          label: "Edytuj",
          sort: "disable",
          field: "edit",
          sortIconBefore: false,
          width: 100,
        },
        {
          label: "Usuń",
          sort: false,
          field: "delete",
          width: 100,
        },
      ],
      rows: [
        ...categories.map((data) => ({
          name: data.name,
          tax: `${data.tax.value}%`,
          edit: (
            <MDBBtn
              onClick={() => {
                editCategory(data);
              }}
              position="center"
              color="info"
              size="sm"
            >
              <i className="ri-edit-2-fill" />
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              onClick={() => {
                deleteSalonServiceCategories(data.id);
              }}
              position="center"
              color="danger"
              size="sm"
            >
              <i className="ri-delete-bin-line" />
            </MDBBtn>
          ),
        })),
      ],
    };
  };

  const handleAddCategory = () => {
    const body = {
      name: categoryForm.name,
      service_category_root: categoryForm.id,
      tax: categoryForm.tax,
    };

    postServiceCategories
      .postServiceCategory(salon_name, body)
      .then((response) => {
        setOpen(false);
        if (response.ok) {
          ToastNotifications(
            "success",
            "Kategoria została dodana pomyślnie.",
            "Sukces!",
          );
          response.json().then((data) => {
            fetchData();
            setCategoryForm({ name: "", service_category: "", tax: "" });
          });
        }
      })
      .catch(() => {
        ToastNotifications(
          "error",
          "Spróbuj ponownie później",
          "Wystąpił błąd",
        );
      });
  };

  const handleEditCategory = () => {
    const body = {
      name: categoryForm.name,
      service_category_root: categoryForm.id,
      tax: categoryForm.tax,
    };

    patchServiceCategories
      .updateServiceCategory(salon_name, categoryForm.id, body)
      .then((response) => {
        if (response.ok) {
          getServiceCategories
            .getSalonServiceCategories(salon_name)
            .then((response) => response.json())
            .then((data) => {
              setCategories(
                data.map((category) => ({
                  ...category,
                  service_category: category.service_category
                    ? category.service_category
                    : "",
                  tax: category.tax ? category.tax : "",
                })),
              );
              setCategoryForm({
                name: "",
                service_category: "",
                tax: "",
              });
              setOpen(false);
              ToastNotifications(
                "success",
                "Kategoria została edytowana pomyślnie.",
                "Sukces!",
              );
            });
        }
      })
      .catch(() => {
        ToastNotifications(
          "error",
          "Spróbuj ponownie później",
          "Wystąpił błąd",
        );
      });
  };

  const optionTaxGroup = [
    {
      options: [
        ...taxes.map((taxData) => ({
          value: taxData.id,
          label: taxData.value,
        })),
      ],
    },
  ];

  return (
    <>
      <Card>
        <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
          <div className="hk-pg-header align-items-top">
            <div>
              <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                Kategorie usług
              </h2>
              <div className="flex-between-wrapper">
                <div className="flex-buttons">
                  <Button
                    data-toggle="modal"
                    data-taget="#modalPostCustomer"
                    type="button"
                    onClick={() => {
                      addCategory();
                    }}
                    color="primary"
                    className="waves-effect waves-light"
                  >
                    Dodaj Kategorię
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CardBody className="custom-data-table">
          <MDBDataTable
            responsive
            striped
            bordered
            data={dataValue()}
            {...translations.datatable}
          />
        </CardBody>
      </Card>
      <Container>
        <Row>
          <Col sm={6} md={4} xl={3} className="mt-4">
            <Modal isOpen={open} toggle={handleClose}>
              <ModalHeader>
                {categoryForm.newCategory
                  ? "Dodaj kategorię"
                  : "Edytuj Kategorię"}
              </ModalHeader>
              <ModalBody>
                <AvForm>
                  <AvField
                    id="editCategoryName"
                    onChange={(e) => {
                      setCategoryForm({
                        ...categoryForm,
                        name: e.target.value,
                      });
                    }}
                    name="name"
                    label="Nazwa"
                    placeholder="Wprowadź nazwę"
                    type="text"
                    errorMessage="Wprowadź nazwę"
                    validate={{ required: { value: true } }}
                    value={categoryForm.name}
                  />
                  <FormGroup className="select4-container">
                    <Label>Podatek</Label>
                    <Select
                      defaultValue={
                        categoryForm?.tax?.id
                          ? {
                              label: categoryForm?.tax?.value
                                ? categoryForm?.tax?.value + "%"
                                : null,
                              value: categoryForm?.tax?.id
                                ? categoryForm?.tax?.id
                                : null,
                            }
                          : null
                      }
                      onChange={(e) => {
                        setCategoryForm({
                          ...categoryForm,
                          tax: e.value,
                        });
                      }}
                      options={optionTaxGroup}
                      classNamePrefix="select4-selection"
                      isSearchable={false}
                      placeholder="Wybierz podatek"
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <div>
                      <Button
                        id="edit_customer"
                        color="primary"
                        className="mr-1"
                        onClick={() => {
                          categoryForm.newCategory
                            ? handleAddCategory()
                            : handleEditCategory();
                        }}
                      >
                        Zapisz
                      </Button>{" "}
                      <Button
                        onClick={() => {
                          setOpen(false);
                        }}
                        type="reset"
                        color="secondary"
                      >
                        Anuluj
                      </Button>
                    </div>
                  </FormGroup>
                </AvForm>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Categories;

const deleteMsg = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger",
    cancelButton: "btn btn-light",
  },
  buttonsStyling: false,
});
