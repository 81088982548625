export const getSalonProducts = async (salon_name) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/products`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getInactiveProducts = async (salon_name) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/products?is_active=false`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getSalonProductsByCategoryAndType = async (
  salon_name,
  type,
  category,
) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/products?product_category=${category}&product_type=${type}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
