import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
//Import Components
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import LatestTransactions from "./LatestTransactions";
import * as getStatistics from "../../Api/Statistics/getStatistics";
import * as getStatistic from "../../Api/Statistics/getStatistics";
import MiniWidgets from "../Stats/MiniWidgets";
import moment from "moment";
import * as getCustomers from "../../Api/Customers/getCustomers";
import * as visitsAll from "../../Api/Visits/getVisits";
import * as getService from "../../Api/Services/getServices";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Visitio", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
      report: [],
      servicesData: [],
      visitsPrice: [],
      visitsTotalPrice: 0,
      avarangeVisitPrice: 0,
      visits_count: 0,
      thisMonthReport: {},
      nextMonthReport: {},
      customersTotal: 0,
      visitsTable: [],
      servicesName: [],
      allDataVisits: [],
    };
    this.salon_name = localStorage.getItem("salon_name");
  }

  componentDidMount() {
    getStatistics
      .getReport(this.salon_name)
      .then((response) => response.json())
      .then((data) => this.setState({ report: data }));
    const thisMonthStart = moment().startOf("month").format("YYYY-MM-DD");
    const thisMonthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const nextMonthStart = moment()
      .add(1, "M")
      .startOf("month")
      .format("YYYY-MM-DD");
    const nextMonthEnd = moment()
      .add(1, "M")
      .endOf("month")
      .format("YYYY-MM-DD");
    getStatistics
      .getReport(this.salon_name, thisMonthStart, thisMonthEnd)
      .then((response) => response.json())
      .then((data) => this.setState({ thisMonthReport: data }));
    getStatistics
      .getReport(this.salon_name, nextMonthStart, nextMonthEnd)
      .then((response) => response.json())
      .then((data) => this.setState({ nextMonthReport: data }));
    visitsAll
      .getVisitLimit(this.salon_name)
      .then((response) => response.json())
      .then((data) => this.setState({ visitsTable: data }));
    getService
      .getSalonServices(this.salon_name)
      .then((response) => response.json())
      .then((data) => this.setState({ servicesName: data }));

    // visitsAll
    //   .getVisitsBetweenDates(this.salon_name, '2021-01-01', '2021-12-31')
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let allData = [];
    //     if(data && data.length > 0) {
    //       for(let i = 0; i < data.length; i++) {
    //         if(allData.length === 0) {
    //           allData.push({
    //             id: data[i].services[0],
    //             count: 0
    //           });
    //
    //           if(data[i].services.length > 1) {
    //             for(let h = 1; h < data[i].services.length; h++) {
    //               let checkF = false;
    //               for(let k = 0; k < allData.length; k++) {
    //                 if(data[i].services[h] === allData[k].id) {
    //                   checkF = true;
    //                   allData[k].count = allData[k].count + 1;
    //                 }
    //                 if(k === allData.length - 1 && !checkF) {
    //                   allData.push({
    //                     id: data[i].services[h],
    //                     count: 0
    //                   })
    //                 }
    //               }
    //             }
    //           }
    //         } else {
    //           for(let h = 0; h < data[i].services.length; h++) {
    //             let checkF = false;
    //             for(let k = 0; k < allData.length; k++) {
    //               if(data[i].services[h] === allData[k].id) {
    //                 checkF = true;
    //                 allData[k].count = allData[k].count + 1;
    //               }
    //               if(k === allData.length - 1 && !checkF) {
    //                 allData.push({
    //                   id: data[i].services[h],
    //                   count: 0
    //                 })
    //               }
    //             }
    //           }
    //         }
    //
    //         if(i === data.length - 1) {
    //           this.setState({
    //             allDataVisits: allData.sort((item, item2) => item.count - item2.count)
    //           })
    //         }
    //       }
    //     }
    //   });
    // getStatistics
    //   .getProducts(this.salon_name, nextMonthStart, nextMonthEnd)
    //   .then((response) => response.json())
    //   .then((data) => console.log(data));

    getCustomers.getSalonCustomers(this.salon_name).then((response) => {
      response.json().then((data) => {
        this.setState({ customersTotal: data?.length || 0 });
      });
    });
    this.getRaportServices();
  }

  handleCountVisitPrice = (array) => {
    if (array && array.length > 0) {
      return array?.reduce(function (total, num) {
        return parseFloat(total) + parseFloat(num);
      });
    }

    return 0;
  };

  getRaportServices = () => {
    const storedUserSalon = localStorage.getItem("salon_name");
    getStatistic.getServices(storedUserSalon).then((response) => {
      response
        .json()
        .then((data) => {
          this.setState({
            servicesData: data.services,
          });
          return data;
        })
        .then((dane) => {
          const visits_price = dane.services.map((d) => d.visits_price);
          const visits_count = dane.services.map((d) => d.visits_count);
          const sum = this.handleCountVisitPrice(visits_price);
          const avarageVisitPrice = this.handleAvarangePrice(
            sum,
            dane.services_total[0].visits_total_count,
          );

          this.setState({
            visitsPrice: visits_price,
            visitsTotalPrice: sum,
            avarangeVisitPrice: avarageVisitPrice,
            visits_count: visits_count.length,
          });
        });
    });
  };

  handleAvarangePrice = (sum, visitsLength) => {
    var avarange = sum / visitsLength;
    var roundNumber = Math.round(avarange * 100) / 100;
    return roundNumber;
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={8}>
                <Row>
                  <MiniWidgets
                    reports={this.state.report}
                    state={this.state}
                    thisMonthReport={this.state.thisMonthReport}
                    nextMonthReport={this.state.nextMonthReport}
                  />
                </Row>
                {/* revenue Analytics */}
                <RevenueAnalytics state={this.state} />
              </Col>

              <Col xl={4}>
                {/* sales Analytics */}
                <SalesAnalytics
                  allDataVisits={this.state.allDataVisits}
                  servicesName={this.state.servicesName}
                  state={this.state}
                />

                {/* earning reports */}
                <EarningReports state={this.state} />
              </Col>
            </Row>

            <Row>
              {/* latest transactions */}
              <LatestTransactions
                visitsTable={this.state.visitsTable}
                servicesName={this.state.servicesName}
                state={this.state}
              />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
