import React, { Component } from "react";
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logoWhite from "../../assets/images/logo-light.png";
import css from "./Register.modules.css";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      password: "",
      isOwnerForm: false,
      isCustomerRegister: false,
      salon_name_regex: /^[A-Za-z0-9](?:[A-Za-z0-9\-_]{0,18}[A-Za-z0-9])?$/i,
      checkValid: false,
      salon_name: null,
      salons: [],
      salonIndustries: [],
      selectedSalonIndustry: 1,
    };

    localStorage.removeItem("salon_name");
  }

  handleSelectSalon = (selectedSalonName) => {
    this.setState({ salon_name: selectedSalonName.target.value });
    localStorage.setItem("salon_name", selectedSalonName.target.value);
  };

  componentDidMount() {
    this.props.registerUserFailed("");
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  render() {
    return (
      <>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white" />
          </Link>
        </div>
        <div className="dataFlex data-flex-register">
          <div className="authentication-bg register-wrapper">
            <Link to="/" className="logo ml-3 mt-2 authentication">
              <img src={logoWhite} height="40" alt="logo" />
            </Link>
            <Link to="/register-owner">
              <div className="register-option">
                <h1>Rejestracja dla właściciela salonu</h1>
              </div>
            </Link>
            <Link to="/register-client">
              <div className="register-option">
                <h1>Rejestracja dla użytkownika salonu</h1>
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStateToProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
