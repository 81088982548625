import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
} from "reactstrap";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import StepProgressBar from "react-step-progress";
import Select from "react-select";
import * as getProductCategories from "../../Api/Products/Categories/getProductCategories";
export default class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products_categories: [],
      selectedGroup: null,
      selectedMulti: null,
      selectedMulti2: null,
      breadcrumbItems: [
        { title: "Inwentaryzacja", link: "#" },
        { title: "Rozpocznij inwentaryzacje", link: "#" },
      ],
    };
  }

  getProductsCategories() {
    getProductCategories
      .getProductCategories(this.state.salon_name)
      .then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            let apiData = [
              {
                id: 0,
                name: "brak",
              },
            ];
            data.forEach((item) => {
              apiData.push({
                id: item.id,
                name: item.name,
              });
            });

            this.setState({ products_categories: apiData });
            this.setState({
              optionCategoryGroup: [
                ...apiData.map((categoryData) => ({
                  value: categoryData.id,
                  label: categoryData.name,
                })),
              ],
            });
          } else if (response.status === 401) {
            return null;
          }
        });
      });
  }

  handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };
  handleMulti2 = (selectedMulti2) => {
    this.setState({ selectedMulti2 });
  };
  handleSelectGroup = (selectedGroup) => {
    this.setState({ selectedGroup });
  };

  textareachange(event) {
    var count = event.target.value.length;
    if (count > 0) {
      this.setState({ textareabadge: true });
    } else {
      this.setState({ textareabadge: false });
    }
    this.setState({ textcount: event.target.value.length });
  }

  render() {
    const { selectedMulti } = this.state;
    const { selectedMulti2 } = this.state;
    const { selectedGroup } = this.state;
    const optionGroup = [
      {
        options: [
          { label: "Mustard", value: "Mustard" },
          { label: "Ketchup", value: "Ketchup" },
          { label: "Relish", value: "Relish" },
        ],
      },
      {
        label: "Camping",
        options: [
          { label: "Tent", value: "Tent" },
          { label: "Flashlight", value: "Flashlight" },
          { label: "Toilet Paper", value: "Toilet Paper" },
        ],
      },
    ];

    return (
      <>
        <div className="page-content">
          <StepProgressBar
            buttonWrapperClass="hide"
            previousBtnDisabled
            startingStep={0}
            steps={[
              {
                label: "Rozpocznij inwentaryzacje",
                name: "step 1",
                // content: step1Content
              },
              {
                label: "Wprowadź produkty",
                name: "step 2",
                // content: step2Content,
                // validator: step2Validator
              },
              {
                label: "Podsumowanie stanów magazynowych",
                name: "step 3",
                // content: step2Content,
                // validator: step2Validator
              },
              {
                label: "Podsumowanie inwentaryzacji",
                name: "step 4",
                // content: step3Content,
                // validator: step3Validator
              },
            ]}
          />
          <Container fluid>
            <Breadcrumbs
              title="Nowa inwentaryzacja"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <FormGroup>
                      <FormGroup className="select2-container">
                        <Label className="control-label">
                          Rodzaj produktow
                        </Label>
                        <Col md={10}>
                          <Select
                            value={selectedMulti}
                            isMulti={true}
                            onChange={this.handleMulti}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="select2-container">
                        <Label className="control-label">Zakres</Label>
                        <Col md={10}>
                          <Select
                            value={selectedMulti2}
                            isMulti={true}
                            onChange={this.handleMulti2}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="select2-container">
                        <Label>Nazwa</Label>
                        <Col md={10}>
                          <Input
                            className="form-control"
                            type="text"
                            defaultValue="Artisanal kale"
                            id="example-text-input"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup className="select2-container">
                        <Label>Sporządził</Label>
                        <Col md={10}>
                          <Select
                            value={selectedGroup}
                            onChange={this.handleSelectGroup}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Label>Opis</Label>
                        <Col md={10}>
                          <Input
                            type="textarea"
                            id="textarea"
                            onChange={this.textareachange}
                            maxLength="225"
                            rows="3"
                            placeholder="Opis ma limit 225 znaków."
                          />
                          {this.state.textareabadge ? (
                            <span className="badgecount badge badge-info">
                              {" "}
                              {this.state.textcount} / 225{" "}
                            </span>
                          ) : null}
                        </Col>
                      </FormGroup>
                    </FormGroup>
                  </CardBody>
                  <Container styles={{ marginLeft: "30px" }}>
                    <Button
                      data-toggle="modal"
                      data-taget="#modalPostCustomer"
                      type="button"
                      color="primary"
                      className="waves-effect waves-light mr-1"
                    >
                      Rozpocznij wprowadzanie
                    </Button>
                    <Link to="/inwentaryzacja">
                      <Button
                        xs={6}
                        data-toggle="modal"
                        data-taget="#modalPostCustomer"
                        type="button"
                        outline
                        color="primary"
                        className="waves-effect waves-light mr-1"
                      >
                        Powrót
                      </Button>
                    </Link>
                  </Container>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
