import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { StocktakingForm } from "./StocktakingForm";
import { StocktakingProducts } from "./StocktakingProducts";
import moment from "moment";

//API
import * as postStocktaking from "../../Api/Stocktaking/postStocktaking";
import * as getProducts from "../../Api/Products/getProducts";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const steps = [
  "Dane Inwentaryzacji",
  "Przeglądanie Inwentarza",
  "Podsumowanie",
];

export const StocktakingSteper = () => {
  const salon_name = localStorage.getItem("salon_name");
  const [activeStep, setActiveStep] = useState(0);
  const [stocktakingData, setStocktakingData] = useState("");
  const [stocktakingProductData, setStocktakingProductData] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [productData, setProductData] = useState([]);

  const handleStocktakingFormSubmit = async (stocktakingInitialData) => {
    let products = [];
    let productRequests = [];
    let dataBody = {
      name: stocktakingInitialData.name,
      products: [],
      user: stocktakingInitialData.creatorName.id,
      description: stocktakingInitialData.description,
    };

    if (stocktakingInitialData.isFull) {
      productRequests.push(getProducts.getSalonProducts(salon_name));
    } else {
      stocktakingInitialData.selectedCategories.forEach((cat) =>
        productRequests.push(
          getProducts.getSalonProductsByCategoryAndType(
            salon_name,
            stocktakingInitialData.selectedType.id,
            cat.id,
          ),
        ),
      );
    }

    Promise.all(productRequests)
      .then((values) => {
        return values.map((response) => {
          return response.json();
        });
      })
      .then(async (data) => {
        let products = [];
        for (const product of data) {
          products = [...products, ...(await product)];
        }
        let productsId = products.map((product) => {
          return product.id;
        });
        dataBody.products = productsId;
        setProductData(products);
        postStocktaking
          .postStocktaking(salon_name, dataBody)
          .then((response) => response.json())
          .then((data) => console.log(data));
      });

    // .then((data)=>{sssss
    // console.log(data)
    //  console.log(data)
    // console.log(dataBody)
    // console.log(products)
    // let productsId=  products.map( product=>{
    //   console.log(product);
    //   return product.id
    // })
    // console.log(productsId)
    // setProductData(...products)

    // postStocktaking.postStocktaking(salon_name,dataBody).then(response=>response.json()).then(data=>console.log(data))
    // })

    // postStocktaking.postStocktaking(salon_name,dataBody}).then(response=>response.json()).then(data=>console.log(data))
    // Promise.all(productRequests).then(values=>console.log(values))

    setStocktakingData(stocktakingInitialData);
    setActiveStep(1);
  };

  const handleStocktakingProductsSubmit = (data) => {
    setStocktakingProductData(data);
    setCreationDate(moment());
    setActiveStep(2);
  };

  //   useEffect(() => {
  //     setActiveStep(1)
  //  }, [stocktakingData]);

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <StocktakingForm
            salonName={salon_name}
            handleSubmit={handleStocktakingFormSubmit}
          />
        );
      case 1:
        return (
          <StocktakingProducts
            products={productData}
            salonName={salon_name}
            handleSubmit={handleStocktakingProductsSubmit}
          />
        );
      case 2:
        return <div>Podsumowanie</div>;
      default:
        return "Unknown stepIndex";
    }
  };

  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {getStepContent(activeStep)}
    </div>
  );
};
