import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import HistoryTable from "./HIstoryTable";

const initialState = {
  customerId: undefined,
  row: {
    first_name: undefined,
    last_name: undefined,
    phone_number: undefined,
    locality: undefined,
    birthdate: undefined,
    email: undefined,
  },
  historyEntries: [],
  form: {
    note: undefined,
  },
};

export default class HistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      customerId: props.customerId,
      ...{
        form: {
          note: props.note,
        },
      },
    };
    console.log("[HistoryModal] props", props);
    console.log("[HistoryModal] state", this.state);
    console.log("[HistoryModal] form", this.state.form);
  }

  componentDidMount() {
    const historyEntries = [];
    for (let i = 0; i < 40; i++) {
      historyEntries.push({
        action: i % 2 === 0 ? "Zapłacił za wizytę" : "Zapisał się na wizytę",
        details: (
          <div>
            <ul>
              <li>Wartość: {i % 2 === 0 ? "500" : "400"}zł</li>
              <li>
                Usługi:
                <br />
                {i % 2 !== 0 && i % 3 !== 0 ? <span>Strzyżenie</span> : ""}
                {i % 2 === 0 && <span>Strzyżenie, Malowanie, Manicure</span>}
                {i % 3 === 0 && <span>Strzyżenie, Malowanie</span>}
              </li>
            </ul>
          </div>
        ),
        date: "15/03/2021",
      });
    }

    this.setState({
      historyEntries: historyEntries,
    });
  }

  render() {
    const { onClosed } = this.props;

    return (
      <Modal isOpen={true} size="xl" centered={true} onClosed={onClosed}>
        <ModalHeader toggle={onClosed}>
          Historia klienta: {this.state.row.first_name}{" "}
          {this.state.row.last_name}
        </ModalHeader>
        <ModalBody>
          <HistoryTable remoteData={this.state.historyEntries} />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={onClosed}
            color="light"
            className="waves-effect"
          >
            Zamknij
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
