import React, { Component } from "react";
import FinalizeForm, {
  defaultState as finalizeFormDefaultState,
  scaffoldVisit,
} from "../Calendar/components/FinalizeForm";
import * as getVisits from "../../Api/Visits/getVisits";
import * as getUsers from "../../Api/Users/getUsers";
import { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import * as getServices from "../../Api/Services/getServices";

export function calculateNettoPriceOfService(service) {
  return (
    Math.round(
      (service.price / (1 + service.tax.value / 100) + Number.EPSILON) * 100,
    ) / 100
  );
}

export function calculateVatPriceOfService(service) {
  return (
    Math.round(
      (service.price - calculateNettoPriceOfService(service) + Number.EPSILON) *
        100,
    ) / 100
  );
}

class VisitsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...finalizeFormDefaultState,
      ...{
        salon_name: localStorage.getItem("salon_name"),
        visit: {
          id: +props.match.params.id,
        },
        loaded: false,
      },
    };
  }

  componentDidMount() {
    registerLocale("pl", pl);
    Promise.all([
      getServices
        .getSalonServices(this.state.salon_name)
        .then((response) => response.json()),
      getUsers
        .getSalonEmployes(this.state.salon_name)
        .then((response) => response.json()),
      getVisits
        .getVisitById(this.state.salon_name, this.state.visit.id)
        .then((response) => response.json()),
    ]).then(async (results) => {
      const [salonServices, salonEmployees, visit] = results;
      const empId = await this.bezczelnieZnajdzPracownikaDanejWizyty(
        visit.id,
        salonEmployees,
      );
      this.setState({
        start: new Date(visit.appointment_at),
        description: visit.description,
        note: visit.note,
        selectedTabIndex: 0,
        visit: scaffoldVisit(salonEmployees, visit, salonServices),
        customer: visit.customer,
        service: visit.service,

        employees: salonEmployees,
        employee: salonEmployees.find((emp) => emp.id === empId),
        loaded: true,
      });
    });
  }

  // TODO remove it, employee id should be returned by API
  async bezczelnieZnajdzPracownikaDanejWizyty(visitId, employees) {
    for (const emp of employees) {
      const remoteVisits = await getVisits
        .getVisitsByEmployee(this.state.salon_name, emp.id)
        .then((response) => response.json());
      for (const remoteVisit of remoteVisits) {
        if (remoteVisit.id === visitId) {
          return new Promise((a, r) => a(emp.id));
        }
      }
    }
  }

  render() {
    return (
      <div className="page-content">
        {this.state.loaded && <FinalizeForm {...this.state} lazyLoad={false} />}
      </div>
    );
  }
}

export default VisitsEdit;
