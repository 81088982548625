import React from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ToastNotifications = (toastType, message, title, timeOut = 5000) => {
  toastr.options = {
    closeButton: false,
    debug: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: 300,
    hideDuration: 1000,
    timeOut: timeOut,
    extendedTimeOut: 1000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
  };

  if (toastType === "info") {
    return toastr.info(message, title);
  }

  if (toastType === "warning") {
    return toastr.warning(message, title);
  }

  if (toastType === "error") {
    return toastr.error(message, title);
  }

  return toastr.success(message, title);
};

export default ToastNotifications;
