export const getLoggedUserInfo = async () => {
  return await fetch(process.env.REACT_APP_HOST_API + "/api/v1/me", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  });
};

export const getSalonEmployes = async (salon_name) => {
  return await fetch(
    process.env.REACT_APP_HOST_API +
      `/api/v1/${salon_name}/users?role=ROLE_EMPLOYEE`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getSalonEmployesPublic = async (salon_name) => {
  return await fetch(
    process.env.REACT_APP_HOST_API + `/api/v1/${salon_name}/employees-public`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};

export const getUsersBySalonName = async (salon_name) => {
  return await fetch(
    process.env.REACT_APP_HOST_API + `/api/v1/${salon_name}/users`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
