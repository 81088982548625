import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { logoutUser } from "../../store/actions";
import { Route, Redirect } from "react-router-dom";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLogoutUser() {
    localStorage.clear();
    window.location.replace(process.env.REACT_APP_HOST + "/login");
  }

  componentDidMount() {
    this.handleLogoutUser();
  }

  render() {
    return (
      <React.Fragment>
        <h1>&nbsp;</h1>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(null, { logoutUser })(Logout));
