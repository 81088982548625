import React, { Component } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

class SalesAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [30, 20, 10, 10, 12, 18],
      options: {
        labels: [
          "Strzyżenie",
          "Farbowanie",
          "Stylizacja włosów",
          "Balejaż",
          "Czesanie",
          "Układanie włosów",
        ],
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        colors: [
          "#5664d2",
          "#1cbb8c",
          "#eeb902",
          "#f10f37",
          "#faaabb",
          "#000",
          "#0f0",
        ],
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            {/*<div className="float-right">*/}
            {/*    <select className="custom-select custom-select-sm">*/}
            {/*      {['Sty', 'Lut', 'Mrz', 'Kwi', 'Maj', 'Cze', 'Lip' ].map((month, ix) => (*/}
            {/*        <option key={`${ix} + ${month}`} value={ix} defaultChecked={ix===0}>{month}</option>*/}
            {/*      ))}*/}
            {/*    </select>*/}
            {/*</div>*/}

            <h4 className="card-title mb-4">
              Najczęsciej wybierane usługi 2021
            </h4>

            <div id="donut-chart" className="apex-charts">
              <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type="donut"
                height="230"
              />
            </div>

            <Row>
              {this.state.options.labels.map((label, ix) => (
                <Col xs={4} key={`${ix} + ${label}`}>
                  <div className="text-center mt-4">
                    <p className="mb-2 text-truncate">
                      <i className="mdi text-primary font-size-10 mr-1" />{" "}
                      {label}
                    </p>
                    <h5>{this.state.series[ix]}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default SalesAnalytics;
