export const getProducts = async (salonName) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salonName}/statistics/products`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getEmployees = async (salonName) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salonName}/statistics/employees`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getServices = async (salonName) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salonName}/statistics/services`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getReport = async (salonName, dateStart, dateEnd) => {
  function query() {
    if (!dateStart && !dateEnd) return "";
    let query = "?";
    if (dateStart) query = query + "dateStart=" + dateStart + "&";
    if (dateEnd) query = query + "dateEnd=" + dateEnd + "&";
    return query.slice(0, query.length - 1);
  }
  return await fetch(
    `${
      process.env.REACT_APP_HOST_API
    }/api/v1/${salonName}/statistics/report${query()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getReportDivided = async (salonName, dateStart, dateEnd) => {
  function query() {
    if (!dateStart && !dateEnd) return "";
    let query = "?";
    if (dateStart) query = query + "dateStart=" + dateStart + "&";
    if (dateEnd) query = query + "dateEnd=" + dateEnd + "&";
    return query.slice(0, query.length - 1);
  }
  return await fetch(
    `${
      process.env.REACT_APP_HOST_API
    }/api/v1/${salonName}/statistics/report-divided${query()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getReportFilter = async (
  salonName,
  filter,
  dateStart,
  dateEnd,
) => {
  function query() {
    if (!dateStart && !dateEnd && !filter) return "";
    let query = "?";
    if (dateStart) query = query + "dateStart=" + dateStart + "&";
    if (dateEnd) query = query + "dateEnd=" + dateEnd + "&";
    if (filter === "0" || filter === "1")
      query = query + "isFinalized=" + filter + "&";
    return query.slice(0, query.length - 1);
  }
  return await fetch(
    `${
      process.env.REACT_APP_HOST_API
    }/api/v1/${salonName}/statistics/report-filtered${query()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getSales = async (salonName, dateStart, dateEnd) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salonName}/sales`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
