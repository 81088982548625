import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import Select from "react-select";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

import { connect } from "react-redux";

// import images
import logoDark from "../../assets/images/logo-dark.png";
import logowhite from "../../assets/images/logo-light.png";

// SWEETALERT2
import Swal from "sweetalert2";

import * as postUsers from "../../Api/Users/postUsers";
import * as getSalons from "../../Api/Salons/getSalons";

class RegisterCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      password: "",
      isCustomerForm: false,
      isOwnerForm: false,
      salon_name_regex: /^[A-Za-z0-9](?:[A-Za-z0-9\-_]{0,18}[A-Za-z0-9])?$/i,
      checkValid: false,
      salons: [],
    };
  }

  handleRegisterCustomer() {
    const first_name = document.querySelector("#first_name");
    const last_name = document.querySelector("#last_name");
    const ownerpassword = document.querySelector("#ownerpassword");
    const ownermail = document.querySelector("#ownermail");
    let data = {
      first_name: first_name.value,
      last_name: last_name.value,
      password: ownerpassword.value,
      username: ownermail.value,
    };
    const registerAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });
    postUsers
      .registerCustomer(data, localStorage.getItem("salon_name"))
      .then((response) => {
        if (response.status === 201) {
          registerAlert
            .fire({
              title: "Sukces",
              text: "Konto zostało pomyślnie utworzone. Wysłaliśmy maila z linkiem potwierdzającym założenie konta. Sprawdź swoją pocztę.",
              icon: "success",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.replace(process.env.REACT_APP_HOST + "/login");
              }
            });
        } else if (response.status === 400) {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Niepoprawne dane lub salon już istnieje!",
          });
        }
      });
  }

  getSalons() {
    getSalons.getAllSalons().then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((salon) => {
            return {
              id: salon.id,
              name: salon.name,
              locality: salon.locality,
              created_at: salon.created_at,
            };
          });
          this.setState({
            salons: apiData,
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  componentDidMount() {
    this.getSalons();
    document.body.classList.add("auth-body-bg");
  }

  render() {
    const { salons } = this.state;

    let optionSalons = [
      ...salons.map((salonData) => ({
        value: salonData.id,
        label: salonData.name,
      })),
    ];

    return (
      <>
        <div className="p-2 mt-5">
          <div className="text-center">
            <Link to="/" className="logo">
              <img src={logoDark} height="20" alt="logo" />
            </Link>
          </div>
          <AvForm
            onValidSubmit={this.handleRegisterCustomer}
            className="form-horizontal"
          >
            <Container>
              <div className="center">
                <FormGroup className="select-container d-flex flex-column justify-center align-center mb-5">
                  <h6 className="text-center m-3">Wybierz Salon</h6>
                  <Select
                    className="m-auto select-salon-field"
                    onChange={this.handleSelectSalon}
                    options={optionSalons}
                    classNamePrefix="select2-selection"
                    isSearchable={false}
                  />
                </FormGroup>
              </div>
              <Row className="justify-content-center">
                <Col xl={6}>
                  <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-user-2-line auti-custom-input-icon" />
                    <Label htmlFor="first_name">Imię</Label>
                    <AvField
                      name="first_name"
                      type="text"
                      className="form-control"
                      id="first_name"
                      validate={{ required: true }}
                      placeholder="Wprowadź nazwę"
                    />
                  </FormGroup>
                  <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-lock-2-line auti-custom-input-icon" />
                    <Label htmlFor="ownerpassword">Hasło</Label>
                    <AvField
                      name="ownerpassword"
                      type="password"
                      className="form-control"
                      id="ownerpassword"
                      validate={{ required: true }}
                      placeholder="Wprowadź hasło"
                    />
                  </FormGroup>
                </Col>
                <Col xl={6}>
                  <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-file-user-fill auti-custom-input-icon" />
                    <Label htmlFor="last_name">Nazwisko</Label>
                    <AvField
                      name="last_name"
                      type="text"
                      className="form-control"
                      id="last_name"
                      validate={{ required: true }}
                      placeholder="Wprowadź nazwisko"
                    />
                  </FormGroup>
                  <FormGroup className="auth-form-group-custom mb-4">
                    <i className="ri-mail-line auti-custom-input-icon" />
                    <Label htmlFor="ownermail">Adres Email</Label>
                    <AvField
                      name="ownermail"
                      type="text"
                      className="form-control"
                      id="ownermail"
                      validate={{
                        email: true,
                        required: true,
                      }}
                      placeholder="Wprowadź adres e-mail"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <div className="mt-4 text-center">
                <Button
                  color="primary"
                  className="w-md waves-effect waves-light fluid login-btn"
                  type="submit"
                >
                  {this.props.loading ? "Loading ..." : "Zarejestruj się"}
                </Button>
              </div>
            </Container>

            <div className="mt-4 text-center">
              <p className="mb-0">
                Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z{" "}
                <Link to="#" className="text-primary">
                  Regulaminem
                </Link>
              </p>
            </div>
          </AvForm>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(RegisterCustomer);
