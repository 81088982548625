// import the progress bar
import StepProgressBar from "react-step-progress";
// import the stylesheet
import "react-step-progress/dist/index.css";
import React, { Component } from "react";
import Header from "../../components/Header/Header";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardHeader,
  Col,
  Container,
  Row,
  Media,
} from "reactstrap";
import "./MultiStep.scss";
import { DatePicker, Space } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { Button } from "reactstrap";
import avatar3 from "../../assets/images/users/avatar-5.jpg";
import { Link } from "react-router-dom";
import HeaderService from "../../components/HeaderService/HeaderService";

class ServiceOrder extends Component {
  state = {
    salon_name: localStorage.getItem("salon_name"),
  };

  render() {
    const { RangePicker } = DatePicker;
    // setup the step content
    const step1Content = <h1>Step 1 Content</h1>;
    const step2Content = <h1>Step 2 Content</h1>;
    const step3Content = <h1>Step 3 Content</h1>;

    // setup step validators, will be called before proceeding to the next step
    function step2Validator() {
      // return a boolean
    }

    function step3Validator() {
      // return a boolean
    }

    function onFormSubmit() {
      // handle the submit logic here
      // This function will be executed at the last step
      // when the submit button (next button in the previous steps) is pressed
    }

    return (
      <>
        <HeaderService />
        <Container>
          <div className="page-content">
            <Row>
              <Col>
                <StepProgressBar
                  buttonWrapperClass="hide"
                  previousBtnDisabled
                  startingStep={1}
                  onSubmit={onFormSubmit}
                  steps={[
                    {
                      label: "Usługi",
                      name: "step 1",
                      // content: step1Content
                    },
                    {
                      label: "Termin",
                      name: "step 2",
                      // content: step2Content,
                      // validator: step2Validator
                    },
                    {
                      label: "Logowanie/Rejestracja",
                      name: "step 3",
                      // content: step2Content,
                      // validator: step2Validator
                    },
                    {
                      label: "Podsumowanie",
                      name: "step 4",
                      // content: step3Content,
                      // validator: step3Validator
                    },
                  ]}
                />
              </Col>
            </Row>
          </div>
          <div className="container added-service-wrapper">
            <Card outline color="primary" className="border background-white">
              <CardHeader className="bg-transparent">
                <h5 className="my-0 text-primary">
                  <i className=" ri-calendar-check-fill mr-3" />
                  1. Strzyżenie
                </h5>
              </CardHeader>
              <CardBody>
                <Media className="mt-3">
                  <Link className="pr-3" to="#">
                    <img
                      src={avatar3}
                      alt="Nazox"
                      className="avatar-sm rounded-circle"
                    />
                  </Link>
                  <Media body>
                    <h5 className="mt-0 font-size-14">Anna Kowalska</h5>
                  </Media>
                </Media>
                <CardTitle className="mt-0 text-right">
                  Kwota: 45,00 zł
                </CardTitle>
                <CardTitle className="mt-0 text-right">
                  Czas usługi: 20min
                </CardTitle>
              </CardBody>
            </Card>
          </div>
          <div className="container date-wrapper">
            <div className="d-flex flex-column justify-content-between align-items-center date-box">
              <div className="datepicker-header">
                <h5>Wybierz datę</h5>
                <DatePicker className="datepicker-input" />
              </div>
              <div className="date-hours-box">
                <h5 className="datepicker-header">Wybierz godzinę</h5>
                <ul>
                  <li className="row justify-content-between align-items-center py-2">
                    <div className="col">
                      <span>9:00-10:00</span>
                    </div>
                    <div className="col col-sm-3">
                      <Link to="/podsumowanie">
                        <Button>WYBIERZ</Button>
                      </Link>
                    </div>
                  </li>
                  <li className="row justify-content-between align-items-center py-2">
                    <div className="col">
                      <span>10:00-10:15</span>
                    </div>
                    <div className="col col-sm-3">
                      <Link to="/podsumowanie">
                        <Button>WYBIERZ</Button>
                      </Link>
                    </div>
                  </li>
                  <li className="row justify-content-between align-items-center py-2">
                    <div className="col">
                      <span>10:15-10:30</span>
                    </div>
                    <div className="col col-sm-3">
                      <Link to="/podsumowanie">
                        <Button>WYBIERZ</Button>
                      </Link>
                    </div>
                  </li>
                  <li className="row justify-content-between align-items-center py-2">
                    <div className="col">
                      <span>10:30-10:45</span>
                    </div>
                    <div className="col col-sm-3">
                      <Link to="/podsumowanie">
                        <Button>WYBIERZ</Button>
                      </Link>
                    </div>
                    {/* {
                    this.state.salon_name === true ?
                      (
                        <div className="col col-sm-3"><Link to="/"><Button>WYBIERZ</Button></Link></div>
                      )
                      :
                      (
                        <div className="col col-sm-3"><Link to="/podsumowanie"><Button>WYBIERZ</Button></Link></div>
                      )
                  } */}
                  </li>
                </ul>
              </div>
              {/* <Link to="/">
                <Button className="mt-5" size="lg" color="primary">Potwierdź</Button>
              </Link> */}
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default ServiceOrder;
