import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
} from "reactstrap";
import Select, { components } from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import scrollGridPlugin from "@fullcalendar/scrollgrid";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import plLocale from "@fullcalendar/core/locales/pl";
import SidebarCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.scss";
import Swal from "sweetalert2";
import moment from "moment";
import pl from "date-fns/locale/pl";
import * as getUsers from "../../Api/Users/getUsers";
import * as getVisits from "../../Api/Visits/getVisits";
import * as getService from "../../Api/Services/getServices";
import * as getCustomers from "../../Api/Customers/getCustomers";
import DatePicker, { registerLocale } from "react-datepicker";
import * as postVisits from "../../Api/Visits/postVisits";
import * as deleteVisits from "../../Api/Visits/deleteVisits";
import * as postCustomer from "../../Api/Customers/postCustomers";
import * as patchVisits from "../../Api/Visits/patchVisits";
import FinalizeModal from "./components/FinalizeModal";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { scaffoldVisit } from "./components/FinalizeForm";
import ToastNotifications from "../../components/ToastNotification/ToastNotification";
import { convertTime } from "../../helpers/convertTime";
const { ValueContainer, Placeholder } = components;
export default class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekendsVisible: true,
      currentEvents: [],
      modal_standard: false,
      pickerDate: new Date(),
      salonEmployes: [],
      selectedCalendarEmployee: "",
      selectedEmployeeVisits: [],
      salonServices: [],
      salonCustomers: [],
      newApointmentDate: "",
      // SELECT OPTIONS
      formSelectedService: null,
      formSelectedCustomer: null,
      formSelectedEmployee: null,
      formSelectedDate: null,
      minutesTotal: [],
      serviceNetPrice: 0,
      serviceGrossPrice: 0,
      totalNetPrice: 0,
      totalGrossPrice: 0,
      totalTax: 0,
      noteAsHelper: "",
      isReception: false,
      selectedEmployeeCheckbox: [],
      selectedResource: [],
      slotMaxTime: localStorage.getItem("salon_slot_max") || "19:00:00",
      slotMinTime: localStorage.getItem("salon_slot_min") || "06:00:00",
      slotInterval: localStorage.getItem("salon_slot_interval") || 15,
      employees: [],
      calendarFullSize: false,
      calendarShowAll: false,
      finalizeModal: {
        isOpen: false,
        employee: undefined,
        customer: undefined,
        event: undefined, // https://fullcalendar.io/docs/event-object
        service: {
          id: undefined,
          name: undefined,
        },
      },
      isAddingVisit: false,
      addServiceError: false,
      addVisitClientError: false,
      showFullHours: false,
      showOptions: false,
    };

    this.phoneRegex = /([+(\d]{1})(([\d+() -.]){7,16})([+(\d]{1})/;
    this.serviceRef = React.createRef();
    this.salon_name = localStorage.getItem("salon_name");
    this.calendarRef = React.createRef();
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_addCustomer = this.tog_addCustomer.bind(this);
    this.addVisit = this.addVisit.bind(this);
    this.addCustomer = this.addCustomer.bind(this);

    this.handleSelectService = this.handleSelectService.bind(this);
    this.handleSelectCustomer = this.handleSelectCustomer.bind(this);
    this.handleSelectEmployee = this.handleSelectEmployee.bind(this);

    this.loadEvents = this.loadEvents.bind(this);
    this.changeStyle = this.changeStyle.bind(this);
  }

  handleTotalNetPriceClick = (e) => {
    this.setState({
      totalNetPrice: e.target.value,
    });
  };

  // handleTotalGrossPriceClick = (e) => {
  //   const { totalTax } = this.state;

  //   this.setState({
  //     totalNetPrice: ((e.target.value * 100) / (100 + totalTax)).toFixed(2),
  //     totalGrossPrice: e.target.value,
  //   });
  // };

  handleTotalGrossPriceClick = (e) => {
    const { totalTax } = this.state;
    const grossPrice = parseFloat(e.target.value);

    const netPrice = grossPrice / (1 + totalTax / 100);

    this.setState({
      totalNetPrice: netPrice.toFixed(2),
      totalGrossPrice: grossPrice,
    });
  };

  handleTotalTaxClick = (e) => {
    this.setState({
      totalTax: e.target.value,
    });
  };

  handleTotalNotAsHelperClick = (e) => {
    this.setState({
      noteAsHelper: e.target.value,
    });
  };

  changeStyle = () => {
    const minutes = this.state.minutesTotall;
    minutes.map((item) => {
      if (item.minutes < 60) {
        let d = Array.prototype.slice.call(
          document.getElementsByClassName("fc-timegrid-event-harness-inset"),
        );
        d.map((item) => (item.style.width = "200px"));
      }
    });
  };

  setVisitClass = (visitData) => {
    if (visitData.is_finalized) {
      return "fc-bg-finalized";
    }

    if (visitData.is_missed) {
      return "fc-bg-missed";
    }

    return "fc-bg-nonfinalized";
  };

  componentDidMount() {
    registerLocale("pl", pl);
    Promise.all([
      getUsers
        .getSalonEmployes(this.salon_name)
        .then((response) => response.json()),
      getService
        .getSalonServices(this.salon_name)
        .then((response) => response.json()),
      getCustomers
        .getSalonCustomers(this.salon_name)
        .then((response) => response.json()),
    ]).then(async (results) => {
      const [employeeData, services, customers] = results;
      this.setState({
        salonEmployes: employeeData,
        selectedCalendarEmployee: employeeData[0],
        formSelectedEmployee:
          employeeData.length > 0
            ? {
                value: { employee: employeeData[0] },
                label: `${employeeData[0].first_name} ${employeeData[0].last_name}`,
              }
            : { value: "", label: "" },
        salonServices: services,
        formSelectedService: [{ value: "", label: "" }],
        // formSelectedService:
        //   services.length > 0
        //     ? [
        //         {
        //           value: { service: services[0] },
        //           label: `${services[0].title} (${convertTime(
        //             services[0].service_time_max,
        //           )})`,
        //         },
        //       ]
        //     : [{ value: "", label: "" }],
        salonCustomers: customers,
        formSelectedCustomer: { value: "", label: "" },
        // totalNetPrice: services.length > 0 ? services[0].price.toFixed(2) : 0,
        totalNetPrice: 0,
        // totalGrossPrice:
        //   services.length > 0
        //     ? (
        //         (services[0].price * (services[0].tax.value + 100)) /
        //         100
        //       ).toFixed(2)
        //     : 0,
        totalGrossPrice: 0,
        // totalTax: services.length > 0 ? services[0].tax.value : 0,
        totalTax: 0,
        // serviceNetPrice: services.length > 0 ? services[0].price.toFixed(2) : 0,
        serviceNetPrice: 0,
        // serviceGrossPrice:
        //   services.length > 0
        //     ? (
        //         (services[0].price * (services[0].tax.value + 100)) /
        //         100
        //       ).toFixed(2)
        //     : 0,
        serviceGrossPrice: 0,
      });
    });
  }

  handleSelectEmployee = (formSelectedEmployee) => {
    this.setState({ formSelectedEmployee });
  };

  handleSelectService = (formSelectedService) => {
    if (formSelectedService) {
      const totalGross = formSelectedService.reduce(
        (a, b) => a + b.value.service.price,
        0,
      );
      const totalNet = formSelectedService
        .reduce(
          (a, b) =>
            Number(a) +
            Number(
              (
                b.value.service.price /
                (1 + b.value.service.tax.value / 100)
              ).toFixed(2),
            ),
          0,
        )
        .toFixed(2);
      const totalTax = (totalGross - totalNet).toFixed(2);
      const taxValue = formSelectedService.reduce(
        (a, b) => b.value.service.tax.value.toFixed(0),
        23,
      );

      this.setState({
        formSelectedService,
        totalNetPrice: totalNet,
        totalGrossPrice: totalGross,
        taxValue: taxValue,
        totalTax: totalTax,
        serviceNetPrice: totalNet,
        serviceGrossPrice: totalGross,
        addServiceError: false,
      });
    } else {
      this.setState({
        formSelectedService,
        totalNetPrice: 0,
        totalGrossPrice: 0,
        totalTax: 0,
        serviceNetPrice: 0,
        serviceGrossPrice: 0,
      });
    }
  };

  handleSelectCustomer = (formSelectedCustomer) => {
    this.setState({ formSelectedCustomer });
    this.setState({
      addVisitClientError: false,
    });
  };

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_addCustomer() {
    this.setState((prevState) => ({
      modal_addCustomer: !prevState.modal_addCustomer,
    }));
    this.removeBodyCss();
  }

  createTitleFromServices(visitServices, allServices) {
    return visitServices
      .map((id) => {
        const currentService = allServices.find((s) => s.id === id);
        if (currentService) {
          return currentService.title;
        }
        return "";
      })
      .join(", ");
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  selectEmployeeForCalendar = (employeeId) => {
    let newArray = this.state.selectedEmployeeCheckbox;
    let newResources = [];

    if (this.state.selectedEmployeeCheckbox.length === 0) {
      newArray.push(employeeId);
    } else if (newArray.indexOf(employeeId) !== -1) {
      newArray = newArray.filter((e) => e !== employeeId);
    } else {
      newArray.push(employeeId);
    }

    this.state.resources.forEach((item) => {
      newArray.forEach((itemId) => {
        if (item.id === itemId) {
          newResources.push(item);
        }
      });
    });

    this.setState({
      selectedEmployeeCheckbox: newArray,
      selectedResource: newResources,
    });
  };

  changeSelectedEmployee = (event, employee) => {
    getVisits
      .getVisitsByEmployee(this.salon_name, employee.id)
      .then((response) => response.json())
      .then((data) => {
        const visits = data
          .map((visit) => ({
            ...visit,
            ...{
              id: visit.id,
              title: this.createTitleFromServices(
                visit.services,
                this.state.salonServices,
              ),
              start: new Date(visit.appointment_at),
              end: new Date(visit.appointment_at_end_max),
              customer: visit.customer,
              color: visit.is_finalized ? "gray" : visit?.user?.color || null,
              //serviceId:visit.service.id,
              description: visit.description,
              classNames: ["hoverable-event", this.setVisitClass(visit)],
              editable: !(visit.is_finalized || visit.is_missed),
            },
          }))
          .map((visit) =>
            scaffoldVisit(
              this.state.salonEmployes,
              visit,
              this.state.salonServices,
            ),
          );

        this.setState({
          selectedCalendarEmployee: employee,
          selectedEmployeeVisits: visits,
        });
      });
  };

  onFinalizeModalClose = () => {
    this.setState({
      finalizeModal: {
        isOpen: false,
      },
    });
  };

  findAndAddColor = () => {
    const elements = document.getElementsByClassName("fc-timegrid-slot");
    const slotMinT = this.state.slotMinTime || "06:00:00";
    const slotMaxT = this.state.slotMaxTime || "19:00:00";
    if (this.state.showFullHours) {
      for (let i = 0; i < elements.length; i++) {
        const elementTime = elements[i].getAttribute("data-time");
        if (slotMinT <= elementTime && slotMaxT > elementTime) {
          elements[i].parentElement.classList.remove("night-work-background");
        } else {
          elements[i].parentElement.classList.add("night-work-background");
        }
      }
    } else {
      for (let i = 0; i < elements.length; i++) {
        elements[i].parentElement.classList.remove("night-work-background");
      }
    }
  };

  async loadEvents(dateStart, dateEnd, type) {
    this.setState({
      showOptions: type !== "dayGridMonth",
    });

    if (type === "recepcja") {
      this.setState({ isReception: true });
      this.loadResources();

      const startMoment = moment(dateStart);
      const endMoment = moment(dateEnd);

      const targetsOneDay =
        startMoment.add(1, "d").format("Y-M-d") === endMoment.format("Y-M-d");

      let start = moment(dateStart).format("Y-MM-DD", { trim: false });
      let end = moment(dateEnd).format("Y-MM-DD", { trim: false });

      if (targetsOneDay) {
        start =
          moment(dateStart).format("Y-MM-DD", { trim: false }) + " 00:00:00";
        end =
          moment(dateStart).format("Y-MM-DD", { trim: false }) + " 23:59:59";
      }

      const response = await getVisits.getVisitsBetweenDates(
        this.salon_name,
        start,
        end,
      );
      if (response.headers.get("content-type") === "application/json") {
        const data = await response.json();

        const events = [
          ...data.map((visit) => ({
            id: visit.id,
            title: this.createTitleFromServices(
              visit.services,
              this.state.salonServices,
            ),
            start: new Date(visit.appointment_at),
            end: new Date(visit.appointment_at_end_max),
            customer: visit.customer,
            //        serviceId:visit.service.id,
            description: visit.description,
            color: visit.is_finalized ? "gray" : visit?.user?.color || null,
            classNames: ["hoverable-event", this.setVisitClass(visit)],
            editable: !(visit.is_finalized || visit.is_missed),

            //title:        visit.service.title,
            //start:        moment(new Date(visit.appointment_at)).format(),
            //end:          moment(new Date(visit.appointment_at_end_max)).format(),
            resourceId: +visit.user.id,
          })),
        ];

        this.setState({
          selectedEmployeeVisits: events,
        });
      }

      this.findAndAddColor();
      return response;
    }

    Promise.all([
      getUsers
        .getSalonEmployes(this.salon_name)
        .then((response) => response.json()),
      getService
        .getSalonServices(this.salon_name)
        .then((response) => response.json()),
      getCustomers
        .getSalonCustomers(this.salon_name)
        .then((response) => response.json()),
    ]).then(async (results) => {
      const [employeeData, services, customers] = results;

      if (employeeData[0]) {
        getVisits
          .getVisitsByEmployee(this.salon_name, employeeData[0].id)
          .then((response) => response.json())
          .then((employeeVisitsData) => {
            const visits = employeeVisitsData
              .map((visit) => ({
                ...visit,
                ...{
                  id: visit.id,
                  title: this.createTitleFromServices(
                    visit.services,
                    this.state.salonServices,
                  ),
                  start: new Date(visit.appointment_at),
                  end: new Date(visit.appointment_at_end_max),
                  customer: visit.customer,
                  color: visit.is_finalized
                    ? "gray"
                    : visit?.user?.color || null,
                  //serviceId:visit.service.id,
                  description: visit.description,
                  classNames: ["hoverable-event", this.setVisitClass(visit)],
                  editable: !(visit.is_finalized || visit.is_missed),
                  services: visit.services,
                },
              }))
              .map((visit) => scaffoldVisit(employeeData, visit, services));

            this.setState({
              salonEmployes: employeeData,
            });

            if (this.state.selectedEmployeeVisits?.length === 0) {
              this.setState({
                selectedEmployeeVisits: visits,
              });
            }

            if (!this.state.selectedCalendarEmployee) {
              this.setState({
                selectedCalendarEmployee: employeeData[0],
              });
            }

            if (!this.state.formSelectedEmployee) {
              this.setState({
                formSelectedEmployee:
                  employeeData.length > 0
                    ? {
                        value: { employee: employeeData[0] },
                        label: `${employeeData[0].first_name} ${employeeData[0].last_name}`,
                      }
                    : { value: "", label: "" },
              });
            }

            this.setState({ isReception: false });

            let czas = visits;
            let czasy = [];
            czas.map((item) => {
              let diff = Math.abs(
                new Date(item.appointment_at) -
                  new Date(item.appointment_at_end_max),
              );
              let minutes = Math.floor(diff / 1000 / 60);
              czasy.push({
                id: item !== undefined ? item.id : null,
                minutes: minutes,
              });
            });

            this.setState({
              minutesTotall: czasy,
            });
            // this.changeStyle();
          });
      }

      this.setState({
        salonServices: services,
        // formSelectedService:
        //   services.length > 0
        //     ? [
        //         {
        //           value: { service: services[0] },
        //           label: `${services[0].title} (${convertTime(
        //             services[0].service_time_max,
        //           )})`,
        //         },
        //       ]
        //     : [{ value: "", label: "" }],
        formSelectedService: [{ value: "", label: "" }],
        salonCustomers: customers,
        formSelectedCustomer: { value: "", label: "" },
        // formSelectedCustomer:
        //   customers.length > 0
        //     ? {
        //         value: { customer: customers[0] },
        //         label: `${customers[0].first_name} ${customers[0].last_name}`,
        //       }
        //     : { value: "", label: "" },
      });

      this.findAndAddColor();
    });
  }

  async loadResources() {
    const response = await getUsers.getSalonEmployes(this.salon_name);
    if (response.headers.get("content-type") === "application/json") {
      const data = await response.json();

      this.setState({
        resources: data.map((item) => ({
          id: +item.id,
          title: `${item.first_name} ${item.last_name}`,
        })),
        selectedEmployeeCheckbox: data.map((item) => item.id),
        selectedResource: data.map((item) => ({
          id: +item.id,
          title: `${item.first_name} ${item.last_name}`,
        })),
        employees: data,
      });
    }
    return response;
  }

  onViewMount = (viewObject, element) => {
    const view = viewObject.view;
    if (this.loadEvents && view.currentStart && view.currentEnd) {
      this.loadEvents(
        moment(view.currentStart).format("Y-MM-DD", { trim: false }),
        moment(view.currentEnd).format("Y-MM-DD", { trim: false }),
        view.type,
      );
    }
    setTimeout(() => {
      viewObject.el
        .querySelectorAll(".fc-col-header-cell-cushion")
        .forEach((element) => {
          if (this.state.slotMinTime && this.state.slotMaxTime) {
            const maxSplit = this.state.slotMaxTime.split(":");
            const minSplit = this.state.slotMinTime.split(":");
            element.innerHTML =
              element.innerHTML +
              `<br /> ${minSplit[0]}:${minSplit[1]}-${maxSplit[0]}:${maxSplit[1]}`;
          } else {
            element.innerHTML = element.innerHTML + "<br>6:00-19:00</br>";
          }
        });
    }, 250);
  };

  render() {
    return (
      <>
        <div className="page-content">
          {this.state.finalizeModal.isOpen && (
            <FinalizeModal
              {...this.state.finalizeModal}
              onClosed={this.onFinalizeModalClose}
              handleAfterValidSubmit={(visit) => {
                this.setState((prev) => ({
                  finalizeModal: false,
                  selectedEmployeeVisits: prev.selectedEmployeeVisits.map(
                    (sev) =>
                      sev.id == visit.id
                        ? {
                            ...sev,
                            ...visit,
                            ...{
                              classNames: [
                                "hoverable-event",
                                this.setVisitClass(visit),
                              ],
                            },
                          }
                        : sev,
                  ),
                }));
              }}
            />
          )}
          <Container fluid>
            <Row>
              <Col sm={12} md={12} lg={2}>
                <Card>
                  <CardBody>
                    {/*<DateTimePickerComponent/> */}
                    {this.renderSidebar()}
                    <h3 className="mt-4">
                      Pracownicy ({this.state.salonEmployes?.length})
                    </h3>
                    <ul>
                      {this.state.salonEmployes !== undefined &&
                        this.state.salonEmployes?.map((employee) => (
                          <li key={employee.id}>
                            <Input
                              value={employee}
                              checked={
                                this.state.isReception
                                  ? this.state.selectedEmployeeCheckbox.indexOf(
                                      employee.id,
                                    ) !== -1
                                  : employee.id ===
                                    this.state.selectedCalendarEmployee.id
                              }
                              id={`employee${employee.id}`}
                              type={
                                this.state.isReception ? "checkbox" : "radio"
                              }
                              onChange={(e) => {
                                if (this.state.isReception) {
                                  this.selectEmployeeForCalendar(employee.id);
                                } else {
                                  this.setState({
                                    formSelectedEmployee: {
                                      value: { employee: { ...employee } },
                                      label: `${employee.first_name} ${employee.last_name}`,
                                    },
                                  });
                                  this.changeSelectedEmployee(e, employee);
                                }
                              }}
                            />
                            {`${employee.first_name} ${employee.last_name}`}
                          </li>
                        ))}
                    </ul>
                  </CardBody>
                </Card>

                {this.state.showOptions && (
                  <Card>
                    <CardBody>
                      <h4 className="mt-2 mb-3">Ustawienia</h4>
                      <div
                        className="custom-control custom-checkbox mb-2"
                        onClick={() => {
                          this.setState({
                            showFullHours: !this.state.showFullHours,
                          });
                        }}
                      >
                        <Input
                          type="checkbox"
                          className="custom-control-input"
                          checked={this.state.showFullHours}
                          defaultChecked={this.state.showFullHours}
                          onChange={() => {}}
                        />
                        <Label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Pokaż całą dobę
                        </Label>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
              <Col className="d-lg-none calendar-full-width-button">
                <button
                  className="fc-button"
                  onClick={() => {
                    this.setState({
                      calendarShowAll: !this.state.calendarShowAll,
                    });
                  }}
                >
                  {this.state.calendarShowAll
                    ? "Włącz scroll "
                    : "Wyłącz scroll"}
                </button>
                <button
                  className="fc-button"
                  onClick={() => {
                    this.setState({ calendarFullSize: true });
                  }}
                >
                  Pełny widok
                </button>
              </Col>
              <Col
                sm={12}
                md={12}
                lg={10}
                className={
                  this.state.calendarFullSize ? "calendar-full-width" : ""
                }
              >
                <i
                  className="mdi mdi-close-circle-outline"
                  onClick={() => {
                    this.setState({ calendarFullSize: false });
                  }}
                />
                <Card>
                  <CardBody>
                    <div
                      id="my-cal"
                      className={
                        this.state.showFullHours ? "custom-calendar" : ""
                      }
                    >
                      <FullCalendar
                        locales={plLocale}
                        locale={"pl"}
                        contentHeight={
                          this.state.calendarFullSize ||
                          this.state.calendarShowAll
                            ? window.innerHeight - 120
                            : null
                        }
                        longPressDelay={1}
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                          resourceTimeGridPlugin,
                          momentTimezonePlugin,
                          scrollGridPlugin,
                        ]}
                        eventDisplay="block"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right:
                            "dayGridMonth,timeGridWeek,timeGridDay,recepcja",
                        }}
                        initialView="dayGridMonth"
                        editable={true}
                        selectable={true}
                        eventOverlap={true}
                        slotEventOverlap={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={this.state.weekendsVisible}
                        slotMinTime={
                          this.state.showFullHours
                            ? "00:00:00"
                            : this?.state?.slotMinTime || "06:00:00"
                        }
                        slotMaxTime={
                          this.state.showFullHours
                            ? "23:59:59"
                            : this?.state?.slotMaxTime || "19:00:00"
                        }
                        slotDuration={{
                          minute: this?.state?.slotInterval || 15,
                        }}
                        slotLabelInterval={{
                          hour: 1,
                        }}
                        // slotEventOverlap={false}
                        // slotWidth={"200px"}
                        events={this.state.selectedEmployeeVisits}
                        select={this.handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        eventClick={this.handleEventClick}
                        eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                        eventDrop={this.handleEventDrop}
                        eventDurationEditable={false}
                        // businessHours={ {daysOfWeek: [ 1, 2, 3, 4, 5], // Monday - Thursday
                        expandRows={true}
                        weekNumbers={true}
                        allDaySlot={true}
                        allDayText={"Dzień"}
                        weekNumberFormat={{ week: "short" }}
                        views={{
                          recepcja: {
                            type: "resourceTimeGrid",
                            buttonText: "Recepcja",
                            // duration: { days: 1 },
                            // groupByResource: true,
                            // dayMinWidth: isMobile || isTablet ? null : 200,
                            dayMinWidth:
                              this.state.calendarFullSize ||
                              this.state.calendarShowAll
                                ? null
                                : 200,
                          },
                        }}
                        resources={this.state.selectedResource}
                        viewDidMount={this.onViewMount}
                        datesSet={(dateInfo) =>
                          this.loadEvents(
                            dateInfo.start,
                            dateInfo.end,
                            dateInfo.view.type,
                          )
                        }
                        //   startTime: '8:00',
                        //   endTime: '18:00', }}
                        /* you can update a remote database when these fire:
                              eventAdd={function(){}}
                              eventChange={function(){}}
                              eventRemove={function(){}}
                              */
                        ref={this.calendarRef}
                        // eventMouseEnter={function (info) {
                        //   var tis = info.el;
                        //   var popup = info.event.extendedProps.popup;
                        //   console.log(tis.offsetTop);
                        //   console.log(popup);
                        //   var tooltip =
                        //     '<div class="tooltipevent" style="top:' +
                        //     (tis.offsetTop - 5) +
                        //     "px;left:" +
                        //     (tis.offsetLeft + tis.offsetWidth / 2) +
                        //     'px"><div>' +
                        //     "test21123" +
                        //     "</div><div>" +
                        //     "test2" +
                        //     "</div></div>";
                        //   return (document.body.innerHTML += tooltip);
                        // }}
                        // eventMouseLeave={function (info) {
                        //   console.log(info);
                        //   info.el.style.zIndex = 8;
                        //   document
                        //     .getElementsByClassName("tooltipevent")[0]
                        //     .remove();
                        // }}
                        // eventDidMount={function (info) {
                        //   // var tooltip = new Tooltip(info.el, {
                        //   //   title: info.event.extendedProps.total_price_gross,
                        //   //   placement: "top",
                        //   //   trigger: "hover",
                        //   //   container: "body",
                        //   // });
                        // }}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }

  onPickerDateChange = (date) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.gotoDate(date);
    this.setState({
      pickerDate: date,
    });
  };

  reloadLoading = () => {
    this.setState({
      isAddingVisit: false,
    });
  };

  filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    const isAdmin =
      localStorage.getItem("is_owner") || localStorage.getItem("is_sa");

    if (isAdmin) {
      return true;
    }

    return currentDate.getTime() < selectedDate.getTime();
  };

  addCustomer = (event, errors, values) => {
    if (errors.length > 0) {
      ToastNotifications("error", "Uzupełnij wymagające dane", "Błąd!");
    } else {
      postCustomer
        .registerCustomer(this.salon_name, values)
        .then((response) => response.json())
        .then((response) => {
          this.setState((prevState) => ({
            modal_addCustomer: !prevState.modal_addCustomer,
            salonCustomers: [...prevState.salonCustomers, response],
          }));
          this.handleSelectCustomer({
            label: `${response.first_name} ${response.last_name}`,
            value: {
              customer: {
                ...response,
              },
            },
          });
          ToastNotifications("success", "Klient został dodany.", "Sukces!");
        });
    }
  };

  addVisit = (event, values) => {
    if (!this.state.isAddingVisit) {
      this.setState({
        isAddingVisit: true,
      });

      const selectedId = this.state.formSelectedService.reduce((a, b) => {
        if (b.value?.service?.id) {
          a.push(b.value?.service?.id);
        }

        return a;
      }, []);

      let dataBody = {
        total_price_net: parseFloat(this.state.totalNetPrice),
        total_price_gross: parseFloat(this.state.totalGrossPrice),
        total_price_tax: parseFloat(this.state.totalTax),
        note: this.state.noteAsHelper,

        description: values.description,
        appointment_at: moment(this.state.formSelectedDate).format(
          "YYYY-MM-DD HH:mm:ss",
        ),
        user: this.state.formSelectedEmployee?.value?.employee?.id,
        customer: this.state.formSelectedCustomer?.value?.customer?.id,
        services: selectedId,
      };
      let statusCode;
      let hasError = 0;
      let hasErrorText = "";
      let hasErrorTitle = "";

      if (dataBody.appointment_at === "Invalid date") {
        hasError = hasError + 1;
        this.setState({
          isAddingVisit: false,
        });
        hasErrorText = "Wybierz poprawną datę";
        hasErrorTitle = "Zła data";
      }
      if (!this.state.formSelectedCustomer?.value?.customer?.id) {
        hasError = hasError + 1;
        this.setState({
          isAddingVisit: false,
          addVisitClientError: true,
        });
        hasErrorText = "Wybierz klienta";
        hasErrorTitle = "Brak klienta";
      }
      if (!selectedId.length) {
        hasError = hasError + 1;
        this.setState({
          isAddingVisit: false,
          addServiceError: true,
        });
        hasErrorText = "Brak wybranej usługi";
        hasErrorTitle = "Wybierz usługe";
      }
      if (hasError > 0) {
        if (hasError > 1) {
          hasErrorText = "Uzupełnij poprawnie dane";
          hasErrorTitle = "Uwaga!";
        }
        return ToastNotifications("error", hasErrorText, hasErrorTitle);
      } else {
        postVisits
          .createVisit(dataBody, this.salon_name)
          .then((response) => {
            statusCode = response.status;
            if (statusCode !== 201) {
              return false;
            }
            return response.json();
          })
          .then((data) => {
            if (statusCode === 201) {
              const service = this.state.salonServices.find(
                (ss) => ss.id === dataBody.services[0],
              );
              localStorage.setItem("isVisable", true);

              let visit = {
                id: data.id,
                total_price_net: data.totalNetPrice,
                total_price_gross: data.totalGrossPrice,
                total_price_tax: data.totalTax,
                note: data.noteAsHelper,

                title: this.createTitleFromServices(
                  selectedId,
                  this.state.salonServices,
                ),
                start: new Date(data.appointment_at),
                end: new Date(data.appointment_at_end_max),
                customer: data.customer,
                color: data.is_finalized ? "gray" : data?.user?.color || null,
                serviceId: service.id,
                description: data.description,
                is_finalized: data.is_finalized,
                is_missed: data.is_missed,
                editable: !(data.is_finalized || data.is_missed),
                user: data?.user,
                classNames: ["hoverable-event", this.setVisitClass(data)],
              };

              this.setState((prevState) => ({
                selectedEmployeeVisits: [
                  ...prevState.selectedEmployeeVisits,
                  visit,
                ],
                modal_standard: !prevState.modal_standard,
                formSelectedCustomer: { value: "", label: "" },
              }));
              setTimeout(() => {
                this.reloadLoading();
                if (this.state.isReception) {
                  document
                    .getElementsByClassName("fc-recepcja-button")[0]
                    .click();
                }
              }, 1000);
              ToastNotifications("success", "Wizyta Została dodana.", "Sukces");
            } else {
              ToastNotifications(
                "error",
                "Wystąpił błąd podczas dodawania wizyty. Sprawdź poprawność danych i spróbuj ponownie.",
                "Wystąpił błąd",
              );

              setTimeout(() => {
                this.reloadLoading();
              }, 1000);
            }
          });
      }
    }
  };

  renderSidebar() {
    const { formSelectedDate, formSelectedService } = this.state;
    let appointmentStart = formSelectedDate
      ? moment(formSelectedDate).format("DD/MM/YYYY HH:mm")
      : "";
    let appointmentEnd = moment(formSelectedDate).add(1, "m");
    let appointmentEndEmpty = moment(formSelectedDate).add(0, "m");
    let multiTitle = "";
    if (formSelectedService?.length) {
      if (formSelectedService[0]?.value) {
        const totalTime = formSelectedService.reduce(
          (a, b) => a + b.value.service.service_time_max,
          0,
        );
        const totalTitle = formSelectedService.reduce(
          (a, b) => a + b.value.service.title + ", ",
          "",
        );
        appointmentEnd = moment(
          moment(formSelectedDate).add(totalTime, "m").toDate(),
        );

        multiTitle = totalTitle;
      }
    }

    const renderTime = () => {
      if (
        formSelectedService?.length &&
        formSelectedService[0]?.value?.service?.service_time_max
      ) {
        const totalTime = formSelectedService.reduce(
          (a, b) => a + b.value.service.service_time_max,
          0,
        );
        return (
          <p>
            {`Czas wizyty: `}
            <strong>{convertTime(totalTime)}</strong>
          </p>
        );
      }

      return <></>;
    };

    const renderTitle = () => {
      if (!this.state.salonServices?.length) {
        return <h2>Musisz utworzyć przynajmniej jedną usługę</h2>;
      }

      if (
        !formSelectedService ||
        (formSelectedService?.length && !formSelectedService[0]?.value)
      ) {
        return (
          <>
            Wizyta --- {appointmentStart} -{" "}
            {appointmentEndEmpty.format("HH:mm")}
          </>
        );
      }

      if (formSelectedService?.length && formSelectedService[0]?.value) {
        return (
          <>
            {formSelectedService.length > 1
              ? `${multiTitle}`
              : `${formSelectedService[0]?.value?.service.title}`}{" "}
            {appointmentStart} - {appointmentEnd.format("HH:mm")}
            {/* <a href="#">zmień</a> */}
          </>
        );
      }

      return <></>;
    };

    const CustomValueContainer = ({ children, ...props }) => {
      const isActive = !!(
        this.state.formSelectedService &&
        this.state.formSelectedService[0].value
      );

      return (
        <ValueContainer {...props}>
          {React.Children.map(children, (child) =>
            child && child.type !== Placeholder ? child : null,
          )}
          <div
            className={`custom-placeholder ${
              isActive ? "custom-placeholder-active" : ""
            }`}
          >
            {this.state.formSelectedService &&
            this.state.formSelectedService[0].value
              ? "Dodaj kolejną"
              : props.selectProps.placeholder}
          </div>
        </ValueContainer>
      );
    };

    const renderServiceOptions = () =>
      this.state.salonServices
        .filter((service) => {
          const data =
            this.state.formSelectedEmployee?.value?.employee
              ?.servicesPerformed ||
            this.state.formSelectedEmployee?.value?.employee
              ?.services_performed ||
            [];

          if (!data.length) {
            return service;
          }

          if (data.filter((item) => item === service.id).length) {
            return service;
          }
        })
        .map((service) => ({
          value: { service },
          label: `${service.title} (${convertTime(service.service_time_max)})`,
        }));

    return (
      <>
        <div className="sidebar mb-5">
          <div className="sidebar-section">
            <SidebarCalendar
              onChange={(value) => this.onPickerDateChange(value)}
              value={this.state.pickerDate}
            />
            {/*<Label className="m-4">*/}
            {/*  <Input*/}
            {/*    type="checkbox"*/}
            {/*    checked={this.state.weekendsVisible}*/}
            {/*    onChange={this.handleWeekendsToggle}*/}
            {/*  />*/}
            {/*  wł. / wył. weekendy*/}
            {/*</Label>*/}

            {/*<h3 color="primary">*/}
            {/*  Wydarzenia ({this.state.selectedEmployeeVisits.length})*/}
            {/*</h3>*/}

            {/*<ul className="list-group visit-list">*/}
            {/*  {this.state.currentEvents.map(renderSidebarEvent)}*/}
            {/*</ul>*/}
          </div>
        </div>
        <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
          <ModalHeader>{renderTitle()}</ModalHeader>
          <ModalBody>
            {this.state.salonServices?.length && (
              <AvForm onValidSubmit={this.addVisit} id="addVisit">
                <FormGroup className="select2-container">
                  <Label>Klient</Label>
                  <Select
                    value={this.state.formSelectedCustomer}
                    onChange={this.handleSelectCustomer}
                    options={this.state.salonCustomers.map((customer, idx) => ({
                      value: { customer },
                      label: `${customer.first_name} ${customer.last_name}`,
                    }))}
                    classNamePrefix={`${
                      this.state.addVisitClientError
                        ? "select-client-error"
                        : ""
                    } select2-selection`}
                    isSearchable={true}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Wybierz klienta",
                      },
                    }}
                  />
                </FormGroup>
                <Button
                  onClick={this.tog_addCustomer}
                  className="mb-2 text-center w-100"
                >
                  Dodaj Klienta
                </Button>
                <FormGroup className="select2-container">
                  <Label>Pracownik</Label>
                  <Select
                    value={this.state.formSelectedEmployee}
                    onChange={this.handleSelectEmployee}
                    options={this.state.salonEmployes?.map((employee, idx) => ({
                      value: { employee },
                      label: `${employee.first_name} ${employee.last_name}`,
                    }))}
                    classNamePrefix="select2-selection"
                    isSearchable={false}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Wybierz pracownika",
                      },
                    }}
                  />
                </FormGroup>
                <FormGroup className="select2-container">
                  <Label>Usługa</Label>
                  <Select
                    components={{ ValueContainer: CustomValueContainer }}
                    value={
                      this.state.formSelectedService?.length &&
                      this.state.formSelectedService[0]?.value
                        ? this.state.formSelectedService
                        : null
                    }
                    onChange={this.handleSelectService}
                    options={renderServiceOptions()}
                    isMulti
                    placeholder="Wybierz usługę lub usługi"
                    classNamePrefix={`${
                      this.state.addServiceError ? "select-client-error" : ""
                    } select2-selection`}
                    isSearchable={false}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "Wybierz usługe",
                      },
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <div className="form-flex-price">
                    <p>
                      {`Cena netto: `}
                      <strong>{this.state.serviceNetPrice}zł</strong>
                      <br />
                      {`VAT: `} <strong>{this.state.taxValue}%</strong>{" "}
                      <strong>({this.state.totalTax} zł)</strong>
                      <br />
                      {`Cena brutto: `}
                      <strong>{this.state.serviceGrossPrice}zł</strong>
                    </p>
                    {renderTime()}
                  </div>
                </FormGroup>

                <AvField
                  className=""
                  name="netPrice"
                  label="Cena netto"
                  type="number"
                  min={0.0}
                  step={0.01}
                  id="netPrice"
                  // onChange={this.handleTotalNetPriceClick}
                  disabled
                  value={
                    this.state.totalNetPrice === 0
                      ? "0"
                      : this.state.totalNetPrice
                  }
                />

                <AvField
                  className=""
                  name="grossPrice"
                  label="Cena brutto"
                  type="number"
                  min={0.0}
                  step={0.01}
                  id="grossPrice"
                  onChange={this.handleTotalGrossPriceClick}
                  value={
                    this.state.totalGrossPrice === 0
                      ? "0"
                      : this.state.totalGrossPrice
                  }
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Wpisz cenę brutto",
                    },
                  }}
                />

                <Label>Data</Label>
                <DatePicker
                  selected={this.state.formSelectedDate}
                  onChange={(date) => {
                    this.setState({ formSelectedDate: date });
                  }}
                  showTimeSelect
                  filterTime={this.filterPassedTime}
                  minTime={new Date(this.state.formSelectedDate).setHours(
                    Number(this.state.slotMinTime.split(":")[0]),
                  )}
                  maxTime={new Date(this.state.formSelectedDate).setHours(
                    Number(this.state.slotMaxTime.split(":")[0]),
                  )}
                  locale="pl"
                  timeFormat="p"
                  timeIntervals={5}
                  dateFormat="Pp"
                  className={`form-visit-datapicker ${
                    !moment(this.state.formSelectedDate).isValid() && "error"
                  }`}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: "Wybierz datę",
                    },
                  }}
                />
                <AvField
                  className="addDescription"
                  name="description"
                  label="Opis"
                  type="text"
                  placeholder="Wprowadź opis"
                  errorMessage="Wprowadź opis"
                  validate={{ required: { value: false } }}
                  id="addDescription"
                />

                {/*<AvField*/}
                {/*  className=""*/}
                {/*  name="tax"*/}
                {/*  label="wartość podatku"*/}
                {/*  type="number"*/}
                {/*  min={0.0}*/}
                {/*  step={0.01}*/}
                {/*  id="tax"*/}
                {/*  disabled*/}
                {/*  // onChange={this.handleTotalTaxClick}*/}
                {/*  value={formSelectedService.value.service.tax.value}*/}
                {/*/>*/}

                <AvField
                  className="textArea"
                  name="note"
                  label="Notka (widoczne dla pracowników)"
                  type="textarea"
                  id="note"
                  onChange={this.handleTotalNotAsHelperClick}
                />

                <FormGroup className="mb-0">
                  <div>
                    <Button
                      id="add_new_customer"
                      type="submit"
                      color="primary"
                      className="mr-1"
                    >
                      Dodaj wizytę
                    </Button>{" "}
                    <Button
                      onClick={this.tog_standard}
                      type="reset"
                      color="secondary"
                    >
                      Anuluj
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            )}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal_addCustomer}
          toggle={this.tog_addCustomer}
        >
          <ModalHeader
            toggle={() => this.setState({ modal_addCustomer: false })}
          >
            Dodaj Klienta
          </ModalHeader>
          <ModalBody>
            <AvForm onSubmit={this.addCustomer} id="addCustomerForm">
              <AvField
                onChange={this.handleCheckField}
                className="addCustomerField"
                name="first_name"
                label="Imię"
                placeholder="Wprowadź imię"
                type="text"
                errorMessage="Wprowadź imię"
                validate={{ required: { value: true } }}
                id="addFirstName"
              />
              <AvField
                className="addCustomerField"
                name="last_name"
                label="Nazwisko"
                type="text"
                placeholder="Wprowadź nazwisko"
                errorMessage="Wprowadź nazwisko"
                validate={{ required: { value: true } }}
                id="addLastName"
              />
              <AvField
                onChange={this.handleCheckField}
                className="addCustomerField"
                name="phone_number"
                label="Numer telefon"
                type="text"
                errorMessage="Wprowadź prawidłowy numer"
                id="addPhoneNumber"
                placeholder="Wprowadź numer telefonu"
                validate={{
                  pattern: {
                    value: this.phoneRegex,
                  },
                }}
              />
              <AvField
                className="addCustomerField"
                name="locality"
                label="Lokalizacja"
                type="text"
                placeholder="Wprowadź lokalizacje"
                errorMessage="Wprowadź lokalizacje"
                validate={{ required: { value: false } }}
                id="addLocality"
              />
              <AvField
                className="addCustomerField"
                name="birthdate"
                label="Data"
                type="date"
                errorMessage="Wprowadź datę urodzenia"
                validate={{ required: { value: false } }}
                id="addBirthdate"
              />
              <AvField
                className="addCustomerField"
                name="email"
                label="E-Mail  "
                placeholder="Wprowadź adres e-mail"
                type="email"
                errorMessage="Nieprawidłowy adres e-mail"
                validate={{
                  required: { value: false },
                  email: { value: true },
                }}
                id="addEmail"
              />
              <FormGroup className="mb-0">
                <div>
                  <Button
                    id="add_new_customer"
                    color="primary"
                    className="mr-1"
                  >
                    Dodaj Klienta
                  </Button>{" "}
                  <Button
                    onClick={this.tog_addCustomer}
                    type="reset"
                    color="secondary"
                  >
                    Anuluj
                  </Button>
                </div>
              </FormGroup>
            </AvForm>
          </ModalBody>
        </Modal>
      </>
    );
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleEventDragStart = (info) => {
    //  info.event.setProp('backgroundColor','yellow');
  };

  handleEventDragStop = (info) => {
    // info.event.setProp('classNames',['testowa-red'])
    // info.event.setProp('backgroundColor','black');
  };

  handleDateSelect = (selectInfo) => {
    this.tog_standard();
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    if (selectInfo?.resource?._resource?.id) {
      const employeeResourceData = this.state.employees.filter(
        (item) =>
          Number(item.id) === Number(selectInfo?.resource?._resource?.id),
      );
      this.setState({
        formSelectedEmployee: {
          value: { employee: { ...employeeResourceData[0] } },
          label: selectInfo?.resource?._resource?.title,
        },
      });
    }
    const newDate = selectInfo.view.type === "dayGridMonth";
    const changeDate = new Date(selectInfo.start);
    changeDate.setHours(Number(this.state.slotMinTime.split(":")[0]));

    this.setState({
      formSelectedDate: newDate ? changeDate : new Date(selectInfo.start),
    });

    // if (title) {
    //   calendarApi.addEvent({
    //     id: createEventId(),
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay
    //   })
    // }
  };

  handleEventClick = (clickInfo) => {
    const selectedEmployeeVisit = this.state.selectedEmployeeVisits.find(
      (sev) => sev.id == clickInfo.event.id,
    );

    deleteMsg
      .fire({
        title: `${clickInfo.event.title}`,
        html:
          "<p><b>" +
          moment(clickInfo.event.start).format("H:mm") +
          " - " +
          moment(clickInfo.event.end).format("H:mm") +
          "</b></p>" +
          '<p className="text-white m-2 d-inline">' +
          (clickInfo.event.extendedProps.customer
            ? clickInfo.event.extendedProps.customer.first_name + " "
            : "") +
          (clickInfo.event.extendedProps.customer
            ? clickInfo.event.extendedProps.customer.last_name + " "
            : "") +
          "</p><br/>",
        icon: "info",
        showCancelButton:
          selectedEmployeeVisit.editable || selectedEmployeeVisit.is_missed,
        showCloseButton: true,
        showDenyButton:
          selectedEmployeeVisit.editable || selectedEmployeeVisit.is_finalized,
        confirmButtonText: "Usuń",
        denyButtonText: selectedEmployeeVisit.editable
          ? "Finalizuj"
          : "Sfinalizowano",
        denyButtonColor: "#1cbb8c",
        cancelButtonText: selectedEmployeeVisit.editable
          ? "Nie odbyła się"
          : "Wizyta nie odbyła się",
        reverseButtons: true,
      })
      .then((result) => {
        if (
          result.isDismissed &&
          result.dismiss === "cancel" &&
          selectedEmployeeVisit.editable
        ) {
          let dataBody = {
            is_missed: true,
            user: clickInfo.event.extendedProps.user.id,
            customer: clickInfo.event.extendedProps.customer.id,
            appointment_at: moment(selectedEmployeeVisit.start).format(
              "YYYY-MM-DD HH:mm:ss",
            ),
          };
          patchVisits
            .updateVisit(this.salon_name, clickInfo.event.id, dataBody)
            .then((response) => response.json())
            .then((result) => {
              if (result) {
                ToastNotifications(
                  "success",
                  "Status wizyty został zmieniony",
                  "Sukces!",
                );
                this.setState((prev) => ({
                  selectedEmployeeVisits: prev.selectedEmployeeVisits.map(
                    (sev) =>
                      sev.id === result.id
                        ? {
                            ...sev,
                            ...result,
                            ...{
                              classNames: [
                                "hoverable-event",
                                this.setVisitClass(result),
                              ],
                            },
                          }
                        : sev,
                  ),
                }));
              }
            });
        } else if (result.isConfirmed) {
          deleteVisits
            .deleteVisit(this.salon_name, clickInfo.event.id)
            .then(() => {
              ToastNotifications(
                "success",
                "Wizyta została usunięta",
                "Sukces!",
              );
              this.setState({
                selectedEmployeeVisits:
                  this.state.selectedEmployeeVisits.filter(
                    (item) => item.id != clickInfo.event.id,
                  ),
              });
            });
        } else if (result.isDenied && selectedEmployeeVisit.editable) {
          this.setState({
            finalizeModal: {
              isOpen: true,
              salon_name: this.salon_name,
              employee: this.state.selectedCalendarEmployee,
              customer: clickInfo.event.extendedProps.customer,
              event: clickInfo.event,
            },
          });
        }
      });
  };

  handleEventDrop = (eventDropInfo) => {
    let dataBody = {
      description: eventDropInfo.event.extendedProps.description,
      user: this.state.selectedCalendarEmployee.id,
      customer: eventDropInfo.event.extendedProps.customer.id,
      service: eventDropInfo.event.extendedProps.serviceId,
      appointment_at: moment(eventDropInfo.event.start).format(
        "YYYY-MM-DD HH:mm:ss",
      ),
    };
    patchVisits
      .updateVisit(this.salon_name, eventDropInfo.event.id, dataBody)
      .then((response) => response.json());
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
  };
}

function renderEventContent(eventInfo) {
  return (
    <div title="r" className="event-container">
      <p className="text-white m-2 d-inline">
        {moment(eventInfo.event.start).format("H:mm")}-
        {moment(eventInfo.event.end).format("H:mm")}
      </p>
      <br />
      <p className="text-white m-2 d-inline">{eventInfo.event.title}</p>
      <br />
      <p className="text-white m-2 d-inline">
        {eventInfo.event.extendedProps.customer
          ? eventInfo.event.extendedProps.customer.first_name + " "
          : ""}
        {eventInfo.event.extendedProps.customer
          ? eventInfo.event.extendedProps.customer.last_name
          : ""}
      </p>
      <br />
    </div>
  );
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id} className="list-group-item text-justify">
      <b>
        {formatDate(event.start, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </b>
      <i>
        {" "}
        - {event.title} <br /> {event.extendedProps.customer.first_name}{" "}
        {event.extendedProps.customer.last_name}
      </i>
    </li>
  );
}

const addMsg = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const deleteMsg = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-light",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});
