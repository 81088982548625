import React, { Component } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Button, Col, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";

export default class Order extends Component {
  render() {
    return (
      <div className="container page-content min-vh-100">
        <Col xs={12}>
          <h1 className={"pb-3"}>Zamów dostawę</h1>
        </Col>
        <AvForm id="userLoginForm" className="form-horizontal">
          <FormGroup className="auth-form-group-custom mb-4">
            <Label htmlFor="name">Nazwa zamówienia</Label>
            <AvField
              name="name"
              type="text"
              className="form-control"
              id="name"
              validate={{ required: true }}
              placeholder="Nazwa zamówienia"
            />
          </FormGroup>

          <FormGroup>
            <Label for="orderDate">Na kiedy chcesz dostawę?</Label>
            <DatePicker
              selected={new Date()}
              name="orderDate"
              id={"orderDate"}
              showTimeSelect
              locale="pl"
              timeFormat="p"
              timeIntervals={5}
              dateFormat="Pp"
              className="form-visit-datapicker"
              maxDate={new Date()}
              required
            />
          </FormGroup>

          <FormGroup className="auth-form-group-custom mb-4">
            <Label htmlFor="message">Lista produktów</Label>
            <AvField
              name="message"
              type={"products"}
              style={{ height: 200 }}
              className="form-control"
              id="products"
              placeholder="Lista produktów jakimi jesteś zainteresowany"
            />
          </FormGroup>

          <FormGroup className="select2-container">
            <Label>Marki</Label>
            <Select
              options={[
                "Yellow",
                "Swarzkop",
                "GK HAIR",
                "Jewula Cosmetics",
              ].map((item) => ({ value: item, label: item }))}
              classNamePrefix="select2-selection"
              isSearchable={false}
              validate={{ required: true }}
            />
          </FormGroup>

          <div className="mt-4 text-center">
            <Button
              color="primary"
              className="w-md waves-effect waves-light fluid login-btn p-3"
              type="submit"
            >
              Zamów
            </Button>
          </div>
        </AvForm>
      </div>
    );
  }
}
