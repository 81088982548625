 export const postWarehouseProducts = async (dataBody) => {
    return await fetch(`${process.env.REACT_APP_HOST_API}/api/v1/warehouse-products`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(dataBody),
    });
  };
  