import React from "react";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import * as postUsers from "../../../Api/Users/postUsers";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";

export const defaultState = {
  btnColor: "primary",
  salon_name: localStorage.getItem("salon_name"),
  color: "",
  colorRgb: "#5664d2",
  simple_color1: "",
  simple_color: false,
  id: undefined,
  enabled: 0,
  first_name: undefined,
  last_name: undefined,
  roles: [
    {
      value: "ROLE_EMPLOYEE",
      label: "Employee",
    },
    {
      value: "ROLE_ANALYST",
      label: "Analyst",
    },
  ],
  username: undefined,
  password: undefined,

  selectedRoles: [
    {
      value: "ROLE_EMPLOYEE",
      label: "Employee",
    },
  ],

  customErrors: {
    username: [],
  },
};

export default class EmployeesCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.state = { ...defaultState, ...props };
  }

  onDragRgb(c1) {
    this.setState({ colorRgb: c1 });
  }

  handleValidSubmit(event, values) {
    const { handleAfterValidSubmit } = this.props;

    const body = {
      enabled: values.enabled,
      first_name: values.first_name,
      last_name: values.last_name,
      roles: values.roles,
      username: values.username,
      password: values.password,
      color: this.state.colorRgb,
    };

    postUsers
      .registerUser(body, this.state.salon_name)
      .then(async (response) => {
        const newState = {
          btnColor: "success",
          customErrors: this.state.customErrors,
        };

        const status = response.status;
        if (status >= 200 && status < 300) {
          newState.customErrors = {
            ...newState,
            ...{
              customErrors: {
                username: [],
              },
            },
          };

          this.setState(newState);
          if (handleAfterValidSubmit) {
            handleAfterValidSubmit(body);
          }
          return response;
        }

        newState.btnColor = "danger";
        const data = await response.text(); // we have an error here

        if (status === 400) {
          switch (data) {
            case "Username already exists.":
              newState.customErrors.username = [
                ...this.state.customErrors.username,
                ["Taki użytkownik już istnieje"],
              ];
              break;
          }
        }

        this.setState(newState);
      });
  }

  handleSubmit(event, errors, values) {
    if (this.state.selectedRoles === null) {
      errors = [...errors, "roles"];
    }
    this.setState({ errors, values });
  }

  handleInvalidSubmit(event, errors, values) {}

  render() {
    return (
      <AvForm
        className="form-horizontal"
        onSubmit={this.handleSubmit}
        onInvalidSubmit={this.handleInvalidSubmit}
        onValidSubmit={this.handleValidSubmit}
        model={this.state}
      >
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <AvField
                        name="username"
                        label="Login"
                        errorMessage="Wpisz poprawnie e-mail"
                        validate={{
                          email: true,
                          required: {
                            value: true,
                            errorMessage: "Wpisz poprawnie e-mail",
                          },
                        }}
                        placeholder={"example@example.com"}
                      />
                      {this.state.customErrors.username &&
                        this.state.customErrors.username.map((customError) => (
                          <span className="text-danger pb-2">
                            {customError}
                          </span>
                        ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField
                        name="first_name"
                        label="Imię"
                        errorMessage="Wpisz poprawnie Imię"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Wpisz poprawnie imię",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField
                        name="last_name"
                        label="Nazwisko"
                        errorMessage="Wpisz poprawnie Nazwisko"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Wpisz poprawnie nazwisko",
                          },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvGroup className="select2-container">
                        <Label>Role</Label>
                        <Select
                          name="roles"
                          defaultValue={this.state.roles[0]}
                          value={this.state.selectedRoles}
                          isMulti
                          options={this.state.roles}
                          onChange={(values) => {
                            this.setState({ selectedRoles: values });
                          }}
                          className="select2 select2-multiple"
                        />
                        {this.state.errors &&
                          this.state.errors.indexOf("roles") >= 0 && (
                            <span className="text-danger">
                              Wybierz przynajmniej jedną rolę
                            </span>
                          )}
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <FormGroup className="m-b-0">
                        <Label>Kolor</Label>
                        <InputGroup
                          className="colorpicker-default"
                          title="Using format option"
                        >
                          <Input
                            readOnly
                            value={this.state.colorRgb}
                            type="text"
                            className="form-control input-lg"
                          />
                          <InputGroupAddon addonType="append">
                            <span
                              className="input-group-text colorpicker-input-addon"
                              onClick={() =>
                                this.setState({
                                  simple_color1: !this.state.simple_color1,
                                })
                              }
                            >
                              <i
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  background: this.state.colorRgb,
                                }}
                              />
                            </span>
                          </InputGroupAddon>
                        </InputGroup>

                        {this.state.simple_color1 ? (
                          <ColorPicker
                            saturationHeight={100}
                            saturationWidth={100}
                            value={this.state.colorRgb}
                            onDrag={this.onDragRgb.bind(this)}
                          />
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField type="select" name="enabled" label="Aktywny">
                        <option value={0}>Nie</option>
                        <option value={1}>Tak</option>
                      </AvField>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField
                        autoComplete="new-password"
                        type="password"
                        name="password"
                        label="Hasło"
                        errorMessage="Hasła powinno mieć minimum 6 znaków"
                        validate={{ required: true, minLength: { value: 6 } }}
                      />
                    </Col>
                    <Col xs="12">
                      <AvField
                        type="password"
                        name="password_confirmation"
                        label="Potwierdź hasło"
                        errorMessage="Hasła nie są takie same"
                        validate={{
                          required: {
                            value: true,
                            error: "test",
                            errorMessage: "Hasła nie są takie same",
                          },
                          match: { value: "password" },
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="my-3">
          <Button
            color={this.state.btnColor}
            className="w-md waves-effect waves-light"
            type="submit"
          >
            Zapisz
          </Button>
        </div>
      </AvForm>
    );
  }
}
