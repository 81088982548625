import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as getCategories from "../../Api/Products/Categories/getProductCategories";
import * as deleteProductCategory from "../../Api/Products/Categories/deleteProductCategory";
import * as getTaxes from "../../Api/Taxes/getTaxes";
import * as postCategory from "../../Api/Products/Categories/postProductCategory";
import * as patchCategory from "../../Api/Products/Categories/patchCategories";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import Swal from "sweetalert2";

function getModalStyle() {
  const top = 10;
  const left = 10;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Categories = () => {
  const salon_name = localStorage.getItem("salon_name");
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [taxes, setTaxes] = useState([]);
  const [edit, setEdit] = useState(false);
  const [categoryForm, setCategoryForm] = useState({
    name: "",
    product_category_root: "",
    tax: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let isMounted = true;
    getCategories
      .getProductCategories(salon_name)
      .then((response) => response.json())
      .then((data) => {
        setCategories(
          data.map((category) => ({
            ...category,
            product_category_root: category.product_category_root
              ? category.product_category_root
              : "",
            tax: category.tax ? category.tax : "",
          })),
        );
      });
    getTaxes
      .getAllTaxes()
      .then((response) => response.json())
      .then((data) => setTaxes(data));
    return () => {
      isMounted = false;
    };
  }, []);

  const deleteCategory = (e, id) => {
    console.log(id);

    deleteMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Zmiany będą nieodwracalne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteProductCategory
            .deleteProductCategory(salon_name, id)
            .then((response) => {
              if (response.ok) {
                setCategories(categories.filter((cat) => cat.id !== id));
                addMsg.fire(
                  "Sukces!",
                  "Kategoria została usunięta pomyślnie.",
                  "success",
                );
              }
            });
        }
      });
  };
  const handleNameChange = (e) => {
    setCategoryForm({ ...categoryForm, name: e.target.value });
  };

  const handleTaxesChange = (e) => {
    setCategoryForm({ ...categoryForm, tax: e.target.value });
  };

  const handleRootCategoryChange = (e) => {
    setCategoryForm({ ...categoryForm, product_category_root: e.target.value });
  };

  const handleDataSubmit = (e) => {
    e.preventDefault();
    let body = categoryForm.product_category_root
      ? {
          name: categoryForm.name,
          product_category_root: categoryForm.product_category_root.id,
          tax: categoryForm.tax.id,
        }
      : { name: categoryForm.name, tax: categoryForm.tax.id };
    if (edit) {
      patchCategory
        .updateCategory(salon_name, categoryForm.id, body)
        .then((response) => {
          if (response.ok) {
            getCategories
              .getProductCategories(salon_name)
              .then((response) => response.json())
              .then((data) => {
                setCategories(
                  data.map((category) => ({
                    ...category,
                    product_category_root: category.product_category_root
                      ? category.product_category_root
                      : "",
                    tax: category.tax ? category.tax : "",
                  })),
                );
                setCategoryForm({
                  name: "",
                  product_category_root: "",
                  tax: "",
                });
                setOpen(false);
                addMsg.fire(
                  "Sukces!",
                  "Kategoria została edytowana pomyślnie.",
                  "success",
                );
              });
          }
        });
    } else {
      postCategory.postProductCategory(salon_name, body).then((response) => {
        setOpen(false);
        if (response.ok) {
          addMsg.fire(
            "Sukces!",
            "Kategoria została dodana pomyślnie.",
            "success",
          );

          response.json().then((data) => {
            setCategories([...categories, { ...categoryForm, id: data.id }]);
            setCategoryForm({ name: "", product_category_root: "", tax: "" });
          });
        }
      });
    }
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <form onSubmit={handleDataSubmit}>
        <h3 className="mb-3">Dodaj Kategorie</h3>
        <Paper elevation={3} className="p-3">
          <FormControl required className="w-100 my-3">
            <TextField
              id="filled-name"
              label="Nazwa"
              value={categoryForm.name}
              onChange={handleNameChange}
              variant="outlined"
              required
            />
          </FormControl>

          <FormControl className="w-100 my-3">
            <InputLabel id="demo-controlled-open-select-label">
              Kategoria nadrzędna
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={categoryForm.product_category_root}
              onChange={handleRootCategoryChange}
            >
              {categories
                .filter((option) => option.id !== categoryForm.id)
                .map((option) => (
                  <MenuItem
                    key={`${option.name}`}
                    value={option}
                    className="text-capitalize"
                  >
                    {`${option.name}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl className="w-100 my-3">
            <InputLabel id="demo-controlled-open-select-label">
              Podatki
            </InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              value={categoryForm.tax}
              onChange={handleTaxesChange}
            >
              {taxes.map((tax) => (
                <MenuItem
                  key={`${tax.id}`}
                  value={tax}
                  className="text-capitalize"
                >
                  {`${tax.value}%`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" variant="contained" color="primary">
            {edit ? "Edytuj" : "Dodaj"} Kategorie
          </Button>
        </Paper>
      </form>
    </div>
  );

  const editCategory = (e, categoryRow) => {
    setEdit(true);
    let categoryOption = categories.find(
      (selectedOption) =>
        selectedOption.id === categoryRow.product_category_root.id,
    );

    setCategoryForm({
      id: categoryRow.id,
      name: categoryRow.name,
      product_category_root: categoryOption,
      tax: taxes.find((tax) => tax.id === categoryRow.tax.id),
    });
    handleOpen();
  };

  const addCategory = () => {
    setEdit(false);
    setCategoryForm({ name: "", product_category_root: "", tax: "" });
    handleOpen();
  };

  return (
    <>
      <Grid
        container
        direction="row"
        className="page-content"
        justify="left"
        alignItems="center"
      >
        <Grid item xs={12} sm={6}>
          <div className="w-100 d-flex">
            <h2 className=" ">Kategorie</h2>
            <IconButton onClick={addCategory} className="" aria-label="add">
              <AddIcon className="pb-1" fontSize="small" />
            </IconButton>
          </div>

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nazwa</TableCell>
                  <TableCell align="right">Kategoria Nadrzędna</TableCell>
                  <TableCell align="right">Podatek</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      {row.product_category_root.name}
                    </TableCell>
                    <TableCell align="right">{row.tax.value}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={(e) => editCategory(e, row)}
                        aria-label="edit"
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={(e) => deleteCategory(e, row.id)}
                        aria-label="delete"
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  );
};
export default Categories;

const addMsg = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const deleteMsg = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});
