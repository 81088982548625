import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

import FinalizeForm from "./FinalizeForm";

const initialState = {
  customer: undefined,
  event: undefined,
  form: {
    visit: undefined,
    salon_name: undefined,
    start: undefined,
    description: undefined,
    employee: undefined,
    customer: undefined,
  },
};

export default class FinalizeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      ...{
        customer: props.customer,
        form: {
          visit: {
            id: +props.event.id,
            services: [],
            payment: "cash",
          },
          salon_name: props.salon_name,
          start: props.event.start,
          description: "",
          employee: props.employee,
          customer: props.customer,
        },
      },
    };
  }

  render() {
    const { onClosed } = this.props;

    return (
      <Modal isOpen={true} size="xl" centered={true} onClosed={onClosed}>
        <ModalHeader toggle={onClosed}>
          Finalizuj wizytę klienta: {this.state.customer.first_name}
        </ModalHeader>
        <ModalBody>
          <FinalizeForm
            {...this.state.form}
            lazyLoad={false}
            handleAfterValidSubmit={(data) => {
              this.props.handleAfterValidSubmit(data);
              //this.props.event.setProp('backgroundColor','gray');
              //this.props.event.setProp('editable',false);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={onClosed}
            color="light"
            className="waves-effect"
          >
            Zamknij
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
