import React, { Component } from "react";
import Swal from "sweetalert2";
import "./employees-table.scss";
import "./ProfileSettings.modules.css";
import * as PostAvatars from "../../Api/Avatars/postAvatars";
import $ from "jquery";
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as postUsers from "../../Api/Users/postUsers";
import ToastNotifications from "../../components/ToastNotification/ToastNotification";

class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.state = {
      avatarName: null,
      prevView: "",
      uploadedFiles: null,
      avatarDefault:
        `${process.env.REACT_APP_HOST_API}/uploads/avatars/` +
        localStorage.getItem("avatar"),
    };
  }

  handleSendAvatar = () => {
    const fileSize = this.state.uploadedFiles;

    if (fileSize.fileSize > 1024) {
      alert("Zdjęcie jest za duże podaj inne");
      return;
    } else {
      let formdata = new FormData();
      formdata.append("avatar", this.state.uploadedFiles);

      const id = localStorage.getItem("user_id");
      const salonName = localStorage.getItem("salon_name");

      const registerAlert = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
        },
        buttonsStyling: false,
      });

      PostAvatars.postAvatars(salonName, id, formdata).then((response) => {
        if (response.status === 201) {
          registerAlert
            .fire({
              title: "Sukces",
              text: "Avatar został pomyślnie dodany.",
              icon: "success",
              confirmButtonText: "Ok",
            })
            .then((result) => {
              if (result.isConfirmed) {
                response.json().then((data) => {
                  localStorage.setItem("avatar", data);
                  this.setState({
                    avatarName:
                      `${process.env.REACT_APP_HOST_API}/uploads/avatars/` +
                      localStorage.getItem("avatar"),
                    uploadedFiles: null,
                  });

                  $("#avatarTopMenu").attr("src", this.state.avatarName);
                });
              }
            });
        } else if (response.status === 404) {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Niepoprawne dane lub błędny plik.",
          });
        }
      });
    }
  };

  handleChangeImg = (e) => {
    if (e.target.files.length) {
      this.setState({
        uploadedFiles: e.target.files[0],
        avatarName: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  handleResetPassword() {
    const owner_password = document.querySelector("#owner_password");
    const confirm_owner_password = document.querySelector(
      "#confirm_owner_password",
    );
    const password =
      owner_password.value === confirm_owner_password.value
        ? owner_password.value
        : null;

    let data = {
      password: password,
      repeat_password: password,
    };

    postUsers.resetPassword(data).then((response) => {
      if (response.status === 200) {
        owner_password.value = "";
        confirm_owner_password.value = "";
        return ToastNotifications(
          "success",
          "Hasło zostało zmienione",
          "Sukces",
          10000,
        );
      } else if (response.status === 400) {
        return ToastNotifications("error", "Niepoprawne dane", "Błąd");
      } else {
        return ToastNotifications(
          "error",
          "Wystąpił błąd. Spróbuj ponownie później.",
          "Błąd",
        );
      }
    });
  }

  render() {
    const fullName =
      localStorage.getItem("first_name") +
      " " +
      localStorage.getItem("last_name");

    return (
      <React.Fragment>
        <div className="container page-content">
          <Card className="p-2">
            <CardBody>
              <div className="gridDisplay mt-4 mt-md-0">
                <div>
                  <img
                    className="rounded-circle avatar-xl"
                    alt="Nazox"
                    src={
                      this.state.avatarName === null
                        ? this.state.avatarDefault
                        : this.state.avatarName
                    }
                  />
                </div>
                <div>
                  <a className="fontStyleName">{fullName}</a>
                  <div className="file-input displayFlex">
                    <input
                      type="file"
                      id="avatar"
                      className="file"
                      onChange={this.handleChangeImg}
                    />
                    <label htmlFor="avatar" style={{ margin: "1%" }}>
                      Zmień avatar
                      <p className="file-name"></p>
                    </label>

                    {this.state.uploadedFiles && (
                      <>
                        <input
                          type="button"
                          id="changeAvatar"
                          className="file"
                          onClick={() => this.handleSendAvatar()}
                        />
                        <label
                          htmlFor="changeAvatar"
                          className="secondButton"
                          style={{ margin: "1%" }}
                        >
                          Zapisz
                          <p className="file-name"></p>
                        </label>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
          <Card className="p-2">
            <CardBody>
              <div className="mb-4">
                <h3>Zmiana hasła:</h3>
              </div>
              <AvForm
                onValidSubmit={this.handleResetPassword}
                className="form-horizontal"
              >
                <Container>
                  <Row>
                    <Col xl={6}>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-lock-2-line auti-custom-input-icon" />
                        <Label htmlFor="owner_password">Hasło</Label>
                        <AvField
                          errorMessage="Wpisz silne hasło"
                          name="owner_password"
                          type="password"
                          className="form-control"
                          id="owner_password"
                          validate={{ required: true }}
                          placeholder="Wprowadź hasło"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={6}>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-lock-password-line auti-custom-input-icon" />
                        <Label htmlFor="confirm_owner_password">
                          Potwierdź hasło
                        </Label>
                        <AvField
                          name="confirm_owner_password"
                          type="password"
                          className="form-control"
                          id="confirm_owner_password"
                          validate={{
                            required: {
                              value: true,
                              error: "test",
                              errorMessage: "Hasła nie są takie same",
                            },
                            match: {
                              value: "owner_password",
                              errorMessage: "Hasła nie są takie same",
                            },
                          }}
                          placeholder="Powtórz hasło"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>
                <div className="text-center button-wrapper">
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light fluid login-btn"
                    type="submit"
                  >
                    Zmień hasło
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default ProfileSettings;
