import { Button, Grid, makeStyles } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect, useRef, useState } from "react";
import * as getProducts from "../../Api/Products/getProducts";
import "./Stocktaking.scss";

const columns = [
  { id: "name", field: "name", headerName: "Nazwa", width: 250 },
  { id: "type", field: "type", headerName: "Typ", width: 150 },
  {
    id: "consumptionUnit",
    field: "consumptionUnit",
    headerName: "Jednostka",
    width: 150,
  },
  { id: "price", field: "price", headerName: "Cena (PLN)", width: 150 },
  {
    id: "quantity",
    field: "quantity",
    type: "number",
    headerName: "Ilość ksiegowa",
    width: 150,
  },
  {
    id: "real_quantity",
    field: "real_quantity",
    type: "number",
    headerName: "Ilość Rzeczywista",
    width: 200,
    editable: true,
  },
  {
    id: "quantity_difference",
    field: "quantity_difference",
    type: "number",
    valueGetter: getPriceDifference,
    headerName: "Różnica",
    width: 200,
    cellClassName: (params) => {
      if (params.value > 0) {
        return "cell-to-much";
      } else if (params.value == 0) {
        return "cell-correct-amount";
      } else {
        return "cell-to-low";
      }
    },
  },
  {
    field: "estimated_value",
    type: "number",
    valueGetter: getEstimatedValue,
    headerName: "Szacunkowa Wartość (PLN)",
    width: 250,
  },
];

function onChange(params) {
  console.log(params);
}

function getPriceDifference(params) {
  return params?.row?.quantity - params?.row?.real_quantity;
}

function getEstimatedValue(params) {
  return params?.row?.real_quantity * params?.row?.price;
}

const useStyles = makeStyles({
  root: {
    minHeight: "450px",
  },
});

export const StocktakingProducts = (props) => {
  const dataGrid = useRef(null);
  const [products, setProducts] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // getProducts.getSalonProducts(props.salonName).then(response=>response.json()).then(responseData=>{
      let gridData = props.products.map(function (data) {
        return {
          id: data.id,
          name: data.name,
          type: data.product_type.name,
          consumptionUnit: data.consumption_unit.name,
          price: data.sell_price,
          quantity: data.quantity,
          real_quantity: 0,
          // quantity_difference:0,
          //  estimated_value: 0
        };
      });

      //     setProducts(gridData)})
      setProducts(gridData);
    }

    return () => {
      isMounted = false;
    };
  }, [props.products]);

  const onCellEdit = (params) => {
    if (params.field === "real_quantity") {
      let value = parseInt(params.props.value);
      let copy = [...products];
      let idx = copy.findIndex((product) => product.id === params.id);
      copy[idx] = {
        ...copy[idx],
        real_quantity: value,
        quantity_difference: copy[idx].quantity - value,
        estimated_value: copy[idx].price * value,
      };
      setProducts(copy);
    }
    // console.log(params)
  };

  return (
    <>
      <DataGrid
        onEditCellChange={onCellEdit}
        className={classes.root}
        rows={products}
        columns={columns}
      />
      <Button
        onClick={() => {
          props.handleSubmit(products);
        }}
        className="mt-3"
        variant="contained"
        size="small"
        color="primary"
      >
        Podsumuj inwentaryzację
      </Button>
    </>
  );
};
