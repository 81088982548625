import React, { Component } from "react";
import { Row, Col, Card, CardBody, ButtonGroup, Button } from "reactstrap";
import Select from "react-select";

//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import * as getStatistics from "../../Api/Statistics/getStatistics";
import moment from "moment";
import * as visitsAll from "../../Api/Visits/getVisits";
import * as getService from "../../Api/Services/getServices";
import * as getCustomers from "../../Api/Customers/getCustomers";

class RevenueAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visitsMonth: 0,
      lastYearReport: {},
      thisYearReport: {},
      chartData: [],
      series: [
        // {
        //   name: "2021",
        //   type: "column",
        //   data: [23, 42, 35, 27, 43, 22, 17],
        // },
        // {
        //   name: "2020",
        //   type: "line",
        //   data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16],
        // },
      ],
      options: {
        // chart: {
        //   toolbar: {
        //     show: false,
        //   },
        // },
        // stroke: {
        //   width: [0, 3],
        //   curve: "smooth",
        // },
        // plotOptions: {
        //   bar: {
        //     horizontal: false,
        //     columnWidth: "20%",
        //   },
        // },
        // dataLabels: {
        //   enabled: false,
        // },
        //
        // legend: {
        //   show: false,
        // },
        // colors: ["#5664d2", "#1cbb8c"],
        // labels: [
        //   "Sty",
        //   "Lut",
        //   "Mrz",
        //   "Kwi",
        //   "Maj",
        //   "Cze",
        //   "Lip",
        //   "Sie",
        //   "Wrz",
        //   "Paź",
        //   "Lis",
        //   "Gru",
        // ],
      },
    };
  }

  componentDidMount() {
    let dataArray = [];
    const dataNames = [
      "Sty",
      "Lut",
      "Mrz",
      "Kwi",
      "Maj",
      "Cze",
      "Lip",
      "Sie",
      "Wrz",
      "Paź",
      "Lis",
      "Gru",
    ];

    const lastYear = moment().add(-1, "Y").startOf("year").format("YYYY-MM-DD");
    const lastYearEnd = moment()
      .add(-1, "Y")
      .endOf("year")
      .format("YYYY-MM-DD");
    const thisYear = moment().add(0, "Y").startOf("year").format("YYYY-MM-DD");
    const thisYearEnd = moment().add(0, "Y").endOf("year").format("YYYY-MM-DD");

    getStatistics
      .getReport(localStorage.getItem("salon_name"), lastYear, lastYearEnd)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          lastYearReport: data,
        });
      });
    getStatistics
      .getReport(localStorage.getItem("salon_name"), thisYear, thisYearEnd)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          thisYearReport: data,
        });
      });
    for (let i = 11; i >= 0; i--) {
      const month = moment().add(-i, "M").startOf("month").format("MM");
      const monthFullDate = moment()
        .add(-i, "M")
        .startOf("month")
        .format("YYYY-MM-DD");
      const monthEndDate = moment()
        .add(-i, "M")
        .endOf("month")
        .format("YYYY-MM-DD");
      getStatistics
        .getReport(
          localStorage.getItem("salon_name"),
          monthFullDate,
          monthEndDate,
        )
        .then((response) => response.json())
        .then((data) => {
          dataArray.push({
            fullDate: new Date(monthFullDate),
            month: month,
            data: data,
          });
          if (dataArray.length === 12) {
            dataArray.sort((date1, date2) => date1.fullDate - date2.fullDate);
            let visitMon = 0;
            let series1 = [];
            let series2 = [];
            let label1 = [];
            for (let g = 0; g < dataArray.length; g++) {
              series1.push(dataArray[g].data?.visits_total_count || 0);
              series2.push(dataArray[g].data?.services_value_brutto || 0);
              label1.push(dataNames[Number(dataArray[g].month) - 1]);
              if (g === dataArray.length - 1) {
                visitMon = dataArray[g].data.services_value_brutto;
              }
            }
            series1.push(0);
            series2.push(0);
            label1.push(
              dataNames[Number(dataArray[dataArray.length - 1].month)],
            );
            this.setState({
              series: [
                // {
                //   name: "Wizyty",
                //   type: "column",
                //   data: series1,
                // },
                {
                  name: "Przychód",
                  // type: "line",
                  type: "column",
                  data: series2,
                },
              ],
              options: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                stroke: {
                  width: [3, 3],
                  curve: "smooth",
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: "20%",
                  },
                },
                dataLabels: {
                  enabled: false,
                },

                legend: {
                  show: false,
                },
                colors: ["#5664d2", "#1cbb8c"],
                labels: label1,
              },
              visitsMonth: visitMon,
            });
          }
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            {/*<div className="float-right d-none d-md-inline-block">*/}
            {/*  <ButtonGroup className="mb-2">*/}
            {/*    <Button size="sm" color="light" type="button">*/}
            {/*      Dziś*/}
            {/*    </Button>*/}
            {/*    <Button size="sm" color="light" active type="button">*/}
            {/*      Tydz.*/}
            {/*    </Button>*/}
            {/*    <Button size="sm" color="light" type="button">*/}
            {/*      Miesiąc*/}
            {/*    </Button>*/}
            {/*  </ButtonGroup>*/}
            {/*</div>*/}
            <div className="month-select">
              <Select
                label="Miesiąc"
                placeholder="Wybierz miesiąc"
                options={this.state.options.months}
              />
            </div>
            <h4 className="card-title mb-4">Analiza dochodów</h4>
            <div>
              <div id="line-column-chart" className="apex-charts" dir="ltr">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="line"
                  height="280"
                />
              </div>
            </div>
          </CardBody>

          <CardBody className="border-top text-center">
            <Row>
              <Col sm={4}>
                {/*<p className="text-muted text-truncate mb-0">Ten miesiąc</p>*/}
                {/*<div className="d-inline-flex">*/}
                {/*  <h5 className="mr-2">{this.state.visitsMonth} zł</h5>*/}
                {/*  /!*<div className="text-success">*!/*/}
                {/*  /!*  <i className="mdi mdi-menu-up font-size-14"> </i>2.2 %*!/*/}
                {/*  /!*</div>*!/*/}
                {/*</div>*/}
                <div className="mt-4 mt-sm-0">
                  <p className="mb-2 text-muted text-truncate">
                    {/*<i className="mdi mdi-circle text-primary font-size-10 mr-1"/>{" "}*/}
                    Ten miesiąc
                  </p>
                  <div className="d-inline-flex">
                    <h5 className="mb-0 mr-2">
                      {this.state?.visitsMonth || 0} zł
                    </h5>
                    {/*<div className="text-success">*/}
                    {/*  <i className="mdi mdi-menu-up font-size-14"> </i>1.29 %*/}
                    {/*</div>*/}
                  </div>
                </div>
              </Col>

              <Col sm={4}>
                <div className="mt-4 mt-sm-0">
                  <p className="mb-2 text-muted text-truncate">
                    {/*<i className="mdi mdi-circle text-primary font-size-10 mr-1"/>{" "}*/}
                    W tym roku:
                  </p>
                  <div className="d-inline-flex">
                    <h5 className="mb-0 mr-2">
                      {this.state.thisYearReport?.services_value_brutto || 0} zł
                    </h5>
                    {/*<div className="text-success">*/}
                    {/*  <i className="mdi mdi-menu-up font-size-14"> </i>1.29 %*/}
                    {/*</div>*/}
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="mt-4 mt-sm-0">
                  <p className="mb-2 text-muted text-truncate">
                    {/*<i className="mdi mdi-circle text-success font-size-10 mr-1"/>{" "}*/}
                    W poprzednim roku:
                  </p>
                  <div className="d-inline-flex">
                    <h5 className="mb-0">
                      {this.state.lastYearReport?.services_value_brutto || 0} zł
                    </h5>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default RevenueAnalytics;
