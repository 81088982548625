import React from "react";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import * as patchUsers from "../../../Api/Users/patchUsers";
import Select from "react-select";
import ColorPicker from "@vtaits/react-color-picker";
import * as getService from "../../../Api/Services/getServices";
import { convertTime } from "../../../helpers/convertTime";

export const defaultState = {
  btnColor: "primary",
  salon_name: localStorage.getItem("salon_name"),
  color: "",
  simple_color1: "",
  simple_color: false,
  id: undefined,
  enabled: 0,
  first_name: undefined,
  last_name: undefined,
  roles: [
    {
      value: "ROLE_EMPLOYEE",
      label: "Employee",
    },
    {
      value: "ROLE_ANALYST",
      label: "Analyst",
    },
  ],
  username: undefined,
  password: undefined,

  selectedRoles: [],

  customErrors: {
    username: [],
  },
};

export default class EmployeesEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.state = {
      ...defaultState,
      ...props,
      salonServices: [],
      selectedServices: [],
    };
    this.salon_name = localStorage.getItem("salon_name");
  }

  getServices() {
    getService
      .getSalonServices(this.salon_name)
      .then((response) => response.json())
      .then(async (results) => {
        if (results) {
          let selectedUserService = [];
          const data =
            this.props?.servicesPerformed ||
            this.props?.services_performed ||
            [];
          if (data.length) {
            selectedUserService = results.reduce((dataReturn, item) => {
              if (data?.filter((performed) => performed === item.id).length) {
                dataReturn.push({
                  value: { service: item },
                  label: `${item.title} (${convertTime(
                    item.service_time_max,
                  )})`,
                });
              }

              return dataReturn;
            }, []);
          }

          this.setState({
            salonServices: results,
            selectedServices: selectedUserService,
          });
        }
      });
  }

  componentDidMount() {
    this.getServices();
  }

  onDragRgb(c1) {
    this.setState({ color: c1 });
  }

  handleValidSubmit(event, values) {
    const { handleAfterValidSubmit } = this.props;

    const body = {
      enabled: values.enabled ? true : false,
      first_name: values.first_name,
      last_name: values.last_name,
      roles: this.state.selectedRoles.map((sr) => sr.value),
      username: values.username,
      color: this.state.color,
      services_performed: this.state.selectedServices.reduce(
        (dataReturn, item) => {
          if (item?.value?.service?.id) {
            dataReturn.push(item?.value.service.id);
          }

          return dataReturn;
        },
        [],
      ),
    };
    if (values.password.length) {
      body.password = values.password;
    }

    patchUsers
      .updateUser(body, this.state.salon_name, this.state.id)
      .then(async (response) => {
        const contentType = response.headers.get("content-type");
        const contentLength = +response.headers.get("content-length");

        const newState = {
          btnColor: "success",
          customErrors: this.state.customErrors,
        };

        const status = response.status;
        if (status >= 200 && status < 300) {
          newState.customErrors = {
            ...newState,
            ...{
              customErrors: {
                username: [],
              },
            },
          };

          this.setState(newState);

          if (handleAfterValidSubmit) {
            if (
              contentType &&
              contentType.indexOf("application/json") !== -1 &&
              contentLength > 0
            ) {
              handleAfterValidSubmit(await response.json());
            } else {
              handleAfterValidSubmit(this.state);
            }
          }

          return response;
        }

        newState.btnColor = "danger";
        const data = await response.text(); // we have an error here

        if (status === 400) {
          switch (data) {
            case "Username already exists.":
              newState.customErrors.username = [
                ...this.state.customErrors.username,
                ["Taki użytkownik już istnieje"],
              ];
              break;
          }
        }

        this.setState(newState);
      });
  }

  handleSubmit(event, errors, values) {
    if (this.state.selectedRoles === null) {
      errors = [...errors, "roles"];
    }
    this.setState({ errors, values });
  }

  handleInvalidSubmit(event, errors, values) {}

  renderOptions() {
    return this.state.salonServices
      .filter((item) => {
        if (!this.state.selectedServices?.length) {
          return item;
        }

        if (
          this.state.selectedServices?.filter(
            (selected) => selected.value.service.id === item.id,
          ).length === 0
        ) {
          return item;
        }
      })
      .map((service) => {
        return {
          value: { service },
          label: `${service.title} (${convertTime(service.service_time_max)})`,
        };
      });
  }

  render() {
    return (
      <AvForm
        className="form-horizontal"
        onSubmit={this.handleSubmit}
        onInvalidSubmit={this.handleInvalidSubmit}
        onValidSubmit={this.handleValidSubmit}
        model={this.state}
      >
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <AvField
                        name="username"
                        label="Login"
                        validate={{ email: true, required: true }}
                      />
                      {this.state.customErrors.username &&
                        this.state.customErrors.username.map((customError) => (
                          <span className="text-danger pb-2">
                            {customError}
                          </span>
                        ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField
                        name="first_name"
                        label="Imię"
                        validate={{ required: true }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField
                        name="last_name"
                        label="Nazwisko"
                        validate={{ required: true }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvGroup className="select2-container">
                        <Label>Role</Label>
                        <Select
                          name="roles"
                          defaultValue={this.state.selectedRoles}
                          value={this.state.selectedRoles}
                          isMulti
                          options={this.state.roles}
                          onChange={(values) => {
                            this.setState({ selectedRoles: values });
                          }}
                          className="select2 select2-multiple"
                        />
                        {this.state.errors &&
                          this.state.errors.indexOf("roles") >= 0 && (
                            <span className="text-danger">
                              Wybierz przynajmniej jedną rolę
                            </span>
                          )}
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvGroup className="select2-container">
                        <Label>Usługi wykonywane przez pracownika</Label>
                        <Select
                          name="services"
                          defaultValue={null}
                          value={
                            this.state.selectedServices?.length &&
                            this.state.selectedServices[0]?.value
                              ? this.state.selectedServices
                              : null
                          }
                          isMulti
                          placeholder="Wybierz usługę lub usługi"
                          isSearchable={false}
                          options={this.renderOptions()}
                          onChange={(values) => {
                            this.setState({ selectedServices: values });
                          }}
                          className="select2 select2-multiple"
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <FormGroup className="m-b-0">
                        <Label>Kolor</Label>
                        <InputGroup
                          className="colorpicker-default"
                          title="Using format option"
                        >
                          <Input
                            name="color"
                            value={this.state.color}
                            type="text"
                            className="form-control input-lg"
                            onChange={(e) => {
                              this.setState({
                                color: e.target.value,
                              });
                            }}
                          />
                          <InputGroupAddon addonType="append">
                            <span
                              className="input-group-text colorpicker-input-addon"
                              onClick={() =>
                                this.setState({
                                  simple_color1: !this.state.simple_color1,
                                })
                              }
                            >
                              <i
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  background: this.state.color,
                                }}
                              />
                            </span>
                          </InputGroupAddon>
                        </InputGroup>

                        {this.state.simple_color1 ? (
                          <ColorPicker
                            saturationHeight={100}
                            saturationWidth={100}
                            value={this.state.color}
                            onDrag={this.onDragRgb.bind(this)}
                          />
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField type="select" name="enabled" label="Aktywny">
                        <option value={0}>Nie</option>
                        <option value={1}>Tak</option>
                      </AvField>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField
                        type="password"
                        name="password"
                        label="Nowe hasło"
                      />
                    </Col>
                    <Col xs="12">
                      <AvField
                        type="password"
                        name="password_confirmation"
                        label="Potwierdź nowe hasło"
                        validate={{ match: { value: "password" } }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="my-3">
          <Button
            color={this.state.btnColor}
            className="w-md waves-effect waves-light"
            type="submit"
          >
            Zapisz
          </Button>
        </div>
      </AvForm>
    );
  }
}
