// Lists all Warehouses owned by user
export const getOwnedWarehouses = async () => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouses/owned`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

// Lists all Warehouses available to user
export const getAvailableWarehouses = async () => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouses/available`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};

// Lists all Warehouses by salon
export const getWarehousesInSalons = async (salon_name) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouses/salons/${salon_name}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};

// Get one warehouse by ID
export const getWarehouse = async (warehouse_id) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/warehouses/${warehouse_id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};
