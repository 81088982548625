export const updateWarehouseProduct = async (selectedProductId, data) => {
    return await fetch(
      `${process.env.REACT_APP_HOST_API}/api/v1/warehouse-products/${selectedProductId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(data),
      },
    )
  };
