import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import translations from "../../../helpers/translations/pl";
import * as styles from "./history-table.module.scss";

const defaultState = {
  remoteData: [],
};

class HistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      ...props,
    };
  }

  prepareData() {
    return {
      columns: this.prepareColumnDefinitions(),
      rows: this.prepareRemoteItems(),
    };
  }

  prepareColumnDefinitions() {
    return [
      {
        label: "Czynność",
        field: "action",
        sort: "asc",
      },
      {
        label: "Szczegóły",
        field: "details",
        sort: "asc",
      },
      {
        label: "Data",
        field: "date",
        sort: "asc",
      },
    ];
  }

  prepareRemoteItems() {
    return this.state.remoteData.map(this.prepareRemoteItem);
  }

  /**
   * Maps a remote record to the one that matches a column definition
   *
   * @param remoteItem
   * @returns {{title: string}}
   */
  prepareRemoteItem = (remoteItem) => ({
    action: remoteItem.action,
    details: remoteItem.details,
    date: remoteItem.date,
  });

  render() {
    return (
      <MDBDataTable
        id={"history"}
        className={styles.historyTable}
        responsive
        hover
        striped
        bordered
        data={this.prepareData()}
        {...translations.datatable}
      />
    );
  }
}

export default HistoryTable;
