import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import plLocale from "@fullcalendar/core/locales/pl";
import SidebarCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./calendar.scss";
import Swal from "sweetalert2";
import moment from "moment";
import pl from "date-fns/locale/pl";
// import DateTimePickerComponent from '../../components/DateTimePicker/DateTimePicker';

//Api
import * as getUsers from "../../Api/Users/getUsers";
import * as getVisits from "../../Api/Visits/getVisits";
import * as getService from "../../Api/Services/getServices";
import * as getCustomers from "../../Api/Customers/getCustomers";
import DatePicker, { registerLocale } from "react-datepicker";

//API
import * as postVisits from "../../Api/Visits/postVisits";
import * as deleteVisits from "../../Api/Visits/deleteVisits";
import * as postCustomer from "../../Api/Customers/postCustomers";
import * as patchVisits from "../../Api/Visits/patchVisits";
import { Tooltip } from "chart.js";
import FinalizeModal, {
  initialState as finalizeModalInitialState,
} from "../../pages/Calendar/components/FinalizeModal";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import * as getServices from "../../Api/Services/getServices";
import { scaffoldVisit } from "../../pages/Calendar/components/FinalizeForm";

import { red } from "@material-ui/core/colors";

export default class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekendsVisible: true,
      currentEvents: [],
      modal_standard: false,
      pickerDate: new Date(),
      salonEmployes: [],
      selectedCalendarEmployee: "",
      selectedEmployeeVisits: [],
      salonServices: [],
      salonCustomers: [],
      newApointmentDate: "",
      // SELECT OPTIONS
      formSelectedService: null,
      formSelectedCustomer: null,
      formSelectedEmployee: null,
      formSelectedDate: null,
      minutesTotal: [],

      totalNetPrice: 0,
      totalGrossPrice: 0,
      totalTax: 0,
      noteAsHelper: "",

      finalizeModal: {
        isOpen: false,
        employee: undefined,
        customer: undefined,
        event: undefined,
        service: {
          id: undefined,
          name: undefined,
        },
      },
      isAddingVisit: false,
    };

    this.phoneRegex = /([+(\d]{1})(([\d+() -.]){7,16})([+(\d]{1})/;
    this.serviceRef = React.createRef();
    this.salon_name = localStorage.getItem("salon_name");
    this.calendarRef = React.createRef();
    this.tog_standard = this.tog_standard.bind(this);
    this.tog_addCustomer = this.tog_addCustomer.bind(this);
    this.addVisit = this.addVisit.bind(this);
    this.addCustomer = this.addCustomer.bind(this);

    this.handleSelectService = this.handleSelectService.bind(this);
    this.handleSelectCustomer = this.handleSelectCustomer.bind(this);
    this.handleSelectEmployee = this.handleSelectEmployee.bind(this);

    this.loadEvents = this.loadEvents.bind(this);
    this.changeStyle = this.changeStyle.bind(this);
  }

  handleTotalNetPriceClick = (e) => {
    this.setState({
      totalNetPrice: e.target.value,
    });
  };

  handleTotalGrossPriceClick = (e) => {
    this.setState({
      totalGrossPrice: e.target.value,
    });
  };

  handleTotalTaxClick = (e) => {
    this.setState({
      totalTax: e.target.value,
    });
  };

  handleTotalNotAsHelperClick = (e) => {
    this.setState({
      noteAsHelper: e.target.value,
    });
  };

  changeStyle = () => {
    const minutes = this.state.minutesTotall;
    minutes.map((item) => {
      if (item.minutes < 60) {
        let d = Array.prototype.slice.call(
          document.getElementsByClassName("fc-timegrid-event-harness-inset"),
        );
        d.map((item) => (item.style.width = "200px"));
      }
    });
  };

  componentDidMount() {
    registerLocale("pl", pl);
    Promise.all([
      getUsers
        .getSalonEmployes(this.salon_name)
        .then((response) => response.json()),
      getService
        .getSalonServices(this.salon_name)
        .then((response) => response.json()),
      getCustomers
        .getSalonCustomers(this.salon_name)
        .then((response) => response.json()),
    ]).then(async (results) => {
      const [employeeData, services, customers] = results;

      this.setState({
        salonEmployes: employeeData,
        selectedCalendarEmployee: employeeData[0],
        formSelectedEmployee:
          employeeData.length > 0
            ? {
                value: { employee: employeeData[0] },
                label: `${employeeData[0].first_name} ${employeeData[0].last_name}`,
              }
            : { value: "", label: "" },
        salonServices: services,
        formSelectedService:
          services.length > 0
            ? { value: { service: services[0] }, label: services[0].title }
            : { value: "", label: "" },
        salonCustomers: customers,
        formSelectedCustomer:
          customers.length > 0
            ? {
                value: { customer: customers[0] },
                label: `${customers[0].first_name} ${customers[0].last_name}`,
              }
            : { value: "", label: "" },
      });
    });
  }

  handleSelectEmployee = (formSelectedEmployee) => {
    this.setState({ formSelectedEmployee });
  };
  handleSelectService = (formSelectedService) => {
    this.setState({ formSelectedService });
  };

  handleSelectCustomer = (formSelectedCustomer) => {
    this.setState({ formSelectedCustomer });
  };
  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_addCustomer() {
    this.setState((prevState) => ({
      modal_addCustomer: !prevState.modal_addCustomer,
    }));
    this.removeBodyCss();
  }

  createTitleFromServices(visitServices, allServices) {
    return visitServices
      .map((id) => {
        const currentService = allServices.find((s) => s.id === id);
        if (currentService) {
          return currentService.title;
        }
        return "";
      })
      .join(", ");
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  changeSelectedEmployee = (event, employee) => {
    getVisits
      .getVisitsByEmployee(this.salon_name, employee.id)
      .then((response) => response.json())
      .then((data) => {
        const visits = data
          .map((visit) => ({
            ...visit,
            ...{
              id: visit.id,
              title: this.createTitleFromServices(
                visit.services,
                this.state.salonServices,
              ),
              start: new Date(visit.appointment_at),
              end: new Date(visit.appointment_at_end_max),
              customer: visit.customer,
              description: visit.description,
              classNames: [
                "hoverable-event",
                visit.is_finalized ? "fc-bg-finalized" : "fc-bg-nonfinalized",
              ],
              editable: !visit.is_finalized,
            },
          }))
          .map((visit) =>
            scaffoldVisit(
              this.state.salonEmployes,
              visit,
              this.state.salonServices,
            ),
          );

        this.setState({
          selectedCalendarEmployee: employee,
          selectedEmployeeVisits: visits,
        });
      });
  };

  onFinalizeModalClose = () => {
    this.setState({
      finalizeModal: {
        isOpen: false,
      },
    });
  };

  async loadEvents(dateStart, dateEnd, type) {
    if (type === "recepcja") {
      this.loadResources();

      const startMoment = moment(dateStart);
      const endMoment = moment(dateEnd);

      const targetsOneDay =
        startMoment.add(1, "d").format("Y-M-d") === endMoment.format("Y-M-d");

      let start = moment(dateStart).format("Y-MM-DD", { trim: false });
      let end = moment(dateEnd).format("Y-MM-DD", { trim: false });

      if (targetsOneDay) {
        start =
          moment(dateStart).format("Y-MM-DD", { trim: false }) + " 00:00:00";
        end =
          moment(dateStart).format("Y-MM-DD", { trim: false }) + " 23:59:59";
      }

      const response = await getVisits.getVisitsBetweenDates(
        this.salon_name,
        start,
        end,
      );
      if (response.headers.get("content-type") === "application/json") {
        const data = await response.json();

        const events = [
          ...data.map((visit) => ({
            id: visit.id,
            title: this.createTitleFromServices(
              visit.services,
              this.state.salonServices,
            ),
            start: new Date(visit.appointment_at),
            end: new Date(visit.appointment_at_end_max),
            customer: visit.customer,
            description: visit.description,
            classNames: [
              "hoverable-event",
              visit.is_finalized ? "fc-bg-finalized" : "fc-bg-nonfinalized",
            ],
            editable: !visit.is_finalized,
            resourceId: +visit.user.id,
          })),
        ];

        this.setState({
          selectedEmployeeVisits: events,
        });
      }
      return response;
    }

    Promise.all([
      getUsers
        .getSalonEmployes(this.salon_name)
        .then((response) => response.json()),
      getService
        .getSalonServices(this.salon_name)
        .then((response) => response.json()),
      getCustomers
        .getSalonCustomers(this.salon_name)
        .then((response) => response.json()),
    ]).then(async (results) => {
      const [employeeData, services, customers] = results;

      if (employeeData[0]) {
        getVisits
          .getVisitsByEmployee(this.salon_name, employeeData[0].id)
          .then((response) => response.json())
          .then((employeeVisitsData) => {
            const visits = employeeVisitsData
              .map((visit) => ({
                ...visit,
                ...{
                  id: visit.id,
                  title: this.createTitleFromServices(
                    visit.services,
                    this.state.salonServices,
                  ),
                  start: new Date(visit.appointment_at),
                  end: new Date(visit.appointment_at_end_max),
                  customer: visit.customer,
                  description: visit.description,
                  classNames: [
                    "hoverable-event",
                    visit.is_finalized
                      ? "fc-bg-finalized"
                      : "fc-bg-nonfinalized",
                  ],
                  editable: !visit.is_finalized,
                  services: visit.services,
                },
              }))
              .map((visit) => scaffoldVisit(employeeData, visit, services));

            this.setState({
              salonEmployes: employeeData,
              selectedCalendarEmployee: employeeData[0],
              selectedEmployeeVisits: visits,
              formSelectedEmployee:
                employeeData.length > 0
                  ? {
                      value: { employee: employeeData[0] },
                      label: `${employeeData[0].first_name} ${employeeData[0].last_name}`,
                    }
                  : { value: "", label: "" },
            });

            let czas = visits;
            let czasy = [];
            czas.map((item) => {
              let diff = Math.abs(
                new Date(item.appointment_at) -
                  new Date(item.appointment_at_end_max),
              );
              let minutes = Math.floor(diff / 1000 / 60);
              czasy.push({
                id: item !== undefined ? item.id : null,
                minutes: minutes,
              });
            });

            this.setState({
              minutesTotall: czasy,
            });
          });
      }

      this.setState({
        salonServices: services,
        formSelectedService:
          services.length > 0
            ? { value: { service: services[0] }, label: services[0].title }
            : { value: "", label: "" },
        salonCustomers: customers,
        formSelectedCustomer:
          customers.length > 0
            ? {
                value: { customer: customers[0] },
                label: `${customers[0].first_name} ${customers[0].last_name}`,
              }
            : { value: "", label: "" },
      });
    });
  }

  async loadResources() {
    const response = await getUsers.getSalonEmployes(this.salon_name);
    if (response.headers.get("content-type") === "application/json") {
      const data = await response.json();
      this.setState({
        resources: data.map((item) => ({
          id: +item.id,
          title: `${item.first_name} ${item.last_name}`,
        })),
      });
    }
    return response;
  }

  onViewMount = (viewObject, element) => {
    const view = viewObject.view;
    if (this.loadEvents && view.currentStart && view.currentEnd) {
      this.loadEvents(
        moment(view.currentStart).format("Y-MM-DD", { trim: false }),
        moment(view.currentEnd).format("Y-MM-DD", { trim: false }),
        view.type,
      );
    }
    setTimeout(() => {
      viewObject.el
        .querySelectorAll(".fc-col-header-cell-cushion")
        .forEach((element) => {
          element.innerHTML = element.innerHTML + "<br>6:00-19:00</br>";
        });
    }, 250);
  };

  render() {
    return (
      <div className="page-content">
        <div className="container-fluid">
          <Card>
            <CardBody>
              {/* <DateTimePickerComponent/> */}
              {this.renderSidebar()}
              <h3 className="mt-4">
                Pracownicy ({this.state.salonEmployes.length})
              </h3>
              <ul className="list-group visit-list">
                {this.state.salonEmployes !== undefined &&
                  this.state.salonEmployes.map((employee) => (
                    <li
                      key={employee.id}
                      className="list-group-item text-justify"
                    >
                      <b>{`${employee.first_name} ${employee.last_name}`}</b>
                    </li>
                  ))}
              </ul>
            </CardBody>
          </Card>
        </div>
        <div style={{ display: "none" }}>
          <div className="page-content">
            {this.state.finalizeModal.isOpen && (
              <FinalizeModal
                {...this.state.finalizeModal}
                onClosed={this.onFinalizeModalClose}
                handleAfterValidSubmit={(visit) => {
                  this.setState((prev) => ({
                    selectedEmployeeVisits: prev.selectedEmployeeVisits.map(
                      (sev) =>
                        sev.id == visit.id
                          ? {
                              ...sev,
                              ...visit,
                              ...{
                                classNames: [
                                  "hoverable-event",
                                  visit.is_finalized
                                    ? "fc-bg-finalized"
                                    : "fc-bg-nonfinalized",
                                ],
                              },
                            }
                          : sev,
                    ),
                  }));
                }}
              />
            )}
            <div id="mk">
              <Container fluid>
                <Row>
                  <Col lg={2} xs={12}></Col>
                  <Col lg={10} xs={12}>
                    <Card>
                      <CardBody>
                        <div id="my-cal">
                          <FullCalendar
                            locales={plLocale}
                            locale={"pl"}
                            plugins={[
                              dayGridPlugin,
                              timeGridPlugin,
                              interactionPlugin,
                              resourceTimeGridPlugin,
                            ]}
                            headerToolbar={{
                              left: "prev,next today",
                              center: "title",
                              right:
                                "dayGridMonth,timeGridWeek,timeGridDay,recepcja",
                            }}
                            initialView="dayGridMonth"
                            editable={true}
                            selectable={true}
                            selectMirror={true}
                            dayMaxEvents={true}
                            weekends={this.state.weekendsVisible}
                            slotMinTime={"6:00:00"}
                            slotMaxTime={"19:00:00"}
                            events={this.state.selectedEmployeeVisits}
                            select={this.handleDateSelect}
                            eventContent={renderEventContent} // custom render function
                            eventClick={this.handleEventClick}
                            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
                            eventDrop={this.handleEventDrop}
                            eventDurationEditable={false}
                            expandRows={true}
                            weekNumbers={true}
                            allDaySlot={true}
                            allDayText={"Dzień"}
                            weekNumberFormat={{ week: "short" }}
                            views={{
                              recepcja: {
                                type: "resourceTimeGrid",
                                buttonText: "Recepcja",
                              },
                            }}
                            resources={this.state.resources}
                            viewDidMount={this.onViewMount}
                            datesSet={(dateInfo) =>
                              this.loadEvents(
                                dateInfo.start,
                                dateInfo.end,
                                dateInfo.view.type,
                              )
                            }
                            ref={this.calendarRef}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onPickerDateChange = (date) => {
    let calendarApi = this.calendarRef.current.getApi();
    calendarApi.gotoDate(date);
    this.setState({
      pickerDate: date,
    });
  };
  filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  addCustomer = (event, errors, values) => {
    postCustomer
      .registerCustomer(this.salon_name, values)
      .then((response) => response.json())
      .then((response) => {
        this.setState((prevState) => ({
          modal_addCustomer: !prevState.modal_addCustomer,
          salonCustomers: [...prevState.salonCustomers, response],
        }));
        addMsg.fire({
          title: "Sukces!",
          text: "Klient został dodany.",
          icon: "success",
          confirmButtonText: "Ok",
        });
      });
  };

  reloadLoading = () => {
    this.setState({
      isAddingVisit: false,
    });
  };

  addVisit = (event, values) => {
    if (!this.state.isAddingVisit) {
      this.setState({ isAddingVisit: true });
      let dataBody = {
        total_price_net: parseFloat(this.state.totalNetPrice),
        total_price_gross: parseFloat(this.state.totalGrossPrice),
        total_price_tax: parseFloat(this.state.totalTax),
        note: this.state.noteAsHelper,

        description: values.description,
        appointment_at: moment(this.state.formSelectedDate).format(
          "YYYY-MM-DD HH:mm:ss",
        ),
        user: this.state.formSelectedEmployee.value.employee.id,
        customer: this.state.formSelectedCustomer.value.customer.id,
        services: [this.state.formSelectedService.value.service.id],
      };

      postVisits
        .createVisit(dataBody, this.salon_name)
        .then((response) => response.json())
        .then((data) => {
          const service = this.state.salonServices.find(
            (ss) => ss.id === dataBody.services[0],
          );

          let visit = {
            id: data.id,
            total_price_net: data.totalNetPrice,
            total_price_gross: data.totalGrossPrice,
            total_price_tax: data.totalTax,
            note: data.noteAsHelper,

            title: this.createTitleFromServices(
              [service.id],
              this.state.salonServices,
            ),
            start: new Date(data.appointment_at),
            end: new Date(data.appointment_at_end_max),
            customer: data.customer,
            serviceId: service.id,
            description: data.description,
            is_finalized: data.is_finalized,
            editable: !data.is_finalized,
            classNames: [
              "hoverable-event",
              data.is_finalized ? "fc-bg-finalized" : "fc-bg-nonfinalized",
            ],
          };

          this.setState((prevState) => ({
            isAddingVisit: false,
            selectedEmployeeVisits: [
              ...prevState.selectedEmployeeVisits,
              visit,
            ],
            modal_standard: !prevState.modal_standard,
          }));
          setTimeout(() => {
            this.reloadLoading();
          }, 1000);
          addMsg.fire({
            title: "Sukces!",
            text: "Wizyta Została dodana.",
            icon: "success",
            confirmButtonText: "Ok",
          });
        })
        .catch(() => {
          setTimeout(() => {
            this.reloadLoading();
          }, 1000);
        });
    }
  };

  renderSidebar() {
    const { formSelectedDate, formSelectedService } = this.state;
    let appointmentStart = formSelectedDate
      ? moment(formSelectedDate).format("DD/MM/YYYY HH:mm")
      : "";
    let appointmentEnd = moment(formSelectedDate).add(1, "m");
    if (formSelectedService) {
      if (formSelectedService.value) {
        appointmentEnd = moment(
          moment(formSelectedDate)
            .add(formSelectedService.value.service.service_time_max, "m")
            .toDate(),
        );
      }
    }
    return (
      <>
        <div className="sidebar">
          <div className="sidebar-section">
            <h3 color="primary">
              Wydarzenia ({this.state.selectedEmployeeVisits.length})
            </h3>

            <ul className="list-group visit-list">
              {this.state.currentEvents.map(renderSidebarEvent)}
            </ul>
          </div>
        </div>
        <Modal isOpen={this.state.modal_standard} toggle={this.tog_standard}>
          <ModalHeader>
            <>
              {!formSelectedService ||
                (!formSelectedService.value && (
                  <h2>Musisz utworzyć przynajmniej jedną usługę</h2>
                ))}
              {formSelectedService && formSelectedService.value && (
                <>
                  {formSelectedService
                    ? formSelectedService.value.service.title
                    : "Wizyta"}{" "}
                  {appointmentStart} - {appointmentEnd.format("HH:mm")}
                  {/* <a href="#">zmień</a> */}
                </>
              )}
            </>
          </ModalHeader>
          <ModalBody>
            {formSelectedService && formSelectedService.value && (
              <AvForm onValidSubmit={this.addVisit} id="addVisit">
                <FormGroup className="select2-container">
                  <Label>Klient</Label>
                  <Select
                    value={this.state.formSelectedCustomer}
                    onChange={this.handleSelectCustomer}
                    options={this.state.salonCustomers.map((customer, idx) => ({
                      value: { customer },
                      label: `${customer.first_name} ${customer.last_name}`,
                    }))}
                    classNamePrefix="select2-selection"
                    isSearchable={true}
                    validate={{ required: true }}
                  />
                </FormGroup>
                <Button
                  onClick={this.tog_addCustomer}
                  className="mb-2 text-center w-100"
                >
                  Dodaj Klienta
                </Button>
                <FormGroup className="select2-container">
                  <Label>Pracownik</Label>
                  <Select
                    value={this.state.formSelectedEmployee}
                    onChange={this.handleSelectEmployee}
                    options={this.state.salonEmployes.map((employee, idx) => ({
                      value: { employee },
                      label: `${employee.first_name} ${employee.last_name}`,
                    }))}
                    classNamePrefix="select2-selection"
                    isSearchable={false}
                    validate={{ required: true }}
                  />
                </FormGroup>
                <FormGroup className="select2-container">
                  <Label>Usługa</Label>
                  <Select
                    value={this.state.formSelectedService}
                    onChange={this.handleSelectService}
                    options={this.state.salonServices.map((service, idx) => ({
                      value: { service },
                      label: `${service.title}`,
                    }))}
                    classNamePrefix="select2-selection"
                    isSearchable={false}
                    validate={{ required: true }}
                  />
                </FormGroup>
                <Label>Data</Label>
                <DatePicker
                  selected={this.state.formSelectedDate}
                  onChange={(date) => {
                    this.setState({ formSelectedDate: date });
                  }}
                  showTimeSelect
                  filterTime={this.filterPassedTime}
                  minTime={new Date().setHours(5)}
                  maxTime={new Date().setHours(18)}
                  locale="pl"
                  timeFormat="p"
                  timeIntervals={5}
                  dateFormat="Pp"
                  className="form-visit-datapicker"
                />
                <AvField
                  className="addDescription"
                  name="description"
                  label="Opis"
                  type="text"
                  placeholder="Wprowadź opis"
                  errorMessage="Wprowadź opis"
                  validate={{ required: { value: false } }}
                  id="addDescription"
                />

                <AvField
                  className=""
                  name="netPrice"
                  label="Cena netto"
                  type="number"
                  min={0.0}
                  step={0.01}
                  id="netPrice"
                  onChange={this.handleTotalNetPriceClick}
                />

                <AvField
                  className=""
                  name="grossPrice"
                  label="Cena brutto"
                  type="number"
                  min={0.0}
                  step={0.01}
                  id="grossPrice"
                  onChange={this.handleTotalGrossPriceClick}
                />

                <AvField
                  className=""
                  name="tax"
                  label="wartość podatku"
                  type="number"
                  min={0.0}
                  step={0.01}
                  id="tax"
                  onChange={this.handleTotalTaxClick}
                />

                <AvField
                  className="textArea"
                  name="note"
                  label="Notka (widoczne dla pracowników)"
                  type="textarea"
                  id="note"
                  onChange={this.handleTotalNotAsHelperClick}
                />

                <FormGroup className="mb-0">
                  <div>
                    <Button
                      id="add_new_customer"
                      type="submit"
                      color="primary"
                      className="mr-1"
                    >
                      Dodaj wizytę
                    </Button>{" "}
                    <Button
                      onClick={this.tog_standard}
                      type="reset"
                      color="secondary"
                    >
                      Anuluj
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            )}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal_addCustomer}
          toggle={this.tog_addCustomer}
        >
          <ModalHeader
            toggle={() => this.setState({ modal_addCustomer: false })}
          >
            Dodaj Klienta
          </ModalHeader>
          <ModalBody>
            <AvForm onSubmit={this.addCustomer} id="addCustomerForm">
              <AvField
                onChange={this.handleCheckField}
                className="addCustomerField"
                name="first_name"
                label="Imię"
                placeholder="Wprowadź imię"
                type="text"
                errorMessage="Wprowadź imię"
                validate={{ required: { value: true } }}
                id="addFirstName"
              />
              <AvField
                className="addCustomerField"
                name="last_name"
                label="Nazwisko"
                type="text"
                placeholder="Wprowadź nazwisko"
                errorMessage="Wprowadź nazwisko"
                validate={{ required: { value: true } }}
                id="addLastName"
              />
              <AvField
                onChange={this.handleCheckField}
                className="addCustomerField"
                name="phone_number"
                label="Numer telefon"
                type="text"
                errorMessage="Wprowadź prawidłowy numer"
                id="addPhoneNumber"
                placeholder="Wprowadź numer telefonu"
                validate={{
                  pattern: {
                    value: this.phoneRegex,
                  },
                }}
              />
              <AvField
                className="addCustomerField"
                name="locality"
                label="Lokalizacja"
                type="text"
                placeholder="Wprowadź lokalizacje"
                errorMessage="Wprowadź lokalizacje"
                validate={{ required: { value: false } }}
                id="addLocality"
              />
              <AvField
                className="addCustomerField"
                name="birthdate"
                label="Data"
                type="date"
                errorMessage="Wprowadź datę urodzenia"
                validate={{ required: { value: false } }}
                id="addBirthdate"
              />
              <AvField
                className="addCustomerField"
                name="email"
                label="E-Mail  "
                placeholder="Wprowadź adres e-mail"
                type="email"
                errorMessage="Nieprawidłowy adres e-mail"
                validate={{
                  required: { value: false },
                  email: { value: true },
                }}
                id="addEmail"
              />
              <FormGroup className="mb-0">
                <div>
                  <Button
                    id="add_new_customer"
                    color="primary"
                    className="mr-1"
                  >
                    Dodaj Klienta
                  </Button>{" "}
                  <Button
                    onClick={this.tog_addCustomer}
                    type="reset"
                    color="secondary"
                  >
                    Anuluj
                  </Button>
                </div>
              </FormGroup>
            </AvForm>
          </ModalBody>
        </Modal>
      </>
    );
  }

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleDateSelect = (selectInfo) => {
    this.tog_standard();
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection
    this.setState({
      formSelectedDate: selectInfo.start,
    });
  };

  handleEventClick = (clickInfo) => {
    const selectedEmployeeVisit = this.state.selectedEmployeeVisits.find(
      (sev) => sev.id == clickInfo.event.id,
    );
    deleteMsg
      .fire({
        title: `${clickInfo.event.title}`,
        html:
          "<p><b>" +
          moment(clickInfo.event.start).format("H:mm") +
          " - " +
          moment(clickInfo.event.end).format("H:mm") +
          "</b></p>" +
          '<p className="text-white m-2 d-inline">' +
          (clickInfo.event.extendedProps.customer
            ? clickInfo.event.extendedProps.customer.first_name + " "
            : "") +
          (clickInfo.event.extendedProps.customer
            ? clickInfo.event.extendedProps.customer.last_name + " "
            : "") +
          "</p><br/>",
        icon: "info",
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: "Usuń",
        denyButtonText: selectedEmployeeVisit.editable
          ? "Finalizuj"
          : "Sfinalizowano",
        denyButtonColor: "gray",
        cancelButtonText: "Anuluj",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteVisits
            .deleteVisit(this.salon_name, clickInfo.event.id)
            .then((result) => {
              this.setState({
                selectedEmployeeVisits:
                  this.state.selectedEmployeeVisits.filter(
                    (item) => item.id != clickInfo.event.id,
                  ),
              });
            });
        } else if (result.isDenied && selectedEmployeeVisit.editable) {
          this.setState({
            finalizeModal: {
              isOpen: true,
              salon_name: this.salon_name,
              employee: this.state.selectedCalendarEmployee,
              customer: clickInfo.event.extendedProps.customer,
              event: clickInfo.event,
            },
          });
        }
      });
  };

  handleEventDrop = (eventDropInfo) => {
    let dataBody = {
      description: eventDropInfo.event.extendedProps.description,
      user: this.state.selectedCalendarEmployee.id,
      customer: eventDropInfo.event.extendedProps.customer.id,
      service: eventDropInfo.event.extendedProps.serviceId,
      appointment_at: moment(eventDropInfo.event.start).format(
        "YYYY-MM-DD HH:mm:ss",
      ),
    };
    patchVisits
      .updateVisit(this.salon_name, eventDropInfo.event.id, dataBody)
      .then((response) => response.json());
  };

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
  };
}

function renderEventContent(eventInfo) {
  return (
    <div title="r" className="event-container">
      <p className="text-white m-2 d-inline">
        {moment(eventInfo.event.start).format("H:mm")}-
        {moment(eventInfo.event.end).format("H:mm")}
      </p>
      <br />
      <p className="text-white m-2 d-inline">{eventInfo.event.title}</p>
      <br />
      <p className="text-white m-2 d-inline">
        {eventInfo.event.extendedProps.customer
          ? eventInfo.event.extendedProps.customer.first_name
          : ""}
        {eventInfo.event.extendedProps.customer
          ? eventInfo.event.extendedProps.customer.last_name
          : ""}
      </p>
      <br />
    </div>
  );
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id} className="list-group-item text-justify">
      <b>
        {formatDate(event.start, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </b>
      <i>
        {" "}
        - {event.title} <br /> {event.extendedProps.customer.first_name}{" "}
        {event.extendedProps.customer.last_name}
      </i>
    </li>
  );
}

const addMsg = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

const deleteMsg = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});
