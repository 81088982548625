export const updateServiceData = async (salon_name, serviceId, dataBody) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/services/${serviceId}`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(dataBody),
    },
  );
};
export const undoServiceRemoval = async (salon_name, service_id) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/services/${service_id}/undo`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
