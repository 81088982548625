import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import EmployeesTable from "./components/EmployeesTable";
import EmployeesCreateModal from "./components/EmployeesCreateModal";
import EmployeesEditModal from "./components/EmployeesEditModal";

const defaultState = {
  salon_name: localStorage.getItem("salon_name"),
  remoteData: [],
  remoteReload: 0,
  createModal: {
    isOpen: false,
  },
  editModal: {
    isOpen: false,
    form: undefined,
  },

  tableKey: Math.random(),
};

class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      ...props,
    };

    this.handleCreateModalOpen = this.handleCreateModalOpen.bind(this);
    this.handleCreateModalClose = this.handleCreateModalClose.bind(this);
    this.handleAfterValidCreateSubmit =
      this.handleAfterValidCreateSubmit.bind(this);

    this.handleEditModalOpen = this.handleEditModalOpen.bind(this);
    this.handleEditModalClose = this.handleEditModalClose.bind(this);
    this.handleAfterValidEditSubmit =
      this.handleAfterValidEditSubmit.bind(this);
  }

  handleCreateModalOpen = () => {
    this.setState({ createModal: { isOpen: true } });
  };

  handleCreateModalClose = () => {
    this.setState({ createModal: { isOpen: false } });
  };

  handleAfterValidCreateSubmit = (data) => {
    const newRemoteData = [...this.state.remoteData, data];
    this.setState({
      createModal: { isOpen: false },
      remoteData: newRemoteData,
    });
  };

  handleEditModalOpen = (form) => {
    const allRoles = [
      // This should be fetched from API
      {
        value: "ROLE_EMPLOYEE",
        label: "Employee",
      },
      {
        value: "ROLE_ANALYST",
        label: "Analyst",
      },
    ];

    const f = {
      // Avoid modifying passed remote item
      ...form,
      ...{
        enabled: form.enabled ? 1 : 0,
        selectedRoles: allRoles.filter(
          (ar) => form.roles.indexOf(ar.value) >= 0,
        ),
        roles: allRoles,
      },
    };
    this.setState({ editModal: { isOpen: true, form: f } });
  };

  handleEditModalClose = () => {
    this.setState({ editModal: { isOpen: false } });
  };

  handleAfterValidEditSubmit = (data) => {
    const newRemoteData = this.state.remoteData.map((remoteItem) => {
      if (remoteItem.id === data.id) {
        return data;
      }
      return remoteItem;
    });
    this.setState({
      editModal: { isOpen: false },
      remoteData: newRemoteData,
      remoteReload: this.state.remoteReload + 1,
    });
  };

  render() {
    return (
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                  <div className="hk-pg-header align-items-top">
                    <div>
                      <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                        Pracownicy
                      </h2>
                      <Button
                        type="button"
                        onClick={this.handleCreateModalOpen}
                        color="primary"
                        className="waves-effect waves-light"
                      >
                        Dodaj Pracownika
                      </Button>
                      {this.state.createModal.isOpen && (
                        <EmployeesCreateModal
                          onClosed={this.handleCreateModalClose}
                          handleAfterValidSubmit={
                            this.handleAfterValidCreateSubmit
                          }
                        />
                      )}
                      {this.state.editModal.isOpen && (
                        <EmployeesEditModal
                          onClosed={this.handleEditModalClose}
                          handleAfterValidSubmit={
                            this.handleAfterValidEditSubmit
                          }
                          form={this.state.editModal.form}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <CardBody>
                  <EmployeesTable
                    remoteData={this.state.remoteData}
                    onRemoteDataFetched={(data) =>
                      this.setState({ remoteData: data })
                    }
                    remoteReload={this.state.remoteReload}
                    onEditClick={(event, remoteItem) => {
                      this.handleEditModalOpen(remoteItem);
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Employees;
