import React, { Component } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Container,
  FormGroup,
  Label,
} from "reactstrap";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { forgetUser } from "../../store/actions";
import logoDark from "../../assets/images/logo-dark.png";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    this.props.forgetUser(values, this.props.history);
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white" />
          </Link>
        </div>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col md={6} lg={6} xl={5}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={logoDark} height="20" alt="logo" />
                              </Link>
                            </div>
                            <h4 className="font-size-18 mt-4">
                              Zresetuj hasło
                            </h4>
                            <p className="text-muted">
                              Zresetuj swoje hasło do systemu Visitio.
                            </p>
                          </div>

                          <div className="p-2 mt-5">
                            {this.props.forgetError &&
                            this.props.forgetError ? (
                              <Alert color="danger" className="mb-4">
                                {this.props.forgetError}
                              </Alert>
                            ) : null}
                            {this.props.message ? (
                              <Alert color="success" className="mb-4">
                                {this.props.message}
                              </Alert>
                            ) : (
                              <AvForm
                                className="form-horizontal"
                                onValidSubmit={this.handleValidSubmit}
                              >
                                <FormGroup className="auth-form-group-custom mb-4">
                                  <i className="ri-mail-line auti-custom-input-icon" />
                                  <Label htmlFor="useremail">Email</Label>
                                  <AvField
                                    name="useremail"
                                    errorMessage="Niepoprawny adres e-mail"
                                    value={this.state.username}
                                    type="email"
                                    validate={{ email: true, required: true }}
                                    className="form-control"
                                    id="useremail"
                                    placeholder="Podaj adres email"
                                  />
                                </FormGroup>

                                <div className="mt-4 text-center">
                                  <Button
                                    color="primary"
                                    className="w-md waves-effect waves-light"
                                    type="submit"
                                  >
                                    {this.props.loading
                                      ? "Loading..."
                                      : "Reset"}
                                  </Button>
                                </div>
                              </AvForm>
                            )}
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              Masz konto?{" "}
                              <Link
                                to="/login"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Zaloguj się{" "}
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6} xl={7}>
                <div className="authentication-bg">
                  <div className="bg-overlay" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateTooProps = (state) => {
  const { message, forgetError, loading } = state.Forget;
  return { message, forgetError, loading };
};

export default withRouter(
  connect(mapStateTooProps, { forgetUser })(ForgetPasswordPage),
);
