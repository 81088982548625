import React, { Component } from "react";
import { MDBBtn, MDBDataTable } from "mdbreact";
import { getAllSalonsNoPublic } from "../../../Api/Salons/getSalonsWithToken";
import "./salons-table.scss";
import Swal from "sweetalert2";
import equal from "fast-deep-equal";
import moment from "moment";
import translations from "../../../helpers/translations/pl";
import * as updateSalons from "../../../Api/Salons/patchSalons";
import {
  Row,
  Col,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as deleteSalon from "../../../Api/Salons/deleteSalon";
import ToastNotifications from "../../../components/ToastNotification/ToastNotification";
import * as getUsers from "../../../Api/Users/getUsers";
import { UserSalonsContext } from "../../../providers/UserSalonsContext";
import * as getSelectedData from "../../../Api/Salons/getSelectedSalon";

const defaultState = {
  salon_name: localStorage.getItem("salon_name"),
  remoteData: [],
  defaultInputValue: [],
};

class SalonsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      ...props,
      defaultInputValue: [],
      newDate: null,
      selectedSalonIndustry: null,
      isOpen: false,
      modal_standard: false,
    };

    this.tog_standard = this.tog_standard.bind(this);
  }

  fetchData() {
    getAllSalonsNoPublic()
      .then((response) => response.json())
      .then((data) => {
        const { onRemoteDataFetched } = this.props;

        if (onRemoteDataFetched) {
          onRemoteDataFetched(data);
        }

        this.setState({ remoteData: data });
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.remoteData, prevProps.remoteData)) {
      this.fetchData();
    }
  }

  prepareData(setSalons) {
    return {
      columns: this.prepareColumnDefinitions(),
      rows: this.prepareRemoteItems(setSalons),
    };
  }

  prepareColumnDefinitions() {
    return [
      {
        label: "Nazwa",
        field: "name",
        sort: "asc",
      },
      {
        label: "Lokalizacja",
        field: "locality",
        sort: "asc",
      },
      {
        label: "Dodano",
        field: "created_at",
        sort: "asc",
      },
      {
        label: "Branża (rodzaj salonu)",
        field: "salon_industry",
        sort: "asc",
      },
      {
        label: "Edytuj",
        sort: "disable",
        field: "edit",
      },
      {
        label: "Usuń",
        sort: "disable",
        field: "delete",
      },
    ];
  }

  prepareRemoteItems(setSalons) {
    if (typeof this?.state?.remoteData === "object") {
      return Object.values(this.state.remoteData).map((item) =>
        this.prepareRemoteItem(item, setSalons),
      );
    } else {
      return this?.state?.remoteData?.map((item) =>
        this.prepareRemoteItem(item, setSalons),
      );
    }
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
  }

  removeSalon(salonName, setSalons) {
    const deleteMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-light",
      },
      buttonsStyling: false,
    });

    deleteMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Zmiany będą nieodwracalne!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Nie",
        confirmButtonText: "Tak",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteSalon.deleteSalon(salonName).then((response) => {
            if (response.status === 200) {
              ToastNotifications("success", "Salon został usunięty", "Sukces!");
              this.fetchData();
              getUsers.getLoggedUserInfo().then((getLoggedUserInfoResponse) => {
                if (getLoggedUserInfoResponse.status === 200) {
                  getLoggedUserInfoResponse.json().then((data2) => {
                    if (data2?.salons?.length) {
                      setSalons(data2.salons);
                    }
                  });
                }
              });
            } else if (response.status === 400) {
              ToastNotifications(
                "error",
                "Wystąpił błąd. Spróbuj ponownie później.",
                "Błąd!",
              );
            } else if (response.status === 403) {
              ToastNotifications(
                "error",
                "Nie masz uprawnień do usuwania Salonu",
                "Brak uprawnień",
              );
            }
          });
        }
      })
      .catch(() => {
        ToastNotifications(
          "error",
          "Wystąpił błąd. Spróbuj ponownie później.",
          "Błąd!",
        );
      });
  }

  /**
   * Maps a remote record to the one that matches a column definition
   *
   * @param remoteItem
   * @param setSalons
   * @returns {{title: string}}
   */
  prepareRemoteItem = (remoteItem, setSalons) => {
    return {
      ...{
        name: remoteItem.label ? remoteItem.label : remoteItem.name,
        locality: remoteItem.locality,
        created_at: moment(remoteItem.created_at).format("Y-MM-DD HH:mm", {
          trim: false,
        }),
        salon_industry: remoteItem.salon_industry
          ? remoteItem.salon_industry.name
          : "",
        trading_goal: remoteItem.trading_goal,
        trading_name: remoteItem.trading_name,
        trading_progress: remoteItem.trading_progress,
        edit: (
          <MDBBtn
            onClick={() => {
              this.tog_standard();
              this.setState({
                defaultInputValue: remoteItem,
                newDate: new Date(remoteItem.created_at)
                  .toISOString()
                  .split("T")[0],
                isOpen: true,
              });
            }}
            position="center"
            color="info"
            size="sm"
          >
            <i className="ri-edit-2-fill" />
          </MDBBtn>
        ),
        delete: (
          <MDBBtn
            onClick={() => {
              this.removeSalon(remoteItem.name, setSalons);
            }}
            position="center"
            color="danger"
            size="sm"
          >
            <i className="ri-delete-bin-line" />
          </MDBBtn>
        ),
      },
    };
  };

  handleSelectSalonIndustry = (selectedSalonIndustry) => {
    this.setState({
      selectedSalonIndustry: selectedSalonIndustry.target.value,
    });
  };

  handleValidSubmit = (event, values) => {
    const currentSalon = localStorage.getItem("salon_name");

    const body = {
      label: values.name,
      locality: values.locality,
      created_at: moment(this.state.newDate).format("Y-MM-DD", { trim: false }),
      salon_industry: this.state.selectedSalonIndustry,
      trading_goal: values.trading_goal,
      trading_name: values.trading_name,
      trading_progress: values.trading_progress,
      slot_min_time: values.slot_min_time,
      slot_max_time: values.slot_max_time,
      slot_length: values.slot_length,
      min_reservation_length: values.min_reservation_length,
    };

    updateSalons.updateSalons(currentSalon, body).then(async (response) => {
      const newState = {
        btnColor: "success",
        customErrors: this.state.customErrors,
      };

      const status = response.status;
      if (status === 200) {
        return Swal.fire({
          icon: "success",
          title: "Sukces",
          text: "Dane salonu zaktualizowano!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.tog_standard();
          }

          getSelectedData
            .getSalon(currentSalon)
            .then((response) => response.json())
            .then((response) => {
              const slotLength = response?.slot_length || 15;
              localStorage.setItem("salon_slot_min", response.slot_min_time);
              localStorage.setItem("salon_slot_max", response.slot_max_time);
              localStorage.setItem("salon_slot_interval", slotLength);
              localStorage.setItem(
                "salon_min_reservation_length",
                response.min_reservation_length,
              );
            });
        });
      }
    });
  };

  render() {
    return (
      <UserSalonsContext.Consumer>
        {({ setSalons }) => (
          <>
            <MDBDataTable
              id={"salons"}
              autoWidth={false}
              responsive
              striped
              bordered
              data={this.prepareData(setSalons)}
              {...translations.datatable}
            />
            <Container>
              <Row>
                <Col sm={6} md={4} xl={3} className="mt-4">
                  <Modal
                    isOpen={this.state.modal_standard}
                    toggle={this.tog_standard}
                    className="edit-salon-modal"
                  >
                    <ModalHeader
                      toggle={() => this.setState({ modal_standard: false })}
                    >
                      Edytyj Salon
                    </ModalHeader>
                    <ModalBody>
                      <AvForm
                        onValidSubmit={(event, values) =>
                          this.handleValidSubmit(event, values)
                        }
                        id="editServiceForm"
                      >
                        <AvField
                          id="editName"
                          className="editServiceField"
                          name="name"
                          label="Nazwa"
                          placeholder="Wprowadź nazwe"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "nie podałeś wymaganej nazwy",
                            },
                            minLength: {
                              value: 3,
                              errorMessage: "za krótka nazwa",
                            },
                          }}
                          value={this.state.defaultInputValue.label}
                        />
                        <AvField
                          id="editLocality"
                          className="editServiceField"
                          placeholder="dodaj lokalizacje"
                          name="locality"
                          label="Lokalizacja"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "nie podałeś wymaganej nazwy",
                            },
                            minLength: {
                              value: 3,
                              errorMessage: "za krótka nazwa",
                            },
                          }}
                          type="text"
                          value={this.state.defaultInputValue.locality}
                        />
                        <AvField
                          className="editServiceField"
                          name="date"
                          label="Dodano"
                          type="date"
                          id="editDate"
                          value={this.state.newDate}
                        />
                        <FormGroup className="edit-slot-time-wrapper">
                          <Label for="salon_industry">
                            Godziny pracy salonu
                          </Label>
                          <div>
                            <AvField
                              className="editServiceField"
                              name="slot_min_time"
                              label="Od"
                              type="time"
                              id="editSlotMin"
                              value={this.state.defaultInputValue.slot_min_time}
                            />
                            <AvField
                              className="editServiceField"
                              name="slot_max_time"
                              label="Do"
                              type="time"
                              id="editSlotMax"
                              value={this.state.defaultInputValue.slot_max_time}
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label for="salon_industry">
                            Branża (rodzaj salonu)
                          </Label>
                          <AvField
                            type="select"
                            id="salon_industry"
                            name="salon_industry"
                            onChange={this.handleSelectSalonIndustry}
                          >
                            {this.props.salonIndustries.map(
                              (salon_industry) => (
                                <option
                                  key={salon_industry.id}
                                  value={salon_industry.id}
                                >
                                  {salon_industry.name}
                                </option>
                              ),
                            )}
                          </AvField>
                        </FormGroup>
                        <FormGroup className="form-flex-wrapper">
                          <div>
                            <AvField
                              className="editServiceField"
                              name="slot_length"
                              label="Slot czasowy w kalendarzu (min)"
                              type="number"
                              id="editTrading_slot_length"
                              max={60}
                              min={1}
                              value={this.state.defaultInputValue.slot_length}
                            />
                            <AvField
                              className="editServiceField"
                              name="min_reservation_length"
                              label="Minimalny czas wizyty (min)"
                              type="number"
                              id="editTrading_min_reservation_length"
                              max={60}
                              min={1}
                              value={
                                this.state.defaultInputValue
                                  .min_reservation_length
                              }
                            />
                          </div>
                        </FormGroup>

                        <AvField
                          className="editServiceField"
                          name="trading_name"
                          label="Nazwa celu"
                          type="input"
                          id="editTrading_name"
                          value={this.state.defaultInputValue.trading_name}
                        />

                        <AvField
                          className="editServiceField"
                          name="trading_goal"
                          label="Cel do osiągnięcia (zł)"
                          validate={{
                            number: true,
                            // errorMessage: "proszę podać liczbę",
                          }}
                          type="number"
                          id="editTrading_goal"
                          value={this.state.defaultInputValue.trading_goal}
                        />

                        <AvField
                          className="editServiceField"
                          name="trading_progress"
                          label="Ilość wpłaconych pieniędzy"
                          type="number"
                          validate={{
                            number: true,
                            // errorMessage: "proszę podać liczbę",
                          }}
                          id="editTrading_progress"
                          value={this.state.defaultInputValue.trading_progress}
                        />

                        <FormGroup className="mb-0">
                          <div>
                            <Button
                              id="edit_customer"
                              type="submit"
                              color="primary"
                              className="mr-1"
                            >
                              Zapisz
                            </Button>{" "}
                            <Button
                              onClick={this.tog_standard}
                              type="reset"
                              color="secondary"
                            >
                              Anuluj
                            </Button>
                          </div>
                        </FormGroup>
                      </AvForm>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </UserSalonsContext.Consumer>
    );
  }
}

export default SalonsTable;
