import React from "react";
import "toastr/build/toastr.min.css";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="spinner-wrapper">
      <div className="loader-spinner" />
    </div>
  );
};

export default Loader;
