const translations = {
  datatable: {
    entriesLabel: "Pokaż wierszy",
    infoLabel: ["Pokaż", "do", "z", "rekordów"], // ie. 1 to 20 of 100
    noRecordsFoundLabel: "Brak rekordów",
    paginationLabel: ["Poprzednie", "Następne"],
    searchLabel: "Szukaj...",
  },
};

export default translations;
