import React, { Component } from "react";
import { MDBBtn, MDBDataTable } from "mdbreact";
import { getSalonEmployes } from "../../../Api/Users/getUsers";
import "./employees-table.scss";
import translations from "../../../helpers/translations/pl";
import Swal from "sweetalert2";
import * as deleteUser from "../../../Api/Users/deleteUser";
import ToastNotifications from "../../../components/ToastNotification/ToastNotification";

const defaultState = {
  salon_name: localStorage.getItem("salon_name"),
  remoteData: [],
};

class EmployeesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      ...props,
    };
  }

  fetchData() {
    getSalonEmployes(this.state.salon_name)
      .then((response) => response.json())
      .then((data) => {
        const { onRemoteDataFetched } = this.props;

        if (onRemoteDataFetched) {
          onRemoteDataFetched(data);
        }
        this.setState({
          remoteData: data ? data.sort((a, b) => b.id - a.id) : [],
        });
      })
      .catch(() => {
        return false;
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.remoteData.length !== prevProps.remoteData.length ||
      this.props.remoteReload !== prevProps.remoteReload
    ) {
      this.fetchData();
    }
  }

  prepareData() {
    return {
      columns: this.prepareColumnDefinitions(),
      rows: this.prepareRemoteItems(),
    };
  }

  prepareColumnDefinitions() {
    return [
      {
        label: "Imię",
        field: "first_name",
        sort: "asc",
      },
      {
        label: "Nazwisko",
        field: "last_name",
        sort: "asc",
      },
      {
        label: "Login",
        field: "username",
        sort: "asc",
      },
      {
        label: "Role",
        field: "roles",
        sort: false,
      },
      {
        label: "Kolor",
        field: "color",
        sort: false,
      },
      {
        label: "Aktywny",
        field: "enabled",
        sort: false,
      },
      {
        label: "Edytuj",
        field: "edit",
        searchable: false,
      },
      {
        label: "Usuń",
        sort: "disable",
        field: "delete",
      },
    ];
  }

  prepareRemoteItems() {
    return this.state.remoteData.map(this.prepareRemoteItem);
  }

  removeEmployees(employeeId) {
    const salonName = localStorage.getItem("salon_name");
    const deleteMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    deleteMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Zmiany będą nieodwracalne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteUser
            .deleteUser(salonName, employeeId)
            .then((response) => {
              if (response.status === 200) {
                ToastNotifications(
                  "success",
                  "Pracownik został usunięty",
                  "Sukces!",
                );
                this.fetchData();
              } else {
                ToastNotifications(
                  "error",
                  "Wystąpił błąd. Spróbuj ponownie później.",
                  "Błąd!",
                );
              }
            })
            .catch(() => {
              ToastNotifications(
                "error",
                "Wystąpił błąd. Spróbuj ponownie później.",
                "Błąd!",
              );
            });
        }
      });
  }

  /**
   * Maps a remote record to the one that matches a column definition
   *
   * @param remoteItem
   * @returns {{title: string}}
   */
  prepareRemoteItem = (remoteItem) => {
    const { onEditClick } = this.props;

    const defaultFields = {
      edit: (
        <MDBBtn
          position="center"
          color="info"
          size="sm"
          onClick={(event) => onEditClick(event, remoteItem)}
        >
          <i className="ri-edit-2-fill" />
        </MDBBtn>
      ),
      delete: (
        <MDBBtn
          onClick={() => {
            this.removeEmployees(remoteItem.id);
          }}
          position="center"
          color="danger"
          size="sm"
        >
          <i className="ri-delete-bin-line" />
        </MDBBtn>
      ),
    };

    return {
      ...defaultFields,
      ...{
        enabled: remoteItem.enabled ? (
          <i className={"ri-checkbox-fill"} />
        ) : (
          <i className={"ri-checkbox-blank-line"} />
        ),
        first_name: remoteItem.first_name,
        last_name: remoteItem.last_name,
        roles: (
          <ul>
            {remoteItem.roles.map((role, ix) => (
              <li key={ix}>{role}</li>
            ))}
          </ul>
        ),
        color: remoteItem?.color ? (
          <div
            className="employee-color-table"
            style={{ background: remoteItem.color }}
          />
        ) : (
          <></>
        ),
        username: remoteItem.username,
      },
    };
  };

  render() {
    return (
      <MDBDataTable
        id={"employees"}
        responsive
        striped
        bordered
        data={this.prepareData()}
        {...translations.datatable}
      />
    );
  }
}

export default EmployeesTable;
