import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import InputIcon from "@material-ui/icons/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { deleteStocktaking } from "../../Api/Stocktaking/deleteStocktaking";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ActiveStocktakingsTable = (props) => {
  const deleteStocktaking = (e, id) => {
    props.handleStocktakingDelete(id);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nazwa</TableCell>
              <TableCell align="right">Data Utworzenia</TableCell>
              <TableCell align="right">Sporządził</TableCell>
              <TableCell align="center">Opis</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.tableData.map((row, index) => (
              <TableRow key={`${row.name} + ${index}`}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  {moment(row.created_at).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell align="right">{`${row.user.first_name} ${row.user.last_name}`}</TableCell>
                <TableCell align="center">{row.description}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={(e) => console.log(e)} aria-label="edit">
                    <InputIcon fontSize="medium" />
                  </IconButton>
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={(e) => deleteStocktaking(e, row.id)}
                    aria-label="delete"
                  >
                    <DeleteIcon fontSize="medium" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default ActiveStocktakingsTable;
