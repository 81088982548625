import React, { Component } from "react";
import { Row, Col, Button, Container, Label, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link, withRouter } from "react-router-dom";
import * as getSalons from "../../Api/Salons/getSalons";
import * as postUsers from "../../Api/Users/postUsers";
import ToastNotifications from "../../components/ToastNotification/ToastNotification";

class RegisterClient extends Component {
  constructor(props) {
    super(props);
    this.getSalons = this.getSalons.bind(this);
    this.state = {
      salons: [],
    };
  }

  getSalons() {
    getSalons.getAllSalons().then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((salon) => {
            return {
              id: salon.id,
              name: salon.name,
              locality: salon.locality,
              created_at: salon.created_at,
            };
          });
          this.setState({
            salons: apiData,
            salon_name: apiData[0].name,
          });
          localStorage.setItem("salon_name", apiData[0].name);
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  handleRegisterCustomer() {
    const first_name = document.querySelector("#first_name");
    const last_name = document.querySelector("#last_name");
    const owner_password = document.querySelector("#owner_password");
    const owner_mail = document.querySelector("#owner_mail");
    let data = {
      first_name: first_name.value,
      last_name: last_name.value,
      password: owner_password.value,
      username: owner_mail.value,
    };
    postUsers
      .registerCustomer(data, localStorage.getItem("salon_name"))
      .then((response) => {
        if (response.status === 201) {
          ToastNotifications(
            "success",
            "Konto zostało pomyślnie utworzone. Wysłaliśmy maila z linkiem potwierdzającym założenie konta. Sprawdź swoją pocztę.",
            "Sukces",
            10000,
          );
          this.props.history.push("/login");
        } else if (response.status === 400) {
          return ToastNotifications(
            "error",
            "Niepoprawne dane lub nazwa jest zajęta!",
            "Błąd rejestracji.",
          );
        }
      });
  }

  componentDidMount() {
    this.getSalons();
  }

  render() {
    const { salons } = this.state;

    let optionSalons = [
      ...salons.map((salonData) => ({
        value: salonData.id,
        label: salonData.name,
      })),
    ];

    return (
      <>
        <Row style={{ margin: "0" }}>
          <Col sm={12} md={6} className="p-0">
            <div className="customer-bg">
              <a className="logo ml-3 mt-2 authentication" href="/" />
              <div className="w-xxl-75 w-sm-90 w-75 text-content m-auto ">
                <h1 className="display-4 text-white font-weight-normal title">
                  Zarejestruj się do aplikacji jako klient
                </h1>
                <p className="text-white description">
                  Klienci oraz właściciele salonów mogą skorzystać z świetnych
                  funkcjonalności naszej aplikacji pzekonaj się teraz!
                </p>
                <div className="action">
                  <a href="#">
                    <i className="fa fa-play" aria-hidden="true" />
                  </a>
                  <span>Jak działa aplikacja?</span>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} className="p-0">
            <div className="login-form">
              <AvForm
                onValidSubmit={this.handleRegisterCustomer}
                className="form-horizontal"
              >
                <Container>
                  <Row>
                    <Col xl={12}>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-briefcase-line auti-custom-input-icon" />
                        <Label htmlFor="salon_industry">Wybierz salon</Label>
                        <AvField
                          type="select"
                          id="salon"
                          name="salon"
                          onChange={this.handleSelectSalon}
                        >
                          {optionSalons.map((salon) => (
                            <option key={salon.value} value={salon.label}>
                              {salon.label}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-user-2-line auti-custom-input-icon" />
                        <Label htmlFor="first_name">Imię</Label>
                        <AvField
                          errorMessage="Wpisz imię"
                          name="first_name"
                          type="text"
                          className="form-control"
                          id="first_name"
                          validate={{ required: true }}
                          placeholder="Wprowadź nazwę"
                        />
                      </FormGroup>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-lock-2-line auti-custom-input-icon" />
                        <Label htmlFor="owner_password">Hasło</Label>
                        <AvField
                          errorMessage="Wpisz silne hasło"
                          name="owner_password"
                          type="password"
                          className="form-control"
                          id="owner_password"
                          validate={{ required: true }}
                          placeholder="Wprowadź hasło"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={6}>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-file-user-fill auti-custom-input-icon" />
                        <Label htmlFor="last_name">Nazwisko</Label>
                        <AvField
                          errorMessage="Wpisz nazwisko"
                          name="last_name"
                          type="text"
                          className="form-control"
                          id="last_name"
                          validate={{ required: true }}
                          placeholder="Wprowadź nazwisko"
                        />
                      </FormGroup>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-mail-line auti-custom-input-icon" />
                        <Label htmlFor="owner_mail">Adres Email</Label>
                        <AvField
                          errorMessage="Niepoprawny adres e-mail"
                          name="owner_mail"
                          type="text"
                          className="form-control"
                          id="owner_mail"
                          validate={{ email: true, required: true }}
                          placeholder="Wprowadź adres e-mail"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>

                <div className="text-center">
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light fluid login-btn"
                    type="submit"
                  >
                    Zarejestruj się
                  </Button>
                </div>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z{" "}
                    <Link to="#" className="text-primary">
                      Regulaminem
                    </Link>
                  </p>
                </div>
              </AvForm>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(RegisterClient);
