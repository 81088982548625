export const getSalon = async (salon_name) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/salons/${salon_name}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
