import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { checkLogin, apiError } from "../../store/actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Api
import * as postOautch from "../../Api/Oauth/postOauth";
import * as getUsers from "../../Api/Users/getUsers";
import SteperLogin from "./PublicServicesAuth/SteperLogin";
import SteperRegistration from "./PublicServicesAuth/SteperRegistration";

export default class PublicServiceLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasAccount: true,
    };
  }

  showRegistrationForm = () => {
    console.log("fired");
    this.setState({ hasAccount: false });
  };

  showLoginForm = () => {
    this.setState({ hasAccount: true });
  };

  render() {
    return (
      <>
        {this.state.hasAccount ? (
          <SteperLogin
            setLoggedCustomerId={this.props.setLoggedCustomerId}
            salon_name={this.props.salon_name}
            showRegistrationForm={this.showRegistrationForm}
            stepToSumary={this.props.stepToSumary}
          />
        ) : (
          <SteperRegistration
            salonName={this.props.salon_name}
            showLoginForm={this.showLoginForm}
          />
        )}
      </>
    );
  }
}
