import React, { Component } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// reactstrap
import { Button, FormGroup } from "reactstrap";

//i18n
import { withNamespaces } from "react-i18next";

//Import Logos
import logoSmLight from "../../assets/images/logo-sm-light.png";
import logoLight from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";
import logoSmDark from "../../assets/images/logo-sm-dark.png";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

import "./HeaderService.scss";

class HeaderService extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <header id="page-topbar">
          {/* <div className="navbar-header public-view"> */}
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoSmDark} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="20" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoSmLight} alt="" height="22" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLight} alt="" height="20" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="d-none d-lg-inline-block ml-1">
              <Link to="/">
                {/* <Button type="button" color="primary" size="xl" className="waves-effect waves-light mr-3 header___login--btn2" > */}
                <Button
                  type="button"
                  color="primary"
                  size="xl"
                  className="waves-effect waves-light mr-3 header___login--btn"
                >
                  Zaloguj się
                </Button>
              </Link>
            </div>
          </div>
        </header>
      </>
    );
  }
}

// const mapStatetoProps = state => {
//   // const { layoutType } = state.Layout;
//   return { layoutType };
// };

// export default connect(mapStatetoProps, { toggleRightSidebar })(withNamespaces()(HeaderService));
export default HeaderService;
