import React, { Component } from "react";
import {
  Row,
  Col,
  Alert,
  Button,
  Container,
  FormGroup,
  Label,
} from "reactstrap";

import { Link, withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// import images
import logodark from "../../assets/images/logo-dark.png";
import { connect } from "react-redux";
import { forgetUser } from "../../store/auth/forgetpwd/actions";
import { resetUserPassword } from "../../store/auth/forgetpwd/actions";

class ForgetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.resetUserPassword(values.password, "", this.props.history);
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white" />
          </Link>
        </div>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <div>
                              <Link to="/" className="logo">
                                <img src={logodark} height="20" alt="logo" />
                              </Link>
                            </div>

                            <h4 className="font-size-18 mt-4">
                              Wprowadź nowe hasło
                            </h4>
                            <p className="text-muted">Po czym je potwierdź</p>
                          </div>

                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleValidSubmit}
                            >
                              {this.props.forgetError &&
                              this.props.forgetError ? (
                                <Alert color="danger" className="mb-4">
                                  {this.props.forgetError}
                                </Alert>
                              ) : null}
                              {this.props.message ? (
                                <Alert color="success" className="mb-4">
                                  {this.props.message}
                                </Alert>
                              ) : null}
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-rotate-lock-line auti-custom-input-icon" />
                                <Label for="password">Hasło</Label>
                                <AvField
                                  name="password"
                                  type="password"
                                  className="form-control"
                                  id="password"
                                  placeholder="Wprowadź nowe hasło"
                                />
                              </FormGroup>

                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-rotate-lock-fill auti-custom-input-icon" />
                                <Label for="password_confirmation">
                                  Potwierdź hasło
                                </Label>
                                <AvField
                                  name="password_confirmation"
                                  type="password"
                                  className="form-control"
                                  id="password_confirmation"
                                  placeholder="Potwierdź nowe hasło"
                                  validate={{ match: { value: "password" } }}
                                />
                              </FormGroup>

                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  {this.props.loading
                                    ? "Wysyłanie..."
                                    : "Zresetuj"}
                                </Button>
                              </div>
                            </AvForm>
                          </div>

                          <div className="mt-5 text-center">
                            <p>
                              Nie masz konta?{" "}
                              <Link
                                to="/register"
                                className="font-weight-medium text-primary"
                              >
                                {" "}
                                Zarejestruj się{" "}
                              </Link>{" "}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="authentication-bg">
                  <div className="bg-overlay" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { message, forgetError, loading } = state.Forget;
  return { message, forgetError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { resetUserPassword })(ForgetPasswordPage),
);
