import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import VisitsTable from "./components/VisitsTable";

const defaultState = {
  salon_name: localStorage.getItem("salon_name"),
};

class Visits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultState,
      ...props,
    };
  }

  render() {
    return (
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                  <div className="hk-pg-header align-items-top">
                    <div>
                      <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                        Wizyty
                      </h2>
                    </div>
                  </div>
                </div>
                <CardBody>
                  <VisitsTable />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Visits;
