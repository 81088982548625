export const getConsumptionUnits = async () => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/consumption-units`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
