import React from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import * as getUsers from "../../../Api/Users/getUsers";
import * as postSalons from "../../../Api/Salons/postSalons";
import DatePicker from "react-datepicker";
import moment from "moment";
import { UserSalonsContext } from "../../../providers/UserSalonsContext";
import ToastNotifications from "../../../components/ToastNotification/ToastNotification";

export const defaultState = {
  btnColor: "primary",
  salon_name: localStorage.getItem("salon_name"),

  name: undefined,
  //employees: [],
  owner: localStorage.getItem("user_id"),
  createdAt: new Date(),

  customErrors: {
    name: [],
  },
};

export default class SalonsCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.state = {
      ...defaultState,
      ...props,
      selectedSalonIndustry: 1,
    };
  }

  handleValidSubmit(event, values, setSalons) {
    const { handleAfterValidSubmit } = this.props;

    const body = {
      label: values.name,
      locality: values.locality,
      created_at: moment(values.created_at).format("Y-MM-DD", { trim: false }),
      owner: this.state.owner,
      trading_goal: values.trading_goal,
      trading_progress: values.trading_progress,
      trading_name: values.trading_name,
      salon_industry: this.state.selectedSalonIndustry,
      slot_min_time: values.slot_min_time || "06:00:00",
      slot_max_time: values.slot_max_time || "19:00:00",
    };

    postSalons.postSalon(body).then(async (response) => {
      const newState = {
        btnColor: "success",
        customErrors: this.state.customErrors,
      };

      const status = response.status;
      if (status >= 200 && status < 300) {
        newState.customErrors = {
          ...newState,
          ...{
            customErrors: {
              name: [],
            },
          },
        };

        this.setState(newState);
        if (handleAfterValidSubmit) {
          handleAfterValidSubmit(await response.json());
        }

        ToastNotifications("success", "Salon został dodany", "Sukces");

        return getUsers
          .getLoggedUserInfo()
          .then((getLoggedUserInfoResponse) => {
            if (getLoggedUserInfoResponse.status === 200) {
              getLoggedUserInfoResponse.json().then((data2) => {
                if (data2?.salons?.length) {
                  setSalons(data2.salons);
                }
              });
            }

            return response;
          });
      }

      newState.btnColor = "danger";
      const data = await response.text(); // we have an error here

      if (status === 400) {
        if (data.indexOf("Salon already exists")) {
          ToastNotifications("error", "Taki salon już istnieje", "Błąd!");
        } else {
          ToastNotifications(
            "error",
            "Wystąpił błąd podczas dodawania salonu. Spróbuj ponownie później.",
            "Błąd!",
          );
        }
      }

      this.setState(newState);
    });
  }

  handleSubmit(event, errors, values) {
    this.setState({ errors, values });
  }

  handleInvalidSubmit(event, errors, values) {}

  handleSelectSalonIndustry = (selectedSalonIndustry) => {
    this.setState({
      selectedSalonIndustry: selectedSalonIndustry.target.value,
    });
  };

  render() {
    return (
      <UserSalonsContext.Consumer>
        {({ setSalons }) => (
          <AvForm
            className="form-horizontal"
            onSubmit={this.handleSubmit}
            onInvalidSubmit={this.handleInvalidSubmit}
            onValidSubmit={(event, values) =>
              this.handleValidSubmit(event, values, setSalons)
            }
            model={this.state}
          >
            <Container fluid className="p-0">
              <Row className="no-gutters">
                <Col xs={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col xs="12">
                          <AvField
                            name="name"
                            label="Nazwa"
                            validate={{ required: true }}
                          />
                          {this.state.customErrors.name &&
                            this.state.customErrors.name.map((customError) => (
                              <span className="text-danger pb-2">
                                {customError}
                              </span>
                            ))}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <AvField
                            name="locality"
                            label="Lokalizacja"
                            validate={{ required: true }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <AvField name="trading_name" label="Nazwa celu" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <AvField name="trading_goal" label="Kwota celu" />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <AvField
                            name="trading_progress"
                            label="Progres celu"
                          />
                        </Col>
                      </Row>
                      {/*
                  <Row>
                    <Col xs="12">
                      <AvField type="select" name="owner" label="Właściciel" validate={{ required: true, min: {value: 1} }}>
                        <option key={0} value={0}>Wybierz</option>
                        {this.state.employees && this.state.employees.map((emp,ix) => <option key={ix+1} value={emp.id}>{emp.first_name} {emp.last_name}</option>)}
                      </AvField>
                    </Col>
                  </Row>
                  */}
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label for="createdAt">Data utworzenia</Label>
                            <DatePicker
                              selected={this.state.createdAt}
                              onChange={(date) => {
                                this.setState({ createdAt: date });
                              }}
                              name="created_at"
                              id={"createdAt"}
                              showTimeSelect
                              locale="pl"
                              timeFormat="p"
                              timeIntervals={5}
                              dateFormat="Pp"
                              className="form-visit-datapicker"
                              maxDate={new Date()}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <FormGroup className="edit-slot-time-wrapper">
                            <Label for="salon_industry">
                              Godziny pracy salonu
                            </Label>
                            <div>
                              <AvField
                                name="slot_min_time"
                                label="Od"
                                type="time"
                              />
                              <AvField
                                name="slot_max_time"
                                label="Do"
                                type="time"
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label for="salon_industry">
                              Branża (rodzaj salonu)
                            </Label>
                            <AvField
                              type="select"
                              id="salon_industry"
                              name="salon_industry"
                              onChange={this.handleSelectSalonIndustry}
                            >
                              {this.props.salonIndustries.map(
                                (salon_industry) => (
                                  <option
                                    key={salon_industry.id}
                                    value={salon_industry.id}
                                  >
                                    {salon_industry.name}
                                  </option>
                                ),
                              )}
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div className="my-3">
              <Button
                color={this.state.btnColor}
                className="w-md waves-effect waves-light"
                type="submit"
              >
                Zapisz
              </Button>
            </div>
          </AvForm>
        )}
      </UserSalonsContext.Consumer>
    );
  }
}
