import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
} from "reactstrap";
import { MDBDataTable, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";
import HeaderService from "../../components/HeaderService/HeaderService";
import StepProgressBar from "react-step-progress";
export default class Inventory extends Component {
  constructor(props) {
    super(props);
  }
  //   getProductsCategories() {
  //   getProductCategories.getProductCategories(this.state.salon_name)
  //       .then(response => {
  //         response.json()
  //             .then(data => {
  //               if (response.status === 200) {
  //                 let apiData = [{
  //                   id: 0,
  //                   name: 'brak',
  //                 }]
  //                 data.forEach(item => {
  //                   apiData.push({
  //                     id: item.id,
  //                     name: item.name
  //                   })
  //                 })
  //
  //                 this.setState({ products_categories: apiData });
  //                 this.setState({ optionCategoryGroup: [
  //                   ...apiData.map(categoryData => (
  //                       {
  //                         value: categoryData.id,
  //                         label: categoryData.name,
  //                       }
  //                   ))
  //                 ]});
  //
  //               }
  //               else if (response.status === 401) {
  //                 return null;
  //               }
  //             })
  //       });
  // }
  render() {
    let dataValue;
    dataValue = {
      columns: [
        {
          label: "Data i nazwa inwentaryzacji",
          field: "date_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Dostępność produktów",
          field: "product_availability",
          sort: "asc",
          width: 270,
        },
        {
          label: "Liczba produktów z niedoborem w magazynie",
          field: "products_deficiency",
          sort: "asc",
          width: 200,
        },
        {
          label: "Liczba produktów z nadwyżką w magazynie",
          field: "products_surplus",
          sort: "asc",
          width: 100,
        },
        {
          label: "Liczba produktów ze stanem zgodnym",
          field: "products_correct",
          sort: "asc",
          width: 150,
        },
        {
          label: "Porównanie",
          field: "comparision",
          sort: "asc",
          width: 150,
        },
      ],
    };

    return (
      <>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                    {/* <!-- Title --> */}
                    <div className="hk-pg-header align-items-top">
                      <div>
                        <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                          Inwentaryzacja
                        </h2>
                        <Link to="nowa-inwentaryzacja">
                          <Button
                            data-toggle="modal"
                            data-taget="#modalPostCustomer"
                            type="button"
                            color="primary"
                            className="waves-effect waves-light"
                          >
                            Nowa inwentaryzacja
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={dataValue}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
