import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";

import ReactApexChart from "react-apexcharts";

class RegisteredSalonOwnersAnalytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      series: this.props.registered_salon_owners
        ? Object.keys(this.props.registered_salon_owners).map((year) => {
            const registered_salon_owner =
              this.props.registered_salon_owners[year];
            return {
              name: year,
              type: "column",
              data: registered_salon_owner,
            };
          })
        : [],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        stroke: {
          width: [0, 3],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "33%",
          },
        },
        dataLabels: {
          enabled: false,
        },

        legend: {
          show: false,
        },
        colors: ["#5664d2", "#1cbb8c", "#ee4949"],
        labels: [
          "Sty",
          "Lut",
          "Mrz",
          "Kwi",
          "Maj",
          "Cze",
          "Lip",
          "Sie",
          "Wrz",
          "Paź",
          "Lis",
          "Gru",
        ],
      },
    };
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">
              Historia rejestracji właścicieli salonu
            </h4>
            <div>
              <div id="line-column-chart" className="apex-charts" dir="ltr">
                <ReactApexChart
                  options={this.state.options}
                  series={this.state.series}
                  type="line"
                  height="280"
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default RegisteredSalonOwnersAnalytics;
