export const getSalonsIndustries = async () => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/salons-industries`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};
