import React, { Component, componentDidMount } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
  CustomInput,
  InputGroup,
  Form,
  InputGroupAddon,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import "./contactTable.scss";
import Swal from "sweetalert2";
import * as getCustomers from "../../Api/Customers/getCustomers";
import * as postCustomers from "../../Api/Customers/postCustomers";
import * as deleteCustomers from "../../Api/Customers/deleteCustomers";
import * as patchCustomer from "../../Api/Customers/patchCustomers";
import translations from "../../helpers/translations/pl";
import HistoryModal from "../Contacts/components/HistoryModal";
import { constrainPoint } from "@fullcalendar/react";

class ContactTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salon_name: localStorage.getItem("salon_name"),
      users: [],
      body: [],
      addNewCustomerBody: [],
      changeInputValue: [],
      editDataValues: [],
      visible: true,
      lastClickedRow: true,
      historyModalOpen: false,
      modal_standard: false,
      modal_addCustomer: false,
      defaultInputValue: [],
      checkValid: false,
      phoneRegex: /([+(\d]{1})(([\d+() -.]){7,16})([+(\d]{1})/,
      noteEmployee: "",
    };

    this.tog_standard = this.tog_standard.bind(this);
    this.tog_addCustomer = this.tog_addCustomer.bind(this);
    this.editCustomer = this.editCustomer.bind(this);
    this.addCustomer = this.addCustomer.bind(this);
  }

  tog_standard() {
    this.setState((prevState) => ({
      modal_standard: !prevState.modal_standard,
    }));
    this.removeBodyCss();
  }

  tog_editCustomer() {
    this.setState((prevState) => ({
      modal_editCustomer: !prevState.modal_editCustomer,
    }));
    this.removeBodyCss();
  }

  tog_addCustomer() {
    this.setState((prevState) => ({
      modal_addCustomer: !prevState.modal_addCustomer,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  addCustomer() {
    let addCustomerValues = [];
    const addCustomerFormFields =
      document.querySelectorAll(".addCustomerField");
    addCustomerFormFields.forEach((field) => {
      addCustomerValues[field.name] = field.value;
    });

    const dataBody = Object.assign({}, addCustomerValues);
    const regexPhoneValue = addCustomerValues.phone_number.match(
      this.state.phoneRegex,
    );

    if (addCustomerValues.first_name === "") {
      this.setState({ checkValid: false });
    } else if (addCustomerValues.last_name === "") {
      this.setState({ checkValid: false });
    } else if (addCustomerValues.phone_number === "") {
      this.setState({ checkValid: true });
    } else if (
      !regexPhoneValue ||
      regexPhoneValue[0] !== addCustomerValues.phone_number
    ) {
      this.setState({ checkValid: false });
    } else {
      this.setState({ checkValid: true });
    }

    if (this.state.checkValid) {
      const addCustomerAlert = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      addCustomerAlert
        .fire({
          title: "Sukces",
          text: "Pomyślnie dodano nowy kontakt.",
          icon: "success",
          confirmButtonText: "Ok",
        })
        .then((result) => {
          this.tog_addCustomer();
          if (result.isConfirmed) {
            // fetch(`${process.env.REACT_APP_HOST_API}/api/v1/${this.state.salon_name}/customers`,{
            //   method:'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //     'Accept': 'application/json',
            //     'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            //   },
            //   body:JSON.stringify(dataBody),
            // })
            postCustomers
              .registerCustomer(this.state.salon_name, dataBody)
              .then((response) => {
                if (response.status === 201) {
                  this.getCustomers();
                } else if (response.status === 401) {
                  this.unauthorizedException();
                  this.errorMessage();
                  return null;
                }
              });
          }
        });
    } else return;
  }

  editCustomer(customerId) {
    let editCustomerValues = [];
    const editCustomerFormFields =
      document.querySelectorAll(".editCustomerField");
    editCustomerFormFields.forEach((field) => {
      editCustomerValues[field.name] = field.value;
    });
    const dataBody = Object.assign({}, editCustomerValues);
    const regexPhoneValue = editCustomerValues.phone_number.match(
      this.state.phoneRegex,
    );

    if (editCustomerValues.first_name === "") {
      this.setState({ checkValid: false });
    } else if (editCustomerValues.last_name === "") {
      this.setState({ checkValid: false });
    } else if (editCustomerValues.phone_number === "") {
      this.setState({ checkValid: true });
    } else if (
      !regexPhoneValue ||
      regexPhoneValue[0] !== editCustomerValues.phone_number
    ) {
      this.setState({ checkValid: false });
    } else {
      this.setState({ checkValid: true });
    }
    const editMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    if (this.state.checkValid) {
      editMsg
        .fire({
          title: "Sukces!",
          text: "Kontakt został zmieniony.",
          icon: "success",
          confirmButtonText: "Ok",
        })
        .then((result) => {
          this.tog_standard();
          if (result.isConfirmed) {
            // fetch(`${process.env.REACT_APP_HOST_API}/api/v1/${this.state.salon_name}/customers/${customerId}`,{
            //   method:'PATCH',
            //   headers: {
            //     'Content-Type': 'application/json',
            //     'Accept': 'application/json',
            //     'Authorization': `Bearer ${localStorage.getItem('access_token')}`
            //   },
            //   body:JSON.stringify(dataBody),
            // })
            patchCustomer
              .updateCustomer(this.state.salon_name, customerId, dataBody)
              .then((response) => {
                if (response.status === 200) {
                  this.getCustomers();
                } else if (response.status === 401) {
                  this.unauthorizedException();
                  return null;
                }
              });
          }
        });
    } else return;
  }

  removeCustomer(customerId) {
    const deleteMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    deleteMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Zmiany będą nieodwracalne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          // fetch(`${process.env.REACT_APP_HOST_API}/api/v1/${this.state.salon_name}/customers/${customerId}`, {
          //   method:'DELETE',
          //   headers: {
          //     'Content-Type': 'application/json',
          //     'Accept': 'application/json',
          //     'Authorization': `Bearer ${localStorage.getItem('access_token')}`
          //   }
          // })
          deleteCustomers
            .deleteCustomer(this.state.salon_name, customerId)
            .then((response) => {
              if (response.status === 200) {
                this.getCustomers();
              } else if (response.status === 401) {
                this.unauthorizedException();
                return null;
              }
            });
          deleteMsg.fire("Usunięto!", "Kontakt został usunięty.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          deleteMsg.fire("Anulowano", "Kontakt zachowany", "error");
        }
      });
  }

  getCustomers() {
    // fetch(`${process.env.REACT_APP_HOST_API}/api/v1/${this.state.salon_name}/customers`, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json',
    //     'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    //   }
    // })
    getCustomers.getSalonCustomers(this.state.salon_name).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((user) => {
            return {
              customerId: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
              phone_number:
                user.phone_number !== undefined ? user.phone_number : "",
              locality: user.locality !== undefined ? user.locality : "",
              birthdate:
                user.birthdate !== undefined
                  ? new Date(user.birthdate).toISOString().split("T")[0]
                  : "",
              note: user.note !== undefined ? user.note : "",
              email: user.email !== undefined ? user.email : "",
              edit: (
                <MDBBtn position="center" color="info" size="sm">
                  <i className="ri-edit-2-fill" />
                </MDBBtn>
              ),
              delete: (
                <MDBBtn position="center" color="danger" size="sm">
                  <i className="ri-delete-bin-line" />
                </MDBBtn>
              ),
            };
          });
          this.setState({
            users: apiData,
          });
        } else if (response.status === 401) {
          this.unauthorizedException();
          return null;
        }
      });
    });
  }

  componentDidMount() {
    this.getCustomers();
  }
  unauthorizedException() {
    localStorage.clear();
    window.location.replace(`${this.state.host}/login`);
  }

  handleAddNote = (e) => {
    this.setState({
      noteEmployee: e.target.value,
    });
  };

  render() {
    const { users } = this.state;
    const dataValue = {
      columns: [
        {
          label: "Imię",
          field: "first_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Nazwisko",
          field: "last_name",
          sort: "asc",
          width: 270,
        },
        {
          label: "Numer telefonu",
          field: "phone_number",
          sort: "asc",
          width: 200,
        },
        {
          label: "Lokalizacja",
          field: "locality",
          sort: "asc",
          width: 100,
        },
        {
          label: "Data urodzenia",
          field: "birthdate",
          sort: "asc",
          width: 150,
        },
        {
          label: "Email",
          field: "email",
          sort: "asc",
          width: 150,
        },
        {
          label: "Historia",
          sort: "disable",
          field: "history",
          sortIconBefore: false,
        },
        {
          label: "Edytuj",
          sort: "disable",
          field: "edit",
          sortIconBefore: false,
        },
        {
          label: "Usuń",
          sort: false,
          field: "delete",
        },
      ],
      rows: [
        ...users.map((data) => ({
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number:
            data.phone_number !== undefined ? data.phone_number : "",
          locality: data.locality !== undefined ? data.locality : "",
          birthdate:
            data.birthdate !== undefined && data.birthdate !== ""
              ? new Date(data.birthdate).toISOString().split("T")[0]
              : "",
          email: data.email !== undefined ? data.email : "",
          history: (
            <MDBBtn
              onClick={() => {
                this.setState({ historyModalOpen: true, lastClickedRow: data });
              }}
              position="center"
              color="info"
              size="sm"
            >
              <i className="ri-history-fill" />
            </MDBBtn>
          ),
          edit: (
            <MDBBtn
              onClick={() => {
                this.tog_standard();
                this.setState({ defaultInputValue: data });
              }}
              position="center"
              color="info"
              size="sm"
            >
              <i className="ri-edit-2-fill" />
            </MDBBtn>
          ),
          delete: (
            <MDBBtn
              onClick={() => {
                this.removeCustomer(data.customerId);
              }}
              position="center"
              color="danger"
              size="sm"
            >
              <i className="ri-delete-bin-line" />
            </MDBBtn>
          ),
        })),
      ],
    };

    return (
      <>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                    {/* <!-- Title --> */}
                    <div className="hk-pg-header align-items-top">
                      <div>
                        <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                          Kontakty
                        </h2>
                        {/* <a href="#" className="button" data-toggle="modal" data-target="#modalPostCustomer">Dodaj klienta</a> */}
                        <Button
                          data-toggle="modal"
                          data-taget="#modalPostCustomer"
                          type="button"
                          onClick={this.tog_addCustomer}
                          color="primary"
                          className="waves-effect waves-light"
                        >
                          Dodaj Klienta
                        </Button>
                      </div>
                    </div>
                    {/* <!-- /Title --> */}
                    {/* <!-- /Container --> */}
                  </div>
                  <CardBody>
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={dataValue}
                      {...translations.datatable}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* ADD CUSTOMER MODAL */}
        <Container>
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              <Modal
                isOpen={this.state.modal_addCustomer}
                toggle={this.tog_addCustomer}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_addCustomer: false })}
                >
                  Dodaj Klient
                </ModalHeader>
                <ModalBody>
                  <AvForm onSubmit={this.addCustomer} id="addCustomerForm">
                    <AvField
                      onChange={this.handleCheckField}
                      className="addCustomerField"
                      name="first_name"
                      label="Imię"
                      placeholder="Wprowadź imię"
                      type="text"
                      errorMessage="Wprowadź imię"
                      validate={{ required: { value: true } }}
                      id="addFirstName"
                    />
                    <AvField
                      className="addCustomerField"
                      name="last_name"
                      label="Nazwisko"
                      type="text"
                      placeholder="Wprowadź nazwisko"
                      errorMessage="Wprowadź nazwisko"
                      validate={{ required: { value: true } }}
                      id="addLastName"
                    />
                    <AvField
                      onChange={this.handleCheckField}
                      className="addCustomerField"
                      name="phone_number"
                      label="Numer telefon"
                      type="text"
                      errorMessage="Wprowadź prawidłowy numer"
                      id="addPhoneNumber"
                      placeholder="Wprowadź numer telefonu"
                      validate={{
                        pattern: {
                          value: this.state.phoneRegex,
                        },
                      }}
                    />
                    <AvField
                      className="addCustomerField"
                      name="locality"
                      label="Lokalizacja"
                      type="text"
                      placeholder="Wprowadź lokalizacje"
                      errorMessage="Wprowadź lokalizacje"
                      validate={{ required: { value: false } }}
                      id="addLocality"
                    />
                    <AvField
                      className="addCustomerField"
                      name="birthdate"
                      label="Data"
                      type="date"
                      errorMessage="Wprowadź datę"
                      validate={{ required: { value: false } }}
                      id="addBirthdate"
                    />
                    <AvField
                      className="addCustomerField"
                      name="email"
                      label="E-Mail"
                      placeholder="Wprowadź adres e-mail"
                      type="email"
                      errorMessage="Nieprawidłowy adres e-mail"
                      validate={{
                        required: { value: false },
                        email: { value: true },
                      }}
                      id="addEmail"
                    />

                    <AvField
                      className="addCustomerField"
                      name="note"
                      label="Notka (informacja dla pracownika)"
                      placeholder="Wprowadź notke"
                      type="textarea"
                      id="addNote"
                    />
                    <FormGroup className="mb-0">
                      <div>
                        <Button
                          id="add_new_customer"
                          type="submit"
                          color="primary"
                          className="mr-1"
                        >
                          Dodaj Klienta
                        </Button>{" "}
                        <Button
                          onClick={this.tog_addCustomer}
                          type="reset"
                          color="secondary"
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormGroup>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>

        {/* EDIT MODAL */}
        <Container>
          <Row>
            <Col sm={6} md={4} xl={3} className="mt-4">
              {this.state.historyModalOpen && (
                <HistoryModal
                  {...this.state.lastClickedRow}
                  onClosed={() => this.setState({ historyModalOpen: false })}
                />
              )}
              <Modal
                isOpen={this.state.modal_standard}
                toggle={this.tog_standard}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_standard: false })}
                >
                  Edytuj kontakt
                </ModalHeader>
                <ModalBody>
                  <AvForm
                    onSubmit={() => {
                      this.editCustomer(
                        this.state.defaultInputValue.customerId,
                      );
                    }}
                    id="editCustomerForm"
                  >
                    {/* <AvForm onSubmit={this.handleSubmitEdit} id="editCustomerForm"> */}
                    <AvField
                      className="editCustomerField"
                      value={this.state.defaultInputValue.first_name}
                      name="first_name"
                      label="Imię"
                      placeholder="Wprowadź imię"
                      type="text"
                      errorMessage="Wprowadź imię"
                      validate={{ required: { value: true } }}
                      id="editFirstName"
                    />
                    <AvField
                      className="editCustomerField"
                      value={this.state.defaultInputValue.last_name}
                      name="last_name"
                      label="Nazwisko"
                      type="text"
                      placeholder="Wprowadź nazwisko"
                      errorMessage="Wprowadź nazwisko"
                      validate={{ required: { value: true } }}
                      id="editLastName"
                    />
                    <AvField
                      className="editCustomerField"
                      value={this.state.defaultInputValue.phone_number}
                      name="phone_number"
                      label="Numer telefon"
                      placeholder="Wprowadź numer telefonu"
                      type="text"
                      errorMessage="Wprowadź prawidłowy numer"
                      validate={{
                        pattern: {
                          value: this.state.phoneRegex,
                        },
                      }}
                      id="editPhoneNumber"
                    />
                    {/* <Phone/> */}

                    <AvField
                      className="editCustomerField"
                      value={this.state.defaultInputValue.locality}
                      name="locality"
                      label="Lokalizacja"
                      type="text"
                      placeholder="Wprowadź lokalizacje"
                      errorMessage="Wprowadź lokalizacje"
                      validate={{ required: { value: false } }}
                      id="locality"
                    />
                    <AvField
                      className="editCustomerField"
                      value={this.state.defaultInputValue.birthdate}
                      name="birthdate"
                      label="Data"
                      type="date"
                      errorMessage="Wprowadź datę"
                      validate={{ required: { value: false } }}
                      id="birthdate"
                    />
                    <AvField
                      className="editCustomerField"
                      value={this.state.defaultInputValue.email}
                      name="email"
                      label="E-Mail"
                      placeholder="Wprowadź adres e-mail"
                      type="email"
                      errorMessage="Nieprawidłowy adres e-mail"
                      validate={{
                        required: { value: false },
                        email: { value: true },
                      }}
                      id="email"
                    />
                    <AvField
                      className="editCustomerField"
                      value={this.state.defaultInputValue.note}
                      name="note"
                      label="Notatka"
                      placeholder="Wprowadź notatkę"
                      type="textarea"
                      validate={{
                        required: { value: false },
                      }}
                      id="note"
                    />
                    <FormGroup className="mb-0">
                      <div>
                        <Button
                          id="edit_customer"
                          type="submit"
                          color="primary"
                          className="mr-1"
                        >
                          Zapisz
                        </Button>{" "}
                        <Button
                          onClick={this.tog_standard}
                          type="reset"
                          color="secondary"
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormGroup>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ContactTable;
