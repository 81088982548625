import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import {
  Chip,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  useTheme,
} from "@material-ui/core";
//Api
import * as getTypes from "../../Api/Products/Types/getProductTypes";
import * as getCategories from "../../Api/Products/Categories/getProductCategories";
import * as getUsers from "../../Api/Users/getUsers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const StocktakingForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [typeOptions, setTypeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [employees, setEmployees] = useState([]);

  const [stocktakingInitialData, setStocktakingInitialData] = useState({
    selectedType: "",
    isFull: true,
    selectedCategories: [],
    name: "",
    creatorName: "",
    description: "",
  });

  const handleRangeChange = (e) => {
    setStocktakingInitialData({
      ...stocktakingInitialData,
      isFull: e.target.value,
    });
  };

  const handleTypeChange = (e) => {
    setStocktakingInitialData({
      ...stocktakingInitialData,
      selectedType: e.target.value,
    });
  };

  const handleselectedCategoriesChange = (e) => {
    setStocktakingInitialData({
      ...stocktakingInitialData,
      selectedCategories: e.target.value,
    });
  };

  const handleNameChange = (e) => {
    setStocktakingInitialData({
      ...stocktakingInitialData,
      name: e.target.value,
    });
  };

  const handleCreatorNameChange = (e) => {
    setStocktakingInitialData({
      ...stocktakingInitialData,
      creatorName: e.target.value,
    });
  };

  const handleDescriptionChange = (e) => {
    setStocktakingInitialData({
      ...stocktakingInitialData,
      description: e.target.value,
    });
  };

  const handleDataSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit(stocktakingInitialData);
  };

  useEffect(() => {
    getTypes
      .getProductsTypes()
      .then((response) => response.json())
      .then((data) => setTypeOptions(data));
    getCategories
      .getProductCategories(props.salonName)
      .then((response) => response.json())
      .then((data) => setCategoryOptions(data));
    getUsers
      .getUsersBySalonName(props.salonName)
      .then((response) => response.json())
      .then((data) =>
        setEmployees(
          data.filter(
            (user) =>
              user.roles.includes("ROLE_EMPLOYEE") ||
              user.roles.includes("ROLE_OWNER"),
          ),
        ),
      );
    return () => {
      console.log("unmount");
    };
  }, []);

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12} sm={5}>
        <form onSubmit={handleDataSubmit}>
          <Paper elevation={3} className="p-3">
            <FormControl required className="w-100 my-3">
              <InputLabel id="demo-controlled-open-select-label">
                Rodzaj Produktów
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                className="text-capitalize"
                value={stocktakingInitialData.selectedType}
                onChange={handleTypeChange}
              >
                {typeOptions.map((option) => (
                  <MenuItem
                    key={option.name}
                    value={option}
                    className="text-capitalize"
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl required className="w-100 my-3">
              <InputLabel id="demo-mutiple-chip-label">
                Zakres Inwentaryzacji
              </InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                className="text-capitalize"
                value={stocktakingInitialData.isFull}
                onChange={handleRangeChange}
                input={<Input id="select-multiple-chip" />}
                MenuProps={MenuProps}
              >
                <MenuItem value={true} className="text-capitalize">
                  Pełna Inwentaryzacja
                </MenuItem>

                <MenuItem value={false} className="text-capitalize">
                  Wybrane Kategorie
                </MenuItem>
              </Select>
            </FormControl>
            {stocktakingInitialData.isFull ? (
              ""
            ) : (
              <FormControl required className="w-100 my-3">
                <InputLabel id="demo-mutiple-chip-label">
                  Kategorie produktów
                </InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  className="text-capitalize"
                  value={stocktakingInitialData.selectedCategories}
                  onChange={handleselectedCategoriesChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value.name}
                          label={value.name}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {categoryOptions.map((option) => (
                    <MenuItem
                      key={option.name}
                      value={option}
                      className="text-capitalize"
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl required className="w-100 my-3">
              <TextField
                id="filled-name"
                label="Nazwa"
                value={stocktakingInitialData.name}
                onChange={handleNameChange}
                variant="outlined"
                required
              />
            </FormControl>

            <FormControl required className="w-100 my-3">
              <InputLabel id="demo-controlled-open-select-label">
                Sporządził
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={stocktakingInitialData.creatorName}
                onChange={handleCreatorNameChange}
              >
                {employees.map((option) => (
                  <MenuItem
                    key={`${option.first_name} ${option.last_name}`}
                    value={option}
                    className="text-capitalize"
                  >
                    {`${option.first_name} ${option.last_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="w-100 my-3">
              <TextField
                id="filled-multiline-flexible"
                label="Opis"
                multiline
                rows={4}
                value={stocktakingInitialData.description}
                onChange={handleDescriptionChange}
                variant="outlined"
              />
            </FormControl>

            <Button type="submit" variant="contained" color="primary">
              Rozpocznij Inventaryzacje
            </Button>
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
};
