import React, { Component } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Card,
  CardBody,
} from "reactstrap";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import * as getTpays from "../../Api/Tpays/getTpays";
import moment from "moment";

const expandRow = {
  renderer: (row) => (
    <>
      Action :
      <Link to="#" className="mr-3 text-primary">
        <i className="mdi mdi-pencil font-size-18" />
      </Link>
      <Link to="#" className="text-danger">
        <i className="mdi mdi-trash-can font-size-18" />
      </Link>
    </>
  ),
  showExpandColumn: true,
  expandByColumnOnly: true,
};

class LatestTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      data: {
        columns: [
          {
            dataField: "id",
            text: "Id",
          },
          {
            dataField: "title",
            text: "Tytuł transakcji",
          },
          {
            dataField: "created_at",
            text: "Data",
          },
          {
            dataField: "amount",
            text: "Kwota",
          },
          {
            dataField: "status",
            text: "Status płatności",
          },
        ],
        rows: [],
      },
      tpays: [],
      dataRows: [],
    };
  }

  statusSwitch(param) {
    switch (param) {
      case "correct":
        return (
          <div className="badge badge-soft-success font-size-12">{param}</div>
        );
      case "pending":
        return (
          <div className="badge badge-soft-warning font-size-12">{param}</div>
        );
      case "error":
        return (
          <div className="badge badge-soft-danger font-size-12">{param}</div>
        );
      default:
        return null;
    }
  }

  getTpays() {
    getTpays.getTpays(this.props.salon_name).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = [];
          let dataRows = [];
          data.forEach((item) => {
            apiData.push({
              id: item.id,
              title: item.title,
              amount: item.amount,
              status: item.status,
              created_at: item.created_at,
            });
            dataRows.push({
              id: item.id,
              title: (
                <Link to="#" className="text-dark font-weight-bold">
                  {item.title}
                </Link>
              ),
              created_at: moment(item.created_at).format("DD-MM-YYYY HH:mm:ss"),
              amount: item.amount + " zł",
              status: this.statusSwitch(item.status),
            });
          });
          this.setState({ tpays: apiData });
          this.setState({ dataRows: dataRows });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  componentDidMount() {
    this.getTpays();
  }

  render() {
    const { data } = this.state;

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList: [
        {
          text: "5th",
          value: 5,
        },
        {
          text: "10th",
          value: 10,
        },
        {
          text: "All",
          value: data.rows.length,
        },
      ],
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
    };

    return (
      <React.Fragment>
        <Col lg={8}>
          <Card>
            <CardBody>
              <Dropdown
                isOpen={this.state.menu}
                toggle={() => this.setState({ menu: !this.state.menu })}
                className="float-right"
              >
                <DropdownToggle tag="i" className="arrow-none card-drop">
                  <i className="mdi mdi-dots-vertical" />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>Sales Report</DropdownItem>

                  <DropdownItem>Export Report</DropdownItem>

                  <DropdownItem>Profit</DropdownItem>

                  <DropdownItem>Action</DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <h4 className="card-title mb-4">Ostatnie transakcje</h4>

              <BootstrapTable
                keyField="id"
                data={this.state.dataRows}
                columns={data.columns}
                expandRow={expandRow}
                pagination={paginationFactory(options)}
                selectRow={selectRow}
              />
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default LatestTransactions;
