export const postAvatars = async (salonName, id, dataBody) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salonName}/users/${id}/avatar`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: dataBody,
    },
  );
};
