import React, { Component } from "react";

import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardColumns,
  Container,
  CardGroup,
} from "reactstrap";

// import images
import googleAds from "../../assets/images/appStore/google-ads.png";
import inst from "../../assets/images/appStore/instagram.png";
import smsApi from "../../assets/images/appStore/integracja-sms-api.png";
import lms from "../../assets/images/appStore/lms.png";
import hair from "../../assets/images/appStore/mobilny-fryzjer.png";
import payU from "../../assets/images/appStore/Payu.png";
import popularHurt from "../../assets/images/appStore/popularne-hurtownie.png";
import tikTok from "../../assets/images/appStore/tiktok.png";
import multilingualism from "../../assets/images/appStore/wielojęzycznosc.png";
import multiCurrency from "../../assets/images/appStore/wielowalutowosc.png";
import googleMaps from "../../assets/images/appStore/wizytowka-google-maps.png";
import socialMedia from "../../assets/images/appStore/zaawansowane-social-media.png";
import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

class AppStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Home", link: "/" },
        { title: "AppStore", link: "/app-store" },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="AppStore"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg top className="img-fluid" src={smsApi} alt="Skote" />
                  <CardBody>
                    <CardTitle className="h4">Integracja SMS API</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg
                    top
                    className="img-fluid"
                    src={googleMaps}
                    alt="Skote"
                  />
                  <CardBody>
                    <CardTitle className="h4">Wizytówka Google Maps</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                  {/*<ul className="list-group list-group-flush">*/}
                  {/*    <li className="list-group-item">Cras justo odio</li>*/}
                  {/*    <li className="list-group-item">Dapibus ac facilisis in</li>*/}
                  {/*</ul>*/}
                  {/*<CardBody>*/}
                  {/*    <Link to="#" className="card-link">Card link</Link>*/}
                  {/*    <Link to="#" className="card-link">Another link</Link>*/}
                  {/*</CardBody>*/}
                </Card>
              </Col>

              <Col md={6} xl={3}>
                <Card>
                  <CardImg
                    top
                    className="img-fluid"
                    src={multilingualism}
                    alt="Skote"
                  />
                  <CardBody>
                    <CardTitle className="h4">Wielojęzyczność</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg top className="img-fluid" src={hair} alt="Skote" />
                  <CardBody>
                    <CardTitle className="h4">Mobilny fryzjer</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg
                    top
                    className="img-fluid"
                    src={socialMedia}
                    alt="Skote"
                  />
                  <CardBody>
                    <CardTitle className="h4">
                      Zaawansowane social media
                    </CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg
                    top
                    className="img-fluid"
                    src={googleAds}
                    alt="Skote"
                  />
                  <CardBody>
                    <CardTitle className="h4">Google Ads</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg top className="img-fluid" src={inst} alt="Skote" />
                  <CardBody>
                    <CardTitle className="h4">Instagram</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg top className="img-fluid" src={tikTok} alt="Skote" />
                  <CardBody>
                    <CardTitle className="h4">Tiktok</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg
                    top
                    className="img-fluid"
                    src={popularHurt}
                    alt="Skote"
                  />
                  <CardBody>
                    <CardTitle className="h4">Popularne hurtownie</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg top className="img-fluid" src={lms} alt="Skote" />
                  <CardBody>
                    <CardTitle className="h4">LMS</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg
                    top
                    className="img-fluid"
                    src={multiCurrency}
                    alt="Skote"
                  />
                  <CardBody>
                    <CardTitle className="h4">Wielowalutowość</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} xl={3}>
                <Card>
                  <CardImg top className="img-fluid" src={payU} alt="Skote" />
                  <CardBody>
                    <CardTitle className="h4">Payu</CardTitle>
                    <CardText>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Fusce gravida viverra dignissim.
                    </CardText>
                    <Link
                      to="#"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Pobierz
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AppStore;
