import React, { Component } from "react";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import SalonsTable from "./components/SalonsTable";
import SalonsCreateModal from "./components/SalonsCreateModal";

import * as getSalonsIndustries from "../../Api/Salons/Industries/getSalonsIndustries";

const defaultState = {
  salon_name: localStorage.getItem("salon_name"),
  remoteData: [],

  createModal: {
    isOpen: false,
  },

  tableKey: Math.random(),
};

class Salons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      ...props,
      salonIndustries: [],
    };

    this.handleCreateModalOpen = this.handleCreateModalOpen.bind(this);
    this.handleCreateModalClose = this.handleCreateModalClose.bind(this);
    this.handleAfterValidCreateSubmit =
      this.handleAfterValidCreateSubmit.bind(this);
    this.getSalonsIndustries = this.getSalonsIndustries.bind(this);
  }

  getSalonsIndustries() {
    getSalonsIndustries.getSalonsIndustries().then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((salon_industry) => {
            return {
              id: salon_industry.id,
              name: salon_industry.name,
            };
          });
          this.setState({
            salonIndustries: apiData,
          });
        }
      });
    });
  }

  componentDidMount() {
    this.getSalonsIndustries();
  }

  handleCreateModalOpen = () => {
    this.setState({ createModal: { isOpen: true } });
  };

  handleCreateModalClose = () => {
    this.setState({ createModal: { isOpen: false } });
  };

  handleAfterValidCreateSubmit = (data) => {
    const newRemoteData = [...this.state.remoteData, data];
    this.setState({
      createModal: { isOpen: false },
      remoteData: newRemoteData,
    });
  };

  render() {
    return (
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                  <div className="hk-pg-header align-items-top">
                    <div>
                      <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                        Salony
                      </h2>
                      <Button
                        type="button"
                        onClick={this.handleCreateModalOpen}
                        color="primary"
                        className="waves-effect waves-light"
                      >
                        Dodaj Salon
                      </Button>
                      {this.state.createModal.isOpen && (
                        <SalonsCreateModal
                          onClosed={this.handleCreateModalClose}
                          handleAfterValidSubmit={
                            this.handleAfterValidCreateSubmit
                          }
                          salonIndustries={this.state.salonIndustries}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <CardBody>
                  <SalonsTable
                    remoteData={this.state.remoteData}
                    onRemoteDataFetched={(data) =>
                      this.setState({ remoteData: data })
                    }
                    salonIndustries={this.state.salonIndustries}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Salons;
