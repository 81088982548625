import React, { Component } from "react";
import SalonsCreateForm from "./SalonsCreateForm";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const initialState = {
  form: {
    name: "",
  },
};

class SalonsCreateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  render() {
    const { onClosed } = this.props;

    return (
      <Modal isOpen={true} size="md" centered={true} onClosed={onClosed}>
        <ModalHeader toggle={onClosed}>Utwórz nowy salon</ModalHeader>
        <ModalBody>
          <SalonsCreateForm
            {...this.state.form}
            handleAfterValidSubmit={(data) => {
              this.props.handleAfterValidSubmit(data);
            }}
            salonIndustries={this.props.salonIndustries}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={onClosed}
            color="light"
            className="waves-effect"
          >
            Zamknij
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SalonsCreateModal;
