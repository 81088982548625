import React, { Component } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withNamespaces } from "react-i18next";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      avatar: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  makeRequest(method, url) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      };
      xhr.send();
    });
  }

  async doAjaxThings() {
    let avatar =
      localStorage.getItem("avatar") !== "undefined"
        ? `${process.env.REACT_APP_HOST_API}/uploads/avatars/` +
          localStorage.getItem("avatar")
        : null;
    return await this.makeRequest("GET", avatar);
  }

  componentDidMount() {
    this.doAjaxThings().then((result) => {
      if (result) {
        this.setState({ avatar: true });
      }
    });
  }

  render() {
    let avatar =
      localStorage.getItem("avatar") !== "undefined"
        ? `${process.env.REACT_APP_HOST_API}/uploads/avatars/` +
          localStorage.getItem("avatar")
        : null;

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
          title="Ustawienia"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            {avatar && this.state.avatar ? (
              <img
                className="rounded-circle header-profile-user mr-1"
                src={avatar}
                alt="Zdjęcie"
                id="avatarTopMenu"
              />
            ) : (
              <i className="ri-user-3-line header-profile-user-icon" />
            )}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem href="/profile-settings">
              <i className="ri-user-line align-middle mr-1" />{" "}
              {this.props.t("Profil")}
            </DropdownItem>
            <DropdownItem className="d-block" href="/panel-zarzadzania-salonem">
              <i className="ri-settings-2-line align-middle mr-1" />{" "}
              {this.props.t("Ustawienia")}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem className="text-danger" href="/logout">
              <i className="ri-shut-down-line align-middle mr-1 text-danger" />{" "}
              {this.props.t("Wyloguj")}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(ProfileMenu);
