import React, { Component } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from "sweetalert2";
import RevenueAnalytics from "../Stats/RevenueAnalytics";

class SeoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Visitio", link: "/" },
        { title: "Moduł SEO", link: "#" },
      ],
    };
  }

  handleSubmit(event, errors, values) {
    const registerAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });
    const storageSession = JSON.parse(sessionStorage.getItem("seoModule"));
    const seoData = {
      title: values.title || "",
      metaDescription: values.metaDescription || "",
      openGraph: values.openGraph || "",
      salonName: localStorage.getItem("salon_name"),
    };
    let dataStorage = [];
    if (storageSession && storageSession.length > 0) {
      dataStorage = storageSession;
      let check = false;
      for (let i = 0; i < storageSession.length; i++) {
        if (
          storageSession[i].salonName === localStorage.getItem("salon_name")
        ) {
          dataStorage[i] = seoData;
          check = true;
        }
        if (i === storageSession.length - 1) {
          if (!check) {
            dataStorage.push(seoData);
          }
          sessionStorage.setItem("seoModule", JSON.stringify(dataStorage));
        }
      }
    } else {
      dataStorage.push(seoData);
      sessionStorage.setItem("seoModule", JSON.stringify(dataStorage));
    }
    registerAlert.fire({
      title: "Sukces",
      text: "Dane zostały zapisane",
      icon: "success",
      confirmButtonText: "Ok",
    });
  }
  render() {
    const seoModuleSession = JSON.parse(sessionStorage.getItem("seoModule"));
    let salonSeoData;
    if (seoModuleSession && seoModuleSession.length > 0) {
      for (let i = 0; i < seoModuleSession.length; i++) {
        if (
          seoModuleSession[i].salonName === localStorage.getItem("salon_name")
        ) {
          salonSeoData = {
            title: seoModuleSession[i]?.title || "",
            metaDescription: seoModuleSession[i]?.metaDescription || "",
            openGraph: seoModuleSession[i]?.openGraph || "",
          };
        }
      }
    } else {
      salonSeoData = {
        title: "",
        metaDescription: "",
        openGraph: "",
      };
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Moduł SEO"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md={6} sm={12}>
                <Card>
                  <CardBody>
                    <AvForm
                      className="form-horizontal p-5"
                      onSubmit={this.handleSubmit}
                      onInvalidSubmit={this.handleInvalidSubmit}
                      onValidSubmit={this.handleValidSubmit}
                      model={this.state}
                    >
                      <h2 className="pb-1">Title</h2>
                      <AvField
                        type="input"
                        name="title"
                        label=""
                        placeholder="Wpisz tytuł"
                        value={salonSeoData.title}
                      />

                      <h2 className="pb-1 mt-5">Opis meta description</h2>
                      <AvField
                        type="textarea"
                        name="metaDescription"
                        label=""
                        placeholder="Wpisz meta description"
                        value={salonSeoData.metaDescription}
                      />

                      <h2 className="pb-1 mt-5">Open graph Facebook</h2>
                      <AvField
                        type="textarea"
                        name="openGraph"
                        label=""
                        placeholder="Wpisz Open Graph"
                        value={salonSeoData.openGraph}
                      />

                      <div className="my-3 mt-5">
                        <Button
                          color={this.state.btnColor}
                          className="w-md waves-effect waves-light"
                          type="submit"
                        >
                          Zapisz
                        </Button>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} sm={12}>
                <Card className="p-3">
                  <CardBody>
                    <h1>Statystyki</h1>
                    <RevenueAnalytics
                      salon_name={localStorage.getItem("salon_name")}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SeoPage;
