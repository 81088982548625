import React, { Component } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { checkLogin, apiError } from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import logodark from "../../../assets/images/logo-dark.png";
import Swal from "sweetalert2";
import * as postOautch from "../../../Api/Oauth/postOauth";
import * as getUsers from "../../../Api/Users/getUsers";
import { CLIENT_ID, CLIENT_SECRET } from "../../../utils/const";

class SteperLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "admin@themesdesign.in",
      password: "123456",
      data: null,
      user: [],
      isCustomerLogin: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserLogin = this.handleUserLogin.bind(this);
    console.log(props);
  }

  handleSubmit(event, values) {
    this.props.checkLogin(values, this.props.history);
  }

  handleUserLogin() {
    const { hostApi, host } = this.state;
    const email = document.querySelector("#username");
    const password = document.querySelector("#password");
    let data = {
      grant_type: "password",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      username: email.value,
      password: password.value,
    };
    const dataBody = Object.assign({}, data);
    const loginAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });

    postOautch.postOauthToken(dataBody).then((response) => {
      if (response.status === 200) {
        loginAlert
          .fire({
            title: "Sukces",
            text: "Zalogowano pomyślnie.",
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              response.json().then((data) => {
                localStorage.setItem("access_token", data.access_token);
                localStorage.setItem("refresh_token", data.refresh_token);
                getUsers.getLoggedUserInfo().then((response) => {
                  response.json().then((data2) => {
                    if (response.status === 200) {
                      localStorage.setItem("user_salons", data2.salons);
                      localStorage.setItem(
                        "user_salons_labels",
                        JSON.stringify(data2.salonLabels),
                      );
                      localStorage.setItem("salon_name", this.props.salon_name);
                      localStorage.setItem("first_name", data2.first_name);
                      localStorage.setItem("last_name", data2.last_name);
                      localStorage.setItem("username", data2.username);
                      localStorage.setItem("user_id", data2.id);
                      localStorage.setItem("avatar", data2.avatar);
                      localStorage.setItem(
                        "is_owner",
                        data2.roles.indexOf("ROLE_OWNER") >= 0,
                      );
                      localStorage.setItem(
                        "is_sa",
                        data2.roles.indexOf("ROLE_SUPER_ADMIN") >= 0,
                      );
                      localStorage.setItem(
                        "is_employee",
                        data2.roles.indexOf("ROLE_EMPLOYEE") >= 0,
                      );
                      localStorage.setItem(
                        "is_customer",
                        data2.roles.indexOf("ROLE_CUSTOMER") >= 0,
                      );

                      this.props.setLoggedCustomerId(data2.customer_id);
                      this.props.stepToSumary();
                    }
                  });
                });
              });
            }
          });
      } else if (response.status === 400) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Niepoprawny login/hasło!",
        });
      }
    });
  }

  componentDidMount() {
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }

  handleFormSwith = () => {
    this.props.showRegistrationForm();
  };

  render() {
    return (
      <Row className="no-gutters">
        <Col lg={6}>
          <div className="d-flex align-items-center">
            <div className="w-100">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div>
                    <div className="text-center">
                      <div>
                        <Link to="/" className="logo">
                          <img src={logodark} height="20" alt="logo" />
                        </Link>
                      </div>

                      <h4 className="font-size-18 mt-4">Zaloguj się</h4>
                      <p className="text-muted">
                        Zacznij korzystać z aplikacji Visitio
                      </p>
                    </div>

                    {/* <Button color="primary">Zaloguj jako klient</Button> */}
                    {this.props.loginError && this.props.loginError ? (
                      <Alert color="danger">{this.props.loginError}</Alert>
                    ) : null}

                    <div className="p-2 mt-5">
                      {/* <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} > */}
                      <AvForm
                        id="userLoginForm"
                        className="form-horizontal"
                        onValidSubmit={this.handleUserLogin}
                      >
                        <FormGroup className="auth-form-group-custom mb-4">
                          <i className="ri-user-2-line auti-custom-input-icon" />
                          <Label htmlFor="username">Adres Email</Label>
                          <AvField
                            name="username"
                            type="text"
                            className="form-control"
                            id="username"
                            validate={{ email: true, required: true }}
                            placeholder="Wprowadź adres e-mail"
                          />
                        </FormGroup>

                        <FormGroup className="auth-form-group-custom mb-4">
                          <i className="ri-lock-2-line auti-custom-input-icon" />
                          <Label htmlFor="userpassword">Hasło</Label>
                          <AvField
                            name="password"
                            type="password"
                            className="form-control"
                            id="password"
                            placeholder="Wprowadź hasło"
                          />
                        </FormGroup>

                        <div className="custom-control custom-checkbox">
                          <Input
                            type="checkbox"
                            className="custom-control-input"
                            id="customControlInline"
                          />
                          <Label
                            className="custom-control-label"
                            htmlFor="customControlInline"
                          >
                            Zapamiętaj mnie
                          </Label>
                        </div>

                        <div className="mt-4 text-center">
                          <Button
                            color="primary"
                            className="w-md waves-effect waves-light fluid login-btn p-3"
                            type="submit"
                          >
                            Zaloguj się
                          </Button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock mr-1" /> Zapomniałeś/aś
                            hasło?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="align-items-center">
            <div className="w-xxl-75 w-sm-90 w-75 text-content m-auto ">
              <h3 className="font-weight-medium title mb-4">Nie masz konta?</h3>
              <p className=" description mb-4">
                Rejestracja pozwoli Ci szybko rezerwować nowe wizyty
              </p>

              <Button
                color="primary"
                onClick={this.handleFormSwith}
                className="w-100 p-3"
              >
                Zarejestruj się
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError })(SteperLogin),
);
