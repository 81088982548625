import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./registerPage.css";
import SalonImg from "../../assets/images/login-bg.jpg";
import CustomerImg from "../../assets/images/register-bg.jpg";

import RegisterCustomer from "./RegisterCustomer";
import RegisterSalonOwner from "./RegisterSalonOwner";

class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      password: "",
      isOwnerForm: false,
      isCustomerRegister: false,
      salon_name_regex: /^[A-Za-z0-9](?:[A-Za-z0-9\-_]{0,18}[A-Za-z0-9])?$/i,
      checkValid: false,
      salon_name: null,
      salons: [],
      salonIndustries: [],
      selectedSalonIndustry: 1,
    };
  }

  componentDidMount() {
    document.body.classList.add("auth-body-bg");
  }

  render() {
    return (
      <>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white" />
          </Link>
        </div>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={6}>
                <Link to="/register-salonowner">
                  <div
                    className="auth-bg"
                    style={{ backgroundImage: `url(${SalonImg})` }}
                  >
                    <div className="w-xxl-75 w-sm-90 w-75 text-content m-auto ">
                      <h1 className="display-4 text-white font-weight-normal title">
                        Zarządzaj salonem fryzjerskim
                      </h1>
                      <p className="text-white description">
                        The passage experienced a surge in popularity during the
                        1960s when Letraset used it on their dry-transfer
                        sheets, and again during the 90s as desktop publishers
                        bundled the text with their software.
                      </p>
                      {/* <div className="action">
                      <a href="#">
                        <i className="fa fa-play" aria-hidden="true"/>
                      </a>
                      <span>Jak działa aplikacja?</span>
                    </div> */}
                    </div>
                  </div>
                </Link>
              </Col>
              <Col lg={6}>
                <Link to="/register-customer">
                  <div
                    className="customer-bg"
                    style={{ backgroundImage: `url(${CustomerImg})` }}
                  >
                    <div className="w-xxl-75 w-sm-90 w-75 text-content m-auto ">
                      <h1 className="display-4 text-white font-weight-normal title ">
                        Założ konto aby mieć dostęp do swoich zamówień
                      </h1>
                      <p className="text-white description">
                        The passage experienced a surge in popularity during the
                        1960s when Letraset used it on their dry-transfer
                        sheets, and again during the 90s as desktop publishers
                        bundled the text with their software.
                      </p>
                    </div>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(RegisterPage);
