import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./theme.scss";

//Fake backend
// import fakeBackend from './helpers/AuthType/fakeBackend';

//Firebase helper
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Auth Helper
// import trueBackend from "./helpers/AuthType/trueBackend";

// AUTH HELPER

import axios from "axios";
import { UserSalonsContext } from "./providers/UserSalonsContext";

// Activating fake backend
// fakeBackend();
// trueBackend();

// init firebase backend
// initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    const userSalons = localStorage.getItem("user_salons");
    this.state = {
      salons: userSalons ? userSalons.split(",") : [],
      selectedSalon: localStorage.getItem("salon_name"),
    };
    this.getLayout = this.getLayout.bind(this);
    this.setSelectedSalon = this.setSelectedSalon.bind(this);
    this.setSalons = this.setSalons.bind(this);
  }

  setSelectedSalon(selectedSalon) {
    localStorage.setItem("salon_name", selectedSalon); // lot of code uses it, set for sure
    this.setState({ selectedSalon: selectedSalon });
  }

  setSalons(salons) {
    localStorage.setItem("user_salons", salons); // lot of code uses it, set for sure
    this.setState({ salons: salons });
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();
    const salons = this.state.salons;
    const selectedSalon = this.state.selectedSalon;
    const setSalons = this.setSalons;
    const setSelectedSalon = this.setSelectedSalon;

    return (
      <React.Fragment>
        <UserSalonsContext.Provider
          value={{ salons, setSalons, selectedSalon, setSelectedSalon }}
        >
          <Router>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                />
              ))}
            </Switch>
          </Router>
        </UserSalonsContext.Provider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
