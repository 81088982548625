import { Link } from "react-router-dom";
import React, { Component } from "react";

export default class SuperAdminMenu extends Component {
  render() {
    return (
      <>
        <li>
          <Link to="/admin/dashboard" className="waves-effect">
            <i className="ri-dashboard-line" />
            <span className="ml-1">{this.props.t("Panel Główny")}</span>
          </Link>
        </li>
      </>
    );
  }
}
