export const deleteSalonService = async (salon_name, service_id) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/services/${service_id}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};
