export const resetUserPassword = async (data, token) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/reset-password/${token}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    },
  );
};

export const resetPassword = async (data) => {
  return await fetch(`${process.env.REACT_APP_HOST_API}/api/v1/my-password`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
    body: JSON.stringify(data),
  });
};

export const registerUser = async (data, salon_name) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/register`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`, // TODO check if can register normally
      },
      body: JSON.stringify(data),
    },
  );
};

export const registerNewOwner = async (data) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/register-owner`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    },
  );
};

export const registerCustomer = async (data, salon_name) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/register-customer`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    },
  );
};
