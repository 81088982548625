import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
} from "reactstrap";
import FullCalendar, { formatDate } from "@fullcalendar/react";

class Registry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentEvents: [],
      salonEmployes: [],
      selectedCalendarEmployee: "",
      selectedEmployeeVisits: [],
      salonServices: [],
      salonCustomers: [],
    };
  }

  render() {
    return (
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <CardBody>
                <h1>REJESTR</h1>
                <div>
                  <h3 color="primary">
                    Wydarzenia ({this.state.selectedEmployeeVisits.length})
                  </h3>
                  <ul className="list-group visit-list">
                    {this.state.currentEvents.map(renderSidebarEvent)}
                  </ul>
                </div>
              </CardBody>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function renderSidebarEvent(event) {
  return (
    <li key={event.id} className="list-group-item text-justify">
      <b>
        {formatDate(event.start, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
      </b>
      <i>
        {" "}
        - {event.title} <br /> {event.extendedProps.customer.first_name}{" "}
        {event.extendedProps.customer.last_name}
      </i>
    </li>
  );
}

export default Registry;
