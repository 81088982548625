import { Button } from "reactstrap";
import React, { Component } from "react";
// import the progress bar
import StepProgressBar from "react-step-progress";
// import the stylesheet
import "react-step-progress/dist/index.css";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import Header from "../Header/Header";
import "./ServiceWizard.scss";
import { Link } from "react-router-dom";
import HeaderService from "../HeaderService/HeaderService";

class OrderServiceSummary extends Component {
  render() {
    const tpayUrl =
      "https://secure.tpay.com/?id=31155&kwota=47&opis=Abonament%20Visitio&opis_sprzed=Visitio&wyn_email=kontakt%40visitio.pl&email=" +
      localStorage.getItem("username") +
      "&nazwisko=" +
      localStorage.getItem("first_name") +
      "%20" +
      localStorage.getItem("last_name") +
      "&md5sum=9f852e9a52d705e27007ef0be93267be";

    function onFormSubmit() {
      // handle the submit logic here
      // This function will be executed at the last step
      // when the submit button (next button in the previous steps) is pressed
    }

    return (
      <>
        <div className="page-content">
          <HeaderService />
          <StepProgressBar
            buttonWrapperClass="hide"
            previousBtnDisabled
            startingStep={3}
            onSubmit={onFormSubmit}
            steps={[
              {
                label: "Usługi",
                name: "step 1",
                // content: step1Content
              },
              {
                label: "Termin",
                name: "step 2",
                // content: step2Content,
                // validator: step2Validator
              },
              {
                label: "Logowanie/Rejestracja",
                name: "step 3",
                // content: step2Content,
                // validator: step2Validator
              },
              {
                label: "Podsumowanie",
                name: "step 4",
                // content: step3Content,
                // validator: step3Validator
              },
            ]}
          />
          <Container>
            <Row>
              <Col className="m-auto" lg={6}>
                <Card>
                  <CardBody>
                    <h3 className="text-center">Podsumowanie</h3>
                    <div>
                      <h6 className="mt-5">
                        Usługa: <b>Strzyżenie</b>{" "}
                      </h6>
                      <h6 className="">
                        Nazwa Salonu: <b>Kuźnia Stylu</b>
                      </h6>
                      {/* <h6 className="">Nazwa Salonu: <b>{localStorage.getItem('salon_name')}</b></h6> */}
                      <h6 className="">
                        Pracownik: <b>Anna Kowalska</b>
                      </h6>
                      <h6 className="">
                        Koszt: <b>45,00zł</b>
                      </h6>
                      <a href={tpayUrl}>
                        <Button className="float-right" color="success">
                          Potwierdź proces zakupu
                        </Button>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default OrderServiceSummary;
