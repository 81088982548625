export const postSalonService = async (salon_name, dataBody) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/services`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify(dataBody),
    },
  );
};
