import React, { Component } from "react";
import { Row, Col, Button, Container, Label, FormGroup } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link, withRouter } from "react-router-dom";
import * as getSalonsIndustries from "../../Api/Salons/Industries/getSalonsIndustries";
import * as postUsers from "../../Api/Users/postUsers";
import ToastNotifications from "../../components/ToastNotification/ToastNotification";

class RegisterOwner extends Component {
  constructor(props) {
    super(props);
    this.getSalonsIndustries = this.getSalonsIndustries.bind(this);
    this.handleRegisterUser = this.handleRegisterUser.bind(this);
    this.state = {
      salonIndustries: [],
      selectedSalonIndustry: 1,
    };
  }

  getSalonsIndustries() {
    getSalonsIndustries.getSalonsIndustries().then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((salon_industry) => {
            return {
              id: salon_industry.id,
              name: salon_industry.name,
            };
          });
          this.setState({
            salonIndustries: apiData,
          });
        }
      });
    });
  }

  handleRegisterUser() {
    const year = document.querySelector("#salon_created_at");
    const first_name = document.querySelector("#first_name");
    const last_name = document.querySelector("#last_name");
    const salon_locality = document.querySelector("#salon_locality");
    const salon_name = document.querySelector("#salon_name");
    const owner_password = document.querySelector("#owner_password");
    const owner_mail = document.querySelector("#owner_mail");
    const confirm_owner_password = document.querySelector(
      "#confirm_owner_password",
    );

    let data = {
      first_name: first_name.value,
      last_name: last_name.value,
      salon_locality: salon_locality.value,
      salon_label: salon_name.value,
      password:
        owner_password.value === confirm_owner_password.value
          ? owner_password.value
          : null,
      username: owner_mail.value,
      salon_created_at: year.value + "-01-01",
      salon_industry: parseInt(this.state.selectedSalonIndustry),
    };

    postUsers.registerNewOwner(data).then((response) => {
      if (response.status === 201) {
        ToastNotifications(
          "success",
          "Konto zostało pomyślnie utworzone. Wysłaliśmy maila z linkiem potwierdzającym założenie konta. Sprawdź swoją pocztę.",
          "Sukces",
          10000,
        );
        this.props.history.push("/login");
      } else if (response.status === 400) {
        return ToastNotifications(
          "error",
          "Niepoprawne dane lub nazwa jest zajęta!",
          "Błąd rejestracji.",
        );
      }
    });
  }

  handleSelectSalonIndustry = (selectedSalonIndustry) => {
    this.setState({
      selectedSalonIndustry: selectedSalonIndustry.target.value,
    });
  };

  componentDidMount() {
    this.getSalonsIndustries();
  }

  render() {
    const { salonIndustries } = this.state;

    let optionSalonIndustries = [
      ...salonIndustries.map((salonIndustriesData) => ({
        value: salonIndustriesData.id,
        label: salonIndustriesData.name,
      })),
    ];

    return (
      <>
        <Row style={{ margin: "0" }}>
          <Col sm={12} md={6} className="p-0">
            <div className="authentication-bg">
              <a className="logo ml-3 mt-2 authentication" href="/"></a>
              <div className="w-xxl-75 w-sm-90 w-75 text-content m-auto ">
                <h1 className="display-4 text-white font-weight-normal title">
                  Zarejestruj się do aplikacji jako właściciel
                </h1>
                <p className="text-white description">
                  Klienci oraz właściciele salonów mogą skorzystać z świetnych
                  funkcjonalności naszej aplikacji pzekonaj się teraz!
                </p>
                <div className="action">
                  <a href="#">
                    <i className="fa fa-play" aria-hidden="true" />
                  </a>
                  <span>Jak działa aplikacja?</span>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={6} className="p-0">
            <div className="login-form">
              <AvForm
                onValidSubmit={this.handleRegisterUser}
                className="form-horizontal"
              >
                <Container>
                  <Row>
                    <Col xl={6}>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-user-2-line auti-custom-input-icon" />
                        <Label htmlFor="first_name">Imię</Label>
                        <AvField
                          errorMessage="Wpisz imię"
                          name="first_name"
                          type="text"
                          className="form-control"
                          id="first_name"
                          validate={{ required: true }}
                          placeholder="Wprowadź nazwę"
                        />
                      </FormGroup>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className=" ri-find-replace-line auti-custom-input-icon" />
                        <Label htmlFor="salon_locality">Lokalizacja</Label>
                        <AvField
                          errorMessage="Wpisz lokalizację"
                          name="salon_locality"
                          type="text"
                          className="form-control"
                          id="salon_locality"
                          validate={{ required: true }}
                          placeholder="Wprowadź lokalizację"
                        />
                      </FormGroup>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-store-2-line auti-custom-input-icon" />
                        <Label htmlFor="salon_name">Nazwa Salonu</Label>
                        <AvField
                          errorMessage="Wpisz nazwę salonu"
                          name="salon_name"
                          type="text"
                          className="form-control"
                          id="salon_name"
                          validate={{ required: true }}
                          placeholder="Wprowadź nazwę salonu"
                        />
                      </FormGroup>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-lock-2-line auti-custom-input-icon" />
                        <Label htmlFor="owner_password">Hasło</Label>
                        <AvField
                          errorMessage="Wpisz silne hasło"
                          name="owner_password"
                          type="password"
                          className="form-control"
                          id="owner_password"
                          validate={{ required: true }}
                          placeholder="Wprowadź hasło"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={6}>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-file-user-fill auti-custom-input-icon" />
                        <Label htmlFor="last_name">Nazwisko</Label>
                        <AvField
                          errorMessage="Wpisz nazwisko"
                          name="last_name"
                          type="text"
                          className="form-control"
                          id="last_name"
                          validate={{ required: true }}
                          placeholder="Wprowadź nazwisko"
                        />
                      </FormGroup>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-mail-line auti-custom-input-icon" />
                        <Label htmlFor="owner_mail">Adres Email</Label>
                        <AvField
                          errorMessage="Niepoprawny adres e-mail"
                          name="owner_mail"
                          type="text"
                          className="form-control"
                          id="owner_mail"
                          validate={{ email: true, required: true }}
                          placeholder="Wprowadź adres e-mail"
                        />
                      </FormGroup>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-calendar-line auti-custom-input-icon" />
                        <Label htmlFor="salon_created_at">
                          Rok założenia salonu
                        </Label>
                        <AvField
                          name="salon_created_at"
                          type="text"
                          className="form-control"
                          id="salon_created_at"
                          min="1940"
                          max="2100"
                          maxlength="4"
                        />
                      </FormGroup>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-lock-password-line auti-custom-input-icon" />
                        <Label htmlFor="confirm_owner_password">
                          Potwierdź hasło
                        </Label>
                        <AvField
                          name="confirm_owner_password"
                          type="password"
                          className="form-control"
                          id="confirm_owner_password"
                          validate={{
                            required: {
                              value: true,
                              error: "test",
                              errorMessage: "Hasła nie są takie same",
                            },
                            match: {
                              value: "owner_password",
                              errorMessage: "Hasła nie są takie same",
                            },
                          }}
                          placeholder="Powtórz hasło"
                        />
                      </FormGroup>
                      <FormGroup className="auth-form-group-custom mb-4">
                        <i className="ri-briefcase-line auti-custom-input-icon" />
                        <Label htmlFor="salon_industry">Branża salonu</Label>
                        <AvField
                          type="select"
                          id="salon_industry"
                          name="salon_industry"
                          onChange={this.handleSelectSalonIndustry}
                        >
                          {optionSalonIndustries.map((salon_industry) => (
                            <option
                              key={salon_industry.value}
                              value={salon_industry.value}
                            >
                              {salon_industry.label}
                            </option>
                          ))}
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>

                <div className="text-center">
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light fluid login-btn"
                    type="submit"
                  >
                    Zarejestruj się
                  </Button>
                </div>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z{" "}
                    <Link to="#" className="text-primary">
                      Regulaminem
                    </Link>
                  </p>
                </div>
              </AvForm>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(RegisterOwner);
