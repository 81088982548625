import React from "react";
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";
import {
  Label,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Alert,
} from "reactstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel/TabPanel";
import Paper from "@material-ui/core/Paper";
import pl from "date-fns/locale/pl";
import * as getUsers from "../../../Api/Users/getUsers";
import * as getServices from "../../../Api/Services/getServices";
import * as getCustomers from "../../../Api/Customers/getCustomers";
import * as getVisits from "../../../Api/Visits/getVisits";
import * as patchVisits from "../../../Api/Visits/patchVisits";
import moment from "moment";
import {
  calculateNettoPriceOfService,
  calculateVatPriceOfService,
} from "../../Visits/VisitsEdit";
import addMsg from "sweetalert2";
import ToastNotifications from "../../../components/ToastNotification/ToastNotification";

export const defaultState = {
  btnColor: "primary",
  lazyLoad: true,

  salon_name: undefined,
  start: undefined,
  note: undefined,
  description: "",
  selectedTabIndex: 0,
  payment: "cash",
  visit: {
    id: undefined,
    services: [],
  },
  customer: {
    id: undefined,
    first_name: undefined,
    last_name: undefined,
    created_at: undefined,
  },
  salonServices: [],
  services: [],
  servicesToAdd: [],

  employees: [],
  employee: {
    id: undefined,
    enabled: undefined,
    first_name: undefined,
    last_name: undefined,
    roles: [],
    username: undefined,
  },

  summary: {
    netto: 0,
    vat: 0,
    gross: 0,
  },
};

// Temporary workaround
export const scaffoldVisit = (salonEmployees, visit, salonServices) => {
  const usesPrices = Object.keys(visit).indexOf("prices") >= 0;
  //const presentServiceIds = visit.services.map(s => s.id);

  const presentServiceIds = visit.services.map((s) => {
    if (typeof s === "object" && s !== null) {
      return s.id;
    }
    return s;
  });

  let services = [];

  if (usesPrices) {
    JSON.parse(visit.prices)
      .map((vp) => vp.serviceId)
      .forEach((id) => presentServiceIds.push(id));
    services = salonServices
      .filter((salonService) => presentServiceIds.indexOf(salonService.id) >= 0)
      .map((salonService) => {
        let overrides = [];

        if (usesPrices) {
          overrides = JSON.parse(visit.prices);
        } else {
          overrides = [
            {
              serviceId: salonService.id,
              price: salonService.price,
              employeeId: visit.user.id,
            },
          ];
        }

        const matchedOverride = overrides.find(
          (override) => override.serviceId == salonService.id,
        );
        if (matchedOverride) {
          salonService.price = matchedOverride.price;
          salonService.employee = salonEmployees.find(
            (se) => se.id === matchedOverride.employeeId,
          );
        }

        salonService.netto = calculateNettoPriceOfService(salonService);
        salonService.vat = calculateVatPriceOfService(salonService);

        return salonService;
      });
  } else {
    services = salonServices
      .filter((salonService) => presentServiceIds.indexOf(salonService.id) >= 0)
      .map((salonService) => {
        salonService.netto = calculateNettoPriceOfService(salonService);
        salonService.vat = calculateVatPriceOfService(salonService);
        salonService.employee = salonEmployees.find(
          (se) => se.id === visit.user.id,
        );

        return salonService;
      });
  }

  return {
    ...visit,
    ...{
      services: services,
    },
  };
};

export default class FinalizeForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.onCreateNewServiceClick = this.onCreateNewServiceClick.bind(this);
    this.onServicePriceChange = this.onServicePriceChange.bind(this);
    this.state = { ...defaultState, ...props };
  }

  componentDidMount() {
    registerLocale("pl", pl);

    if (!this.state.lazyLoad) {
      Promise.all([
        getUsers
          .getSalonEmployes(this.state.salon_name)
          .then((response) => response.json()),
        getCustomers
          .getSalonCustomer(this.state.salon_name, this.state.customer.id)
          .then((response) => response.json()),
        getVisits
          .getVisitById(this.state.salon_name, this.state.visit.id)
          .then((response) => response.json()),
        getServices
          .getSalonServices(this.state.salon_name)
          .then((response) => response.json()),
      ]).then(async (results) => {
        const [salonEmployees, customer, visit, salonServices] = results;

        const scaffoldedVisit = scaffoldVisit(
          salonEmployees,
          visit,
          salonServices,
        );

        const summary = {
          netto: scaffoldedVisit.services
            .map((s) => calculateNettoPriceOfService(s))
            .reduce((a, b) => a + b, 0),
          vat: scaffoldedVisit.services
            .map((s) => calculateVatPriceOfService(s))
            .reduce((a, b) => a + b, 0),
          gross: scaffoldedVisit.services
            .map((s) => s.price)
            .reduce((a, b) => a + b, 0),
        };

        this.setState({
          salonServices: salonServices,
          employees: salonEmployees,
          customer: customer,
          visit: scaffoldedVisit,
          summary: summary,
        });
      });
    }
  }

  handleValidSubmit(event, values) {
    const { handleAfterValidSubmit } = this.props;

    const servicesToAdd = (
      values.servicesToAdd ? values.servicesToAdd : []
    ).map((sta) => ({
      serviceId: +sta.id,
      price: +sta.price,
      employeeId: +sta.employee.id,
    }));

    values.visit.services
      .map((service) => ({
        serviceId: +service.id,
        price: +service.price,
        employeeId: +service.employee.id,
      }))
      .forEach((s) => servicesToAdd.push(s));

    const body = {
      prices: JSON.stringify(servicesToAdd),
      description: values.description,
      note: values.note,
      //service: this.state.service.id,
      customer: this.state.customer.id,
      user: +this.state.visit.customer.id,
      appointment_at: moment(this.state.start).format("YYYY-MM-DD HH:mm:ss"),
      is_finalized: true,
      is_missed: false,
      payment: values.visit.payment,
    };

    patchVisits
      .updateVisit(this.state.salon_name, this.state.visit.id, body)
      .then((response) => {
        this.setState({
          btnColor: response.status === 200 ? "success" : "danger",
        });
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (handleAfterValidSubmit) {
          handleAfterValidSubmit(data);
          ToastNotifications(
            "success",
            "Zapisałeś zmiany w wizycie.",
            "Sukces!",
          );
        } else {
          addMsg
            .fire("Sukces!", "Zapisałeś zmiany w wizycie.", "success")
            .then((r) => {
              window.location.reload(); // TODO use history package
            });
        }
      });
  }

  handleInvalidSubmit(event, errors, values) {
    this.setState({ errors, values });
  }

  onCreateNewServiceClick(e) {
    const newService = {
      employee: {
        id: undefined,
      },
      price: undefined,
      id: undefined,
    };

    const newServicesToAdd = this.state.servicesToAdd;
    newServicesToAdd.push(newService);

    this.setState((prev) => ({
      servicesToAdd: newServicesToAdd,
    }));
  }

  getTotalPrice(scaffoldedVisit) {
    return scaffoldedVisit.services
      .map((s) => s.price)
      .reduce((a, b) => a + b, 0);
  }

  getVatTotalPrice(scaffoldedVisit) {
    return scaffoldedVisit.services
      .map((s) => calculateVatPriceOfService(s))
      .reduce((a, b) => a + b, 0);
  }

  getNettoTotalPrice(scaffoldedVisit) {
    return scaffoldedVisit.services
      .map((s) => calculateNettoPriceOfService(s))
      .reduce((a, b) => a + b, 0);
  }

  // onServicePriceChange(e, service) {
  //   const existingServicesList = document.querySelectorAll(
  //     'input[type="number"], input[name^="visit.services"], .price',
  //   );
  //   const servicesToAddList = document.querySelectorAll(
  //     'input[type="number"], input[name^="servicesToAdd"], .price',
  //   );

  //   const calculateNetto = Math.round((e.target.value / 1.23) * 100) / 100;

  //   const summary = {
  //     netto: calculateNetto,
  //     vat: 23,
  //     gross: e.target.value,
  //   };

  //   this.setState({
  //     summary: summary,
  //   });
  // }

  onServicePriceChange(e, service) {
    const existingServicesList = document.querySelectorAll(
      'input[type="number"], input[name^="visit.services"], .price',
    );
    const servicesToAddList = document.querySelectorAll(
      'input[type="number"], input[name^="servicesToAdd"], .price',
    );

    let grossPrice = e.target.value;

    if (grossPrice.trim()) {
      grossPrice = parseFloat(grossPrice);
    } else {
      grossPrice = this.state.summary.gross;
    }

    const calculateNetto = Math.round((grossPrice / 1.23) * 100) / 100;

    const summary = {
      netto: calculateNetto,
      vat: 23,
      gross: grossPrice,
    };

    this.setState({
      summary: summary,
    });
  }

  render() {
    return (
      <AvForm
        className="form-horizontal"
        onValidSubmit={this.handleValidSubmit}
        model={this.state}
      >
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12" sm="8" md="4" lg="3">
                      <AvGroup>
                        <Label for="start">Data wizyty</Label>
                        <DatePicker
                          selected={this.state.start}
                          onChange={(date) => {
                            this.setState({ start: date });
                          }}
                          name="start"
                          id={"start"}
                          showTimeSelect
                          minTime={new Date().setHours(5)}
                          maxTime={new Date().setHours(18)}
                          locale="pl"
                          timeFormat="p"
                          timeIntervals={5}
                          dateFormat="Pp"
                          className="form-visit-datapicker"
                        />
                        <AvFeedback>This is an error!</AvFeedback>
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField name="description" label="Opis" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <AvField name="note" label="Notatka" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <Paper>
                        <Tabs
                          value={this.state.selectedTabIndex}
                          onChange={(event, newTabIndex) => {
                            this.setState({ selectedTabIndex: newTabIndex });
                          }}
                          indicatorColor="primary"
                          textColor="primary"
                        >
                          <Tab label="Ogólne" />
                        </Tabs>
                        <TabPanel value={this.state.selectedTabIndex} index={0}>
                          <ul className="message-list">
                            {this.state.visit.services.map((service, ix) => {
                              return (
                                <li className="d-flex" key={ix}>
                                  <div className={"d-none"}>
                                    <AvField
                                      type="number"
                                      name={`visit.services.${ix}.id`}
                                      value={service.id}
                                    />
                                  </div>
                                  <span>{service.title}</span>
                                  <span className="ml-auto">
                                    <AvGroup className="d-flex">
                                      <Label
                                        for={`visit.services.${ix}.price`}
                                        className="mr-2"
                                      >
                                        Cena (zł)
                                      </Label>
                                      <AvField
                                        onChange={(e) =>
                                          this.onServicePriceChange(e, service)
                                        }
                                        type="number"
                                        style={{ width: 85 }}
                                        className={"d-inline-block price"}
                                        name={`visit.services.${ix}.price`}
                                        id={`visit.services.${ix}.price`}
                                      />
                                    </AvGroup>
                                  </span>
                                  <span className="ml-3">
                                    <AvGroup className="d-flex">
                                      <Label
                                        for={`visit.services.${ix}.employee.id`}
                                        className="mr-2"
                                      >
                                        Pracownik
                                      </Label>
                                      <AvField
                                        type="select"
                                        className={"d-inline-block"}
                                        name={`visit.services.${ix}.employee.id`}
                                        id={`visit.services.${ix}.employee.id`}
                                      >
                                        {this.state.employees.map(
                                          (employee) => (
                                            <option
                                              key={employee.id}
                                              value={employee.id}
                                            >
                                              {employee.first_name}{" "}
                                              {employee.last_name}
                                            </option>
                                          ),
                                        )}
                                      </AvField>
                                    </AvGroup>
                                  </span>
                                </li>
                              );
                            })}

                            {this.state.servicesToAdd.map(
                              (serviceToAdd, ix) => (
                                <li
                                  className="d-flex"
                                  key={this.state.visit.services.length + ix}
                                >
                                  <span>
                                    <AvGroup className="d-flex">
                                      <AvField
                                        type="select"
                                        className={"d-inline-block"}
                                        name={`servicesToAdd.${ix}.id`}
                                        id={`servicesToAdd.${ix}.id`}
                                      >
                                        {this.state.salonServices.map(
                                          (service) => (
                                            <option
                                              key={service.id}
                                              value={service.id}
                                            >
                                              {service.title}
                                            </option>
                                          ),
                                        )}
                                      </AvField>
                                    </AvGroup>
                                  </span>
                                  <span className="ml-auto">
                                    <AvGroup className="d-flex">
                                      <Label
                                        for={`servicesToAdd.${ix}.price`}
                                        className="mr-2"
                                      >
                                        Cena (zł)
                                      </Label>
                                      <AvField
                                        type="number"
                                        onChange={(e) =>
                                          this.onServicePriceChange(
                                            e,
                                            serviceToAdd,
                                          )
                                        }
                                        style={{ width: 85 }}
                                        className={"d-inline-block price"}
                                        name={`servicesToAdd.${ix}.price`}
                                        id={`servicesToAdd.${ix}.price`}
                                      />
                                    </AvGroup>
                                  </span>
                                  <span className="ml-3">
                                    <AvGroup className="d-flex">
                                      <Label
                                        for={`servicesToAdd.${ix}.employee.id`}
                                        className="mr-2"
                                      >
                                        Pracownik
                                      </Label>
                                      <AvField
                                        type="select"
                                        className={"d-inline-block"}
                                        name={`servicesToAdd.${ix}.employee.id`}
                                        id={`servicesToAdd.${ix}.employee.id`}
                                      >
                                        {this.state.employees.map(
                                          (employee) => (
                                            <option
                                              key={employee.id}
                                              value={employee.id}
                                            >
                                              {employee.first_name}{" "}
                                              {employee.last_name}
                                            </option>
                                          ),
                                        )}
                                      </AvField>
                                    </AvGroup>
                                  </span>
                                </li>
                              ),
                            )}
                          </ul>
                          <Button
                            type="button"
                            onClick={this.onCreateNewServiceClick}
                            color="primary"
                            className="w-md waves-effect waves-light fluid"
                          >
                            Dodaj usługę
                          </Button>
                        </TabPanel>
                      </Paper>
                    </Col>
                  </Row>
                  <Row className={"mt-2"}>
                    <Col xs="12" sm="4" md="3" lg="2">
                      <AvGroup>
                        <Label for="price">Do zapłaty</Label>
                        <FormGroup className={"mt-2"}>
                          <b className={"d-flex flex-column"}>
                            <span>
                              <span className={"h5"}>
                                {`${this.state.summary.netto} `}
                              </span>
                              zł netto
                            </span>
                            <span>
                              <span className={"h5"}>
                                {`${this.state.summary.vat} `}
                              </span>
                              VAT
                            </span>
                            <span>
                              <span className={"h5"} id="price">
                                {`${this.state.summary.gross} `}
                              </span>
                              zł brutto
                            </span>
                          </b>
                        </FormGroup>
                      </AvGroup>
                    </Col>
                    <Col xs="12" sm="8" md="6" lg="4" className="pb-2">
                      <AvGroup>
                        <Label for="payment">Metoda płatności</Label>
                        <AvField
                          type="select"
                          name="visit.payment"
                          id="payment"
                        >
                          <option value="cash">Gotówka</option>
                          <option value="tpay">TPay</option>
                        </AvField>
                      </AvGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <div className="my-3">
          <Button
            color={this.state.btnColor}
            className="w-md waves-effect waves-light"
            type="submit"
          >
            Zapisz
          </Button>
        </div>
      </AvForm>
    );
  }
}
