import React, { Component } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { Link } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import * as styles from "./registered-salon-owners-payment-list.module.scss";

class RegisteredSalonOwnersPaymentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  render() {
    const data = {
      columns: [
        {
          dataField: "id",
          text: "#",
        },
        {
          dataField: "orderId",
          text: "Wizyta",
        },
        {
          dataField: "billingName",
          text: "Klient",
        },
        {
          dataField: "total",
          text: "Suma",
        },
        {
          dataField: "status",
          text: "Status",
        },
      ],
      rows: this.props.registered_salon_owners_payments.map((payment) => ({
        id: payment.id,
        orderId: (
          <Link to="#" className="text-dark font-weight-bold">
            {payment.id}
          </Link>
        ),
        date: payment.date,
        billingName: payment.billingName,
        total: payment.total + "zł",
        status: payment.paid ? (
          <div className="badge badge-soft-success font-size-12">Zapłacono</div>
        ) : (
          <div className="badge badge-soft-warning font-size-12">
            Niezapłacono
          </div>
        ),
      })),
    };

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: false,
      sizePerPageList: [
        {
          text: "5",
          value: 5,
        },
        {
          text: "10",
          value: 10,
        },
        {
          text: "20",
          value: 20,
        },
        {
          text: "All",
          value: data.rows.length,
        },
      ],
    };

    return (
      <React.Fragment>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">
                Historia wypłat właścicieli salonu
              </h4>
              <BootstrapTable
                classes={styles.table}
                keyField="id"
                data={data.rows}
                columns={data.columns}
                pagination={paginationFactory(options)}
              />
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default RegisteredSalonOwnersPaymentList;
