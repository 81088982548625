export const postOauthToken = async (dataBody) => {
  return fetch(process.env.REACT_APP_HOST_API + "/oauth/v2/token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(dataBody),
  });
};
