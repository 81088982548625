import React, { Component } from "react";
import { Card, CardBody, Col, Media } from "reactstrap";

class MiniWidgets extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        {this.props.reports && (
          <>
            <Col md={4}>
              <Card>
                <CardBody>
                  <Media>
                    <Media body className="overflow-hidden">
                      <p className="text-truncate font-size-14 mb-2">
                        Liczba salonów
                      </p>
                      <h4 className="mb-0">
                        {this.props.reports.salons_total_count}
                      </h4>
                    </Media>
                    <div className="text-primary">
                      <i className={"ri-stack-line font-size-24"} />
                    </div>
                  </Media>
                </CardBody>
              </Card>
            </Col>
          </>
        )}
      </>
    );
  }
}

export default MiniWidgets;
