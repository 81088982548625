export const getVisits = async (
  salon_name,
  page = 1,
  limit = 100,
  allData = 1,
) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/visits?page=${page}&limit=${limit}&all_data=${allData}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getVisitLimit = async (salon_name) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/visits?order=DESC&limit=5`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getVisitsByDate = async (salon_name, date_start, allData = 1) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/visits?date_start=${date_start}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

/**
 * @param salon_name
 * @param start
 * @param end
 * @returns {Promise<Response>}
 */
export const getVisitsBetweenDates = async (salon_name, start, end) => {
  let dateQueryParam = `date_start=${start}`;
  if (end) {
    dateQueryParam += `&date_end=${end}`;
  }

  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/visits?${dateQueryParam}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getVisitsByEmployee = async (salon_name, employeeId) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/visits?user=${employeeId}&all_data=1`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getVisitById = async (salon_name, id) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/visits/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    },
  );
};

export const getPublicVisitsByEmployee = async (
  salon_name,
  employeeId,
  date_start,
  date_end,
) => {
  return await fetch(
    `${process.env.REACT_APP_HOST_API}/api/v1/${salon_name}/visits-public/users/${employeeId}?date_start=${date_start}&date_end=${date_end}`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    },
  );
};
