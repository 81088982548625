import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import * as getStatistics from "../../../Api/Statistics/getStatistics";
import MiniWidgets from "./components/MiniWidgets";
import RegisteredSalonOwnersAnalytics from "./components/RegisteredSalonOwnersAnalytics";
import RegisteredSalonOwnersPaymentList from "./components/RegisteredSalonOwnersPaymentList";

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Visitio", link: "#" },
        { title: "Admin", link: "#" },
        { title: "Dashboard", link: "#" },
      ],
      report: [],
    };
    this.salon_name = localStorage.getItem("salon_name");
  }

  componentDidMount() {
    getStatistics
      .getReport(this.salon_name)
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          report: {
            salons_total_count: 92901,
            registered_salon_owners: {
              2021: [
                4123, 3456, 3789, 1231, 2343, 2300, 2010, 2670, 3020, 4050,
                3075, 2900,
              ],
              2020: [
                2123, 2456, 3789, 3231, 2343, 2300, 3010, 2670, 1020, 2050,
                1075, 3900,
              ],
              2019: [
                1123, 1456, 1789, 1231, 2343, 2300, 2010, 2670, 3020, 4050,
                3075, 2900,
              ],
            },
            registered_salon_owners_payments: [
              {
                id: 1220,
                date: "22/07/2021",
                billingName: "Jan Kowalski",
                total: 250,
                paid: true,
              },
              {
                id: 1219,
                date: "22/07/2021",
                billingName: "Jan Nowak",
                total: 200,
                paid: true,
              },
              {
                id: 1218,
                date: "22/07/2021",
                billingName: "Jan Nowak",
                total: 225,
                paid: false,
              },
              {
                id: 1217,
                date: "22/07/2021",
                billingName: "Marcin Banot",
                total: 250,
                paid: false,
              },
              {
                id: 1216,
                date: "22/07/2021",
                billingName: "Łukasz Zwyk",
                total: 250,
                paid: true,
              },
              {
                id: 1215,
                date: "21/07/2021",
                billingName: "Jan Kowalski",
                total: 250,
                paid: true,
              },
              {
                id: 1214,
                date: "21/07/2021",
                billingName: "Jan Nowak",
                total: 200,
                paid: true,
              },
              {
                id: 1213,
                date: "21/07/2021",
                billingName: "Jan Nowak",
                total: 225,
                paid: false,
              },
              {
                id: 1212,
                date: "21/07/2021",
                billingName: "Marcin Banot",
                total: 250,
                paid: false,
              },
              {
                id: 1211,
                date: "21/07/2021",
                billingName: "Łukasz Zwyk",
                total: 250,
                paid: true,
              },
            ],
          },
        });
      });
  }

  render() {
    return (
      <>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                <Row>
                  <MiniWidgets reports={this.state.report} />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                {this.state.report.registered_salon_owners && (
                  <RegisteredSalonOwnersAnalytics
                    registered_salon_owners={
                      this.state.report.registered_salon_owners
                    }
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                {this.state.report.registered_salon_owners && (
                  <RegisteredSalonOwnersPaymentList
                    registered_salon_owners_payments={
                      this.state.report.registered_salon_owners_payments
                    }
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default AdminDashboard;
