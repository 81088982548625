import React, { Component } from "react";
import { Row, Col, Card, Container, CardBody, Input, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { getWarehouseProductsInWorkspace } from "../../Api/Warehouses/Products/getWarehouseProducts";
import { getStockList } from "../../Api/Warehouses/Product-stock/getWarehouseProductsStock";
import { getOwnedWarehouses } from "../../Api/Warehouses/getWarehouses";
import { getSalon } from "../../Api/Salons/getSelectedSalon";
import './WarehouseStock.css'; // Importowanie stylów

class WarehouseStock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salon_name: localStorage.getItem("salon_name"),
            warehouse_name: localStorage.getItem("warehouse_name"),
            products: [],
            warehouses: [],
            stockData: {},
            searchQuery: "",
            filteredProducts: [],
        };
    }

    componentDidMount() {
        this.loadInitialData();
    }

    async loadInitialData() {
        await this.getCurrentSalonId();
        await this.fetchWarehouses();
        await this.fetchProducts();
        this.fetchStockData();
    }

    async getCurrentSalonId() {
        try {
            const response = await getSalon(this.state.salon_name);
            const data = await response.json();
            if (response.ok) {
                this.setState({ currentSalonId: data.id });
            }
        } catch (error) {
            console.error("Błąd pobierania id salonu:", error);
        }
    }

    async fetchWarehouses() {
        try {
            const response = await getOwnedWarehouses();
            const data = await response.json();
            if (response.ok) {
                this.setState({ 
                    warehouses: data.sort((a, b) => a.id - b.id),
                });
            }
        } catch (error) {
            console.error("Błąd pobierania magazynów:", error);
        }
    }

    async fetchProducts() {
        try {
            const response = await getWarehouseProductsInWorkspace();
            const data = await response.json();
            if (response.ok) {
                this.setState({ 
                    products: data,
                    filteredProducts: data,
                });
            }
        } catch (error) {
            console.error("Błąd pobierania produktów:", error);
        }
    }

    fetchStockData() {
        const { warehouses } = this.state;
        warehouses.forEach((warehouse) => {
            getStockList(warehouse.id).then((response) => {
                if (response.status === 200) {
                    response.json().then((data) => {
                        this.processStockData(warehouse.id, data);
                    });
                } else {
                    console.error(`Błąd podczas pobierania danych dla magazynu ${warehouse.id}`);
                }
            }).catch((error) => {
                console.error("Błąd podczas pobierania danych:", error);
            });
        });
    }

    processStockData(warehouseId, stockData) {
        const { stockData: existingStockData } = this.state;
        const updatedStockData = { ...existingStockData };

        stockData.forEach((item) => {
            if (!updatedStockData[item.product.id]) {
                updatedStockData[item.product.id] = {};
            }
            updatedStockData[item.product.id][warehouseId] = item.quantity;
        });

        this.setState({ stockData: updatedStockData });
    }

    handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        const { products } = this.state;
        
        const filteredProducts = products.filter(product => 
            product.name.toLowerCase().includes(query)
        );

        this.setState({ 
            searchQuery: query,
            filteredProducts: filteredProducts,
        });
    }

    renderTable() {
        const { filteredProducts, warehouses, stockData } = this.state;
        const scrollClass = warehouses.length > 6 ? 'scrollable-table' : '';

        return (
            <div className={`table-wrapper ${scrollClass}`}>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Produkt / Magazyn</th>
                            {warehouses.map((warehouse) => (
                                <th key={warehouse.id}>{warehouse.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredProducts.map((product) => (
                            <tr key={product.id}>
                                <td>{product.name}</td>
                                {warehouses.map((warehouse) => (
                                    <td key={warehouse.id}>
                                        {stockData[product.id] ? stockData[product.id][warehouse.id] || 0 : 0}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        return (
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                                    <div className="hk-pg-header align-items-top">
                                        <div>
                                            <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                                                Stany magazynowe
                                            </h2>
                                            <InputGroup className="mb-3">
                                                <Input 
                                                    type="text" 
                                                    placeholder="Wyszukaj produkt" 
                                                    onChange={this.handleSearch} 
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button color="primary">Szukaj</Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </div>
                                    </div>
                                </div>
                                <CardBody>
                                    {this.renderTable()}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default WarehouseStock;
