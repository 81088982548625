import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import Select from "react-select";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";

import { connect } from "react-redux";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logowhite from "../../assets/images/logo-light.png";

// SWEETALERT2
import Swal from "sweetalert2";

import * as postUsers from "../../Api/Users/postUsers";

class RegisterSalonOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      password: "",
      isOwnerForm: false,
      isCustomerForm: false,
      salon_name_regex: /^[A-Za-z0-9](?:[A-Za-z0-9\-_]{0,18}[A-Za-z0-9])?$/i,
      checkValid: false,
      salonIndustries: [],
    };
  }

  handleRegisterUser() {
    const year = document.querySelector("#salon_created_at");
    const first_name = document.querySelector("#first_name");
    const last_name = document.querySelector("#last_name");
    const salon_locality = document.querySelector("#salon_locality");
    const salon_name = document.querySelector("#salon_name");
    const ownerpassword = document.querySelector("#ownerpassword");
    const ownermail = document.querySelector("#ownermail");
    const confirm_ownerpassword = document.querySelector(
      "#confirm_ownerpassword",
    );
    const { checkValid, salon_name_regex } = this.state;

    let data = {
      first_name: first_name.value,
      last_name: last_name.value,
      salon_locality: salon_locality.value,
      salon_label: salon_name.value,
      password:
        ownerpassword.value === confirm_ownerpassword.value
          ? ownerpassword.value
          : null,
      username: ownermail.value,
      salon_created_at: year.value + "-01-01",
      salon_industry: parseInt(this.state.selectedSalonIndustry),
    };

    const registerAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });
    postUsers.registerNewOwner(data).then((response) => {
      if (response.status === 201) {
        registerAlert
          .fire({
            title: "Sukces",
            text: "Konto zostało pomyślnie utworzone. Wysłaliśmy maila z linkiem potwierdzającym założenie konta. Sprawdź swoją pocztę.",
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.replace(process.env.REACT_APP_HOST + "/login");
            }
          });
      } else if (response.status === 400) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Niepoprawne dane lub salon już istnieje!",
        });
      }
    });
  }

  componentDidMount() {
    document.body.classList.add("auth-body-bg");
  }

  render() {
    const { salonIndustries } = this.state;

    let optionSalonIndustries = [
      ...salonIndustries.map((salonIndustriesData) => ({
        value: salonIndustriesData.id,
        label: salonIndustriesData.name,
      })),
    ];

    return (
      <>
        <div className="p-2 mt-5">
          <div className="text-center">
            <Link to="/" className="logo">
              <img src={logodark} height="20" alt="logo" />
            </Link>
          </div>
          <AvForm
            onValidSubmit={this.handleRegisterUser}
            className="form-horizontal"
          >
            <Container>
              <div className="center">
                <Row className="justify-content-center">
                  <Col xl={6}>
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-user-2-line auti-custom-input-icon" />
                      <Label htmlFor="first_name">Imię</Label>
                      <AvField
                        name="first_name"
                        type="text"
                        className="form-control"
                        id="first_name"
                        validate={{ required: true }}
                        placeholder="Wprowadź nazwę"
                      />
                    </FormGroup>
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className=" ri-find-replace-line auti-custom-input-icon" />
                      <Label htmlFor="salon_locality">Lokalizacja</Label>
                      <AvField
                        name="salon_locality"
                        type="text"
                        className="form-control"
                        id="salon_locality"
                        validate={{ required: true }}
                        placeholder="Wprowadź lokalizację"
                      />
                    </FormGroup>
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-store-2-line auti-custom-input-icon" />
                      <Label htmlFor="salon_name">Nazwa Salonu</Label>
                      <AvField
                        name="salon_name"
                        type="text"
                        className="form-control"
                        id="salon_name"
                        validate={{ required: true }}
                        placeholder="Wprowadź nazwę salonu"
                      />
                    </FormGroup>
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-lock-2-line auti-custom-input-icon" />
                      <Label htmlFor="ownerpassword">Hasło</Label>
                      <AvField
                        name="ownerpassword"
                        type="password"
                        className="form-control"
                        id="ownerpassword"
                        validate={{ required: true }}
                        placeholder="Wprowadź hasło"
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={6}>
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-file-user-fill auti-custom-input-icon" />
                      <Label htmlFor="last_name">Nazwisko</Label>
                      <AvField
                        name="last_name"
                        type="text"
                        className="form-control"
                        id="last_name"
                        validate={{ required: true }}
                        placeholder="Wprowadź nazwisko"
                      />
                    </FormGroup>
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-mail-line auti-custom-input-icon" />
                      <Label htmlFor="ownermail">Adres Email</Label>
                      <AvField
                        name="ownermail"
                        type="text"
                        className="form-control"
                        id="ownermail"
                        validate={{
                          email: true,
                          required: true,
                        }}
                        placeholder="Wprowadź adres e-mail"
                      />
                    </FormGroup>
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-calendar-line auti-custom-input-icon" />
                      <Label htmlFor="salon_created_at">
                        Rok założenia salonu
                      </Label>
                      <AvField
                        name="salon_created_at"
                        type="text"
                        className="form-control"
                        id="salon_created_at"
                        min="1940"
                        max="2100"
                        maxLength="4"
                      />
                    </FormGroup>
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-lock-password-line auti-custom-input-icon" />
                      <Label htmlFor="confirm_ownerpassword">
                        Potwierdź hasło
                      </Label>
                      <AvField
                        name="confirm_ownerpassword"
                        type="password"
                        className="form-control"
                        id="confirm_ownerpassword"
                        validate={{ required: true }}
                        placeholder="Powtórz hasło"
                      />
                    </FormGroup>
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i className="ri-briefcase-line auti-custom-input-icon" />
                      <Label htmlFor="salon_industry">Branża salonu</Label>
                      <AvField
                        type="select"
                        id="salon_industry"
                        name="salon_industry"
                        onChange={this.handleSelectSalonIndustry}
                      >
                        {optionSalonIndustries.map((salon_industry) => (
                          <option
                            key={salon_industry.value}
                            value={salon_industry.value}
                          >
                            {salon_industry.label}
                          </option>
                        ))}
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="mt-4 text-center">
                  <Button
                    color="primary"
                    className="w-md waves-effect waves-light fluid login-btn"
                    type="submit"
                  >
                    {this.props.loading ? "Loading ..." : "Zarejestruj się"}
                  </Button>
                </div>
              </div>
            </Container>

            <div className="mt-4 text-center">
              <p className="mb-0">
                Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z{" "}
                <Link to="#" className="text-primary">
                  Regulaminem
                </Link>
              </p>
            </div>
          </AvForm>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(RegisterSalonOwner);
