import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Media,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import avatar3 from "../../../assets/images/calendar.png";
import "../rightbar.scss";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      firstName: "",
      lastName: "",
      appoitment: "",
      isVisible: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    localStorage.removeItem("isVisable");

    this.setState((prevState) => ({
      menu: !prevState.menu,
      isVisible: false,
    }));
  }

  componentDidMount() {
    this.forceUpdate();
    this.handleGetNotification();
    this.setState({
      isVisible: localStorage.getItem("isVisable"),
    });
  }

  componentDidUpdate() {
    let date = this.state.appointment;
    let data = localStorage.getItem("data");
    let localData = data ? moment(data).calendar() : "";

    if (date !== localData) {
      this.handleGetNotification();
    }
  }

  handleGetNotification = () => {
    let firstName = localStorage.getItem("imie");
    let lastName = localStorage.getItem("nazwisko");
    let data = localStorage.getItem("data");
    let appointment = data ? moment(data).calendar() : "";

    this.setState({
      firstName,
      lastName,
      appointment,
      isVisible: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
          title="Powiadomienia"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item noti-icon waves-effect"
            id="page-header-notifications-dropdown"
          >
            <i className="ri-notification-3-line" />
            {this.state.isVisible ? <span className="noti-dot" /> : null}
          </DropdownToggle>
          <DropdownMenu
            right
            className="dropdown-menu-lg p-0"
            aria-labelledby="page-header-notifications-dropdown"
          >
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {this.props.t("Powiadomienia")} </h6>
                </Col>
                <div className="col-auto">
                  <Link to="/rejestry" className="small">
                    {this.props.t("Pokaż wszystkie")}
                  </Link>
                </div>
              </Row>
            </div>

            {this.state.appointment ? (
              <SimpleBar style={{ maxHeight: "230px" }}>
                <Link to="#" className="text-reset notification-item">
                  <Media>
                    <img
                      src={avatar3}
                      className="mr-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <Media body>
                      <h6 className="mt-0 mb-1">{this.state.firstName}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {this.props.t("Zapisał/a się na wizytę")}
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline" />{" "}
                          {this.state.appointment}
                        </p>
                      </div>
                    </Media>
                  </Media>
                </Link>
              </SimpleBar>
            ) : (
              <div className="notification-empty-wrapper">
                <p>{this.props.t("Brak nowych powiadomień")}</p>
              </div>
            )}

            <div className="p-2 border-top">
              <Link
                to="/rejestry"
                className="btn btn-sm btn-link font-size-14 btn-block text-center"
              >
                <i className="mdi mdi-arrow-right-circle mr-1" />
                {this.props.t("Zobacz więcej")}
              </Link>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default withNamespaces()(NotificationDropdown);
