import { Link } from "react-router-dom";
import React, { Component } from "react";

export default class CustomerMenu extends Component {
  render() {
    return (
      <>
        <li>
          <Link to="#" className="waves-effect">
            <span className="ml-1">{this.props.t("Ustawienia")}</span>
          </Link>
        </li>
        <li>
          <Link to="rejestr" className=" waves-effect">
            <i className="ri-registry-line" />
            <span className="ml-1">{this.props.t("Rejestr")}</span>
          </Link>
        </li>
      </>
    );
  }
}
