import React, { Component } from "react";
import { MDBBtn, MDBDataTable } from "mdbreact";
import * as getVisits from "../../../Api/Visits/getVisits";
import * as getServices from "../../../Api/Services/getServices";
import { deleteVisit } from "../../../Api/Visits/deleteVisits";
import Swal from "sweetalert2";
import * as styles from "./visits-table.module.scss";
import { Link, Redirect } from "react-router-dom";
import translations from "../../../helpers/translations/pl";

const defaultState = {
  salon_name: localStorage.getItem("salon_name"),
  remoteData: [],
  services: [],
};

class VisitsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      ...props,
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  componentDidMount() {
    getVisits
      .getVisits(this.state.salon_name, 1, 100)
      .then((response) => response.json())
      .then((visits) => {
        getServices
          .getSalonServices(this.state.salon_name)
          .then((response) => response.json())
          .then((services) => {
            this.setState({
              services: services ? services : [],
              remoteData: visits ? visits.sort((a, b) => b.id - a.id) : [],
            });
          });
      });
  }

  prepareData() {
    return {
      columns: this.prepareColumnDefinitions(),
      rows: this.prepareRemoteItems(),
    };
  }

  prepareColumnDefinitions() {
    return [
      {
        label: "Opis",
        field: "description",
        sort: "asc",
      },
      {
        label: "Usługa",
        field: "title",
        sort: "asc",
      },
      {
        label: "Klient (imię)",
        field: "customer_first_name",
        sort: "asc",
      },
      {
        label: "Klient (nazwisko)",
        field: "customer_last_name",
        sort: "asc",
      },
      {
        label: "Cena brutto",
        field: "price",
        sort: "asc",
      },
      {
        label: "Sfinalizowana",
        field: "is_finalized",
        searchable: false,
      },
      {
        label: "Edytuj",
        field: "edit",
        searchable: false,
      },
      {
        label: "Usuń",
        field: "delete",
        searchable: false,
      },
    ];
  }

  prepareRemoteItems() {
    return this.state.remoteData.map(this.prepareRemoteItem);
  }

  /**
   * Maps a remote record to the one that matches a column definition
   *
   * @param remoteItem
   * @returns {{title: string}}
   */
  prepareRemoteItem = (remoteItem) => {
    const defaultFields = {
      edit: (
        <Link to={"/visits/" + remoteItem.id + "/edit"}>
          <MDBBtn position="center" color="info" size="sm">
            <i className="ri-edit-2-fill" />
          </MDBBtn>
        </Link>
      ),
      delete: (
        <MDBBtn
          onClick={(e) => this.onDeleteClick(e, remoteItem)}
          position="center"
          color="danger"
          size="sm"
        >
          <i className="ri-delete-bin-line" />
        </MDBBtn>
      ),
    };

    return {
      ...defaultFields,
      ...{
        description:
          remoteItem.description !== null ? remoteItem.description : "",
        title: remoteItem.services
          .map((serviceId) => {
            const service = this.state.services.find((s) => s.id === serviceId);
            if (service) {
              return service.title;
            }
          })
          .join(", "),
        price: remoteItem.services
          .map((serviceId) => {
            const service = this.state.services.find((s) => s.id === serviceId);
            if (service) {
              return service.price;
            }
            return 0;
          })
          .reduce((a, b) => a + b, 0)
          .toFixed(2),
        customer_first_name: remoteItem.customer
          ? remoteItem.customer.first_name
          : "",
        customer_last_name: remoteItem.customer
          ? remoteItem.customer.last_name
          : "",
        is_finalized: remoteItem.is_finalized ? (
          <i className={"ri-checkbox-fill"} />
        ) : (
          <i className={"ri-checkbox-blank-line"} />
        ),
      },
    };
  };

  onDeleteClick = (event, remoteItem) => {
    const deleteMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    deleteMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Zmiany będą nieodwracalne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.onDeleteConfirmation(remoteItem).then((success) => {
            deleteMsg.fire("Usunięto!", "Wizyta została usunięta.", "success");
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          deleteMsg.fire("Anulowano", "Wizyta zachowana", "error");
        }
      });
  };

  onDeleteConfirmation = (remoteItem) => {
    return deleteVisit(this.state.salon_name, remoteItem.id).then(
      (response) => {
        if (response.status === 200) {
          this.setState((prev) => ({
            remoteData: prev.remoteData.filter(
              (prd) => prd.id !== remoteItem.id,
            ),
          }));
        }
      },
    );
  };

  render() {
    return (
      <MDBDataTable
        id={"visits"}
        className={styles.visitsTable}
        responsive
        striped
        bordered
        data={this.prepareData()}
        {...translations.datatable}
      />
    );
  }
}

export default VisitsTable;
