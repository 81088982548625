import React, { Component } from "react";
import { MDBDataTable, MDBBtn } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import "./modal.scss";
import * as postProduct from "../../Api/Products/postProducts";
import * as getProduct from "../../Api/Products/getProducts";
import * as patchProduct from "../../Api/Products/patchProducts";
import * as deleteProduct from "../../Api/Products/deleteProduct";
import * as getTaxes from "../../Api/Taxes/getTaxes";
import * as getProductCategories from "../../Api/Products/Categories/getProductCategories";
import * as getProductTypes from "../../Api/Products/Types/getProductTypes";
import * as getConsumptionUnits from "../../Api/ConsumptionUnits/getConsumptionUnits";
import translations from "../../helpers/translations/pl";

class Storage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salon_name: localStorage.getItem("salon_name"),
      body: [],
      modal_editProduct: false,
      modal_addProduct: false,
      defaultInputValue: [],
      editedInputValue: [],
      // PRODUCT DATA
      products: [],
      tax_options: [],
      products_categories: [],
      consumption_units: [],
      products_types: [],
      priceRegex: /^\d+(\.\d{0,2}|,\d{0,2})?$/,
      // SELECT OPTIONS
      selectedTaxGroup: null,
      selectedProductTypesGroup: null,
      selectedConsumptionUnitsGroup: null,
      selectedCategoryGroup: null,
      optionTaxGroup: [],
      optionProductTypesGroup: [],
      optionConsumptionUnitsGroup: [],
      optionCategoryGroup: [],
      isActiveProducts: true,
      inactiveProducts: [],
    };

    this.togEditProduct = this.togEditProduct.bind(this);
    this.togAddProduct = this.togAddProduct.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.addProduct = this.addProduct.bind(this);
    // SELECT
    this.handleSelectTaxGroup = this.handleSelectTaxGroup.bind(this);
    this.handleSelectProductTypesGroup =
      this.handleSelectProductTypesGroup.bind(this);
    this.handleSelectConsumptionUnitsGroup =
      this.handleSelectConsumptionUnitsGroup.bind(this);
    this.handleSelectCategoryGroup = this.handleSelectCategoryGroup.bind(this);
  }

  //Select
  handleSelectTaxGroup = (selectedTaxGroup) => {
    this.setState({ selectedTaxGroup });
  };

  handleSelectProductTypesGroup = (selectedProductTypesGroup) => {
    this.setState({ selectedProductTypesGroup });
  };

  handleSelectConsumptionUnitsGroup = (selectedConsumptionUnitsGroup) => {
    this.setState({ selectedConsumptionUnitsGroup });
  };

  handleSelectCategoryGroup = (selectedCategoryGroup) => {
    this.setState({ selectedCategoryGroup });
  };

  togEditProduct() {
    this.setState((prevState) => ({
      modal_editProduct: !prevState.modal_editProduct,
    }));
    this.removeBodyCss();
    const { selectedCategoryGroup } = this.state;

    if (selectedCategoryGroup && selectedCategoryGroup.value !== null) {
      this.setState({
        editedInputValue: {
          label: selectedCategoryGroup.label,
          value: selectedCategoryGroup.value,
        },
      });
    } else {
      this.setState({ editedInputValue: { label: "brak", value: 0 } });
    }
  }

  togAddProduct() {
    this.setState((prevState) => ({
      modal_addProduct: !prevState.modal_addProduct,
    }));
    this.removeBodyCss();
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  addProduct() {
    let addProduct = [];
    const addProductFormFields = document.querySelectorAll(".addProductField");
    const {
      selectedTaxGroup,
      selectedProductTypesGroup,
      selectedConsumptionUnitsGroup,
      selectedCategoryGroup,
      optionTaxGroup,
      optionProductTypesGroup,
      optionConsumptionUnitsGroup,
    } = this.state;

    addProductFormFields.forEach((field) => {
      addProduct[field.name] = field.value;
    });

    selectedTaxGroup
      ? (addProduct["tax"] = selectedTaxGroup.value)
      : (addProduct["tax"] = optionTaxGroup[0].value);
    selectedProductTypesGroup
      ? (addProduct["product_type"] = selectedProductTypesGroup.value)
      : (addProduct["product_type"] = optionProductTypesGroup[0].value);
    selectedConsumptionUnitsGroup
      ? (addProduct["consumption_unit"] = selectedConsumptionUnitsGroup.value)
      : (addProduct["consumption_unit"] = optionConsumptionUnitsGroup[0].value);

    if (selectedCategoryGroup && selectedCategoryGroup.value !== 0) {
      addProduct["product_category"] = selectedCategoryGroup.value;
    }

    addProduct["buy_price"] = parseFloat(addProduct["buy_price"]);
    addProduct["sell_price"] = parseFloat(addProduct["sell_price"]);
    addProduct["min_quantity"] = parseInt(addProduct["min_quantity"]);
    addProduct["quantity"] = parseInt(addProduct["quantity"]);
    addProduct["unit_size"] = parseInt(addProduct["unit_size"]);

    const dataBody = Object.assign({}, addProduct);

    const addProductAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    addProductAlert
      .fire({
        title: "Sukces",
        text: "Pomyślnie dodano produkt.",
        icon: "success",
        confirmButtonText: "Ok",
      })
      .then((result) => {
        this.togAddProduct();
        if (result.isConfirmed) {
          postProduct
            .addProduct(this.state.salon_name, dataBody)
            .then((response) => {
              if (response.status === 201) {
                this.getProducts();
              } else if (response.status === 401) {
                this.errorMessage();
                return null;
              }
            });
          this.setState({ selectedCategoryGroup: null });
        }
      });
  }

  editProduct(serviceId) {
    const {
      selectedTaxGroup,
      selectedProductTypesGroup,
      selectedConsumptionUnitsGroup,
      selectedCategoryGroup,
      optionTaxGroup,
      optionProductTypesGroup,
      optionConsumptionUnitsGroup,
    } = this.state;

    let editProductValues = [];
    const editProductFormFields =
      document.querySelectorAll(".editProductField");
    editProductFormFields.forEach((field) => {
      editProductValues[field.name] = field.value;
    });

    selectedTaxGroup
      ? (editProductValues["tax"] = selectedTaxGroup.value)
      : (editProductValues["tax"] = optionTaxGroup[0].value);
    selectedProductTypesGroup
      ? (editProductValues["product_type"] = selectedProductTypesGroup.value)
      : (editProductValues["product_type"] = optionProductTypesGroup[0].value);
    selectedConsumptionUnitsGroup
      ? (editProductValues["consumption_unit"] =
          selectedConsumptionUnitsGroup.value)
      : (editProductValues["consumption_unit"] =
          optionConsumptionUnitsGroup[0].value);

    if (selectedCategoryGroup && selectedCategoryGroup.value !== 0) {
      editProductValues["product_category"] = selectedCategoryGroup.value;
    }

    editProductValues["buy_price"] = parseFloat(editProductValues["buy_price"]);
    editProductValues["sell_price"] = parseFloat(
      editProductValues["sell_price"],
    );
    editProductValues["min_quantity"] = parseInt(
      editProductValues["min_quantity"],
    );
    editProductValues["quantity"] = parseInt(editProductValues["quantity"]);
    editProductValues["unit_size"] = parseInt(editProductValues["unit_size"]);
    const dataBody = Object.assign({}, editProductValues);

    const editMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    editMsg
      .fire({
        title: "Sukces!",
        text: "Produkt został zmieniony.",
        icon: "success",
        confirmButtonText: "Ok",
      })
      .then((result) => {
        this.togEditProduct();
        if (result.isConfirmed) {
          patchProduct
            .updateProduct(this.state.salon_name, serviceId, dataBody)
            .then((response) => {
              if (response.status === 200) {
                this.getProducts();
              } else if (response.status === 401) {
                return null;
              }
            });
        }
      });
  }

  removeService(product_id) {
    const deleteMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    deleteMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Zmiany będą nieodwracalne!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteProduct
            .deleteProduct(this.state.salon_name, product_id)
            .then((response) => {
              if (response.status === 200) {
                this.getProducts();
                this.getInactiveProducts();
              } else if (response.status === 401) {
                return null;
              }
            });
          deleteMsg.fire("Usunięto!", "Usługa została usunięta.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          deleteMsg.fire("Anulowano", "Usługa zachowana", "error");
        }
      });
  }

  undoProduct(product_id) {
    const undoMsg = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    undoMsg
      .fire({
        title: "Jesteś pewien?",
        text: "Usługa zostanie przywrócona!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak",
        cancelButtonText: "Nie",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          patchProduct
            .undoProductDeletion(this.state.salon_name, product_id)
            .then((response) => {
              if (response.status === 200) {
                this.getProducts();
                this.getInactiveProducts();
              } else if (response.status === 401) {
                return null;
              }
            });
          undoMsg.fire(
            "Przywrócono!",
            "Produkt został przywrócony.",
            "success",
          );
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          undoMsg.fire("Anulowano", "Produkt nie zmieniony", "error");
        }
      });
  }

  getProducts() {
    getProduct.getSalonProducts(this.state.salon_name).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((product) => {
            return {
              id: product.id,
              name: product.name,
              quantity: product.quantity,
              consumption_unit: product.consumption_unit,
              tax: product.tax !== undefined ? product.tax : null,
              product_category:
                product.product_category !== undefined
                  ? product.product_category
                  : "brak",
              buy_price: product.buy_price,
              sell_price: product.sell_price,
              min_quantity: product.min_quantity,
              unit_size: product.unit_size,
              product_type: product.product_type,
              description:
                product.description !== undefined ? product.description : "",
              bar_code: product.bar_code !== undefined ? product.bar_code : "",
              internal_code:
                product.internal_code !== undefined
                  ? product.internal_code
                  : "",
              updated_at: product.updated_at,
            };
          });
          this.setState({
            products: apiData,
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  getInactiveProducts() {
    getProduct.getInactiveProducts(this.state.salon_name).then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((product) => {
            return {
              id: product.id,
              name: product.name,
              quantity: product.quantity,
              consumption_unit: product.consumption_unit,
              tax: product.tax !== undefined ? product.tax : null,
              product_category:
                product.product_category !== undefined
                  ? product.product_category
                  : null,
              buy_price: product.buy_price,
              sell_price: product.sell_price,
              min_quantity: product.min_quantity,
              unit_size: product.unit_size,
              product_type: product.product_type,
              description:
                product.description !== undefined ? product.description : "",
              bar_code: product.bar_code !== undefined ? product.bar_code : "",
              internal_code:
                product.internal_code !== undefined
                  ? product.internal_code
                  : "",
              updated_at: product.updated_at,
            };
          });
          this.setState({
            inactiveProducts: apiData,
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  findService(id) {
    const { products } = this.state;
    products.find((el) => el.id === id);
  }

  getTaxes() {
    getTaxes.getAllTaxes().then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = [];
          data.forEach((item) => {
            apiData.push({
              id: item.id,
              value: item.value,
            });
          });

          this.setState({ tax_options: apiData });
          this.setState({
            optionTaxGroup: [
              ...apiData.map((taxData) => ({
                value: taxData.id,
                label: taxData.value + "%",
              })),
            ],
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  getProductsCategories() {
    getProductCategories
      .getProductCategories(this.state.salon_name)
      .then((response) => {
        response.json().then((data) => {
          if (response.status === 200) {
            let apiData = [
              {
                id: 0,
                name: "brak",
              },
            ];
            data.forEach((item) => {
              apiData.push({
                id: item.id,
                name: item.name,
              });
            });

            this.setState({ products_categories: apiData });
            this.setState({
              optionCategoryGroup: [
                ...apiData.map((categoryData) => ({
                  value: categoryData.id,
                  label: categoryData.name,
                })),
              ],
            });
          } else if (response.status === 401) {
            return null;
          }
        });
      });
  }

  getConsumptionUnits() {
    getConsumptionUnits.getConsumptionUnits().then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = [];
          data.forEach((item) => {
            apiData.push({
              id: item.id,
              name: item.name,
            });
          });

          this.setState({ consumption_units: apiData });
          this.setState({
            optionConsumptionUnitsGroup: [
              ...apiData.map((consumptionUnitData) => ({
                value: consumptionUnitData.id,
                label: consumptionUnitData.name,
              })),
            ],
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  getProductsTypes() {
    getProductTypes.getProductsTypes().then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = [];
          data.forEach((item) => {
            apiData.push({
              id: item.id,
              name: item.name,
            });
          });

          this.setState({ products_types: apiData });
          this.setState({
            optionProductTypesGroup: [
              ...apiData.map((typeData) => ({
                value: typeData.id,
                label: typeData.name,
              })),
            ],
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  componentDidMount() {
    // localStorage.setItem('access_token', 'ZWE1YWQyOTEwZmFlYzA5ODM1MDhkMzUyZjhjOTNmNWY5YTVjMjVhODExYWY3MzM5YWFhODY3YjMxOTZkOThiOQ');
    // localStorage.setItem('salon_name','pbuklad');
    // this.state.salon_name = localStorage.getItem('salon_name');
    this.getProducts();
    this.getInactiveProducts();
    this.getTaxes();
    this.getProductsCategories();
    this.getConsumptionUnits();
    this.getProductsTypes();
  }

  render() {
    const { products, inactiveProducts, isActiveProducts } = this.state;
    let dataValue;
    if (isActiveProducts) {
      dataValue = {
        columns: [
          {
            label: "Nazwa",
            field: "name",
            sort: "asc",
            width: 150,
          },
          {
            label: "Kategoria Produktu",
            field: "product_category",
            sort: "asc",
            width: 270,
          },
          {
            label: "Typ Produktu",
            field: "product_type",
            sort: "asc",
            width: 200,
          },
          {
            label: "Cena Zakupu",
            field: "buy_price",
            sort: "asc",
            width: 100,
          },
          {
            label: "Cena Sprzedaży",
            field: "sell_price",
            sort: "asc",
            width: 150,
          },
          {
            label: "Minimalny Stan Magazynowy",
            field: "min_quantity",
            sort: "asc",
            width: 150,
          },
          {
            label: "Rozmiar Jednostkowy",
            field: "unit_size",
            sort: "asc",
            width: 150,
          },
          {
            label: "Stan Magazynowy",
            field: "quantity",
            sort: "asc",
            width: 150,
          },
          {
            label: "Wartość Sztuk W Magazynie",
            field: "consumption_unit",
            sort: "asc",
            width: 150,
          },
          {
            label: "Edytuj",
            sort: "disable",
            field: "edit",
            sortIconBefore: false,
          },
          {
            label: "Usuń",
            sort: false,
            field: "delete",
          },
        ],
        rows: [
          ...products.map((data) => ({
            name: data.name,
            quantity: data.quantity,
            consumption_unit: data.consumption_unit.name,
            buy_price: data.buy_price,
            sell_price: data.sell_price,
            min_quantity: data.min_quantity,
            unit_size: data.unit_size,
            product_type: data.product_type.name,
            product_category: data.product_category
              ? data.product_category.name
              : null,
            edit: (
              <MDBBtn
                onClick={() => {
                  this.togEditProduct();
                  this.setState({ defaultInputValue: data });
                }}
                position="center"
                color="info"
                size="sm"
              >
                <i className="ri-edit-2-fill" />
              </MDBBtn>
            ),
            delete: (
              <MDBBtn
                onClick={() => {
                  this.removeService(data.id);
                }}
                position="center"
                color="danger"
                size="sm"
              >
                <i className="ri-delete-bin-line" />
              </MDBBtn>
            ),
          })),
        ],
      };
    } else {
      dataValue = {
        columns: [
          {
            label: "Nazwa",
            field: "name",
            sort: "asc",
            width: 150,
          },
          {
            label: "Kategoria Produktu",
            field: "product_category",
            sort: "asc",
            width: 270,
          },
          {
            label: "Typ Produktu",
            field: "product_type",
            sort: "asc",
            width: 200,
          },
          {
            label: "Cena Zakupu",
            field: "buy_price",
            sort: "asc",
            width: 100,
          },
          {
            label: "Cena Sprzedaży",
            field: "sell_price",
            sort: "asc",
            width: 150,
          },
          {
            label: "Minimalny Stan Magazynowy",
            field: "min_quantity",
            sort: "asc",
            width: 150,
          },
          {
            label: "Rozmiar Jednostkowy",
            field: "unit_size",
            sort: "asc",
            width: 150,
          },
          {
            label: "Stan Magazynowy",
            field: "quantity",
            sort: "asc",
            width: 150,
          },
          {
            label: "Wartość Sztuk W Magazynie",
            field: "consumption_unit",
            sort: "asc",
            width: 150,
          },
          {
            label: "Przywróć",
            sort: false,
            field: "undo",
          },
        ],
        rows: [
          ...inactiveProducts.map((data) => ({
            name: data.name,
            quantity: data.quantity,
            consumption_unit: data.consumption_unit.name,
            buy_price: data.buy_price,
            sell_price: data.sell_price,
            min_quantity: data.min_quantity,
            unit_size: data.unit_size,
            product_type: data.product_type.name,
            product_category: data.product_category
              ? data.product_category.name
              : null,
            undo: (
              <MDBBtn
                onClick={() => {
                  this.undoProduct(data.id);
                }}
                position="center"
                color="danger"
                size="sm"
              >
                <i className="ri-arrow-go-back-line" />
              </MDBBtn>
            ),
          })),
        ],
      };
    }

    const {
      optionTaxGroup,
      optionCategoryGroup,
      optionProductTypesGroup,
      optionConsumptionUnitsGroup,
    } = this.state;

    const { defaultInputValue } = this.state;

    this.findService();
    return (
      <>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <div className="container-fluid ml-xl-20 mt-xl-50 mt-sm-30 mt-15">
                    {/* <!-- Title --> */}
                    <div className="hk-pg-header align-items-top">
                      <div>
                        <h2 className="hk-pg-title font-weight-600 mb-10 mt-4">
                          Produkty
                        </h2>
                        <div className="flex-buttons">
                          <Button
                            data-toggle="modal"
                            data-taget="#modalPostCustomer"
                            type="button"
                            onClick={this.togAddProduct}
                            color="primary"
                            className="waves-effect waves-light"
                          >
                            Dodaj produkt
                          </Button>
                          <Link to="/magazyn/kategorie">
                            <Button
                              type="button"
                              color="primary"
                              className="waves-effect waves-light  order-service"
                            >
                              Dodaj kategorie
                            </Button>
                          </Link>

                          <Button
                            data-toggle="modal"
                            type="button"
                            color="success"
                            className="waves-effect waves-light order-service"
                            onClick={() => {
                              this.setState({
                                isActiveProducts: !this.state.isActiveProducts,
                              });
                            }}
                          >
                            {this.state.isActiveProducts
                              ? "Nieaktywne produkty"
                              : "Aktywne produkty"}
                          </Button>

                          <Link to="/inwentaryzacja">
                            <Button
                              type="button"
                              color="primary"
                              className="waves-effect waves-light  order-service"
                            >
                              Inwentaryzacja
                            </Button>
                          </Link>

                          <Link to="/orders">
                            <Button
                              type="button"
                              color="primary"
                              className="waves-effect waves-light  order-service"
                            >
                              Zamów dostawę
                            </Button>
                          </Link>
                        </div>
                        {/* <a href="#" className="button" data-toggle="modal" data-target="#modalPostCustomer">Dodaj klienta</a> */}
                      </div>
                    </div>
                    {/* <!-- /Title --> */}
                    {/* <!-- /Container --> */}
                  </div>
                  <CardBody className="custom-data-table">
                    <MDBDataTable
                      responsive
                      striped
                      bordered
                      data={dataValue}
                      {...translations.datatable}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* ADD PRODUCT MODAL */}
        <Container>
          <Row>
            <Col sm={6} md={4} xl={6} className="mt-4">
              <Modal
                contentClassName="product__modal"
                isOpen={this.state.modal_addProduct}
                toggle={this.togAddProduct}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_addProduct: false })}
                >
                  Dodaj produkt
                </ModalHeader>
                <ModalBody>
                  <AvForm onValidSubmit={this.addProduct} id="addProductForm">
                    <Container>
                      <Row>
                        <Col lg={6}>
                          <AvField
                            id="addProductName"
                            className="addProductField"
                            name="name"
                            label="Nazwa"
                            placeholder="Wprowadź nazwę"
                            type="text"
                            errorMessage="Wprowadź nazwę"
                            validate={{ required: true }}
                          />
                          <AvField
                            id="addBuyPrice"
                            className="addProductField"
                            name="buy_price"
                            label="Cena zakupu"
                            type="number"
                            placeholder="Wprowadź cenę zakupu"
                            errorMessage="Wprowadź prawidłową cenę zakupu"
                            validate={{ required: true }}
                            step="0.01"
                            min="0.00"
                          />
                          <AvField
                            id="addSellPrice"
                            className="addProductField"
                            name="sell_price"
                            label="Cena sprzedaży"
                            type="number"
                            placeholder="Wprowadź cenę sprzedaży"
                            errorMessage="Wprowadź prawidłową cenę sprzedaży"
                            validate={{ required: true }}
                            step="0.01"
                            min="0.00"
                          />
                          <AvField
                            id="addQuantity"
                            className="addProductField"
                            name="quantity"
                            label="Stan magazynowy"
                            type="number"
                            errorMessage="Wprowadź liczbę"
                            placeholder="ilość produktu w magazynie"
                            validate={{ required: true }}
                            step="1"
                            min="0"
                          />
                          <AvField
                            id="addMinQuantity"
                            className="addProductField"
                            name="min_quantity"
                            label="Minimalny stan magazynowy"
                            type="number"
                            errorMessage="Wprowadź liczbę"
                            placeholder="minimalna ilość produktu w magazynie"
                            validate={{ required: true }}
                            step="1"
                            min="0"
                          />
                        </Col>
                        <Col lg={6}>
                          <FormGroup className="select2-container">
                            <Label>Podatek</Label>
                            <Select
                              defaultValue={optionTaxGroup[0]}
                              onChange={this.handleSelectTaxGroup}
                              options={optionTaxGroup}
                              classNamePrefix="select2-selection"
                              isSearchable={false}
                              validate={{ required: true }}
                            />
                          </FormGroup>
                          <FormGroup className="select3-container">
                            <Label>Typ produktu</Label>
                            <Select
                              defaultValue={optionProductTypesGroup[0]}
                              onChange={this.handleSelectProductTypesGroup}
                              options={optionProductTypesGroup}
                              classNamePrefix="select3-selection"
                              isSearchable={false}
                              validate={{ required: true }}
                            />
                          </FormGroup>
                          <FormGroup className="select4-container">
                            <Label>Wartość sztuk w magazynie</Label>
                            <Select
                              defaultValue={optionConsumptionUnitsGroup[0]}
                              onChange={this.handleSelectConsumptionUnitsGroup}
                              options={optionConsumptionUnitsGroup}
                              classNamePrefix="select4-selection"
                              isSearchable={false}
                              validate={{ required: true }}
                            />
                          </FormGroup>
                          <FormGroup className="select5-container">
                            <Label>Kategoria produktu</Label>
                            <Select
                              defaultValue={optionCategoryGroup[0]}
                              onChange={this.handleSelectCategoryGroup}
                              options={optionCategoryGroup}
                              classNamePrefix="select5-selection"
                              isSearchable={false}
                            />
                          </FormGroup>
                          <AvField
                            id="addUnitSize"
                            className="addProductField"
                            name="unit_size"
                            label="Rozmiar jednostkowy"
                            type="number"
                            errorMessage="Wprowadź liczbę"
                            placeholder="rozmiar jednostkowy produktu w magazynie"
                            validate={{ required: true }}
                            step="1"
                            min="0"
                          />
                        </Col>
                        <Col lg={12}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button
                                id="add_new_product"
                                type="submit"
                                color="primary"
                                className="mr-1"
                              >
                                Dodaj
                              </Button>{" "}
                              <Button
                                onClick={this.togAddProduct}
                                type="reset"
                                color="secondary"
                              >
                                Anuluj
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>

        {/* EDIT PRODUCT MODAL */}
        <Container>
          <Row>
            <Col sm={6} md={4} xl={6} className="mt-4">
              <Modal
                contentClassName="product__modal"
                isOpen={this.state.modal_editProduct}
                toggle={this.togEditProduct}
              >
                <ModalHeader
                  toggle={() => this.setState({ modal_editProduct: false })}
                >
                  Edytuj produkt
                </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={() => {
                      this.editProduct(defaultInputValue.id);
                    }}
                    id="editProductForm"
                  >
                    <Container>
                      <Row>
                        <Col lg={6}>
                          <AvField
                            id="editProductName"
                            className="editProductField"
                            name="name"
                            label="Nazwa"
                            placeholder="Wprowadź nazwę"
                            type="text"
                            errorMessage="Wprowadź nazwę"
                            validate={{ required: true }}
                            value={defaultInputValue.name}
                          />
                          <AvField
                            id="editBuyPrice"
                            className="editProductField"
                            name="buy_price"
                            label="Cena zakupu"
                            type="number"
                            placeholder="Wprowadź cenę zakupu"
                            errorMessage="Wprowadź prawidłową cenę zakupu"
                            validate={{ required: true }}
                            step="0.01"
                            min="0.00"
                            value={defaultInputValue.buy_price}
                          />
                          <AvField
                            id="editSellPrice"
                            className="editProductField"
                            name="sell_price"
                            label="Cena sprzedaży"
                            type="number"
                            placeholder="Wprowadź cenę sprzedaży"
                            errorMessage="Wprowadź prawidłową cenę sprzedaży"
                            validate={{ required: true }}
                            step="0.01"
                            min="0.00"
                            value={defaultInputValue.sell_price}
                          />
                          <AvField
                            id="editQuantity"
                            className="editProductField"
                            name="quantity"
                            label="Stan magazynowy"
                            type="number"
                            errorMessage="Wprowadź liczbę"
                            placeholder="ilość produktu w magazynie"
                            validate={{ required: true }}
                            step="1"
                            min="0"
                            value={defaultInputValue.quantity}
                          />
                          <AvField
                            id="editMinQuantity"
                            className="editProductField"
                            name="min_quantity"
                            label="Minimalny stan magazynowy"
                            type="number"
                            errorMessage="Wprowadź liczbę"
                            placeholder="minimalna ilość produktu w magazynie"
                            validate={{ required: true }}
                            step="1"
                            min="0"
                            value={defaultInputValue.min_quantity}
                          />
                        </Col>
                        <Col lg={6}>
                          <FormGroup className="select2-container">
                            <Label>Podatek</Label>
                            <Select
                              defaultValue={{
                                label:
                                  defaultInputValue.tax !== undefined
                                    ? defaultInputValue.tax.value + "%"
                                    : null,
                                value:
                                  defaultInputValue.tax !== undefined
                                    ? defaultInputValue.tax.id
                                    : null,
                              }}
                              onChange={this.handleSelectTaxGroup}
                              options={optionTaxGroup}
                              classNamePrefix="select2-selection"
                              isSearchable={false}
                              validate={{ required: true }}
                            />
                          </FormGroup>
                          <FormGroup className="select3-container">
                            <Label>Typ produktu</Label>
                            <Select
                              defaultValue={{
                                label:
                                  defaultInputValue.product_type !== undefined
                                    ? defaultInputValue.product_type.name
                                    : null,
                                value:
                                  defaultInputValue.tax !== undefined
                                    ? defaultInputValue.product_type.id
                                    : null,
                              }}
                              onChange={this.handleSelectProductTypesGroup}
                              options={optionProductTypesGroup}
                              classNamePrefix="select3-selection"
                              isSearchable={false}
                              validate={{ required: true }}
                            />
                          </FormGroup>
                          <FormGroup className="select4-container">
                            <Label>Wartość sztuk w magazynie</Label>
                            <Select
                              defaultValue={{
                                label:
                                  defaultInputValue.consumption_unit !==
                                  undefined
                                    ? defaultInputValue.consumption_unit.name
                                    : null,
                                value:
                                  defaultInputValue.consumption_unit !==
                                  undefined
                                    ? defaultInputValue.consumption_unit.id
                                    : null,
                              }}
                              onChange={this.handleSelectConsumptionUnitsGroup}
                              options={optionConsumptionUnitsGroup}
                              classNamePrefix="select4-selection"
                              isSearchable={false}
                              validate={{ required: true }}
                            />
                          </FormGroup>
                          <FormGroup className="select5-container">
                            <Label>Kategoria produktu</Label>
                            <Select
                              // defaultValue = {
                              //   this.state.editedInputValue
                              // }
                              defaultValue={{
                                label:
                                  defaultInputValue.product_category !==
                                  undefined
                                    ? defaultInputValue.product_category.name
                                    : this.state.editedInputValue,
                                value:
                                  defaultInputValue.product_category !==
                                  undefined
                                    ? defaultInputValue.product_category.id
                                    : this.state.editedInputValue,
                              }}
                              onChange={this.handleSelectCategoryGroup}
                              options={optionCategoryGroup}
                              classNamePrefix="select5-selection"
                              isSearchable={false}
                            />
                          </FormGroup>
                          <AvField
                            id="editUnitSize"
                            className="editProductField"
                            name="unit_size"
                            label="Rozmiar jednostkowy"
                            type="number"
                            errorMessage="Wprowadź liczbę"
                            placeholder="rozmiar jednostkowy produktu w magazynie"
                            validate={{ required: true }}
                            step="1"
                            min="0"
                            defaultValue={defaultInputValue.unit_size}
                          />
                        </Col>
                        <Col lg={12}>
                          <FormGroup className="mb-0">
                            <div>
                              <Button
                                id="edit_new_product"
                                type="submit"
                                color="primary"
                                className="mr-1"
                              >
                                Zapisz
                              </Button>{" "}
                              <Button
                                onClick={this.togEditProduct}
                                type="reset"
                                color="secondary"
                              >
                                Anuluj
                              </Button>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Container>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Storage;
