import React, { Component, componentDidMount } from "react";
import { MDBBtn } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  CardTitle,
  CardHeader,
  Media,
} from "reactstrap";

import HeaderService from "../../components/HeaderService/HeaderService";

import "./PublicService.scss";
//steper
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { PublicServiceSelection } from "../../components/PublicServices/PublicServiceSelection";
import { PublicServiceCart } from "../../components/PublicServices/PublicServiceCart";
import { ServiceOrderSumary } from "../../components/PublicServices/ServiceOrderSumary";
import PublicServiceLogin from "../../components/PublicServices/PublicServiceLogin";

//Api
import * as getServices from "../../Api/Services/getServices";
import * as getSalons from "../../Api/Salons/getSalons";
import * as getUsers from "../../Api/Users/getUsers";
import * as getService from "../../Api/Services/getServices";
import * as getCustomers from "../../Api/Customers/getCustomers";

const steps = localStorage.getItem("access_token")
  ? ["Usługi", "Termin", "Podsumowanie"]
  : ["Usługi", "Termin", "Rejestracja", "Podsumowanie"];

class PublicServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salon_name: "",
      body: [],
      salons: [],
      modal_standard: false,
      modal_addCustomer: false,
      defaultInputValue: [],
      editedInputValue: [],
      checkValid: false,
      // SERVICES DATA
      services: [],
      salonEmployees: [],
      tax_options: [],
      service_categories: [],
      activeStep: 0,
      selectedSalon: null,
      selectedService: null,
      selectedEmployee: null,
      selectedPeriod: null,
      customer_id: "",
    };

    // SELECT
    this.handleSelectSalon = this.handleSelectSalon.bind(this);
  }

  //Select
  handleSelectSalon = (selectedSalon) => {
    console.log(selectedSalon.value.name);
    getServices
      .getPublicSalonServices(selectedSalon.value.name)
      .then((response) => response.json())
      .then((publicSalons) => {
        this.setState({
          selectedSalon: selectedSalon,
          salon_name: selectedSalon.value.name,
          services: this.buildServicesData(publicSalons),
        });
        this.getSalonEmployees(selectedSalon.value.name);
      });
  };

  getSalonEmployees = (salon_name) => {
    getUsers
      .getSalonEmployesPublic(salon_name)
      .then((response) => response.json())
      .then((salonEmployeesData) => {
        this.setState({
          salonEmployees: salonEmployeesData,
          selectedEmployee:
            salonEmployeesData.length > 0
              ? {
                  value: salonEmployeesData[0],
                  label: `${salonEmployeesData[0].first_name} ${salonEmployeesData[0].last_name}`,
                }
              : "",
        });
      });
  };

  setLoggedCustomerId = (id) => {
    this.setState({ customer_id: id });
  };

  findService(id) {
    const { services } = this.state;
    services.find((el) => el.id === id);
  }

  componentDidMount() {
    getSalons
      .getAllSalons()
      .then((response) => response.json())
      .then((salonData) => {
        if (salonData.length > 0) {
          const accessToken = localStorage.getItem("access_token");
          if (accessToken) {
            const salonName = localStorage.getItem("salon_name");
            if (salonName) {
              getServices
                .getPublicSalonServices(salonName)
                .then((response) => response.json())
                .then((publicServices) => {
                  let salonsData = salonData.map((salon) => ({
                    id: salon.id,
                    name: salon.name,
                    locality: salon.locality,
                    created_at: salon.created_at,
                  }));

                  let correctSalon = salonData.filter(
                    (sd) => sd.name === salonName,
                  );

                  this.setState({
                    services: this.buildServicesData(publicServices),
                    salons: salonsData,
                    salon_name: salonName,
                    selectedSalon: {
                      value: correctSalon,
                      label: correctSalon.name,
                    },
                  });
                  this.getSalonEmployees(salonName);
                });
            }
          } else {
            getServices
              .getPublicSalonServices(salonData[0].name)
              .then((response) => response.json())
              .then((data) => {
                let salonsData = salonData.map((salon) => ({
                  id: salon.id,
                  name: salon.name,
                  locality: salon.locality,
                  created_at: salon.created_at,
                }));
                this.setState({
                  services: this.buildServicesData(data),
                  salons: salonsData,
                  salon_name: salonData[0].name,
                  selectedSalon: {
                    value: salonData[0],
                    label: salonData[0].name,
                  },
                });
                this.getSalonEmployees(salonData[0].name);
              });
          }
        }
      });
  }
  buildServicesData(data) {
    return data.map((service) => ({
      id: service.id,
      title: service.title,
      service_time_min: service.service_time_min,
      service_time_max:
        service.service_time_max !== undefined ? service.service_time_max : "",
      price: service.price !== undefined ? service.price : "",
      tax: service.tax !== undefined ? service.tax : null,
      service_category:
        service.service_category !== undefined
          ? service.service_category
          : null,
      description_public:
        service.description_public !== undefined
          ? service.description_public
          : "",
      description_private:
        service.description_private !== undefined
          ? service.description_private
          : "",
    }));
  }

  buildServiceTableData(servicesData) {
    return {
      columns: [
        {
          label: "Tytuł",
          field: "title",
          sort: "asc",
          width: 150,
        },
        {
          label: "Minimalny Czas Trwania Usługi (min)",
          field: "service_time_min",
          sort: "asc",
          width: 270,
        },
        {
          label: "Maksymalny Czas Trwania Usługi (min)",
          field: "service_time_max",
          sort: "asc",
          width: 200,
        },
        {
          label: "Cena (zł)",
          field: "price",
          sort: "asc",
          width: 100,
        },
        {
          label: "Opis",
          field: "description_public",
          sort: "asc",
          width: 150,
        },
        {
          label: "Zamów usługę",
          sort: "disable",
          field: "order_service",
          sortIconBefore: false,
        },
      ],
      rows: servicesData.map((data) => ({
        title: data.title,
        service_time_min: data.service_time_min,
        service_time_max:
          data.service_time_max !== undefined ? data.service_time_max : "",
        price: data.price !== undefined ? data.price : "",
        description_public:
          data.description_public !== undefined ? data.description_public : "",
        description_private:
          data.description_private !== undefined
            ? data.description_private
            : "",
        // order_service: <MDBBtn onClick={(e) => { this.setState({ selectedService: data, activeStep: 1 }) }} position="center" color="success" size="md"><i className="mdi mdi-cart-outline"/></MDBBtn>,
      })),
    };
  }

  selectVisitDate = (period) => {
    this.setState({ selectedPeriod: period });
    localStorage.getItem("access_token")
      ? this.setState({ activeStep: 3 })
      : this.setState({ activeStep: 2 });
  };

  handleSelectEmployee = (selectedEmployee) => {
    this.setState({ selectedEmployee: selectedEmployee });
  };

  getStepContent(stepIndex) {
    let optionSalons = this.state.salons.map((salonData) => ({
      value: salonData,
      label: salonData.name,
    }));

    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      const salonName = localStorage.getItem("salon_name");
      optionSalons = optionSalons.filter((salon) => salon.label === salonName);
    }

    switch (stepIndex) {
      case 0:
        return (
          <PublicServiceSelection
            optionSalons={optionSalons}
            selectedSalon={
              accessToken ? optionSalons[0] : this.state.selectedSalon
            }
            handleSelectSalon={this.handleSelectSalon}
            dataTable={this.buildServiceTableData(this.state.services)}
          />
        );
      case 1:
        return (
          <PublicServiceCart
            selectedSalonName={this.state.salon_name}
            handleSelectEmployee={this.handleSelectEmployee}
            salonEmployees={this.state.salonEmployees}
            selectedEmployee={this.state.selectedEmployee}
            selectedService={this.state.selectedService}
            selectVisitDate={this.selectVisitDate}
          />
        );
      case 2:
        return (
          <PublicServiceLogin
            salon_name={this.state.salon_name}
            stepToSumary={() => this.setState({ activeStep: 3 })}
            setLoggedCustomerId={this.setLoggedCustomerId}
          />
        );
      case 3:
        return (
          <ServiceOrderSumary
            customerId={this.state.customer_id}
            salonName={this.state.salon_name}
            selectedEmployee={this.state.selectedEmployee}
            selectedService={this.state.selectedService}
            selectedPeriod={this.state.selectedPeriod}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  render() {
    return (
      <>
        <div className="page-content">
          <HeaderService />
          <Container>
            <Stepper
              className="mb-2"
              activeStep={this.state.activeStep}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {this.getStepContent(this.state.activeStep)}
          </Container>
        </div>
      </>
    );
  }
}

export default PublicServices;
