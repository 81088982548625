import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import Sources from "./Sources";
import RevenueByLocations from "./RevenueByLocations";
import ChatBox from "./ChatBox";
import LatestTransactions from "./LatestTransactions";
import EmployeesStats from "./EmployeesStats";

//Api
import * as getStatistics from "../../Api/Statistics/getStatistics";
import * as getStatistic from "../../Api/Statistics/getStatistics";
import moment from "moment";
import * as visitsAll from "../../Api/Visits/getVisits";
import * as getService from "../../Api/Services/getServices";
import * as getCustomers from "../../Api/Customers/getCustomers";
import Loader from "../../components/Loader/Loader";

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thisMonthReport: {},
      nextMonthReport: {},
      customersTotal: 0,
      visitsTable: [],
      visitsTotalPrice: 0,
      servicesName: [],
      breadcrumbItems: [
        { title: "Visitio", link: "/" },
        { title: "Statystyki", link: "#" },
      ],
      reports: [
        {
          icon: "ri-stack-line",
          title: "Liczba Sprzedaży",
          value: "1452",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-store-2-line",
          title: "Przychód Salonu",
          value: "38452",
          rate: "2.4%",
          desc: "From previous period",
        },
        {
          icon: "ri-briefcase-4-line",
          title: "Średnia Cen",
          value: " 15.4",
          rate: "2.4%",
          desc: "From previous period",
        },
      ],
      raport: "",
      reportFinalized: [],
      reportUnFinalized: [],
      reportAllFuture: [],
      reportDivided: [],
      loading: 0,
      ////
    };
    this.handleStartFilter = this.handleStartFilter.bind(this);
    this.salon_name = localStorage.getItem("salon_name");
  }

  componentDidMount() {
    getStatistics
      .getReport(this.salon_name)
      .then((response) => response.json())
      .then((data) => this.setState({ raport: data, report: data }));

    const thisMonthStart = moment().startOf("month").format("YYYY-MM-DD");
    const thisMonthEnd = moment().endOf("month").format("YYYY-MM-DD");
    const today = moment().format("YYYY-MM-DD");
    const yearBack = moment()
      .subtract(12, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endData = "2100-12-31";
    getStatistics
      .getReportDivided(this.salon_name, yearBack, today)
      .then((response) => response.json())
      .then((data) => this.setState({ reportDivided: data }));
    //sfinalizowane
    getStatistics
      .getReportFilter(this.salon_name, "1", "2020-01-01", today)
      .then((response) => response.json())
      .then((data) => this.setState({ reportFinalized: data }));
    // niezfinalizowane
    getStatistics
      .getReportFilter(this.salon_name, "0", "2020-01-01", today)
      .then((response) => response.json())
      .then((data) => this.setState({ reportUnFinalized: data }));
    const nextMonthStart = moment()
      .add(1, "M")
      .startOf("month")
      .format("YYYY-MM-DD");
    const nextMonthEnd = moment()
      .add(1, "M")
      .endOf("month")
      .format("YYYY-MM-DD");
    getStatistics
      .getReport(this.salon_name, thisMonthStart, thisMonthEnd)
      .then((response) => response.json())
      .then((data) => this.setState({ thisMonthReport: data }));
    getStatistics
      .getReport(this.salon_name, nextMonthStart, nextMonthEnd)
      .then((response) => response.json())
      .then((data) => this.setState({ nextMonthReport: data }));
    getStatistics
      .getReport(this.salon_name, today, endData)
      .then((response) => response.json())
      .then((data) => this.setState({ reportAllFuture: data }));
    visitsAll
      .getVisitLimit(this.salon_name)
      .then((response) => response.json())
      .then((data) => this.setState({ visitsTable: data }));
    getService
      .getSalonServices(this.salon_name)
      .then((response) => response.json())
      .then((data) => this.setState({ servicesName: data }));
    getCustomers.getSalonCustomers(this.salon_name).then((response) => {
      response.json().then((data) => {
        this.setState({ customersTotal: data?.length || 0 });
      });
    });
    this.getRaportServices();
  }

  handleStartFilter = (dateStart, dateEnd) => {
    this.setState({
      loading: 1,
    });

    const today = moment().startOf("day");
    const dStart = moment(dateStart).startOf("day");
    const dEnd = moment(dateEnd).startOf("day");
    const thisToday = moment().format("YYYY-MM-DD");
    const thisDateStart = moment(dateStart).format("YYYY-MM-DD");
    const thisDateEnd = moment(dateEnd).format("YYYY-MM-DD");
    const compareEnd = today.diff(dEnd, "days");
    const compareStart = today.diff(dStart, "days");

    //sfinalizowane
    getStatistics
      .getReportFilter(this.salon_name, "1", thisDateStart, thisDateEnd)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          reportFinalized: data,
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        }),
      )
      .catch(() => {
        this.setState({
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        });
      });

    // niezfinalizowane
    getStatistics
      .getReportFilter(
        this.salon_name,
        "0",
        thisDateStart,
        compareEnd < 0 ? thisToday : thisDateEnd,
      )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          reportUnFinalized: data,
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        }),
      )
      .catch(() => {
        this.setState({
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        });
      });

    getStatistics
      .getReport(this.salon_name, thisDateStart, thisDateEnd)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          raport: data,
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        }),
      )
      .catch(() => {
        this.setState({
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        });
      });

    getStatistics
      .getReportDivided(this.salon_name, thisDateStart, thisDateEnd)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          reportDivided: data,
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        }),
      )
      .catch(() => {
        this.setState({
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        });
      });

    getStatistics
      .getReport(
        this.salon_name,
        compareStart > 0 ? thisToday : thisDateStart,
        thisDateEnd,
      )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          reportAllFuture: data,
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        }),
      )
      .catch(() => {
        this.setState({
          loading:
            this.state.loading === 6 || this.state.loading + 1 === 6
              ? 0
              : this.state.loading + 1,
        });
      });
  };

  getRaportServices = () => {
    const storedUserSalon = localStorage.getItem("salon_name");
    getStatistic.getServices(storedUserSalon).then((response) => {
      response
        .json()
        .then((data) => {
          this.setState({
            servicesData: data.services,
          });
          return data;
        })
        .then((dane) => {
          const visits_price = dane.services.map((d) => d.visits_price);
          const visits_count = dane.services.map((d) => d.visits_count);
          const sum = this.handleCountVisitPrice(visits_price);
          const avarageVisitPrice = this.handleAvarangePrice(
            sum,
            dane.services_total[0].visits_total_count,
          );

          this.setState({
            visitsPrice: visits_price,
            visitsTotalPrice: sum,
            avarangeVisitPrice: avarageVisitPrice,
            visits_count: visits_count.length,
          });
        });
    });
  };

  handleCountVisitPrice = (array) => {
    if (array && array.length > 0) {
      return array?.reduce(function (total, num) {
        return parseFloat(total) + parseFloat(num);
      });
    }

    return 0;
  };

  handleAvarangePrice = (sum, visitsLength) => {
    var avarange = sum / visitsLength;
    var roundNumber = Math.round(avarange * 100) / 100;
    return roundNumber;
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading !== 0 ? <Loader /> : <></>}
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Statystyki"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xl={12}>
                <Row className="mini-widgets-wrapper">
                  <MiniWidgets
                    reports={this.state.reportFinalized}
                    state={this.state}
                    thisMonthReport={this.state.thisMonthReport}
                    nextMonthReport={this.state.nextMonthReport}
                    handleStartFilter={this.handleStartFilter}
                    title="Sfinalizowane"
                  />
                </Row>
                <Row className="mini-widgets-wrapper">
                  <MiniWidgets
                    reports={this.state.reportUnFinalized}
                    state={this.state}
                    thisMonthReport={this.state.thisMonthReport}
                    nextMonthReport={this.state.nextMonthReport}
                    title="Niesfinalizowane"
                  />
                </Row>
                <Row className="mini-widgets-wrapper">
                  <MiniWidgets
                    reports={this.state.reportAllFuture}
                    state={this.state}
                    thisMonthReport={this.state.thisMonthReport}
                    nextMonthReport={this.state.nextMonthReport}
                    title="Prognozy"
                  />
                </Row>
                <Row className="mini-widgets-wrapper">
                  <MiniWidgets
                    reports={this.state.report}
                    state={this.state}
                    thisMonthReport={this.state.thisMonthReport}
                    nextMonthReport={this.state.nextMonthReport}
                    title="Ogólne"
                  />
                </Row>
              </Col>
              <Col xl={12}>
                {/*<Row>*/}
                {/*    <MiniWidgets raport={this.state.raport} />*/}
                {/*</Row>*/}

                {/* revenue Analytics */}
                <RevenueAnalytics
                  salon_name={this.salon_name}
                  reportDivided={this.state.reportDivided}
                />
              </Col>

              <Col xl={12} />
            </Row>

            <Row>
              <Col xl={8} />

              <Col xl={4} />
            </Row>

            <Row>
              <Col xl={12} style={{ paddingBottom: "20px" }}>
                <EmployeesStats raport={this.state.raport} />
              </Col>

              <Col xl={4} />
            </Row>

            <Row>
              {/* latest transactions */}
              <LatestTransactions salon_name={this.salon_name} />
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Stats;
