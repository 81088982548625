import React, { Component } from "react";
import { Card, CardBody, ButtonGroup, Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import moment from "moment";

class RevenueAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "month",
    };
  }

  componentDidMount() {}

  renderDayList() {
    const dayArrayData = [];
    if (
      this?.props?.reportDivided &&
      Object.keys(this?.props?.reportDivided)?.length
    ) {
      Object.keys(this.props.reportDivided).forEach((key, index) => {
        dayArrayData.push({
          day: key,
          visits_total_count:
            this.props.reportDivided[key].visits_total_count || 0,
          services_value_brutto:
            this.props.reportDivided[key].services_value_brutto || 0,
        });
      });
    }

    const seriesDay1 = [];
    const seriesDay2 = [];
    const labelDay = [];

    if (dayArrayData.length) {
      for (let g = 0; g < dayArrayData.length; g++) {
        seriesDay1.push(dayArrayData[g].visits_total_count || 0);
        seriesDay2.push(dayArrayData[g].services_value_brutto || 0);
        labelDay.push(dayArrayData[g].day);
      }

      return {
        series: [
          {
            name: "Wizyty",
            type: "column",
            data: seriesDay1,
          },
          {
            name: "Przychód",
            type: "column",
            data: seriesDay2,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            curve: "straight",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20%",
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          xaxis: {
            labels: {
              rotate: 0,
              style: {
                fontSize: "0",
              },
            },
          },
          yaxis: {
            opposite: true,
          },
          colors: ["#5664d2", "#1cbb8c"],
          labels: labelDay,
        },
      };
    }

    return null;
  }

  renderWeekList() {
    const weekArrayData = [];
    if (
      this?.props?.reportDivided &&
      Object.keys(this?.props?.reportDivided)?.length
    ) {
      let startIndex = 0;
      let sevenIndex = 0;
      Object.keys(this.props.reportDivided).forEach((key) => {
        if (weekArrayData.length === 0) {
          weekArrayData.push({
            week: startIndex,
            weekStart: key,
            weekEnd: "",
            visits_total_count:
              this.props.reportDivided[key].visits_total_count || 0,
            services_value_brutto:
              this.props.reportDivided[key].services_value_brutto || 0,
          });
          sevenIndex = sevenIndex + 1;
        } else {
          if (sevenIndex < 7) {
            weekArrayData[startIndex] = {
              week: startIndex,
              weekStart: weekArrayData[startIndex].weekStart,
              weekEnd: sevenIndex === 6 ? key : "",
              visits_total_count:
                weekArrayData[startIndex].visits_total_count +
                (this.props.reportDivided[key].visits_total_count || 0),
              services_value_brutto:
                weekArrayData[startIndex].services_value_brutto +
                (this.props.reportDivided[key].services_value_brutto || 0),
            };
            sevenIndex = sevenIndex + 1;
          } else {
            weekArrayData.push({
              week: startIndex + 1,
              weekStart: key,
              weekEnd: "",
              visits_total_count:
                this.props.reportDivided[key].visits_total_count || 0,
              services_value_brutto:
                this.props.reportDivided[key].services_value_brutto || 0,
            });
            startIndex = startIndex + 1;
            sevenIndex = 1;
          }
        }
      });
    }

    const seriesWeek1 = [];
    const seriesWeek2 = [];
    const labelWeek = [];

    if (weekArrayData.length) {
      for (let g = 0; g < weekArrayData.length; g++) {
        seriesWeek1.push(weekArrayData[g].visits_total_count || 0);
        seriesWeek2.push(weekArrayData[g].services_value_brutto || 0);
        labelWeek.push(
          `${weekArrayData[g].weekStart} - ${weekArrayData[g].weekEnd}`,
        );
      }

      return {
        series: [
          {
            name: "Wizyty",
            type: "column",
            data: seriesWeek1,
          },
          {
            name: "Przychód",
            type: "column",
            data: seriesWeek2,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: [3, 3],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20%",
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors: ["#5664d2", "#1cbb8c"],
          labels: labelWeek,
          xaxis: {
            labels: {
              style: {
                fontSize: "10px",
                fontWeight: 500,
              },
            },
          },
        },
      };
    }

    return null;
  }

  renderMonthList() {
    const dataNames = [
      "Sty",
      "Lut",
      "Mrz",
      "Kwi",
      "Maj",
      "Cze",
      "Lip",
      "Sie",
      "Wrz",
      "Paź",
      "Lis",
      "Gru",
    ];
    const monthArrayData = [];
    if (
      this?.props?.reportDivided &&
      Object.keys(this?.props?.reportDivided)?.length
    ) {
      let startIndex = 0;
      Object.keys(this.props.reportDivided).forEach((key) => {
        const thisMonth = moment(key).startOf("month").format("M");

        if (monthArrayData.length === 0) {
          monthArrayData.push({
            month: thisMonth,
            visits_total_count:
              this.props.reportDivided[key].visits_total_count || 0,
            services_value_brutto:
              this.props.reportDivided[key].services_value_brutto || 0,
          });
        } else {
          if (monthArrayData[startIndex].month === thisMonth) {
            monthArrayData[startIndex] = {
              month: thisMonth,
              visits_total_count:
                monthArrayData[startIndex].visits_total_count +
                (this.props.reportDivided[key].visits_total_count || 0),
              services_value_brutto:
                monthArrayData[startIndex].services_value_brutto +
                (this.props.reportDivided[key].services_value_brutto || 0),
            };
          } else {
            monthArrayData.push({
              month: thisMonth,
              visits_total_count:
                this.props.reportDivided[key].visits_total_count || 0,
              services_value_brutto:
                this.props.reportDivided[key].services_value_brutto || 0,
            });
            startIndex = startIndex + 1;
          }
        }
      });
    }

    const seriesMonths1 = [];
    const seriesMonths2 = [];
    const labelMonths = [];

    if (monthArrayData.length) {
      for (let g = 0; g < monthArrayData.length; g++) {
        seriesMonths1.push(monthArrayData[g].visits_total_count || 0);
        seriesMonths2.push(monthArrayData[g].services_value_brutto || 0);
        labelMonths.push(dataNames[Number(monthArrayData[g].month) - 1]);
      }

      return {
        series: [
          {
            name: "Wizyty",
            type: "column",
            data: seriesMonths1,
          },
          {
            name: "Przychód",
            type: "column",
            data: seriesMonths2,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: [3, 3],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "20%",
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors: ["#5664d2", "#1cbb8c"],
          labels: labelMonths,
        },
      };
    }

    return null;
  }

  renderData() {
    if (
      this?.props?.reportDivided &&
      Object.keys(this?.props?.reportDivided)?.length
    ) {
      switch (this.state.type) {
        case "day":
          return (
            <ReactApexChart
              options={this.renderDayList()?.options}
              series={this.renderDayList()?.series}
              type="area"
              height="280"
            />
          );
        case "week":
          return (
            <ReactApexChart
              options={this.renderWeekList()?.options}
              series={this.renderWeekList()?.series}
              type="bar"
              height="280"
            />
          );
        case "month":
          return (
            <ReactApexChart
              options={this.renderMonthList()?.options}
              series={this.renderMonthList()?.series}
              type="bar"
              height="280"
            />
          );
        default:
          return null;
      }
    }

    return null;
  }

  render() {
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <div className="float-right d-none d-md-inline-block">
              <ButtonGroup className="mb-2">
                <Button
                  size="sm"
                  color="light"
                  active={this.state.type === "day"}
                  type="button"
                  onClick={() => this.setState({ type: "day" })}
                >
                  Dniowy
                </Button>
                <Button
                  size="sm"
                  color="light"
                  active={this.state.type === "week"}
                  onClick={() => this.setState({ type: "week" })}
                  type="button"
                >
                  Tygodniowy
                </Button>
                <Button
                  size="sm"
                  color="light"
                  active={this.state.type === "month"}
                  onClick={() => this.setState({ type: "month" })}
                  type="button"
                >
                  Miesięczny
                </Button>
              </ButtonGroup>
            </div>
            <h4 className="card-title mb-4">Statystyki wizyt</h4>
            <div>
              <div id="line-column-chart" className="apex-charts" dir="ltr">
                {this.renderData()}
              </div>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default RevenueAnalytics;
