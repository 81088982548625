export const convertTime = (time) => {
  if (isNaN(time)) {
    return "";
  }

  const hours = time >= 60 ? Math.floor(time / 60) : 0;
  const minutes = time > 0 ? time - hours * 60 : time;

  if (hours > 0) {
    if (minutes > 0) {
      return `${hours} h ${minutes} min`;
    }
    return `${hours} h`;
  }

  return `${minutes} min`;
};
