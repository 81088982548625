import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import EmployeesEditForm from "./EmployeesEditForm";

const initialState = {
  form: {
    enabled: 0,
    first_name: undefined,
    last_name: undefined,
    roles: [
      {
        value: "ROLE_EMPLOYEE",
        label: "Employee",
      },
      {
        value: "ROLE_ANALYST",
        label: "Analyst",
      },
    ],
    selectedRoles: [],

    customErrors: {
      username: [],
    },

    username: undefined,
    password: undefined,
  },
};

class EmployeesEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      ...props,
    };
  }

  render() {
    const { onClosed } = this.props;

    return (
      <Modal isOpen={true} size="lg" centered={true} onClosed={onClosed}>
        <ModalHeader toggle={onClosed}>
          Edytuj pracownika: {this.state.form.first_name}{" "}
          {this.state.form.last_name} ({this.state.form.username})
        </ModalHeader>
        <ModalBody>
          <EmployeesEditForm
            {...this.state.form}
            handleAfterValidSubmit={(data) => {
              this.props.handleAfterValidSubmit(data);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={onClosed}
            color="light"
            className="waves-effect"
          >
            Zamknij
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EmployeesEditModal;
