import React, { Component } from "react";
import * as getStocktaking from "../../Api/Stocktaking/getStocktaking";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel/TabPanel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StocktakingTable from "../../components/Stocktaking/StocktakingTable";
import moment from "moment";
import { StocktakingSteper } from "../../components/Stocktaking/StocktakingSteper";
import ActiveStocktakingsTable from "../../components/Stocktaking/ActiveStoctakingsTable";

//API
import * as deleteStocktaking from "../../Api/Stocktaking/deleteStocktaking";

export default class Stocktaking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: 0,
      stocktakingsData: [],
    };

    this.salon_name = localStorage.getItem("salon_name");
  }

  componentDidMount() {
    getStocktaking
      .getSalonStocktakings(this.salon_name)
      .then((response) => response.json())
      .then((data) => this.setState({ stocktakingsData: data }));
  }

  handleTabChange = (event, newTab) => {
    this.setState({ tabValue: newTab });
  };

  handleStocktakingDelete = (id) => {
    deleteStocktaking
      .deleteStocktaking(this.salon_name, id)
      .then((response) => {
        if (response.ok) {
          this.setState((prevState) => ({
            stocktakingsData: prevState.stocktakingsData.filter(
              (stocktaking) => stocktaking.id !== id,
            ),
          }));
        }
      });
  };

  renderManagementTab = (tabIndex) => {
    // let activeStocktakings=this.state.stocktakingsData.filter(stocktaking=>!stocktaking.submitted_at)
    // let submitedStocktakings=this.state.stocktakingsData.filter(stocktaking=>stocktaking.submitted_at)
    switch (tabIndex) {
      case 0:
        return (
          <div className="w-75 m-auto">
            <h2 className="text-center">Inventaryzacja</h2>
            <Divider className="my-3" />
            <h3>Inwentaryzacje w toku</h3>
            <ActiveStocktakingsTable
              handleStocktakingDelete={this.handleStocktakingDelete}
              tableData={this.state.stocktakingsData.filter(
                (stocktaking) => !stocktaking.submitted_at,
              )}
            />
            <Divider className="my-3" />
            <h3>Inwentaryzacje zakończone</h3>
            <StocktakingTable
              tableData={this.state.stocktakingsData.filter(
                (stocktaking) => stocktaking.submitted_at,
              )}
            />
          </div>
        );

      case 1:
        return <StocktakingSteper />;

      default:
        return "Unknown stepIndex";
    }
  };

  render() {
    return (
      <div className="page-content min-vh-100">
        <Paper elevation={0}>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Przeglądaj Inventaryzacje" />
            <Tab label="Sporządź Inventaryzację" />
          </Tabs>

          <TabPanel value={this.state.tabValue} index={0}>
            {this.renderManagementTab(this.state.tabValue)}
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            {this.renderManagementTab(this.state.tabValue)}
          </TabPanel>
        </Paper>
      </div>
    );
  }
}
