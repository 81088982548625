import React, { Component } from "react";
import { Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./dashboard.scss";

class LatestTransactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  createTitleFromServices(visitServices, allServices) {
    return visitServices
      .map((id) => {
        const currentService = allServices.find((s) => s.id === id);
        if (currentService) {
          return currentService.title;
        }
        return "";
      })
      .join(", ");
  }

  render() {
    const data = {
      columns: [
        {
          dataField: "id",
          text: "Numer",
        },
        {
          dataField: "orderName",
          text: "Nazwa wizyty",
        },
        {
          dataField: "billingName",
          text: "Klient",
        },
        {
          dataField: "total",
          text: "Suma",
        },
        {
          dataField: "status",
          text: "Status",
        },
        {
          dataField: "edit",
          text: "Wizyta",
        },
      ],
      rows: this.props.visitsTable?.map((item) => ({
        id: item.id,
        orderName: this.createTitleFromServices(
          item.services,
          this.props.servicesName,
        ),
        edit: (
          <Link
            to={`/visits/${item.id}/edit`}
            className="text-dark font-weight-bold"
          >
            Edytuj
          </Link>
        ),
        billingName: `${item?.customer?.first_name || ""} ${
          item?.customer?.last_name || ""
        }`,
        total: item.total_price_net,
        status: item.is_finalized ? (
          <div className="badge badge-soft-success font-size-12">Zapłacono</div>
        ) : (
          <div className="badge badge-soft-warning font-size-12">
            Niezapłacono
          </div>
        ),
      })),
    };

    const options = {
      // pageStartIndex: 0,
      hideSizePerPage: true,
      hidePageListOnlyOnePage: true,
      // sizePerPageList :
      //   [ {
      //     text: '5th', value: 5
      //   }, {
      //     text: '10th', value: 10
      //   }, {
      //     text: 'All', value: data.rows.length
      //   } ]
    };

    return (
      <React.Fragment>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">Ostatnie rezerwacje</h4>
              <BootstrapTable
                keyField="id"
                data={data.rows}
                columns={data.columns}
                pagination={paginationFactory(options)}
              />
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
  }
}

export default LatestTransactions;
