import { Button } from "reactstrap";
import React, { useState } from "react";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import "react-step-progress/dist/index.css";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
//Api
import * as postVisit from "../../Api/Visits/postVisits";
import moment from "moment";

export const ServiceOrderSumary = (props) => {
  const tpayUrl =
    "https://secure.tpay.com/?id=31155&kwota=47&opis=Abonament%20Visitio&opis_sprzed=Visitio&wyn_email=kontakt%40visitio.pl&email=" +
    localStorage.getItem("username") +
    "&nazwisko=" +
    localStorage.getItem("first_name") +
    "%20" +
    localStorage.getItem("last_name") +
    "&md5sum=9f852e9a52d705e27007ef0be93267be";
  const paymentMethods = [
    { value: "Płatność gotowka", label: "Płatność gotowka" },
    { value: "Tpay", label: "Tpay" },
    { value: "Płatność na miejscu", label: "Płatność na miejscu" },
  ];

  let history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0]);

  function handleConfirmation() {
    const userId = localStorage.getItem("user_id");

    let dataBody = {
      appointment_at: moment(props.selectedPeriod.start).format(
        "YYYY-MM-DD HH:mm:ss",
      ),
      user: props.selectedEmployee.value.id,
      services: [props.selectedService.id],
    };

    // Since an user is 1:(0-1) with customer, the API will get that ID on its own if not passed
    if (!userId) {
      dataBody.customer = props.customerId;
    }

    postVisit
      .createVisit(dataBody, props.salonName)
      .then((response) => response.json())
      .then((data) => {
        history.push("/podziekowanie");
      });
  }

  const handleSelectPayment = (payment) => {
    setPaymentMethod(payment);
  };

  return (
    <Row>
      <Col className="m-auto" lg={6}>
        <Card>
          <CardBody>
            <h3 className="text-center">Podsumowanie</h3>
            <div>
              <h6 className="mt-5">
                Usługa: <b>{props.selectedService.title}</b>{" "}
              </h6>
              <h6 className="">
                Nazwa Salonu: <b>{props.salonName}</b>
              </h6>
              <h6 className="">
                Pracownik:{" "}
                <b>{`${props.selectedEmployee.value.first_name} ${props.selectedEmployee.value.last_name}`}</b>
              </h6>
              <h6 className="">
                Koszt: <b>{props.selectedService.price}</b>
              </h6>
              <h6 className="">
                Data Usługi{" "}
                <b>{`${props.selectedPeriod.start.format(
                  "YYYY-MM-DD HH:mm:ss",
                )} - ${props.selectedPeriod.end.format("HH:mm")}`}</b>
              </h6>
              <Select
                className="w-50 mb-2"
                value={paymentMethod}
                onChange={(selectedPayment) =>
                  handleSelectPayment(selectedPayment)
                }
                options={paymentMethods}
                classNamePrefix="select2-selection"
              />
              <Button
                className="float-right"
                onClick={handleConfirmation}
                color="success"
              >
                Potwierdź proces zakupu
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
