import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import Select from "react-select";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../../store/actions";

// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// SWEET ALERT2
import Swal from "sweetalert2";

// IMPORT CUSTOMER REGISTER

//Api
import * as postUsers from "../../../Api/Users/postUsers";
import * as getSalons from "../../../Api/Salons/getSalons";

class SteperRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      password: "",
      isOwnerForm: false,
      isCustomerRegister: false,
      salon_name_regex: /^[A-Za-z0-9](?:[A-Za-z0-9\-_]{0,18}[A-Za-z0-9])?$/i,
      checkValid: false,
      salon_name: null,
      salons: [],
    };
    // this.handleRegisterUser = this.handleRegisterUser.bind(this);
    this.handleSelectSalon = this.handleSelectSalon.bind(this);
  }

  //Select
  handleSelectSalon = (selectedSalonName) => {
    // localStorage.setItem('salon_name', this.setState({salon_name: selectedSalonName.label}));
    this.setState({ salon_name: selectedSalonName.label });
    localStorage.setItem("salon_name", selectedSalonName.label);
  };

  // handleRegisterUser=()=>{
  //     const first_name = document.querySelector('#first_name');
  //     const last_name = document.querySelector('#last_name');
  //     const salon_locality = document.querySelector('#salon_locality');
  //     const salon_name = document.querySelector('#salon_name');
  //     const ownerpassword = document.querySelector('#ownerpassword');
  //     const ownermail = document.querySelector('#ownermail');
  //     const salon_created_at = document.querySelector('#salon_created_at');
  //     const confirm_ownerpassword = document.querySelector('#confirm_ownerpassword');
  //     const { checkValid, salon_name_regex } = this.state;

  //     let data = {
  //         first_name: first_name.value,
  //         last_name: last_name.value,
  //         salon_locality: salon_locality.value,
  //         salon_name: salon_name.value,
  //         password: (ownerpassword.value === confirm_ownerpassword.value ) ? ownerpassword.value : null ,
  //         username: ownermail.value,
  //         salon_created_at: salon_created_at.value,
  //     }

  //     const registerAlert = Swal.mixin({
  //         customClass: {
  //             confirmButton: 'btn btn-success',
  //         },
  //         buttonsStyling: false,
  //     });
  //         postUsers.registerNewOwner(data,this.props.salonName)
  //         .then( response =>{
  //             if(response.status === 201){
  //                 registerAlert.fire({
  //                     title: 'Sukces',
  //                     text: 'Konto zostało pomyślnie utworzone.',
  //                     icon: 'success',
  //                     confirmButtonText: 'Ok',
  //                 }).then( result =>{
  //                     if(result.isConfirmed){
  //                         window.location.replace(process.env.REACT_APP_HOST + '/login');
  //                     }
  //                 })
  //             }
  //             else if(response.status === 400){
  //                 return Swal.fire({
  //                     icon: 'error',
  //                     title: 'Oops...',
  //                     text: 'Niepoprawne dane lub nazwa jest zajęta!',
  //                 });
  //             }
  //         })

  // }

  getSalons() {
    getSalons.getAllSalons().then((response) => {
      response.json().then((data) => {
        if (response.status === 200) {
          let apiData = data.map((salon) => {
            return {
              id: salon.id,
              name: salon.name,
              locality: salon.locality,
              created_at: salon.created_at,
            };
          });
          this.setState({
            salons: apiData,
          });
        } else if (response.status === 401) {
          return null;
        }
      });
    });
  }

  handleRegisterCustomer = () => {
    const first_name = document.querySelector("#first_name");
    const last_name = document.querySelector("#last_name");
    const ownerpassword = document.querySelector("#ownerpassword");
    const ownermail = document.querySelector("#ownermail");
    // const confirm_ownerpassword = document.querySelector('#confirm_ownerpassword');
    // const { checkValid } = this.state;
    let data = {
      first_name: first_name.value,
      last_name: last_name.value,
      password: ownerpassword.value,
      // password: (ownerpassword.value === confirm_ownerpassword.value ) ? ownerpassword.value : null ,
      username: ownermail.value,
    };
    const registerAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });
    // if(checkValid){
    postUsers.registerCustomer(data, this.props.salonName).then((response) => {
      if (response.status === 201) {
        registerAlert
          .fire({
            title: "Sukces",
            text: "Konto zostało pomyślnie utworzone. Wysłaliśmy maila z linkiem potwierdzającym założenie konta. Sprawdź swoją pocztę.",
            icon: "success",
            confirmButtonText: "Ok",
          })
          .then((result) => {
            if (result.isConfirmed) {
              // window.location.replace(process.env.REACT_APP_HOST + '/login');
              this.props.showLoginForm();
            }
          });
      } else if (response.status === 400) {
        return Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Niepoprawne dane lub nazwa jest zajęta!",
        });
      }
    });
    // }
    // else
    // {
    //     return Swal.fire({
    //         icon: 'error',
    //         title: 'Oops...',
    //         text: 'Niepoprawna nazwa salonu!',
    //     });
    // }
  };

  componentDidMount() {
    this.getSalons();
    this.props.registerUserFailed("");
    this.props.apiError("");
    document.body.classList.add("auth-body-bg");
  }

  render() {
    const { salons } = this.state;

    let optionSalons = [
      ...salons.map((salonData) => ({
        value: salonData.id,
        label: salonData.name,
      })),
    ];

    return (
      <Row className="no-gutters">
        <Col lg={12}>
          <div className=" d-flex align-items-center ">
            <div className="w-100">
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div>
                    {this.props.user && (
                      <Alert color="success">
                        Registration Done Successfully.
                      </Alert>
                    )}

                    {this.props.registrationError && (
                      <Alert color="danger">
                        {this.props.registrationError}
                      </Alert>
                    )}

                    <div className="p-2 mt-5">
                      <FormGroup className="d-flex flex-column align-items-center mb-5">
                        <h4 className="text-center m-3">
                          Założ konto już teraz!
                        </h4>
                      </FormGroup>
                      {this.state.isCustomerRegister && (
                        <FormGroup className="select-container d-flex flex-column justify-center align-center mb-5">
                          <h6 className="text-center m-3">Wybierz Salon</h6>
                          <Select
                            className="m-auto select-salon-field"
                            onChange={this.handleSelectSalon}
                            options={optionSalons}
                            classNamePrefix="select2-selection"
                            isSearchable={false}
                          />
                        </FormGroup>
                      )}
                      <AvForm
                        onValidSubmit={this.handleRegisterCustomer}
                        className="form-horizontal"
                      >
                        <Container>
                          <Row>
                            <Col xl={6}>
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon" />
                                <Label htmlFor="first_name">Imię</Label>
                                <AvField
                                  name="first_name"
                                  type="text"
                                  className="form-control"
                                  id="first_name"
                                  validate={{ required: true }}
                                  placeholder="Wprowadź nazwę"
                                />
                              </FormGroup>
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon" />
                                <Label htmlFor="ownerpassword">Hasło</Label>
                                <AvField
                                  name="ownerpassword"
                                  type="password"
                                  className="form-control"
                                  id="ownerpassword"
                                  validate={{ required: true }}
                                  placeholder="Wprowadź hasło"
                                />
                              </FormGroup>
                            </Col>
                            <Col xl={6}>
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-file-user-fill auti-custom-input-icon" />
                                <Label htmlFor="last_name">Nazwisko</Label>
                                <AvField
                                  name="last_name"
                                  type="text"
                                  className="form-control"
                                  id="last_name"
                                  validate={{ required: true }}
                                  placeholder="Wprowadź nazwisko"
                                />
                              </FormGroup>
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-mail-line auti-custom-input-icon" />
                                <Label htmlFor="ownermail">Adres Email</Label>
                                <AvField
                                  name="ownermail"
                                  type="text"
                                  className="form-control"
                                  id="ownermail"
                                  validate={{ email: true, required: true }}
                                  placeholder="Wprowadź adres e-mail"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Container>

                        <div className="text-center">
                          <Button
                            color="primary"
                            className="w-md waves-effect waves-light fluid register-btn"
                            type="submit"
                          >
                            {this.props.loading
                              ? "Loading ..."
                              : "Zarejestruj się"}
                          </Button>
                        </div>

                        <div className="mt-4 text-center">
                          <p className="mb-0">
                            Wyrażam zgodę na przetwarzanie danych osobowych
                            zgodnie z{" "}
                            <Link to="#" className="text-primary">
                              Regulaminem
                            </Link>
                          </p>
                        </div>
                      </AvForm>
                    </div>
                    <div className="mt-5 text-center">
                      <p
                        className="font-weight-medium text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => this.props.showLoginForm()}
                      >
                        Masz już konto? Zaloguj się{" "}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(SteperRegistration);
