import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function EmployeesStats(props) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Imie i nazwisko</TableCell>
            <TableCell align="right">Liczba Wizyt</TableCell>
            <TableCell align="right">Łączny czas wizyt</TableCell>
            <TableCell align="right">Wartość brutto&nbsp;(pln)</TableCell>
            <TableCell align="right">Wartość netto&nbsp;(g)</TableCell>
            <TableCell align="right">Przychód&nbsp;(pln)</TableCell>
            <TableCell align="right">Procent przychodu&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.raport
            ? Object.entries(props.raport.employees)
                .map((e) => e[1])
                .map((row) => (
                  <TableRow key={row.user_id}>
                    <TableCell component="th" scope="row">
                      {`${row.first_name} ${row.last_name}`}
                    </TableCell>
                    <TableCell align="right">{row.visits_count}</TableCell>
                    <TableCell align="right">{row.visits_time}</TableCell>
                    <TableCell align="right">
                      {row.services_value_brutto}
                    </TableCell>
                    <TableCell align="right">
                      {row.services_value_netto}
                    </TableCell>
                    <TableCell align="right">{row.cash_income}</TableCell>
                    <TableCell align="right">{row.percent}</TableCell>
                  </TableRow>
                ))
            : ""}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
