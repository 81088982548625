// Lists all Warehouse Products by Warehouse ID
export const getStockList = async (warehouse_id) => {
    return await fetch(`${process.env.REACT_APP_HOST_API}/api/v1/warehouse-product-stock/warehouses/${warehouse_id}`, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
};

// Lists all Warehouse Products in the Workspace
export const getProductStockInWorkspace = async () => {
    return await fetch(`${process.env.REACT_APP_HOST_API}/api/v1/warehouse-product-stock`, {
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
    });
};
