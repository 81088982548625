import React, { Component } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

//Import images
import successImg from "../../assets/images/payment-successful.png";

class ThankYouPage extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="my-5 pt-5">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center my-5">
                  <h1 className="font-weight-bold text-error">
                    <img src={successImg} alt="" className="error-img" />{" "}
                  </h1>
                  <h3 className="text-uppercase mt-5">
                    Dziękujemy za zamówienie usługi
                  </h3>
                  <div className="mt-5 text-center">
                    <Link to="/">
                      <Button color="success">Powrót do panelu</Button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default ThankYouPage;
