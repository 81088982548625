import React, { Component } from "react";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Button, Col, FormGroup, Label } from "reactstrap";
import Swal from "sweetalert2";

import * as postMail from "../../Api/Help/postMail";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      subject: "",
      content: "",
      salon: "",
    };
    this.handleSendMail = this.handleSendMail.bind(this);
  }

  handleSendMail() {
    const email = document.querySelector("#email");
    const subject = document.querySelector("#subject");
    const content = document.querySelector("#content");
    const salon_name = localStorage.getItem("salon_name");

    let data = {
      email: email.value,
      subject: subject.value,
      content: content.value,
      salon: salon_name,
    };

    const registerAlert = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
      },
      buttonsStyling: false,
    });

    postMail
      .postMail(data, localStorage.getItem("salon_name"))
      .then((response) => {
        if (response.status === 201) {
          registerAlert.fire({
            title: "Sukces",
            text: "Mail został wysłany. Postaramy się jak najszybciej udzielić odpowiedzi.",
            icon: "success",
            confirmButtonText: "Ok",
          });
        } else {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Upewnij się, czy wypełniłeś wszystkie pola.",
          });
        }
      });
  }

  render() {
    return (
      <div className="container page-content min-vh-100">
        <Col xs={12}>
          <h1 className={"pb-3"}>Zgłoś problem</h1>
        </Col>
        <AvForm id="userLoginForm" className="form-horizontal">
          <FormGroup className="auth-form-group-custom mb-4">
            <i className="ri-user-2-line auti-custom-input-icon" />
            <Label htmlFor="email">Adres Email</Label>
            <AvField
              name="email"
              type="text"
              className="form-control"
              id="email"
              validate={{ email: true, required: true }}
              placeholder="Wprowadź adres e-mail w przypadku, gdybyśmy mieli się z Tobą skontaktować"
            />
          </FormGroup>

          <FormGroup className="auth-form-group-custom mb-4">
            <Label htmlFor="subject">Temat</Label>
            <AvField
              name="subject"
              type="text"
              className="form-control"
              id="subject"
              placeholder="Podaj temat"
            />
          </FormGroup>

          <FormGroup className="auth-form-group-custom mb-4">
            <Label htmlFor="content">Wiadomość</Label>
            <AvField
              name="content"
              type={"textarea"}
              style={{ height: 200 }}
              className="form-control"
              id="content"
              placeholder="Napisz do nas wiadomość"
            />
          </FormGroup>

          <div className="mt-4 text-center">
            <Button
              onClick={this.handleSendMail}
              color="primary"
              className="w-md waves-effect waves-light fluid login-btn p-3"
              type="submit"
            >
              Wyślij
            </Button>
          </div>
        </AvForm>
      </div>
    );
  }
}

export default Help;
