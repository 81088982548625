import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_RESET_PASSWORD, FORGET_USER } from "./actionTypes";
import { forgetUserSuccessful, userForgetPasswordError } from "./actions";

// AUTH related methods
import { postForgetPwd } from "../../../helpers/fackBackend_Helper";
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import { resetUserPassword } from "../../../Api/Users/postUsers";

//Initilize firebase
const fireBaseBackend = getFirebaseBackend();

//If user is login then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.forgetPassword,
        user.useremail,
      );
      if (response) {
        yield put(
          forgetUserSuccessful(
            "Jeśli istnieje konto o podanym adresie, to zostanie wysłany mail z przypomnieniem hasła.",
          ),
        );
      }
    } else {
      const response = yield call(
        postForgetPwd,
        `${process.env.REACT_APP_HOST_API}/send-email`,
        { username: user.useremail },
      );
      if (response) {
        yield put(
          forgetUserSuccessful(
            "Jeśli istnieje konto o podanym adresie, to zostanie wysłany mail z przypomnieniem hasła.",
          ),
        );
      }
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* forgetResetPassword({ payload: { password, history } }) {
  const token = new URLSearchParams(history.location.search).get("token");
  if (token) {
    const response = yield resetUserPassword(
      {
        password: password,
      },
      token,
    );

    if (response.status === 404) {
      yield put(
        userForgetPasswordError(
          "Nie udało się zmienić hasła, token jest nieprawidłowy lub nie istnieje - wygeneruj nowy email z linkiem do resetu hasła",
        ),
      );
    }

    if (response.status >= 200 && response.status < 300) {
      yield put(
        forgetUserSuccessful(
          "Twoje hasło zostało zmienione, możesz zalogować się z nowymi danymi",
        ),
      );

      setTimeout(function () {
        history.push("/login");
      }, 3000);
    }
  }
}

export function* watchUserForget() {
  yield takeEvery(FORGET_USER, forgetUser);
  yield takeEvery(FORGET_RESET_PASSWORD, forgetResetPassword);
}

function* forgetSaga() {
  yield all([fork(watchUserForget)]);
}

export default forgetSaga;
