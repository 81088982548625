import {
  FORGET_USER,
  FORGET_USER_SUCCESSFUL,
  FORGET_PASSWORD_API_FAILED,
  FORGET_RESET_PASSWORD,
} from "./actionTypes";

export const resetUserPassword = (password, token, history) => {
  return {
    type: FORGET_RESET_PASSWORD,
    payload: { password, token, history },
  };
};

export const forgetUser = (user, history) => {
  return {
    type: FORGET_USER,
    payload: { user, history },
  };
};

export const forgetUserSuccessful = (message) => {
  return {
    type: FORGET_USER_SUCCESSFUL,
    payload: message,
  };
};

export const userForgetPasswordError = (error) => {
  return {
    type: FORGET_PASSWORD_API_FAILED,
    payload: error,
  };
};
