import React, { Component } from "react";
import * as getUsers from "../../Api/Users/getUsers";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/TabPanel/TabPanel";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataGrid } from "@material-ui/data-grid";
import {
  Form,
  FormGroup,
  Label,
  Input,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";

import "./SalonManagement.scss";

const columns = [
  {
    field: "username",
    headerName: "Nazwa Użytkownika",
    width: 300,
    editable: true,
  },
  { field: "first_name", headerName: "Imie", width: 300, editable: true },
  { field: "last_name", headerName: "Nazwisko", width: 300, editable: true },

  {
    field: "roles",
    headerName: "Rola",
    type: "dateTime",
    width: 450,
    editable: true,
  },
];

export default class SalonManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      salonUsers: [],
    };
    this.salon_name = localStorage.getItem("salon_name");
    this.salon_names = localStorage.getItem("user_salons");
    this.handleSetMail = this.handleSetMail.bind(this);
  }

  componentDidMount() {
    getUsers
      .getUsersBySalonName(this.salon_name)
      .then((response) => response.json())
      .then((data) => this.setState({ salonUsers: data }));
  }

  handleTabChange = (event, newTab) => {
    this.setState({ value: newTab });
  };

  handleSetMail() {
    console.log("click!");
  }

  renderManagementTab = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return (
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Administratorzy</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.salonUsers.length > 0 ? (
                  <div className="user-data-wraper">
                    <DataGrid
                      rows={this.state.salonUsers.filter((user) =>
                        user.roles.includes("ROLE_OWNER"),
                      )}
                      columns={columns}
                    />
                  </div>
                ) : (
                  "Brak"
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Pracownicy</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.salonUsers.length > 0 ? (
                  <div className="user-data-wraper">
                    <DataGrid
                      rows={this.state.salonUsers.filter((user) =>
                        user.roles.includes("ROLE_EMPLOYEE"),
                      )}
                      columns={columns}
                    />
                  </div>
                ) : (
                  "Brak"
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography>Klienci</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {this.state.salonUsers.length > 0 ? (
                  <div className="user-data-wraper">
                    <DataGrid
                      rows={this.state.salonUsers.filter((user) =>
                        user.roles.includes("ROLE_CUSTOMER"),
                      )}
                      columns={columns}
                    />
                  </div>
                ) : (
                  "Brak"
                )}
              </AccordionDetails>
            </Accordion>
          </>
        );
      case 1:
        return (
          <div>
            <Button variant="outlined" color="primary">
              Stwórz nowy salon
            </Button>
            <h2 className="mt-5 mb-5">Twoje Salony</h2>
            <Divider className="mb-5" variant="middle" />
            <div className="salon-wraper mb-5">
              {this.salon_names
                ? this.salon_names.split(",").map((salon) => (
                    <Accordion key={salon}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography>{salon}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          INFORMACJE O SALONIE I OPCJE EDYCJI
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))
                : ""}
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <h2 className="mt-5 mb-5">Ustawienia</h2>
            <Divider className="mb-5" variant="middle" />
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>POMOC</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CardBody>
                    <CardTitle>E-mail</CardTitle>
                    <CardSubtitle className="mb-3">
                      Zmień adres, na który wysyłane będą wiadomości z zakładki
                      "Pomoc".
                    </CardSubtitle>
                    <Form>
                      <FormGroup>
                        <Label for="Email">E-mail</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Adres E-mail"
                        />
                      </FormGroup>
                      <Button onClick={this.handleSetMail}>Zapisz</Button>
                    </Form>
                  </CardBody>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  };

  render() {
    return (
      <div className="page-content min-vh-100">
        <Paper>
          <Tabs
            value={this.state.value}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Użytkownicy" />
            <Tab label="Salony" />
            <Tab label="Ustawienia" />
          </Tabs>

          <TabPanel value={this.state.value} index={0}>
            {this.renderManagementTab(this.state.value)}
          </TabPanel>
          <TabPanel value={this.state.value} index={1}>
            {this.renderManagementTab(this.state.value)}
          </TabPanel>
          <TabPanel value={this.state.value} index={2}>
            {this.renderManagementTab(this.state.value)}
          </TabPanel>
        </Paper>
      </div>
    );
  }
}
