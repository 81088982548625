import React, { Component } from "react";
import EmployeesCreateForm from "./EmployeesCreateForm";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const initialState = {
  form: {
    enabled: 0,
    first_name: undefined,
    last_name: undefined,
    roles: [
      {
        value: "ROLE_EMPLOYEE",
        label: "Employee",
      },
      {
        value: "ROLE_ANALYST",
        label: "Analyst",
      },
    ],
    username: undefined,
    password: undefined,
  },
};

class EmployeesCreateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };

    console.log("[EmployeesCreateModal] props", props);
    console.log("[EmployeesCreateModal] state", this.state);
    console.log("[EmployeesCreateModal] form", this.state.form);
  }

  render() {
    const { onClosed } = this.props;

    return (
      <Modal isOpen={true} size="md" centered={true} onClosed={onClosed}>
        <ModalHeader toggle={onClosed}>Utwórz nowego pracownika</ModalHeader>
        <ModalBody>
          <EmployeesCreateForm
            {...this.state.form}
            handleAfterValidSubmit={(data) => {
              this.props.handleAfterValidSubmit(data);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            onClick={onClosed}
            color="light"
            className="waves-effect"
          >
            Zamknij
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EmployeesCreateModal;
