import Select from "react-select";

import React from "react";
import { Card, CardBody, Col, Row, FormGroup } from "reactstrap";
import { MDBDataTable, MDBBtn } from "mdbreact";
import translations from "../../helpers/translations/pl";

export const PublicServiceSelection = (props) => {
  return (
    <Row>
      <Col xs={12}>
        <Card>
          <CardBody>
            <FormGroup className="select-container w-25 d-flex flex-column justify-center align-center">
              <h2>Wybierz Salon</h2>
              <Select
                value={props.selectedSalon}
                onChange={(selectedSalon) => {
                  props.handleSelectSalon(selectedSalon);
                }}
                options={props.optionSalons}
                classNamePrefix="select2-selection"
                isSearchable={false}
              />
            </FormGroup>
            <MDBDataTable
              className="mt-5"
              responsive
              striped
              bordered
              data={props.dataTable}
              {...translations.datatable}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
