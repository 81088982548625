import React, { useContext } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { UserSalonsContext } from "../../providers/UserSalonsContext";
import * as getSelectedData from "../../Api/Salons/getSelectedSalon";

export const SalonSelect = (props) => {
  const { setSelectedSalon } = useContext(UserSalonsContext);

  const handleSalonChange = (event) => {
    setSelectedSalon(event.target.value);
    getSelectedData
      .getSalon(event.target.value)
      .then((response) => response.json())
      .then((response) => {
        const slotLength = response?.slot_length || 15;
        localStorage.setItem("salon_slot_min", response.slot_min_time);
        localStorage.setItem("salon_slot_max", response.slot_max_time);
        localStorage.setItem("salon_slot_interval", slotLength);
        localStorage.setItem(
          "salon_min_reservation_length",
          response.min_reservation_length,
        );

        if (props.redirect) {
          // TODO: Zmienić to potem
          // window.location.replace(process.env.REACT_APP_HOST + props.redirect);
          window.location.replace(props.redirect);
        } else {
          window.location.reload();
        }
      });
  };

  const findFullName = (name) => {
    const sortedUserSalonsLabel = JSON.parse(
      localStorage.getItem("user_salons_labels") || "[]",
    );

    return sortedUserSalonsLabel.filter((item) => item.name === name)[0]?.label;
  };

  return (
    <UserSalonsContext.Consumer>
      {({ salons, selectedSalon }) => {
        if (!Array.isArray(salons)) {
          salons = salons.split(",");
        }
        return (
          <FormControl variant="outlined" className={props.formClassNames}>
            {props.withLabel ? (
              <InputLabel id="demo-simple-select-outlined-label">
                Zmień Salon
              </InputLabel>
            ) : (
              ""
            )}
            <Select
              className={props.selectClassNames}
              id="demo-simple-select-outlined"
              value={selectedSalon}
              onChange={handleSalonChange}
              label="Salon"
            >
              {salons.map((salon) => (
                <MenuItem value={salon} key={salon}>
                  {findFullName(salon)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }}
    </UserSalonsContext.Consumer>
  );
};
