import React, { Component } from "react";
import FullCalendar from "@fullcalendar/react";

import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import * as getUsers from "../../Api/Users/getUsers";
import * as getVisits from "../../Api/Visits/getVisits";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Card, CardBody, Col } from "reactstrap";
import moment from "moment";

const defaultState = {
  resources: [],
  events: [],
};

/**
 * Instance for testing simple configurations
 */
export default class Calendar2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
    this.salon_name = localStorage.getItem("salon_name");
    this.loadEvents = this.loadEvents.bind(this);
  }

  componentDidMount() {
    this.loadResources();
    this.loadEvents();
  }

  async loadEvents(dateStart, dateEnd) {
    const startMoment = moment(dateStart);
    const endMoment = moment(dateEnd);

    const targetsOneDay =
      startMoment.add(1, "d").format("Y-M-d") === endMoment.format("Y-M-d");

    let start = moment(dateStart).format("Y-M-d", { trim: false });
    let end = moment(dateEnd).format("Y-M-d", { trim: false });

    if (targetsOneDay) {
      start = moment(dateStart).format("Y-M-d", { trim: false }) + " 00:00:00";
      end = moment(dateStart).format("Y-M-d", { trim: false }) + " 23:59:59";
    }

    const response = await getVisits.getVisitsBetweenDates(
      this.salon_name,
      "2021-06-07",
      "2021-06-08",
    );
    if (response.headers.get("content-type") === "application/json") {
      const data = await response.json();
      const events = [
        ...data.map((visit) => ({
          title: visit.service.title,
          start: moment(new Date(visit.appointment_at)).format(),
          end: moment(new Date(visit.appointment_at_end_max)).format(),
          resourceId: +visit.user.id,
        })),
      ];

      this.setState({
        events: events,
      });
    }
    return response;
  }

  async loadResources() {
    const response = await getUsers.getSalonEmployes(this.salon_name);
    if (response.headers.get("content-type") === "application/json") {
      const data = await response.json();
      this.setState({
        resources: data.map((item) => ({
          id: +item.id,
          title: `${item.first_name} ${item.last_name}`,
        })),
      });
    }
    return response;
  }

  onViewMount = (viewObject, element) => {
    const view = viewObject.view;
    if (this.loadEvents && view.currentStart && view.currentEnd) {
      this.loadEvents(
        moment(view.currentStart).format("Y-MM-DD", { trim: false }),
        moment(view.currentEnd).format("Y-MM-DD", { trim: false }),
      );
    }
    setTimeout(() => {
      viewObject.el
        .querySelectorAll(".fc-col-header-cell-cushion")
        .forEach((element) => {
          element.innerHTML = element.innerHTML + "<br>6:00-19:00</br>";
        });
    }, 250);
  };

  render() {
    return (
      <Col xs={12} className={"pt-5"}>
        <Card className={"pt-5"}>
          <CardBody className={"pt-5"}>
            <FullCalendar
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay,recepcja",
              }}
              initialView={"recepcja"}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                resourceTimeGridPlugin,
              ]}
              slotMinTime={"6:00:00"}
              slotMaxTime={"19:00:00"}
              weekNumbers={true}
              allDaySlot={true}
              allDayText={"Dzień"}
              weekNumberFormat={{ week: "short" }}
              views={{
                recepcja: {
                  type: "resourceTimeGrid",
                  buttonText: "Recepcja",
                },
              }}
              resources={this.state.resources}
              events={this.state.events}
              viewDidMount={this.onViewMount}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }
}
